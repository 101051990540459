<template>
<div>
  <div v-if="isLoading">
    <CanPayLoader/>
  </div>
  <div class="container splash">
    <cp-onboardingheader></cp-onboardingheader>

    <div v-if="!isLoading">
      <div class="row heading-row">
        <div class="col-2"></div>

        <div class="col-8">
          <label class="success-text-onboarding quickaccesspin-heading">
            Enter your 4-digit
            <br />Quick Access PIN
          </label>
        </div>
        <div class="col-2"></div>
      </div>
      <!-- sub heading row -->
      <div class="row sub-heading-row">
        <div class="col-1"></div>
        <div class="col-10">
          <label class="success-description-label quickaccesspin-sub-heading">
            Enter your existing PIN or
            <br />create a new PIN
          </label>
        </div>
        <div class="col-1"></div>
      </div>
      <!-- label 1 -->
      <div class="row justify-content-center align-self-center">
        <div class="col-1"></div>
        <div class="col-10">
          <label class="success-description-label quickaccesspin-label"
            >Please enter a 4 digit PIN</label
          >
        </div>
        <div class="col-1"></div>
      </div>
      <!-- pin row -->
      <div class="row">
        <div class="col-1"></div>
        <div id="pin_div" class="col-10 m-l-7">
          <input
            id="pin1"
            autocomplete="nope"
            class="
              form-control
              ssn-text ssn-input-box-padding
              margin-pin-asterisk
              quickaccesspin-input-box-color
            "
            v-on:keyup="isNumber($event)"
            type="text"
            maxlength="1"
            inputmode="numeric"
          />
          <input
            id="pin2"
            autocomplete="nope"
            class="
              form-control
              ssn-text ssn-input-box-padding
              margin-pin-asterisk
              quickaccesspin-input-box-color
            "
            v-on:keyup="isNumber($event)"
            type="text"
            maxlength="1"
            inputmode="numeric"
          />
          <input
            id="pin3"
            autocomplete="nope"
            class="
              form-control
              ssn-text ssn-input-box-padding
              margin-pin-asterisk
              quickaccesspin-input-box-color
            "
            v-on:keyup="isNumber($event)"
            type="text"
            maxlength="1"
            inputmode="numeric"
          />
          <input
            id="pin4"
            autocomplete="nope"
            class="
              form-control
              ssn-text ssn-input-box-padding
              margin-pin-asterisk
              quickaccesspin-input-box-color
            "
            v-on:keyup="isNumber($event)"
            type="text"
            maxlength="1"
            inputmode="numeric"
          /><span
            class="input-group-addon"
            style="vertical-align: super"
            v-on:click="switchVisibility"
          >
            <a>
              <i
                class="fa fa-eye-slash eye-icon onboarding-color-black"
                aria-hidden="true"
                v-if="!show"
              ></i>
              <i
                class="fa fa-eye eye-icon onboarding-color-black"
                aria-hidden="true"
                v-if="show"
              ></i>
            </a>
          </span>
        </div>
        <div id="pin_view_div" class="col-10 m-l-7 visibility">
          <input
            id="pin1_view"
            v-model="pin1"
            class="
              form-control
              ssn-text ssn-input-box-padding
              quickaccesspin-input-box-color
            "
            v-on:keyup="isNumber($event)"
            type="text"
            maxlength="1"
            inputmode="numeric"
            readonly
          />
          <input
            id="pin2_view"
            v-model="pin2"
            class="
              form-control
              ssn-text ssn-input-box-padding
              quickaccesspin-input-box-color
            "
            v-on:keyup="isNumber($event)"
            type="text"
            maxlength="1"
            inputmode="numeric"
            readonly
          />
          <input
            id="pin3_view"
            v-model="pin3"
            class="
              form-control
              ssn-text ssn-input-box-padding
              quickaccesspin-input-box-color
            "
            type="text"
            maxlength="1"
            inputmode="numeric"
            readonly
          />
          <input
            id="pin4_view"
            v-model="pin4"
            class="
              form-control
              ssn-text ssn-input-box-padding
              quickaccesspin-input-box-color
            "
            type="text"
            maxlength="1"
            inputmode="numeric"
            readonly
          />
          <span class="input-group-addon" style="vertical-align: super">
            <a v-on:click="switchVisibility">
              <i
                class="fa fa-eye-slash eye-icon onboarding-color-black"
                aria-hidden="true"
                v-if="!show"
              ></i>
              <i
                class="fa fa-eye eye-icon onboarding-color-black"
                aria-hidden="true"
                v-if="show"
              ></i>
            </a>
          </span>
        </div>
        <div
          class="
            col-1
            eye-icon-div
            d-flex
            align-items-center
            justify-content-center
          "
        >
          <!-- <a v-on:click="switchVisibility">
          <i class="fa fa-eye-slash eye-icon" aria-hidden="true" v-if="!show"></i>
          <i class="fa fa-eye eye-icon" aria-hidden="true" v-if="show"></i>
        </a> -->
        </div>
        <input type="hidden" id="pin_current_state" value="0" />
      </div>
      <!-- label 2 -->
      <div class="row justify-content-center align-self-center">
        <div class="col-1"></div>
        <div class="col-10">
          <label class="success-description-label quickaccesspin-label-two"
            >Re-enter 4 digit PIN</label
          >
        </div>
        <div class="col-1"></div>
      </div>
      <!-- pin row 2 -->
      <div class="row">
        <div class="col-1"></div>
        <div id="re_enter_pin_div" class="col-10 m-l-7">
          <input
            id="re_enter_pin1"
            ref="reenterpin1"
            autocomplete="nope"
            class="
              form-control
              ssn-text ssn-input-box-padding
              margin-pin-asterisk
              quickaccesspin-input-box-color
            "
            v-on:keyup="isNumber($event)"
            type="text"
            maxlength="1"
            inputmode="numeric"
          />
          <input
            id="re_enter_pin2"
            autocomplete="nope"
            class="
              form-control
              ssn-text ssn-input-box-padding
              margin-pin-asterisk
              quickaccesspin-input-box-color
            "
            v-on:keyup="isNumber($event)"
            type="text"
            maxlength="1"
            inputmode="numeric"
          />
          <input
            id="re_enter_pin3"
            autocomplete="nope"
            class="
              form-control
              ssn-text ssn-input-box-padding
              margin-pin-asterisk
              quickaccesspin-input-box-color
            "
            v-on:keyup="isNumber($event)"
            type="text"
            maxlength="1"
            inputmode="numeric"
          />
          <input
            id="re_enter_pin4"
            autocomplete="nope"
            class="
              form-control
              ssn-text ssn-input-box-padding
              margin-pin-asterisk
              quickaccesspin-input-box-color
            "
            v-on:keyup="isNumber($event)"
            @keyup.enter="clickLoginPin"
            type="text"
            maxlength="1"
            inputmode="numeric"
          />
          <span class="input-group-addon" style="vertical-align: super">
            <a v-on:click="switchReEnterVisibility">
              <i
                class="fa fa-eye-slash eye-icon onboarding-color-black"
                aria-hidden="true"
                v-if="!reshow"
              ></i>
              <i
                class="fa fa-eye eye-icon onboarding-color-black"
                aria-hidden="true"
                v-if="reshow"
              ></i>
            </a>
          </span>
        </div>
        <div id="re_enter_pin_view_div" class="col-10 m-l-7 visibility">
          <input
            id="re_enter_pin1_view"
            v-model="re_enter_pin1"
            class="form-control ssn-text ssn-input-box-padding"
            v-on:keyup="isNumber($event)"
            type="text"
            maxlength="1"
            inputmode="numeric"
            readonly
          />
          <input
            id="re_enter_pin2_view"
            v-model="re_enter_pin2"
            class="form-control ssn-text ssn-input-box-padding"
            v-on:keyup="isNumber($event)"
            type="text"
            maxlength="1"
            inputmode="numeric"
            readonly
          />
          <input
            id="re_enter_pin3_view"
            v-model="re_enter_pin3"
            class="form-control ssn-text ssn-input-box-padding"
            type="text"
            maxlength="1"
            inputmode="numeric"
            readonly
          />
          <input
            id="re_enter_pin4_view"
            v-model="re_enter_pin4"
            class="form-control ssn-text ssn-input-box-padding"
            type="text"
            maxlength="1"
            inputmode="numeric"
            readonly
          />
          <span class="input-group-addon" style="vertical-align: super">
            <a v-on:click="switchReEnterVisibility">
              <i
                class="fa fa-eye-slash eye-icon onboarding-color-black"
                aria-hidden="true"
                v-if="!reshow"
              ></i>
              <i
                class="fa fa-eye eye-icon onboarding-color-black"
                aria-hidden="true"
                v-if="reshow"
              ></i>
            </a>
          </span>
        </div>
        <div
          class="
            col-1
            eye-icon-div
            d-flex
            align-items-center
            justify-content-center
          "
        ></div>
        <input type="hidden" id="re_enter_pin_current_state" value="0" />
      </div>
      <div class="row justify-content-center align-self-center">
        <div class="col-1"></div>
        <div class="col-10">
          <label class="pin-link-text-style-black" @click="showPinTextbox"
            >Having trouble entering your PIN?</label
          >
        </div>
        <div class="col-1"></div>
      </div>
      <!-- btn row -->
      <div class="row mt-4">
        <div class="col-12">
          <button
            type="button"
            class="btn-login btn-next-onboarding quick-access-next-btn"
            @click="clickLoginPin"
          >
            Next
          </button>
        </div>
      </div>
    </div>
    <!---- MODAL FOR  Error Modal  ---->

    <div>
      <b-modal
        ref="zero-pp-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="zero-pp-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="col-12 text-center">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="120"
                height="120"
                viewBox="0 0 100 125"
                style="enable-background: new 0 0 100 125"
                xml:space="preserve"
                fill="#e14343"
                class="on-boarding-upgrade-alert-icon"
              >
                <path
                  d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
                />
              </svg>
            </div>
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                {{ pp_alert_message }}
              </label>
            </div>
            <br />
            <br />
            <div class="text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="hideModal('zero-pp-modal')"
              >
                <label class="purchasepower-modal-ok-label">OK</label>
              </button>
            </div>
          </div>
        </div>
      </b-modal>
      <!-- Validation modal -->
      <b-modal
        ref="validation-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="validation-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                {{ error_message }}
              </label>
            </div>
            <br />
            <br />
            <div class="text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="hidevalidationModal"
              >
                <label class="purchasepower-modal-ok-label">OK</label>
              </button>
            </div>
          </div>
        </div>
      </b-modal>
      <!-- PIN Textbox modal -->
      <b-modal
        ref="pin-textbox-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        no-close-on-backdrop
        hide-header
        id="pin-textbox-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="d-block">
              <label class="update-modal-title"
                >Please enter a 4 digit PIN.</label
              >
            </div>
            <div class="row">
              <div class="col-10 text-center">
                <input
                  type="password"
                  name="modal_pin"
                  id="modal_pin"
                  maxlength="4"
                  v-model="modal_pin"
                  class="form-control"
                />
              </div>
              <div class="col-2">
                <span
                  class="input-group-addon"
                  style="vertical-align: middle"
                  @click="togglePinInputType('modal_pin')"
                  ><a><i aria-hidden="true" :class="inputPinCls"></i></a
                ></span>
              </div>
            </div>
            <div class="d-block">
              <label class="update-modal-title">Re-enter 4 digit PIN.</label>
            </div>
            <div class="row">
              <div class="col-10 text-center">
                <input
                  type="password"
                  name="re_enter_modal_pin"
                  id="re_enter_modal_pin"
                  maxlength="4"
                  v-model="re_enter_modal_pin"
                  class="form-control"
                />
              </div>
              <div class="col-2">
                <span
                  class="input-group-addon"
                  style="vertical-align: middle"
                  @click="togglePinInputType('re_enter_modal_pin')"
                  ><a><i aria-hidden="true" :class="inputPinCls1"></i></a
                ></span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <button
                  class="btn btn-danger btn-md center-block tip-btn"
                  @click="hidePinTextbox"
                >
                  <span class="forgetpassword-ok-label">Cancel</span></button
                ><button
                  class="btn btn-danger btn-md center-block tip-ok-btn"
                  @click="clickLoginPinModal"
                >
                  <span class="forgetpassword-ok-label">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</div>
</template>
<script>
import OnboardingHeader from "./OnboardingHeader.vue";
import api from "../../api/onboarding.js";
import constants from "../Common/constant.js";
import Loading from "vue-loading-overlay";
import CanPayLoader from "../CustomLoader/CanPayLoader.vue" 
export default {
  /**
   * write a component's description
   * this component is use to Upgrade to the new canpay the user's email as a consumer
   */
  name: "QuickAccessPinSet",
  /**
   * @description-

   * @returns {any}
   */

  data() {
    return {
      show: false,
      reshow: false,
      loginPin: "",
      pin1: "",
      pin2: "",
      pin3: "",
      pin4: "",
      re_enter_pin1: "",
      re_enter_pin2: "",
      re_enter_pin3: "",
      re_enter_pin4: "",
      pp_alert_message: "",
      isLoading: false,
      fullPage: true,
      error_message: "",
      modal_pin: "",
      re_enter_modal_pin: "",
      inputPinCls: "fa fa-eye-slash onboarding-color-black",
      inputPinCls1: "fa fa-eye-slash onboarding-color-black",
      sessionId: null,
    };
  },
  components: {
    "cp-onboardingheader": OnboardingHeader,
    Loading,
    CanPayLoader
  },
  destroyed() {
    document
      .getElementById("app")
      .style.setProperty("background-color", "#149240");
  },
  created() {},
  mounted() {
    this.$root.$emit("show_header", false);
    this.$root.$emit("changeWhiteBackground", [true, false, ""]);
    document
      .getElementById("app")
      .style.setProperty("background-color", "#ffffff");

    var element = document.getElementsByClassName("wrapper");
    element[0].style.setProperty("background-color", "#ffffff");

    var elementHtml = document.getElementsByTagName("html")[0];
    elementHtml.style.setProperty("background-color", "#ffffff");

    this.phone = localStorage.getItem("phone");
    this.sessionId = atob(window.location.href.split("/").splice(-2, 1)[0]);
    console.log("sessionId", this.sessionId);
    let self = this;
    self.maskPin();
    self.maskReEnterPin();
  },
  methods: {
    showModal(modal) {
      this.$refs[modal].show();
    },
    hideModal(modal) {
      this.$refs[modal].hide();
    },
    showValidationModal(msg) {
      this.error_message = msg;
      this.$refs["validation-modal"].show();
    },
    hidevalidationModal() {
      this.error_message = "";
      this.$refs["validation-modal"].hide();
    },
    showPinTextbox() {
      this.inputPinCls = "fa fa-eye-slash onboarding-color-black";
      this.inputPinCls1 = "fa fa-eye-slash onboarding-color-black";
      this.$refs["pin-textbox-modal"].show();
    },
    hidePinTextbox() {
      this.modal_pin = "";
      this.re_enter_modal_pin = "";
      this.$refs["pin-textbox-modal"].hide();
    },
    togglePinInputType(id) {
      var self = this;
      var inputType = document.getElementById(id);
      if (inputType.type === "password") {
        inputType.type = "text";
        if (id == "modal_pin") {
          self.inputPinCls = "fa fa-eye onboarding-color-black";
        } else {
          self.inputPinCls1 = "fa fa-eye onboarding-color-black";
        }
      } else {
        inputType.type = "password";
        if (id == "modal_pin") {
          self.inputPinCls = "fa fa-eye-slash onboarding-color-black";
        } else {
          self.inputPinCls1 = "fa fa-eye-slash onboarding-color-black";
        }
      }
    },
    maskPin() {
      var self = this;
      var ysp1 = new YouShallPass("*");
      var ysp2 = new YouShallPass("*");
      var ysp3 = new YouShallPass("*");
      var ysp4 = new YouShallPass("*");
      ysp1.fixIE9();
      ysp2.fixIE9();
      ysp3.fixIE9();
      ysp4.fixIE9();
      document
        .querySelector("#pin1")
        .addEventListener("input", ysp1.keyboardInputHandle.bind(ysp1));
      document.querySelector("#pin1").addEventListener("input", function () {
        self.pin1 = ysp1.realText;
      });
      document
        .querySelector("#pin2")
        .addEventListener("input", ysp2.keyboardInputHandle.bind(ysp2));
      document.querySelector("#pin2").addEventListener("input", function () {
        self.pin2 = ysp2.realText;
      });
      document
        .querySelector("#pin3")
        .addEventListener("input", ysp3.keyboardInputHandle.bind(ysp3));
      document.querySelector("#pin3").addEventListener("input", function () {
        self.pin3 = ysp3.realText;
      });
      document
        .querySelector("#pin4")
        .addEventListener("input", ysp4.keyboardInputHandle.bind(ysp4));
      document.querySelector("#pin4").addEventListener("input", function () {
        self.pin4 = ysp4.realText;
      });
    },
    maskReEnterPin() {
      var self = this;
      var ysp1 = new YouShallPass("*");
      var ysp2 = new YouShallPass("*");
      var ysp3 = new YouShallPass("*");
      var ysp4 = new YouShallPass("*");
      ysp1.fixIE9();
      ysp2.fixIE9();
      ysp3.fixIE9();
      ysp4.fixIE9();
      document
        .querySelector("#re_enter_pin1")
        .addEventListener("input", ysp1.keyboardInputHandle.bind(ysp1));
      document
        .querySelector("#re_enter_pin1")
        .addEventListener("input", function () {
          self.re_enter_pin1 = ysp1.realText;
        });
      document
        .querySelector("#re_enter_pin2")
        .addEventListener("input", ysp2.keyboardInputHandle.bind(ysp2));
      document
        .querySelector("#re_enter_pin2")
        .addEventListener("input", function () {
          self.re_enter_pin2 = ysp2.realText;
        });
      document
        .querySelector("#re_enter_pin3")
        .addEventListener("input", ysp3.keyboardInputHandle.bind(ysp3));
      document
        .querySelector("#re_enter_pin3")
        .addEventListener("input", function () {
          self.re_enter_pin3 = ysp3.realText;
        });
      document
        .querySelector("#re_enter_pin4")
        .addEventListener("input", ysp4.keyboardInputHandle.bind(ysp4));
      document
        .querySelector("#re_enter_pin4")
        .addEventListener("input", function () {
          self.re_enter_pin4 = ysp4.realText;
        });
    },
    switchVisibility() {
      var self = this;
      if ($("#pin_current_state").val() == 0) {
        $("#pin_current_state").val("1");
        self.show = true;
        $("#pin_div").addClass("visibility");
        $("#pin_view_div").removeClass("visibility");
      } else {
        $("#pin_current_state").val("0");
        self.show = false;
        $("#pin_view_div").addClass("visibility");
        $("#pin_div").removeClass("visibility");
      }
    },
    switchReEnterVisibility() {
      var self = this;
      if ($("#re_enter_pin_current_state").val() == 0) {
        $("#re_enter_pin_current_state").val("1");
        self.reshow = true;
        $("#re_enter_pin_div").addClass("visibility");
        $("#re_enter_pin_view_div").removeClass("visibility");
      } else {
        $("#re_enter_pin_current_state").val("0");
        self.reshow = false;
        $("#re_enter_pin_view_div").addClass("visibility");
        $("#re_enter_pin_div").removeClass("visibility");
      }
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var self = this;
      if (evt.keyCode == 8 || evt.keyCode == 46) {
        if (evt.target.previousElementSibling != null) {
          evt.target.previousElementSibling.focus();
        }
      } else {
        if (evt.target.nextElementSibling != null) {
          evt.target.nextElementSibling.focus();
        }
        if (evt.target.id === "pin4") {
          self.$refs.reenterpin1.focus();
        }
      }
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    clickLoginPin() {
      let self = this;
      if (self.pin1 == "") {
        this.pin1_color = "red";
        this.pin2_color = "red";
        this.pin3_color = "red";
        this.pin4_color = "red";
        return false;
      } else if (self.pin2 == "") {
        this.pin1_color = "";
        this.pin2_color = "red";
        return false;
      } else if (self.pin3 == "") {
        this.pin1_color = "";
        this.pin2_color = "";
        this.pin3_color = "red";
        return false;
      } else if (self.pin4 == "") {
        this.pin1_color = "";
        this.pin2_color = "";
        this.pin3_color = "";
        this.pin4_color = "red";
        return false;
      } else if (self.re_enter_pin1 == "") {
        this.pin1_color = "";
        this.pin2_color = "";
        this.pin3_color = "";
        this.pin4_color = "";
        this.re_enter_pin1_color = "red";
        this.re_enter_pin2_color = "red";
        this.re_enter_pin3_color = "red";
        this.re_enter_pin4_color = "red";
        return false;
      } else if (self.re_enter_pin2 == "") {
        this.pin1_color = "";
        this.pin2_color = "";
        this.pin3_color = "";
        this.pin4_color = "";
        this.re_enter_pin1_color = "";
        this.re_enter_pin2_color = "red";
        return false;
      } else if (self.re_enter_pin3 == "") {
        this.pin1_color = "";
        this.pin2_color = "";
        this.pin3_color = "";
        this.pin4_color = "";
        this.re_enter_pin1_color = "";
        this.re_enter_pin2_color = "";
        this.re_enter_pin3_color = "red";
        return false;
      } else if (self.re_enter_pin4 == "") {
        this.pin1_color = "";
        this.pin2_color = "";
        this.pin3_color = "";
        this.pin4_color = "";
        this.re_enter_pin1_color = "";
        this.re_enter_pin2_color = "";
        this.re_enter_pin3_color = "";
        this.re_enter_pin4_color = "red";
        return false;
      } else if (
        self.pin1 + self.pin2 + self.pin3 + self.pin4 !==
        self.re_enter_pin1 +
          self.re_enter_pin2 +
          self.re_enter_pin3 +
          self.re_enter_pin4
      ) {
        this.pin1_color = "";
        this.pin2_color = "";
        this.pin3_color = "";
        this.pin4_color = "";
        this.re_enter_pin1_color = "";
        this.re_enter_pin2_color = "";
        this.re_enter_pin3_color = "";
        this.re_enter_pin4_color = "";

        self.pp_alert_message = "Pin does not match.";
        this.showModal("zero-pp-modal");

        return false;
      } else if (
        isNaN(self.pin1 + self.pin2 + self.pin3 + self.pin4) ||
        isNaN(
          self.re_enter_pin1 +
            self.re_enter_pin2 +
            self.re_enter_pin3 +
            self.re_enter_pin4
        )
      ) {
        self.pp_alert_message = "Pin Must be Numeric";
        this.showModal("zero-pp-modal");
        return false;
      } else {
        this.pin1_color = "";
        this.pin2_color = "";
        this.pin3_color = "";
        this.pin4_color = "";
        this.re_enter_pin1_color = "";
        this.re_enter_pin2_color = "";
        this.re_enter_pin3_color = "";
        this.re_enter_pin4_color = "";
        this.loginPin = self.pin1 + self.pin2 + self.pin3 + self.pin4;
        var request = {
          pin: this.loginPin,
          session_id: this.sessionId,
        };
        self.isLoading = true;
        api
          .QuickAccessPinSet(request)
          .then((response) => {
            self.isLoading = false;
            if (response.code == 200) {
              this.$router.push(
                "/bankaccountregistration/" +
                  btoa(this.sessionId) +
                  "/bankaccountregistration"
              );
            }
          })
          .catch((err) => {
            self.isLoading = false;
            self.pp_alert_message = err.response.data.message;
            this.showModal("zero-pp-modal");
            if (err.response.data.code == 401) {
            }
          });
      }
    },
    clickLoginPinModal() {
      let self = this;
      if (self.modal_pin == "") {
        return false;
      } else if (self.re_enter_modal_pin == "") {
        return false;
      } else if (self.modal_pin !== self.re_enter_modal_pin) {
        self.showValidationModal("Pin does not match.");
        return false;
      } else if (isNaN(self.modal_pin) || isNaN(self.re_enter_modal_pin)) {
        self.showValidationModal("Pin Must be Numeric");
        return false;
      } else {
        self.loginPin = self.modal_pin;
        var request = {
          pin: self.loginPin,
          session_id: self.sessionId,
        };
        self.isLoading = true;
        api
          .QuickAccessPinSet(request)
          .then((response) => {
            self.isLoading = false;
            if (response.code == 200) {
              this.$router.push(
                "/bankaccountregistration/" +
                  btoa(self.sessionId) +
                  "/bankaccountregistration"
              );
            }
          })
          .catch((err) => {
            self.isLoading = false;
            self.pp_alert_message = err.response.data.message;
            this.showModal("zero-pp-modal");
            if (err.response.data.code == 401) {
            }
          });
      }
    },
  },
};
</script>
<style lang="scss">
#zero-pp-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
</style>
