<template>
  <div class="container-fluid splash">
    <div class="row p-5 justify-content-center">
      <div class="col-12 mt-5">
        <img height="200" src="../../assets/images/canpay-logo.png" />
      </div>
    </div>
    <div class="row pb-5">
      <div class="col-12">
        <label class="splash-welcome-text-style"
          >The easy way to pay at 700+ <br />
          stores and websites.</label
        >
      </div>
    </div>
    <div class="row mt-5" style="position: fixed; bottom: 5rem; width: 100%">
      <div class="col-6">
        <button type="button" class="btn-login-splash" v-on:click="signIn">
          Sign In
        </button>
      </div>
      <div class="col-6">
        <button type="button" class="btn-signup-splash" v-on:click="signUp">
          Sign Up
        </button>
      </div>
    </div>
    <div class="row" style="position: fixed; bottom: 1rem; width: 100%">
      <div class="col-12 mt-5">
        <span class="text-center">
          <label class="splash-welcome-text-style" v-on:click="storeLocator"
            ><svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 291 488.8"
              style="enable-background: new 0 0 291 488.8"
              xml:space="preserve"
              height="25"
              width="25"
              fill="black"
            >
              <g>
                <path
                  d="M146,72c40,0,73,33,73,74c0,40-33,73-73,73c-41,0-74-33-74-73C72,105,105,72,146,72z M146,93c-29,0-53,24-53,53
		c0,28,24,52,53,52c28,0,52-24,52-52C198,117,174,93,146,93z"
                />
                <path
                  d="M146,21C77,21,21,77,21,146c0,19,7,40,16,57c21,46,48,91,74,134l13,21c10,16,33,16,43,0l11-19c26-43,53-87,74-131
		c9-19,18-41,18-62C270,77,214,21,146,21z M146,0c80,0,145,65,145,146c0,24-9,49-20,71c-22,45-48,90-75,133l-11,19
		c-18,29-61,29-79,0l-13-21c-26-44-54-90-76-136c-9-20-17-44-17-66C0,65,65,0,146,0z"
                />
              </g></svg
            >Store Locator</label
          >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api/login.js";

/**
 * write a component's description
 * this component is use to login the user as a consumer
 */
export default {
  name: "splash",
  /**
   * @description-
   * phoneNumber => this will take the consumer's phone no
   * phoneNumber => this will take the consumer's password
   * pinColor => use to validate the password field
   * phoneNumberColor => use to validate the phoneno field
   * token => use to store the user's token
   * @returns {any}
   */
  mounted() {
    this.$root.$emit("show_header", false);
    this.$root.$emit("changeWhiteBackground", [true, false, ""]);
    var app = document.getElementById("app");
    if (app) {
      app.style.setProperty("background-color", "#ffffff");
    }
    var element = document.getElementsByClassName("content-wrap");
    if (element[0]) {
      element[0].style.setProperty("background-color", "#ffffff");
      element[0].style.height = "114vh";
      if(window.innerWidth>1200){
        element[0].style.height = "121vh";
      }
    }
    if (localStorage.getItem("enrollment") != null) {
      this.$router.push("/login").catch((err) => {});
    }
  },
  destroyed() {
    var e1 = document.getElementById("app");
    if (e1) {
      e1.style.setProperty("background-color", "#149240");
    }
  },
  methods: {
    signUp() {
      this.$router.push("/registration");
    },
    signIn() {
      this.$router.push("/login");
    },
    storeLocator() {
      this.$router.push("/locateretailer");
    },
  },
};
</script>

<style lang="scss">
</style>
