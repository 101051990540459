<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-2"></div>
      <div class="col-sm-8">
        <form>
          <div class="form-group">
            <div class="row">
              <div class="col-md-4 right-align">
                <label for="firstname">
                  <b>Name</b>
                </label>
                <input
                  type="text"
                  placeholder="First Name"
                  name="firstname"
                  class="form-control"
                  v-model="first_name"
                />
              </div>
              <div class="col-md-4">
                <label for="middlename" style="padding-top: 18px;"></label>
                <input
                  type="text"
                  placeholder="Middle Name"
                  name="middlename"
                  class="form-control"
                  v-model="middle_name"
                />
              </div>
              <div class="col-md-4">
                <label for="lastname" style="padding-top: 18px;"></label>
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lastname"
                  class="form-control"
                  v-model="last_name"
                />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-md-12 right-align">
                <label for="info">
                  <b>Your Info</b>
                </label>
                <input
                  type="text"
                  inputmode="numeric"
                  class="form-control"
                  placeholder="Phone Number"
                  name="Phone Number"
          v-mask="'(###) ###-####'"
                  v-model="phone_no"
                />
              </div>
            </div>
             <div class="row">
              <div class="col-md-4 right-align">
                <a v-on:click="clickchange(updatePhone)"
                  ><h5 class="change-phone-text">
                    <u>Change Phone Number</u>
                  </h5></a
                >
              </div>
             </div>
               <div class="row">
              <div class="col-md-12 right-align">
                <label for="dateofbirth" style="padding-top: 18px;"></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Date of Birth"
                  name="dateofbirth"
                  v-model="date_of_birth"
                />
              </div>
              
            </div>
            <div class="row">
              <div class="col-md-12 right-align">
                <label for="dateofbirth" style="padding-top: 8px;"></label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="Email"
                  name="email"
                  v-model="email"
                />
              </div>
              <div style="margin-left: 10px;">
                <a v-on:click="clickchange(updateEmail)"><h5 class="change-phone-text"><u>Change Email</u></h5></a>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-6 right-align">
                <label for="address">
                  <b>Your Address</b>
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Street Address"
                  name="streetaddress"
                  v-model="street_address"
                />
              </div>
              <div class="col-md-6">
                <label for="unitnumber" style="padding-top: 18px;"></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Apt/Unit Number"
                  name="apt/unitnumber"
                  v-model="apt_number"
                />
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-4 right-align">
                <input
                  type="text"
                  class="form-control"
                  placeholder="City"
                  name="city"
                  v-model="city"
                />
              </div>
              <div class="col-md-4">
                <select class="form-control">
                  <option>State</option>
                  <option>1</option>
                  <option>1</option>
                  <option>1</option>
                </select>
              </div>
              <div class="col-md-4" style="text-align: left;">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Zipcode"
                  name="zipcode"
                  v-model="zipcode"
                />
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-6" style="text-align: left;">
                <label for="info">
                  <b>Identifying Info</b>
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Driver License"
                  name="driverlicense"
                  v-model="driving_license"
                />
              </div>
              <div class="col-md-6">
                <label for="info" class="mt-3"></label>
                <select class="form-control">
                  <option>State Issued</option>
                  <option>1</option>
                  <option>1</option>
                  <option>1</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 right-align">
                <label for="securitypin" style="padding-top: 18px;"></label>
                <input
                  type="password"
                  inputmode="numeric"
                  class="form-control"
                  placeholder="4 digit security PIN"
                  name="pin"
                  v-model="pin"
                />
              </div>
              <div class="col-md-6 form-group right-align">
                <label for="SSNnumber" style="padding-top: 18px;"></label>
                <input
                  type="text"
                  inputmode="numeric"
                  class="form-control"
                  placeholder="Last 4 digits of your SSN"
                  name="SSNnumber"
                  v-model="ssn_number"
                />
              </div>
              <div class="col-md-1 mt-3 curser-pointer" title="demo">
                <br />
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-6  right-align">
                <label for="info">
                  <b>Security Question</b>
                </label>
                <select class="form-control">
                  <option>Security Questions</option>
                  <option>1</option>
                  <option>1</option>
                  <option>1</option>
                </select>
              </div>
              <div class="col-md-6">
                <label for="security answer" class="mt-3"></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Security Answer"
                  name="Security Answer"
                />
              </div>
            </div>
          </div>
          <div class="col-md-3 mx-auto text-center">
            <button
              type="submit"
              class="btn btn-light center-block btn-register"
            >
              Save
            </button>
          </div>
        </form>
      </div>
      <div class="col-sm-2"></div>
    </div>

    <!-----------------------  MODAL FOR PURCHASE POWER  !----------------->
    <div>
      <b-modal
        ref="my-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        no-close-on-backdrop
        hide-header
        id="pay-modal-center"
        centered
        title="BootstrapVue"
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="d-block text-center">
              <label class="update-modal-title">{{ alertTitle }}</label>
            </div>
            <br />
            <input
             type="text"
          :inputmode="keyboadeType"
           v-model="changeemail"
              class="form-control mx-auto col-10 offset-1 email-box-style"
              v-bind:style="{ 'border-color': phoneNumberColor }"
              @change="addEvent"
              v-mask="vmaskType"
              @input="addEvent"
               v-if="!(updateType == 'email')" 
            />
                        
            <input
             type="text"
              :maxlength="maxlengthvalue"
          :inputmode="keyboadeType"
           v-model="changeemail"
              class="form-control mx-auto col-10 offset-1 email-box-style"
              v-bind:style="{ 'border-color': phoneNumberColor }"
              @change="addEvent"
              @input="addEvent"
               v-if="(updateType == 'email')" 
            />
            <br />
            <br />
            <div class="col-12 offset-1">
              <button
                type="button"
                class="ml-auto col-5 offset-1 btn1 btn-black"
                style="height: 60px;"
                @click="hideModal"
              >
                <label class="leave-a-tip-label ">Cancel</label>
              </button>
              <button
                type="button"
                class="ml-auto col-5 offset-1 btn1 btn-green"
                style="height: 60px;"
                @click="clickverify"
              >
                <label class="leave-a-tip-label ">{{ sendButtonTitle }}</label>
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <!-----------------------  MODAL FOR PURCHASE POWER  !----------------->
  </div>
</template>

<script>
/**
 * write a component's description
 */
/**
 *  Settings is for showing users profile details and edit the details
 */
import api from "../../api/account.js";
import {mask} from 'vue-the-mask';

export default {
  name: "Account",  directives: {mask},

  /**
   * @description: Apicall to get  user details onload
   * @returns {user details data}
   */
  /**
   * @description
   * @returns {any}
   */
  /**
   * @description
   * @returns {any}
   */
  data() {
    let self = this;
    return {
      vmaskType:"(###) ###-####",
      maxlengthvalue: "",
      keyboadeType:"",
      updateType:"",
      newPhonenumber:"",
      sendButtonTitle: "Verify",
      updatePhone: "phone",
      updateEmail: "email",
      phoneNumberColor: "",
      changeemail:"",
      changePhone:"",
      alertTitle: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      phone_no: "",
      email: "",
      street_address: "",
      date_of_birth: "",
      apt_number: "",
      city: "",
      state: "",
      zipcode: "",
      driving_license: "",
      state_issued: "",
      ssn_number: "",
      status: "",
      created_at: "",
      pin: "",
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,

    };
  },
  created() {
    var self = this;
    api
      .getUserdetails()
      .then(function(response) {
        if (response.code == 200) {
         self.setUserDetails(JSON.parse(JSON.stringify(response)));
        } else if (response.code == 401){
                  this.$router.push("/login");
        }else{
          alert(response.message);
        }
      })
      .catch(function(error) {
      });
  },
  /**
   * @description
   */
  mounted() {
    this.$root.$emit("changeWhiteBackground", [false, true, "common"]);
  },
  methods: {
    /**
     * @description
     * @param {any} type
     */

    setUserDetails(response){
                        let self = this;
       self.first_name = response.data.first_name;
          self.middle_name = response.data.middle_name;
          self.last_name = response.data.last_name;
          self.phone_no = response.data.phone;
          self.email = response.data.email;
          self.date_of_birth = response.data.date_of_birth;
          self.street_address = response.data.street_address;
          self.apt_number = response.data.apt_number;
          self.city = response.data.city;
          self.state = response.data.state;
          self.zipcode = response.data.zipcode;
          self.driving_license = response.data.driving_license;
          self.state_issued = response.data.state_issued;
          self.ssn_number = response.data.ssn_number;
    },
    clickchange(type) {
      if (type == "phone") {
        this.maxlengthvalue = "10";
        this.keyboadeType = "";
        this.alertTitle = "Enter verification code sent on your phone and email.";
        this.vmaskType = "XXXXXX";

      } else {
         this.maxlengthvalue = "6";
          this.keyboadeType = "email";
        this.alertTitle = "Enter verification code sent on your phone.";

      }
      this.updateType = type;
      this.sendVerificationCode_Phone_Email(type);
     
    },
    /**
     * @description
     * @param {any} type
     */
    clickverify(){
                  let self = this;
      if (self.sendButtonTitle == "Verify" && self.updateType == "phone"){
      if ( (self.changeemail == "") ) {
        self.phoneNumberColor = "red";
        return false;
      }else{
          self.verifyVerificationCode_Phone_Email();
      }
      } else if (self.sendButtonTitle == "Verify" && self.updateType == "email"){
      if ( (self.changeemail == "") ) {
        self.phoneNumberColor = "red";
        return false;
      }else{
          self.verifyVerificationCode_Phone_Email();
      }
      }else if (self.sendButtonTitle == "Submit" && self.updateType == "email"){
         if (self.changeemail.length == 0){
         self.phoneNumberColor = "red";
         return false;
      }else if ((self.isEmailValid()) == 'has-error') {
                 self.phoneNumberColor = "red";

        alert("Please enter valid email address.")
        return false;
        }else {
           self.phoneNumberColor = "";
          self.ModifyEmail();
        }
      }else if (self.sendButtonTitle == "Submit" && self.updateType == "phone"){
        if ( (self.changeemail == "") ) {
        self.phoneNumberColor = "red";
        return false;
        }else{
          self.updateType = "new phone"
          self.SendOTPNewPhoneNumber();
        }
      }else if (self.sendButtonTitle == "Verify" && self.updateType == "new phone"){
        if ( (self.changeemail == "") ) {
        self.phoneNumberColor = "red";
        return false;
        }else{
          self.VerifyNewPhoneNumber();
        }
      }
      
    },
    /**
     * @description
     */
    showModal() {
      this.$refs["my-modal"].show();
    },
    /**
     * @description
     */
    hideModal() {
      this.changeemail = "";
      this.newPhonenumber = "";
      this.$refs["my-modal"].hide();
    },
    /**
     * @description
     * @returns {any}
     */
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    /**
     * @description
     * @returns {any}
     */
    isEmailValid: function() {
      return this.changeemail == ""
        ? ""
        : this.reg.test(this.changeemail)
        ? "has-success"
        : "has-error";
    },
    /**
     * @description
     * @param {any} evt
     * @returns {any}
     */
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    
    /**
     * @description
     */
    addEvent() {
      this.phoneNumberColor = "";
    },
    /**
     * @description
     */
    sendVerificationCode_Phone_Email(updatetype) {
      let self = this;

     
        var header = {
          "Content-type": "application/json",
        };
        var request = {
          change_request_type: updatetype,
        };
        self.phoneNumberColor = "";
        self.pinColor = "";
        api
          .sendVerificationCode(request)
          .then((response) => {
            if (response.code == 200) {
              self.sendButtonTitle = "Verify";
               this.showModal();
            } else {
             alert(response.message);
            }
          })
          .catch(function(error) {
          });
      
    },

    /**
     * @description
     */
    verifyVerificationCode_Phone_Email() {
      let self = this;
            self.phone_no = self.phone_no.replace(/[^a-zA-Z0-9]/g,'');

        var header = {
          "Content-type": "application/json",
        };
        var request = {
          phone_no: this.phone_no,
          verification_code: this.changeemail
        };
        self.phoneNumberColor = "";
        self.pinColor = "";
        api
          .verifyVerificationCode(request)
          .then((response) => {
            if (response.code == 200) {
              self.changeemail = "";
             alert(response.message);
             if (self.updateType == "email") {
                self.alertTitle = "Enter new email"
                self.keyboadeType = "email";
                self.maxlengthvalue = "50";
                self.sendButtonTitle = "Submit";
        this.vmaskType = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
                
             }else if (self.updateType == "phone"){
                self.alertTitle = "Enter new phone number"
                self.keyboadeType = "numeric";
                self.maxlengthvalue = "10";
                self.sendButtonTitle = "Submit";
                        self.vmaskType = "(###) ###-####";


             }
            } else {
             alert(response.message);
            }
          })
          .catch(function(error) {
          });
      
    },
    /**
     * @description
     */
    ModifyEmail() {
      let self = this;
        var header = {
          "Content-type": "application/json",
        };
        var request = {
          email: this.changeemail,
        };
        self.phoneNumberColor = "";
        self.pinColor = "";
        api
          .updateNewEmail(request)
          .then((response) => {
            if (response.code == 200) {
              self.changeemail = "";
             alert(response.message);
             self.hideModal();
             self.setUserDetails(JSON.parse(JSON.stringify(response)));
            } else {
             alert(response.message);
            }
          })
          .catch(function(error) {
          });
      
    },
    /**
     * @description
     */
    SendOTPNewPhoneNumber() {
      let self = this;
      var user_details = localStorage.getItem("consumer_login_response")
      ? JSON.parse(localStorage.getItem("consumer_login_response"))
      : null;
      self.changeemail = self.changeemail.replace(/[^a-zA-Z0-9]/g,'');

        var header = {
          "Content-type": "application/json",
        };
        var request = {
          phoneNo: this.changeemail,
          sessionId: (Math.floor((Math.random()*(99999999-100000)+100000))),
          user_id:user_details.user_id,
        };
        self.phoneNumberColor = "";
        self.pinColor = "";
        api
          .sendOtpyNewPhone(request)
          .then((response) => {
            if (response.code == 200) {
              self.newPhonenumber = this.changeemail;
              self.changeemail = "";
              self.updateType = "new phone";
              self.alertTitle = "Enter verification code sent on your new phone number.";
        this.vmaskType = "XXXXXX";

              self.keyboadeType = "";
              self.sendButtonTitle = "Verify"
             alert(response.message);
            } else {
             alert(response.message);
            }
          })
          .catch(function(error) {
          });
      
    },
    /**
     * @description
     */
    VerifyNewPhoneNumber() {
      let self = this;
                        self.newPhonenumber = self.newPhonenumber.replace(/[^a-zA-Z0-9]/g,'');

        var header = {
          "Content-type": "application/json",
        };
        var request = {
          phoneNo: this.newPhonenumber,
          otp: this.changeemail
        };
        self.phoneNumberColor = "";
        self.pinColor = "";
        api
          .verifyNewPhone(request)
          .then((response) => {
            if (response.code == 200) {
              self.changeemail = "";
              this.newPhonenumber = "";
               self.hideModal();
             self.setUserDetails(JSON.parse(JSON.stringify(response)));
             alert(response.message);
            } else {
             alert(response.message);
            }
          })
          .catch(function(error) {
          });
      
    },
    
  },
};
</script>
<style lang="scss">
#pay-modal-center___BV_modal_content_ {
  border-radius: 10px;
  margin: 10px;
  background-color: #ffffff;
}
#pay-modal-center___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}

.content-wrap {
  overflow: visible;
  background-color: #149240;
}
</style>
