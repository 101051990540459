<template>
  <div class="container">
      <div v-if="verifying" class="verification-wrapper">
            <div class="spinner-border text-light verify-spinner mb-3" role="status">
            <span class="sr-only">Loading...</span>
            </div>
            <h2 class="verify-content">Please be patient, <br> we are verifying your details and will unlock your account shortly.</h2>
      </div>
  </div>
</template>

<script>
/**
 * write a component's description
 */
import api from "../../api/login.js";

export default {
    name: "EnterUnlockLink",
    components: {},
    /**
     * @description
     * @returns {any}
     */
    data() {
        return {
        verifying: false,
        email: "",
        phone: "",
        verification_code: "",
        };
    },
    /**
     * @description
     */
    mounted: function () {
        let self = this;
        if(localStorage.getItem('consumer_token')){
            self.$router.push('/pay')
        }else{
            if(self.$route.params.email != '' && self.$route.params.otp != '' && self.$route.params.phone != ''){
                self.$root.$emit("show_header", true);
                self.email = atob(self.$route.params.email);
                self.verification_code = atob(self.$route.params.otp);
                self.phone = atob(self.$route.params.phone);
                self.consumerVerifyUnlockLink();
            }else{
                self.$router.push('/login')
            }
        }
    },
    methods: {
        consumerVerifyUnlockLink(){
            var self = this;
            self.verifying = true
            var request = {
              email: self.email,
              phone: self.phone,
              email_code: self.verification_code,
              phone_code: self.verification_code,
            };

            api
              .consumerAccountVerifyCode(request)
              .then((response) => {
                if (response.code == 200) {
                    self.token = response.data.token;
                    localStorage.removeItem("consumer_token");
                    localStorage.removeItem("consumer_login_response");
                    localStorage.setItem("check_bank", true);
                    localStorage.setItem("consumer_token", response.data.token);
                    localStorage.setItem(
                        "consumer_login_response",
                        JSON.stringify(response.data)
                    );
                    if (response.data.login) {
                        localStorage.setItem("overlay_show", true);
                    }
                    self.$router.push("/pay");
                }else{
                    self.$router.push("/login");
                }
                self.verifying = false
              })
              .catch(function (error) {
                self.verifying = false
                self.$router.push("/login");
              });
        }
    },
};
</script>
<style>
.verification-wrapper{
    height: calc(100vh - 96px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}
.verify-spinner{
    width: 100px;
    height: 100px;
}
.verify-content{
    color: #fff;
    font-size: 20px;
}
</style>
