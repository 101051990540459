<template>
  <div class="container splash">
    <cp-onboardingheader></cp-onboardingheader>
        <div class="row p-3">
      <div class="col-md-12">
    <div class="onboarding-terms-and-condition-header">
      <label>CanPay Terms of Service</label>
    </div>
</div>
</div>
        <div class="row p-3">
      <div class="col-md-12">
    <div class="onboarding-terms-and-condition-pragrapgh">
      <label
        >Please read these Terms of Service (the <strong> “Terms” </strong>) and our Privacy Policy (“ <strong>Privacy Policy </strong>”) carefully because
they govern your use of the website located at  <a
          class="onboarding-terms-and-condition-ancher"
          href="https://www.canpaydebit.com/"
          target="_blank"
        >
        https://www.canpaydebit.com/</a
        >  (the “<strong>Site </strong>”) and our ability to
support your ability to make purchases with supported merchants via the Site and corresponding mobile
application located at www.canpayapp.com (“ <strong>App </strong>”) offered by Trusted Debit, Inc. d/b/a CanPay (“ <strong>CanPay </strong>”).
To make these Terms easier to read, the Site, our services and App are collectively called the “<strong>Services</strong> .”
      </label>
    </div>
</div>
</div>

        <div class="row p-3">
      <div class="col-md-12">
    <div class="onboarding-terms-and-condition-sub-two">
      <label
        >IMPORTANT NOTICE REGARDING ARBITRATION: WHEN YOU AGREE TO THESE TERMS YOU ARE AGREEING
(WITH LIMITED EXCEPTION) TO RESOLVE ANY DISPUTE BETWEEN YOU AND CANPAY THROUGH BINDING,
INDIVIDUAL ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY SECTION 17 “DISPUTE
RESOLUTION” BELOW FOR DETAILS REGARDING ARBITRATION.
</br>
    </label>
        <label>
THESE TERMS INCLUDE, AMONG OTHER THINGS, YOUR AUTHORIZATION FOR DEBITS FROM AND TO YOUR
BANK ACCOUNT (AS DEFINED BELOW) VIA THE AUTOMATED CLEARINGHOUSE NETWORK (“ACH”).
      </label>
      
    </div>

</div>
</div>
    </br>
        <div class="row p-3">
      <div class="col-md-12">
    <ol class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Agreement to Terms.
    </li>
    </ol>
    
    <div class="onboarding-terms-and-condition-pragrapgh">
      <label
        >By using our Services, you agree to be bound by these Terms. If you don’t agree
to be bound by these Terms, do not use the Services.
      </label>
    </div>

    </div>
    </div>

      <div class="row p-3">
      <div class="col-md-12">
    <ol start="2" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
    
  Privacy Policy.
  
</li>
</ol>
    <div class="onboarding-terms-and-condition-pragrapgh">
      <label
        >Please review our Privacy Policy, which also governs your use of the Services, for
information on how we collect, use and share your information.
      </label>
    </div>
</div>
</div>

   <div class="row p-3">
      <div class="col-md-12">
   <ol start="3" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
    
 Changes to these Terms or the Services.
</li>
</ol>

  <div class="onboarding-terms-and-condition-pragrapgh">
      <label
        >We may update the Terms from time to time in our sole
discretion. If we do, we’ll let you know by posting the updated Terms on the Site, to the App and/or may also
send other communications. It’s important that you review the Terms whenever we update them or when you
use the Services. If you continue to use the Services after we have posted updated Terms it means that you
accept and agree to the changes. If you don’t agree to be bound by the changes, you may not use the Services
anymore. Because our Services are evolving over time we may change or discontinue all or any part of the
Services, at any time and without notice, at our sole discretion.
      </label>
    </div>
</div>
</div>


   <div class="row p-3">
      <div class="col-md-12">

       <ol start="4" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
    
Who May Use the Services?
</li>
</ol>
  <div class="onboarding-terms-and-condition-pragrapgh">
  <div class="col-md-12">
      <label>
      
      <strong>(a) &nbsp;	</strong> <u>Eligibility </u>.&nbsp; You must be permitted to open a bank account to use our Services. You may use the
Services only if you can form a binding contract with CanPay. You agree to use the Services in
compliance with these Terms and all applicable local, state, national, and international laws, rules and
regulations. Any use or access to the Services by anyone under 18  years of age is strictly prohibited.
The Services are not available to any Customers of the Services previously removed from the Services
by CanPay, or who are otherwise ineligible to use the Services under applicable law.
      </label>
      <label>
       <strong>(b)&nbsp;	</strong> <u> Registration and Customer ID</u>. &nbsp;If you want to use certain features of the Services, you’ll have to
create an account (“<strong>Account </strong>”). You can do this via the Site or App. Upon registration, you will create
an account by validating your phone number and creating an access code to login to your Account
(“<strong>Customer ID </strong>”). You are the only person authorized to use your Customer ID and access code and for
maintaining the confidentiality of your Customer ID and access code. You shall not permit or allow
other persons to have access to or use your Customer ID and access code. You are responsible for the
use of the Services under your Customer ID. You are responsible for protecting the information on
your computer such as by installing anti-virus software, updating your software, password protecting
your files, and not permitting third party physical or electronic access to your computer or bank
account.
      </label>
      <label>
   <strong>(c) &nbsp;	</strong> <u>Accuracy of Account Information </u>.&nbsp; It’s important that you provide us with accurate, complete
and up-to-date information for your Account and you agree to update such information to keep it
accurate, complete and up-to-date. If you don’t, we might have to suspend or terminate your Account.
You agree that you won’t disclose your Account access code to anyone and you’ll notify us
immediately of any unauthorized use of your Account. You’re responsible for all activities that occur
under your Account, whether or not you know about them.
      </label>
      <label>
      <strong>(d)&nbsp;	</strong><u> Compliance Screening and Inquiries</u>.&nbsp; We may also ask questions and verify information
ourselves or through trusted entities we work with for verification and compliance purposes. You
agree that we may use the information you provide and other information to verify who you are. This
may include looking up available public information and asking other entities, like banks, for
information about you. You acknowledge and agree that we may make any inquiries that we consider
necessary, either directly or through third parties, concerning your identity and creditworthiness
including, without limitation, requiring you to take steps to confirm ownership of your email address,
phone number or financial information, or verifying information against third party databases or
through other sources.
      </label>
      </div>
    </div>
</div>
</div>

 <div class="row p-3">
      <div class="col-md-12">
      <ol start="5" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Feedback.</li>
</ol>
  <div class="onboarding-terms-and-condition-pragrapgh">
    <label>  We appreciate feedback, comments, ideas, proposals and suggestions for improvements to
the Services (“<strong>Feedback </strong>”). If you choose to submit Feedback, you agree that we are free to use it without any
restriction or compensation to you. You grant to us a non-exclusive, transferable, worldwide, perpetual,
irrevocable, fully-paid, royalty-free license, with the right to sublicense, under any and all intellectual property
rights that you own or control to use, copy, modify, create derivative works based upon and otherwise exploit
the Feedback for any purpose.
</label>
       </div>
       </div>
       </div>
       
        <div class="row p-3">
      <div class="col-md-12">
    <ol start="6" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two" id="warranty-info">
CanPay Services.
</li>
</ol>
  <div class="onboarding-terms-and-condition-pragrapgh">
  <label>
 Subject to the terms and conditions of these Terms, you are hereby granted a non-
exclusive, limited, non-transferable, freely revocable license to use the Services as permitted by the features of
the Services. CanPay reserves all rights not expressly granted herein in the Services and the CanPay Content (as
defined below).
</label>
  <div class="col-md-12">
  </br>
  <label>
  <strong>(a) &nbsp;</strong><u> Non-commercial Use </u>.&nbsp; You may only use the Services for your own personal, non-commercial
use and not on behalf of or for the benefit of any third party.
</label>
 <label>
  <strong>(b) &nbsp;</strong><u> General</u>.&nbsp;When you make a purchase via the App (a “<strong>Transaction </strong>”), you expressly authorize us
to charge you for such Transaction. In addition to your Account information, we may request
additional information to process your Transaction including information about your Bank Account as
defined below, address, or other information necessary to initiate a Transaction (such information,
“<strong>Transaction Information </strong>”). You represent and warrant that you have the legal right to use all
payment method(s) represented by any such Transaction Information. You agree to be responsible for
paying any applicable sales and use taxes, and any applicable shipping and handling fees that may
apply to your purchase.
</label>

 <label>
  <strong>(c) &nbsp;</strong><u> Returns and Maintaining Sufficient Funds</u>&nbsp;When using the Services, you understand and agree
that it may take more than 60 days for CanPay to receive notice of the return or reversal of an ACH
debit and/or to exercise any rights granted or reserved under these Terms. You agree at all times to
maintain sufficient funds to satisfy all obligations in connection with your Account or your use of the
Services, including returns, reversals, and associated fees, and to add funds immediately if CanPay
notifies you that your funds are insufficient.
</label>

 <label>
  <strong>(d) &nbsp;</strong><u> Merchant Purchases</u>.&nbsp;We facilitate your ability to make payments to third party merchants. We
do not hold, transfer, disperse, or otherwise process funds on your behalf.
</label>

 <label>
  <strong>(e) &nbsp;</strong><u> Refunds and Reversals</u>.&nbsp;ALL REFUNDS ARE PROCESSED BY MERCHANTS UNLESS OTHERWISE
REQUIRED BY LAW. CANPAY IS NOT RESPONSIBLE FOR MAKING ANY REFUNDS OR REVERSING ANY
PAYMENTS. YOU UNDERSTAND THAT ONCE A TRANSACTION IS PROCESSED, WE ARE NOT ABLE TO
REVERSE THE TRANSACTION THROUGH OUR SERVICES OR OTHERWISE PROVIDE A REFUND IN ANY
WAY FOR THE TRANSACTION. If an ACH debit from your bank account to fund a payment requested by
you through the Services is rejected or reversed for any reason, YOU AUTHORIZE CANPAY TO REVERSE
THE CORRESPONDING PAYMENT TO YOU VIA DEBIT FROM YOUR PAYMENT ACCOUNT, OR IF THERE
ARE INSUFFICIENT FUNDS IN YOUR PAYMENT ACCOUNT AT THE TIME OF DEBIT, YOU AGREE TO PAY
THE AMOUNT OF THE PAYMENT BACK TO CANPAY WITHIN 5 BUSINESS DAYS.You represent and
warrant that CanPay may resubmit a rejected or reversed funding debit to the Customer’s account, in
CanPay’s sole discretion. You also agree to pay all penalties, interest charges, late payment fees and
service fees related to any rejected, reversed or returned funding ACH debit.
</label>
  </div>
  </div>
   </div>
   </div>
    <div class="row p-3">
      <div class="col-md-12">
       <ol start="7" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
ACH Credit/Debit Authorization.
</li>
</ol>

  <div class="onboarding-terms-and-condition-pragrapgh">
  <label>
 By agreeing to these Terms, you authorize CanPay to electronically
debit your designated deposit account at your designated depository financial institution (your “Bank
Account”) via ACH and, if ever applicable, to correct erroneous debits.
</label>
  <div class="col-md-12">
   <label>
  (a) &nbsp;You acknowledge and agree that: (a) Transactions will go through ACH, (b) all Transactions are
governed by ACH rules, and (c) your ACH transactions will comply with U.S. law. You can learn more
about the ACH at<a
          class="onboarding-terms-and-condition-ancher"
          href="https://www.nacha.org/"
          target="_blank"
        > www.nacha.org.</a>
</label>
   <label>
  (b) &nbsp;By using the Service, you authorize us to initiate electronic withdrawals from your bank
account to fund purchases from designated merchants (“<strong>Transactions </strong>”) through electronic funding
methods (“<strong>Debits </strong>”). Debits will also be initiated to pay for any sales, use or other taxes payable on
merchant transactions, and for adjustments to these various amounts. You authorize us to direct your
designated financial institution (“<strong>Bank Account</strong>”) to: (a) charge each Transaction and/or Debit to Bank
Account and (b) respond to our inquiries regarding Bank Account. You agree that Bank Account is
located in the United States, and that all Transactions will be to accounts located in the United States.
</label>
<label>
  (c) &nbsp;Your Agreement and Representations. For purposes of ACH debits and credits, and all
electronic payments made or received through the Services, you: (i) authorize CanPay to initiate and
process Debits in accordance with your payment instructions and as provided under these Terms; and
(ii) agree to be subject to and comply with this Agreement, the Nacha Rules, and all other applicable
laws, rules and regulations.
</label>

<label>
  (d) &nbsp;In addition to any of your other representations and warranties in these Terms, you represent
that: (a) your browser is equipped with at least 128-bit security encryption; (b) you are capable of
printing, storing, or otherwise saving a copy of this electronic authorization for your records; and (c)
the ACH transactions you hereby authorize comply with applicable law.
</label>

<label>
  (e) &nbsp;<strong> [Bank Verification.]</strong>Prior to processing any Transaction, we may verify Bank Account
information. The verification process may include sending you texts, voice calls, or automated/pre-
recorded voice calls. If you provided your mobile phone number to us, you agree we may send such
communications to that number. You agree that as part of the verification process we may: (i) verify
Bank Account information by debiting between $0.01 and $1.00 from Bank Account, then crediting an
amount equal to or more than the same amount back to Bank Account, and requesting you to verify
the amount debited and credited, and/or (ii) verify Bank Account using your login credentials to your
financial institution and we may also ask you questions pertaining to your bank balance and/ or recent
debit transactions. We will only use this verification process to screen for fraud and will not otherwise
debit Bank Account, except for your use of Services. You hereby grant us a limited power of attorney
to initiate the actions in this Section as part of the bank verification process.
</label>

<label>
  (f) &nbsp;You further authorize us to: (i) initiate transactions with Bank Account to collect Transactions,
reinitiate, or initiate a new Debit to Bank Account if any Debit is returned for insufficient funds or
uncollected funds, (ii) credit Bank Account when necessary, at our sole discretion, for any refund
amount due to you, and/or (iii) to send Transactions, electronically or by any other commercially
accepted method, to the appropriate financial institution(s). If CanPay tells you that an account
number or other information concerning your Transactions has changed, you must use this corrected
information in the future to initiate Transactions.
</label>

<label>
  (g) &nbsp;We may: (i) establish security limits on Transactions, such as a maximum number or dollar
amount, (ii) change security limits from time to time without disclosing such changes, and (iii) refuse to
process your Transactions if we reasonably believe Bank Account balance is insufficient to cover the
amounts due or for any other reason we deem reasonable. We may in our sole discretion process any
Transactions that have not been settled after these Terms are terminated.
</label>

<label>
  (h) &nbsp;This ACH authorization will remain in full force and effect until we receive your written
notification of termination in such time and manner as to afford CanPay a reasonable opportunity to
act on your termination notice.
</label>

<label>
  (i) &nbsp;If any amount payable by you is dishonored or returned for any reason, such as, but not
limited to, non-sufficient funds, account closed, inability to locate account, or reversal by you and/or
your bank, CanPay may: (i) reverse any corresponding credit issued to a merchant without liability to
us, (ii) reverse a Transaction, (iii) refuse to perform further Services, (iv) initiate another debit in
substitution for the dishonored debit until the debit is honored and to initiate separate debits to cover
any NSF fee or dishonored Transaction, (v) charge you a one-time insufficient funds penalty fee for
each occurrence, (vi) report this information to any and all credit agencies and/or financial institutions,
and/or (viii) immediately terminate this Agreement.
</label>
  </div>
  </div>
</div>
</div>
   <div class="row p-3">
      <div class="col-md-12">

         <ol start="8" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Transaction History.
</li>
</ol>
  <div class="onboarding-terms-and-condition-pragrapgh">
CanPay will maintain a record of your Transaction(s) made using the Services for
the previous two (2) years or such time as required by applicable law. You may access and view your
Transactions History in the CanPay App or by logging on to your Account via CanPayDebit.com.
  </div>
</div>
</div>

   <div class="row p-3">
      <div class="col-md-12">
         <ol start="9" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Your Content.
</li>
</ol>
 <div class="onboarding-terms-and-condition-pragrapgh">
   <div class="col-md-12">
 <label>
 (a) &nbsp;<u> Posting Content.</u>&nbsp;Our Services may allow you to store or share content such as text (in posts or
communications with others), files, documents, graphics, images, music, software, audio and video.
Anything (other than Feedback) that you post or otherwise make available through the Services is
referred to as “<strong>User Content </strong>”. CanPay does not claim any ownership rights in any User Content and
nothing in these Terms will be deemed to restrict any rights that you may have to your User Content.
</label>

 <label>
 (b) &nbsp;<u> Permissions to Your User Content</u>.&nbsp;By making any User Content available through the Services
you hereby grant to CanPay a non-exclusive, transferable, worldwide, royalty-free license, with the
right to sublicense, to use, copy, modify, create derivative works based upon, distribute, publicly
display, and publicly perform your User Content in connection with operating and providing the
Services.
</label>
 <label>
 (c) &nbsp;<u> Your Responsibility for User Content</u>.&nbsp;You are solely responsible for all your User Content. You
represent and warrant that you have (and will have) all rights that are necessary to grant us the license
rights in your User Content under these Terms. You represent and warrant that neither your User
Content, nor your use and provision of your User Content to be made available through the Services,
nor any use of your User Content by CanPay on or through the Services will infringe, misappropriate or
violate a third party’s intellectual property rights, or rights of publicity or privacy, or result in the
violation of any applicable law or regulation.
</label>

 <label>
 (d) &nbsp;<u> Removal of User Content</u>.&nbsp;You can remove your User Content by specifically deleting it. You
should know that in certain instances, some of your User Content (such as posts or comments you
make) may not be completely removed and copies of your User Content may continue to exist on the
Services. To the maximum extent permitted by law, we are not responsible or liable for the removal or
deletion of (or the failure to remove or delete) any of your User Content.
</label>

 <label>
 (e) &nbsp;<u> CanPay’s Intellectual Property</u>.&nbsp;We may make available through the Services content that is
subject to intellectual property rights. We retain all rights to that content.
</label>
  </div>
  </div>
</div>
</div>

   <div class="row p-3">
      <div class="col-md-12">
         <ol start="10" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Rights and Terms for Apps.
</li>
</ol>
 <div class="onboarding-terms-and-condition-pragrapgh">
  <div class="col-md-12">
   <label>
 (a) &nbsp;<u> App License</u>.&nbsp;If you comply with these Terms, CanPay grants to you a limited non-exclusive,
non-transferable license, with no right to sublicense, to download and install the App on your personal
computers, mobile handsets, tablets, wearable devices, and/or other devices and to run the App solely
for your own personal non-commercial purposes. Except as expressly permitted in these Terms, you
may not: (i) copy, modify or create derivative works based on the App; (ii) distribute, transfer,
sublicense, lease, lend or rent the App to any third party; (iii) reverse engineer, decompile or
disassemble the App (unless applicable law permits, despite this limitation); or (iv) make the
functionality of the App available to multiple users through any means.
</label>
  </div>
</div>
</div>
</div>

<div class="row p-3">
      <div class="col-md-12">
         <ol start="11" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Prohibitions and CanPay’s Enforcement Rights.
</li>
</ol>
 <div class="onboarding-terms-and-condition-pragrapgh">
  <div class="col-md-12">
   <label>
 (a) &nbsp;Post, upload, publish, submit or transmit any User Content that: (i) infringes, misappropriates
or violates a third party’s patent, copyright, trademark, trade secret, moral rights or other intellectual
property rights, or rights of publicity or privacy; (ii) violates, or encourages any conduct that would
violate, any applicable law or regulation or would give rise to civil liability; (iii) is fraudulent, false,
misleading or deceptive; (iv) is defamatory, obscene, pornographic, vulgar or offensive; (v) promotes
discrimination, bigotry, racism, hatred, harassment or harm against any individual or group; (vi) is
violent or threatening or promotes violence or actions that are threatening to any person or entity; or
(vii) promotes illegal or harmful activities or substances;
</label>

   <label>
 (b) &nbsp;Use, display, mirror or frame the Services or any individual element within the Services,
CanPay’s name, any CanPay trademark, logo or other proprietary information, or the layout and design
of any page or form contained on a page, without CanPay’s express written consent;
</label>

   <label>
 (c) &nbsp;Access, tamper with, or use non-public areas of the Services, CanPay’s computer systems, or
the technical delivery systems of CanPay’s providers;
</label>

   <label>
 (d) &nbsp;Attempt to probe, scan or test the vulnerability of any CanPay system or network or breach
any security or authentication measures;
</label>

 <label>
 (e) &nbsp;Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any
technological measure implemented by CanPay or any of CanPay’s providers or any other third party
(including another user) to protect the Services;
</label>


 <label>
 (f) &nbsp;Attempt to access or search the Services or download content from the Services using any
engine, software, tool, agent, device or mechanism (including spiders, robots, crawlers, data mining
tools or the like) other than the software and/or search agents provided by CanPay or other generally
available third-party web browsers;
</label>

 <label>
 (g) &nbsp;Send any unsolicited or unauthorized advertising, promotional materials, email, junk mail,
spam, chain letters or other form of solicitation;
</label>

 <label>
 (h) &nbsp;Use any meta tags or other hidden text or metadata utilizing a CanPay trademark, logo URL or
product name without CanPay’s express written consent;
</label>

 <label>
 (j) &nbsp;Forge any TCP/IP packet header or any part of the header information in any email or
newsgroup posting, or in any way use the Services to send altered, deceptive or false source-
identifying information;
</label>

 <label>
 (k) &nbsp;Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to
provide the Services;
</label>

 <label>
 (I) &nbsp;Interfere with, or attempt to interfere with, the access of any user, host or network, including,
without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services;
</label>

 <label>
 (m) &nbsp;Collect or store any personally identifiable information from the Services from other users of
the Services without their express permission;
</label>

 <label>
 (n) &nbsp;Impersonate or misrepresent your affiliation with any person or entity;
</label>

 <label>
 (o) &nbsp;Violate any applicable law or regulation; or
</label>


 <label>
 (p) &nbsp;Encourage or enable any other individual to do any of the foregoing.
</label>
  </div>
  <label>
  CanPay is not obligated to monitor access to or use of the Services or to review or edit any content. However,
we have the right to do so for the purpose of operating the Services, to ensure compliance with these Terms
and to comply with applicable law or other legal requirements. We reserve the right, but are not obligated, to
remove or disable access to any content, including User Content, at any time and without notice, including, but
not limited to, if we, at our sole discretion, consider it objectionable or in violation of these Terms. We have
the right to investigate violations of these Terms or conduct that affects the Services. We may also consult and
cooperate with law enforcement authorities to prosecute users who violate the law.
  </label>
</div>
</div>
</div>


<div class="row p-3">
<div class="col-md-12">
         <ol start="11" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Links to Third Party Websites or Resources.
</li>
</ol>
 <div class="onboarding-terms-and-condition-pragrapgh">
   <label>
The Services (including the App) may allow you to access
third-party websites or other resources. We provide access only as a convenience and are not responsible for
the content, products or services on or available from those resources or links displayed on such websites. You
acknowledge sole responsibility for and assume all risk arising from, your use of any third-party resources.
</label>

</div>
</div>
</div>


<div class="row p-3">
<div class="col-md-12">
         <ol start="12" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Links to Third Party Websites or Resources.
</li>
</ol>
 <div class="onboarding-terms-and-condition-pragrapgh">
   <label>
The Services (including the App) may allow you to access
third-party websites or other resources. We provide access only as a convenience and are not responsible for
the content, products or services on or available from those resources or links displayed on such websites. You
acknowledge sole responsibility for and assume all risk arising from, your use of any third-party resources.
</label>

</div>
</div>
</div>

<div class="row p-3">
<div class="col-md-12">
         <ol start="13" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Termination.
</li>
</ol>
 <div class="onboarding-terms-and-condition-pragrapgh">
   <label>
We may suspend or terminate your access to and use of the Services, including
suspending access to or terminating your account, at our sole discretion, at any time and without notice to
you. You may cancel your account at any time by logging into your Account and doing it yourself (via the App)
or by sending us an email at <a
          class="onboarding-terms-and-condition-ancher"
        href="mailto:canpay@canpaydebit.com">canpay@canpaydebit.com </a>.]. Upon any termination, discontinuation or
cancellation of the Services or your account, the following Sections will survive:
</label>

</div>
</div>
</div>

<div class="row p-3">
<div class="col-md-12">
         <ol start="14" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Warranty Disclaimers.
</li>
</ol>
 <div class="onboarding-terms-and-condition-pragrapgh">
   <label>
THE SERVICES ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND.
WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES OF
MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT AND NON-INFRINGEMENT, AND
ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. We make no warranty that the
Services will meet your requirements or be available on an uninterrupted, secure, or error-free basis. We make
no warranty regarding the quality, accuracy, timeliness, truthfulness, completeness or reliability of any
information or content on the Services.
</label>

</div>
</div>
</div>


<div class="row p-3">
<div class="col-md-12">
         <ol start="15" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Indemnity.
</li>
</ol>
 <div class="onboarding-terms-and-condition-pragrapgh">
   <label>
You will indemnify and hold CanPay and its officers, directors, employees and agents,
harmless from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses,
including, without limitation, reasonable legal and accounting fees arising out of or in any way connected with
(a) your access to or use of the Services, (b) your User Content, or (c) your violation of these Terms.
</label>

</div>
</div>
</div>

   <div class="row p-3">
      <div class="col-md-12">
         <ol start="16" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Limitation of Liability.
</li>
</ol>
 <div class="onboarding-terms-and-condition-pragrapgh">
  <div class="col-md-12">
   <label>
 (a) &nbsp;<u> Limitation of Liability.</u>&nbsp;IN NO EVENT SHALL CANPAY BE LIABLE TO YOU, YOUR ORGANIZATION
OR INDIVIDUAL, ANY USER, OR ANY THIRD PARTY IN CONNECTION WITH THIS AGREEMENT OR THE
SERVICE, FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING
LOSS OF INCOME, DATA, PROFITS, REVENUE OR BUSINESS INTERRUPTION, OR COST OF SUBSTITUTE
SERVICES, OR OTHER ECONOMIC LOSS, WHETHER OR NOT CANPAY HAS BEEN ADVISED OF THE
POSSIBILITY OF SUCH DAMAGES, AND WHETHER ANY CLAIM FOR RECOVERY IS BASED ON THEORIES
OF CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY) OR OTHERWISE. 
CANPAY WILL NOT BE LIABLE FOR ATTORNEYS’ FEES, EXCEPT AS REQUIRED BY LAW.
</label>

 <label>
NOTWITHSTANDING ANY OTHER PROVISIONS OF THIS AGREEMENT, IN NO EVENT SHALL CANPAY’S
AGGREGATE LIABILITY TO YOU, YOUR ORGANIZATION OR INDIVIDUAL, ANY USER, OR ANY THIRD
PARTY IN CONNECTION WITH THIS AGREEMENT OR THE SERVICE EXCEED THE TOTAL FEES PAID BY
YOU OR YOUR ORGANIZATION OR INDIVIDUAL TO CANPAY IN THE TWELVE MONTH PERIOD
PRECEDING THE EVENT(S) THAT GAVE RISE TO SUCH LIABILITY, OR ONE HUNDRED USD ($100.00),
WHICHEVER IS GREATER, REGARDLESS OF THE FORM OR THEORY OF THE CLAIM OR ACTION.
</label>


 <label>
Each provision of this Agreement that provides for a limitation of liability, disclaimer of warranties, or
exclusion of damages is intended to and does allocate the risks between the parties under this
Agreement. Each of these provisions is severable and independent of all other provisions of this
Agreement. All limitations of liability, disclaimers of warranties, and exclusions of consequential
damages or other damages or remedies will remain fully valid, effective and enforceable in accordance
with their respective terms, even under circumstances that cause an exclusive remedy to fail of its
essential purpose.  SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
DAMAGES, LIABILITY OR CERTAIN WARRANTIES IN CERTAIN CIRCUMSTANCES. ACCORDINGLY, IN
THOSE JURISDICTIONS, SOME OF THESE EXCLUSIONS OR LIMITATIONS MAY NOT APPLY.
</label>
  </div>
</div>
</div>
</div>


<div class="row p-3">
<div class="col-md-12">
         <ol start="17" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Governing Law and Forum Choice.
</li>
</ol>
 <div class="onboarding-terms-and-condition-pragrapgh">
   <label>
These Terms and any action related thereto will be governed by
the Federal Arbitration Act, federal arbitration law, and the laws of the State of Washington, without regard to
its conflict of laws provisions. Except as otherwise expressly set forth in Section 17 “Dispute Resolution,” the
exclusive jurisdiction for all Disputes (defined below) that you and CanPay are not required to arbitrate will be
the state and federal courts located in the United States, and you and CanPay each waive any objection to
jurisdiction and venue in such courts.
</label>

</div>
</div>
</div>



  <div class="row p-3">
      <div class="col-md-12">
         <ol start="18" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Dispute Resolution.
</li>
</ol>
 <div class="onboarding-terms-and-condition-pragrapgh">
  <div class="col-md-12">
   <label>
 (a) &nbsp;<u> Mandatory Arbitration of Disputes.</u>&nbsp;We each agree that any dispute, claim or controversy
arising out of or relating to these Terms or the breach, termination, enforcement, interpretation or
validity thereof or the use of the Services (collectively, “<strong>Disputes</strong>”) will be resolved <strong> solely by binding,
individual arbitration and not in a class, representative or consolidated action or proceeding.</strong> You
and CanPay agree that the U.S. Federal Arbitration Act governs the interpretation and enforcement of
these Terms, and that you and CanPay are each waiving the right to a trial by jury or to participate in a
class action. This arbitration provision shall survive termination of these Terms.
</label>

   <label>
 (b) &nbsp;<u> Exceptions.</u>&nbsp;As limited exceptions to Section 17(a) above: (i) either party may seek to resolve a
Dispute in small claims court if it qualifies; and (ii) we each retain the right to seek injunctive or other
equitable relief from a court to prevent (or enjoin) the infringement or misappropriation of our
intellectual property rights.
</label>


   <label>
 (c) &nbsp;<u> Conducting Arbitration and Arbitration Rules</u>.&nbsp;The arbitration will be conducted by the
American Arbitration Association (“<strong>AAA</strong>”) under its Consumer Arbitration Rules (the “AAA Rules”) then
in effect, except as modified by these Terms. The AAA Rules are available at  <a
          class="onboarding-terms-and-condition-ancher"
          href="https://www.adr.org"
          target="_blank"
        >www.adr.org</a>. or by calling
1-800-778-7879. A party who wishes to start arbitration must submit a written Demand for Arbitration
to AAA and give notice to the other party as specified in the AAA Rules. The AAA provides a form
Demand for Arbitration at <a
          class="onboarding-terms-and-condition-ancher"
          href="https://www.adr.org"
          target="_blank"
        >www.adr.org</a>.
</label>
<label>
Any arbitration hearings will take place in the county (or parish) where you live, unless we both agree
to a different location. The parties agree that the arbitrator shall have exclusive authority to decide all
issues relating to the interpretation, applicability, enforceability and scope of this arbitration
agreement.
</label>


  <label>
 (d) &nbsp;<u> Arbitration Costs</u>.&nbsp;Transaction of all filing, administration and arbitrator fees will be governed
by the AAA Rules, and we won’t seek to recover the administration and arbitrator fees we are
responsible for paying, unless the arbitrator finds your Dispute frivolous. If we prevail in arbitration,
we’ll pay all of our attorneys’ fees and costs and won’t seek to recover them from you. If you prevail in
arbitration you will be entitled to an award of attorneys’ fees and expenses to the extent provided
under applicable law.
</label>


 <label>
 (e) &nbsp;<u> Injunctive and Declaratory Relief</u>.&nbsp;Except as provided in Section 17(b) above, the arbitrator
shall determine all issues of liability on the merits of any claim asserted by either party and may award
declaratory or injunctive relief only in favor of the individual party seeking relief and only to the extent
necessary to provide relief warranted by that party&#39;s individual claim. To the extent that you or we
prevail on a claim and seek public injunctive relief (that is, injunctive relief that has the primary
purpose and effect of prohibiting unlawful acts that threaten future injury to the public), the
entitlement to and extent of such relief must be litigated in a civil court of competent jurisdiction and
not in arbitration. The parties agree that litigation of any issues of public injunctive relief shall be
stayed pending the outcome of the merits of any individual claims in arbitration.
</label>


 <label>
 (f) &nbsp;<u> Class Action Waiver</u>.&nbsp;<strong>YOU AND CANPAY AGREE THAT EACH MAY BRING CLAIMS AGAINST THE
OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER
IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.</strong> Further, if the parties’ dispute is
resolved through arbitration, the arbitrator may not consolidate another person&#39;s claims with your
claims, and may not otherwise preside over any form of a representative or class proceeding. If this
specific provision is found to be unenforceable, then the entirety of this Dispute Resolution section
shall be null and void.
</label>
 <label>
 (g) &nbsp;<u> Severability.</u>.&nbsp;>With the exception of any of the provisions in Section 17(e) of these Terms (&quot;Class
<strong>Action Waiver </strong>&quot;), if an arbitrator or court of competent jurisdiction decides that any part of these
Terms is invalid or unenforceable, the other parts of these Terms will still apply.
</label>
  </div>
</div>
</div>
</div>




  <div class="row p-3">
      <div class="col-md-12">
         <ol start="19" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
General Terms.
</li>
</ol>
 <div class="onboarding-terms-and-condition-pragrapgh">
  <div class="col-md-12">
   <label>
 (a) &nbsp;<u> Reservation of Rights.</u>&nbsp;CanPay and its licensors exclusively own all right, title and interest in
and to the Services, including all associated intellectual property rights. You acknowledge that the
Services are protected by copyright, trademark, and other laws of the United States and foreign
countries. You agree not to remove, alter or obscure any copyright, trademark, service mark or other
proprietary rights notices incorporated in or accompanying the Services.
</label>
   <label>
 (b) &nbsp;<u> Entire Agreement.</u>&nbsp;These Terms constitute the entire and exclusive understanding and
agreement between CanPay and you regarding the Services, and these Terms supersede and replace
all prior oral or written understandings or agreements between CanPay and you regarding the
Services. If any provision of these Terms is held invalid or unenforceable by an arbitrator or a court of
competent jurisdiction, that provision will be enforced to the maximum extent permissible and the
other provisions of these Terms will remain in full force and effect. You may not assign or transfer
these Terms, by operation of law or otherwise, without CanPay’s prior written consent. Any attempt
by you to assign or transfer these Terms, without such consent, will be null. CanPay may freely assign
or transfer these Terms without restriction. Subject to the foregoing, these Terms will bind and inure
to the benefit of the parties, their successors and permitted assigns.
</label>
   <label>
 (c) &nbsp;<u> Notices.</u>&nbsp;Any notices or other communications provided by CanPay under these Terms will be
given: (i) via email; or (ii) by posting to the Services. For notices made by email, the date of receipt will
be deemed the date on which such notice is transmitted.
</label>
   <label>
 (d) &nbsp;<u> Waiver of Rights.</u>&nbsp;CanPay’s failure to enforce any right or provision of these Terms will not be
considered a waiver of such right or provision. The waiver of any such right or provision will be
effective only if in writing and signed by a duly authorized representative of CanPay. Except as
expressly set forth in these Terms, the exercise by either party of any of its remedies under these
Terms will be without prejudice to its other remedies under these Terms or otherwise.
</label>
  </div>
</div>
</div>
</div>


<div class="row p-3">
<div class="col-md-12">
         <ol start="20" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Contact Information.
</li>
</ol>
 <div class="onboarding-terms-and-condition-pragrapgh">
   <label>
If you have any questions about these Terms or the Services, please contact
CanPay at <a
          class="onboarding-terms-and-condition-ancher"
        href="mailto:legal@canpaydebit.com">legal@canpaydebit.com </a> or 1-877-564-5174 or 3040 78th Ave SE, #856, Mercer Island WA 98040.
</label>

</div>
</div>
</div>

</br>
</br>
  </div>
</template>

<script>
import OnboardingHeader from "../OnBoardScreeen/OnboardingHeader.vue";
export default {
  /**
   * write a component's description
   * this component is use to Upgrade to the new canpay the user's email as a consumer
   */
  name: "OnboardingTermsAndCondition",
  /**
   * @description-

   * @returns {any}
   */
  data() {
    let self = this;
    return {};
  },
  components: {
    "cp-onboardingheader": OnboardingHeader,
  },
  destroyed() {
    document
      .getElementById("app")
      .style.setProperty("background-color", "#149240");
  },
  created() {},
  mounted() {
    this.$root.$emit("show_header", false);
    this.$root.$emit("changeWhiteBackground", [true, false, ""]);
    document
      .getElementById("app")
      .style.setProperty("background-color", "#ffffff");

    var element = document.getElementsByClassName("wrapper");
    element[0].style.setProperty("background-color", "#ffffff");

    var elementHtml = document.getElementsByTagName("html")[0];
    elementHtml.style.setProperty("background-color", "#ffffff");

    this.phone = localStorage.getItem("phone");
  },
  methods: {},
};
</script>
<style lang="scss"></style>
