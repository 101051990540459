<template>
  <div class="container splash ">
    <div class="col-12 canpay-logo-position">
      <img class="canpay-logo" src="../../assets/images/canpay-logo.png" />
    </div>
    <div class="col-12 ">
      <img class="upgrade-image " src="../../assets/images/placeholder.png" />
    </div>
    <div class="col-12 upgrade-to-the-new-CanPay">
      <label>Update to the new CanPay </label>
    </div>
    <div
      class="col-12 as-an-existing-customer-there-are-just-a-few-easy-steps-to-get"
    >

    </div>

    <div class="col-12 enter-the-email-you-use-to-sign-in-to-CanPay margin-ipad-pro">
    
    </div>
  
    <div class="row input-box-row row-for-input">
      <button
        type="button"
        class="btn-login btn-get-started"
        v-on:click="submitEmail"
      >
       Update App
      </button>
    </div>
    
  </div>
</template>
<script>
export default {
  /**
   * write a component's description
   * this component is use to Upgrade to the new canpay the user's email as a consumer
   */
  name: "UpgradeCanpayWithEmail",
  /**
   * @description-

   * @returns {any}
   */

  data() {
    let self = this;
    return {
      email: "",
      emailColor: "",
      error: false,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  destroyed() {
    document
      .getElementById("app")
      .style.setProperty("background-color", "#149240");
  },
  created() {},
  mounted() {
    this.$root.$emit("show_header", false);
    this.$root.$emit("changeWhiteBackground", [true, false, ""]);
    document
      .getElementById("app")
      .style.setProperty("background-color", "#ffffff");

    var element = document.getElementsByClassName("wrapper");
    element[0].style.setProperty("background-color", "#ffffff");
  },
  methods: {
    isEmailValid: function() {
      return this.email == ""
        ? ""
        : this.reg.test(this.email)
        ? "has-success"
        : "has-error";
    },
    submitEmail() {

       

        let self = this;
        if (self.email.length == 0) {
          self.emailColor = "red";
        } else if (self.isEmailValid() == "has-error") {
          this.error = true;
        } else {
          self.emailColor = "";
  this.$router.push("/emailverificationcode");
        }

    },
  },
};
</script>
<style lang="scss"></style>
