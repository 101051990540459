<template>
  <div class="container splash" v-if="bankAccount">
    <cp-onboardingheader></cp-onboardingheader>
    <div class="row">
      <div class="col-12 text-center">
        <img
          class="register-phone-image"
          src="../../assets/images/bank-acount.png"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12 upgrade-to-the-new-CanPay">
        <label
          >Please confirm the<br />
          account below is your<br />
          primary bank account
        </label>
      </div>
    </div>
    <div class="mt-5"></div>
    <div class="row mt-5 px-3">
      <div
        class="
          col-12
          enter-the-email-you-use-to-sign-in-to-CanPay
          bank-name
          text-left
          mt-3
        "
      >
        <label>{{ bankName }} </label>
      </div>
    </div>

    <div class="row mt-1 px-3">
      <div class="col-12">
        <div class="form-group no-..">
          <span class="form-control-icon">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 28 20"
              height="20"
              width="20"
              style="enable-background: new 0 0 28 20"
              xml:space="preserve"
            >
              <g>
                <path
                  d="M25,0H3C1.3,0,0,1.3,0,3v14c0,1.7,1.3,3,3,3h22c1.7,0,3-1.3,3-3V3C28,1.3,26.7,0,25,0z M3,2h22c0.6,0,1,0.4,1,1v2H2V3
		C2,2.4,2.4,2,3,2z M25,18H3c-0.6,0-1-0.4-1-1V7h24v10C26,17.6,25.6,18,25,18z"
                />
                <path
                  d="M10,13H6c-0.6,0-1,0.4-1,1s0.4,1,1,1h4c0.6,0,1-0.4,1-1S10.6,13,10,13z"
                />
              </g>
            </svg>
          </span>
          <input
            v-model="bankAccount"
            inputmode="email"
            type="text"
            class="form-control input-box-padding email-address"
            placeholder="xxxx - xxxx - xxxx - 4366"
            readonly
          />
        </div>
      </div>
    </div>

    <!--Button for Create/Manage Account-->
    <div class="row px-3" style="position: fixed; bottom: 4rem; width: 100%">
      <div class="col-12">
        <button type="button" class="btn-signup" v-on:click="confirm">
          My account is correct
        </button>
      </div>
    </div>
    <div class="row" style="position: fixed; bottom: 1rem; width: 100%">
      <div class="col-12">
        <span class="bl-footer-text" @click="update()">
          <u>Choose a different bank account</u>
        </span>
      </div>
    </div>

    <div>
      <b-modal
        ref="zero-pp-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="zero-pp-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="col-12 text-center">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="120"
                height="120"
                viewBox="0 0 100 125"
                style="enable-background: new 0 0 100 125"
                xml:space="preserve"
                fill="#e14343"
                class="on-boarding-upgrade-alert-icon"
              >
                <path
                  d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
                />
              </svg>
            </div>
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                {{ pp_alert_message }}
              </label>
            </div>
            <br />
            <br />
            <div class="text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="hideModal('zero-pp-modal')"
              >
                <label class="purchasepower-modal-ok-label">OK</label>
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import OnboardingHeader from "./OnboardingHeader.vue";
import api from "../../api/onboarding.js";
import constants from "../Common/constant.js";
export default {
  /**
   * write a component's description
   * this component is use to Upgrade to the new canpay the user's email as a consumer
   */
  name: "BankAccountRegistration",
  /**
   * @description-

   * @returns {any}
   */

  data() {
    let self = this;
    return {
      bankAccount: "",
      bankName: "",
      bankAccountNumberSubmit: "",
      routingNumber: "",
      pp_alert_message: "",
      sessionId: null,
    };
  },
  components: {
    "cp-onboardingheader": OnboardingHeader,
  },
  destroyed() {
    document
      .getElementById("app")
      .style.setProperty("background-color", "#149240");
  },
  created() {},
  mounted() {
    this.$root.$emit("show_header", false);
    this.$root.$emit("changeWhiteBackground", [true, false, ""]);
    document
      .getElementById("app")
      .style.setProperty("background-color", "#ffffff");

    var element = document.getElementsByClassName("wrapper");
    element[0].style.setProperty("background-color", "#ffffff");

    var elementHtml = document.getElementsByTagName("html")[0];
    elementHtml.style.setProperty("background-color", "#ffffff");
    this.sessionId = atob(window.location.href.split("/").splice(-2, 1)[0]);
    console.log("sessionId", this.sessionId);
    this.getBankDetails();
  },
  methods: {
    showModal(modal) {
      this.$refs[modal].show();
    },
    hideModal(modal) {
      this.$refs[modal].hide();
    },

    getBankDetails() {
      let self = this;
      var request = {
        session_id: self.sessionId,
      };
      if(localStorage.getItem("microbilt_error_need_bank_link") != null){
        localStorage.removeItem("microbilt_error_need_bank_link");
      }
      api
        .GetBankDetails(request)
        .then((response) => {
          if (response.code == 200) {
            (self.bankAccountNumberSubmit = response.data.account_number),
              (self.bankAccount = response.data.account_number.replace(
                /.(?=.{4})/g,
                "x"
              ));
            self.bankName = response.data.bank_name;
            self.routingNumber = response.data.routing_number;
          }
        })
        .catch((err) => {
          if (err.response.data.data.microbilt_error_need_bank_link == true) {
            localStorage.setItem("microbilt_error_need_bank_link", true);
          }

          if (err.response.data.code == 599) {
            this.$router.push(
              "/onboringconnectbankaccount/" +
                btoa(self.sessionId) +
                "/onboringconnectbankaccount"
            );
          }
          if (err.response.data.code == 401) {
            this.$router.push("/upgradecanpaywithemail");
          }
        });
    },

    update() {
      this.$router.push(
        "/onboringconnectbankaccount/" +
          btoa(this.sessionId) +
          "/onboringconnectbankaccount"
      );
    },
    confirm() {
      var request = {
        session_id: this.sessionId,
        routingNumber: this.routingNumber,
        accountNumber: this.bankAccountNumberSubmit,
        bank_from_v1: 1,
      };

      api
        .ValidateBankdetail(request)
        .then((response) => {
          if (response.code == 200) {
            this.$router.push("/registrationsuccess");
          }
        })
        .catch((err) => {
          // alert(err);

          this.pp_alert_message = err.response.data.message;
          this.showModal("zero-pp-modal");
          if (err.response.data.code == 401) {
            this.$router.push("/upgradecanpaywithemail");
          }
        });
    },
  },
};
</script>
<style lang="scss">
#zero-pp-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
</style>
