<template>
  <div class="container splash">
    <cp-onboardingheader></cp-onboardingheader>
    <div class="row">
      <div class="col-12 text-center">
        <img
          class="success-image"
          src="../../assets/images/success-image.png"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12 success-text-onboarding">
        <label>Success! </label>
      </div>
    </div>
    <div class="row">
      <div class="col-12 success-description-label">
        <label
          >You will now use phone number <br /><b>{{ phone }}</b> to sign in
          to<br />
          CanPay.
        </label>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-12">
        <button
          type="button"
          class="btn-login btn-next-onboarding"
          v-on:click="next"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import OnboardingHeader from "./OnboardingHeader.vue";
export default {
  /**
   * write a component's description
   * this component is use to Upgrade to the new canpay the user's email as a consumer
   */
  name: "ExistingPhoneNumberVerificationSuccess",
  /**
   * @description-

   * @returns {any}
   */

  data() {
    let self = this;
    return {
      phone: "",
      sessionId: null,
    };
  },

  components: {
    "cp-onboardingheader": OnboardingHeader,
  },
  destroyed() {
    document
      .getElementById("app")
      .style.setProperty("background-color", "#149240");
  },
  created() {},
  mounted() {
    this.$root.$emit("show_header", false);
    this.$root.$emit("changeWhiteBackground", [true, false, ""]);
    document
      .getElementById("app")
      .style.setProperty("background-color", "#ffffff");

    var element = document.getElementsByClassName("wrapper");
    element[0].style.setProperty("background-color", "#ffffff");

    var elementHtml = document.getElementsByTagName("html")[0];
    elementHtml.style.setProperty("background-color", "#ffffff");
    this.sessionId = atob(window.location.href.split("/").splice(-2, 1)[0]);
    this.phone = atob(window.location.href.split("/").splice(-3, 1)[0]);
    console.log("sessionId", this.sessionId);
    console.log("phone", this.phone);
  },
  methods: {
    next() {
      this.$router.push("/quickaccesspinset/" +
                btoa(this.sessionId)+"/quickaccesspinset");
    },
  },
};
</script>
<style lang="scss"></style>
