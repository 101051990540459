<template>
  <div class="container splash">
    <cp-onboardingheader></cp-onboardingheader>

    <div class="row">
      <div class="col-12 text-center">
        <img
          class="success-image"
          src="../../assets/images/success-image.png"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 success-text-onboarding">
        <label>Bank Account Confirmed! </label>
      </div>
    </div>
    <div class="row">
      <div class="col-12 success-description-label">
        <label
          >You are now ready to start using<br />
          the new CanPay.
        </label>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <button
          type="button"
          class="btn-login btn-next-onboarding"
          v-on:click="next"
        >
          Add CanPay to your home screen
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import OnboardingHeader from "./OnboardingHeader.vue";
export default {
  /**
   * write a component's description
   * this component is use to Upgrade to the new canpay the user's email as a consumer
   */
  name: "registrationsuccess",
  /**
   * @description-

   * @returns {any}
   */

  data() {
    let self = this;
    return {};
  },
  components: {
    "cp-onboardingheader": OnboardingHeader,
  },
  destroyed() {
    document
      .getElementById("app")
      .style.setProperty("background-color", "#149240");
  },
  created() {},
  mounted() {
    this.$root.$emit("show_header", false);
    this.$root.$emit("changeWhiteBackground", [true, false, ""]);
    document
      .getElementById("app")
      .style.setProperty("background-color", "#ffffff");

    var element = document.getElementsByClassName("wrapper");
    element[0].style.setProperty("background-color", "#ffffff");

    var elementHtml = document.getElementsByTagName("html")[0];
    elementHtml.style.setProperty("background-color", "#ffffff");
  },
  methods: {
    next() {
      this.$router.push("/onboardingsingin");
    },
  },
};
</script>
<style lang="scss"></style>
