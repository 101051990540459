<template>
  <div>
    <div class="row input-box-row">
      <div class="col-2"></div>
      <div class="col-8">
        <span class="text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 100 125"
            style="enable-background:new 0 0 100 100;"
            xml:space="preserve"
            height="80"
            width="80"
            fill="#ffffff"
          >
            <g>
              <path
                d="M65.9,6.6H34.8c-3.4,0-6.1,2.8-6.1,6.1v75.7c0,3.4,2.8,6.1,6.1,6.1h31.1c3.4,0,6.1-2.8,6.1-6.1V12.7   C72,9.3,69.2,6.6,65.9,6.6z M69.5,88c0,2.2-1.8,4-4,4H35.2c-2.2,0-4-1.8-4-4V13.1c0-2.2,1.8-4,4-4h4.5v0.7c0,1.3,1.1,2.4,2.4,2.4   h16.7c1.3,0,2.4-1.1,2.4-2.4V9.1h4.4c2.2,0,4,1.8,4,4V88z"
              />
              <circle cx="40" cy="53.8" r="3.7" />
              <circle cx="50.3" cy="53.8" r="3.7" />
              <path
                d="M60.7,50.1c-2,0-3.7,1.6-3.7,3.7s1.6,3.7,3.7,3.7s3.7-1.6,3.7-3.7S62.7,50.1,60.7,50.1z"
              />
              <path
                d="M40,60.4c-2,0-3.7,1.6-3.7,3.7s1.6,3.7,3.7,3.7s3.7-1.6,3.7-3.7S42,60.4,40,60.4z"
              />
              <path
                d="M50.3,60.4c-2,0-3.7,1.6-3.7,3.7s1.6,3.7,3.7,3.7s3.7-1.6,3.7-3.7S52.4,60.4,50.3,60.4z"
              />
              <path
                d="M60.7,60.4c-2,0-3.7,1.6-3.7,3.7s1.6,3.7,3.7,3.7s3.7-1.6,3.7-3.7S62.7,60.4,60.7,60.4z"
              />
              <path
                d="M40,70.8c-2,0-3.7,1.6-3.7,3.7c0,2,1.6,3.7,3.7,3.7s3.7-1.6,3.7-3.7C43.7,72.4,42,70.8,40,70.8z"
              />
              <path
                d="M50.3,70.8c-2,0-3.7,1.6-3.7,3.7c0,2,1.6,3.7,3.7,3.7s3.7-1.6,3.7-3.7C54,72.4,52.4,70.8,50.3,70.8z"
              />
              <path
                d="M50.3,81.1c-2,0-3.7,1.6-3.7,3.7s1.6,3.7,3.7,3.7s3.7-1.6,3.7-3.7S52.4,81.1,50.3,81.1z"
              />
              <path
                d="M60.7,70.8c-2,0-3.7,1.6-3.7,3.7c0,2,1.6,3.7,3.7,3.7s3.7-1.6,3.7-3.7C64.3,72.4,62.7,70.8,60.7,70.8z"
              />
            </g>
          </svg>
        </span>
      </div>
      <div class="col-2"></div>
    </div>
    <div class="row text-msg-label">
      <div class="col-12">
        <span class="enter-otp-title-style"
          >Enter Text Message Verification Code</span
        >
      </div>
    </div>
    <div class="row enter-otp-input">
      <div class="col-12">
        <div
          v-bind:class="[
            error == true ? 'form-group red-border' : ' form-group no-border',
          ]"
        >
          <input
            type="text"
            autocomplete="nope"
            class="form-control otp-curser"
            @change="addEvent"
            @input="addEvent"
            inputmode="numeric"
            @keypress="isNumber($event)"
            @keyup.enter="enterOTP"
            v-model="otp"
            maxlength="6"
            placeholder="Enter Code"
            v-bind:style="{ 'border-color': otpColor }"
            style="padding-left: 10px !important;"
          />
        </div>
      </div>
    </div>
    <div class="error-div" v-if="error">
      <label class="error-text-style"
        >You must enter a valid code to continue</label
      >
    </div>

    <div class="row input-box-row">
      <div class="col-12">
        <button type="button" class="btn-verify" @click="enterOTP">
          Verify
        </button>
      </div>
    </div>
    <div class="row text-msg-label">
      <div class="col-12">
        <span class="enter-otp-title-style" style="font-weight: 100 !important;"
          >Didn’t get the Text Message?</span
        >
      </div>
    </div>
    <div class="row btn-resend-code" v-if="!codesentFlag && timerCount==0 && resentOtp!=3" >
      <button type="button" class="btn-ResendCode" @click="verifyPhoneNumber">
        Resend Code
      </button>
    </div>
    <div class="row btn-resend-code" v-else-if="timerCount!=0">
      <button type="button" class="btn-ResendCode" disabled>
        <span class="enter-otp-title-style"> Resend Code After &nbsp; &nbsp; &nbsp; &nbsp; {{timerCount}} Seconds</span>
        
      </button>
    </div>
     <div class="row btn-resend-code" v-else>
      <button type="button" class="btn-ResendCode" disabled>
        <span class="enter-otp-title-style"> Try Again Some Other Time </span>
        
      </button>
    </div>
    <!-----------------------  MODAL FOR SUCCESS  !----------------->

    <div>
      <b-modal
        ref="my-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        no-close-on-backdrop
        id="pay-modal-center"
        centered
        title="BootstrapVue"
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="success-top-spacing"></div>
            <div class="row" style="margin-bottom:5px;">
              <div class="col-4"></div>
              <div class="col-4">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 100 125"
                  style="enable-background:new 0 0 100 125;"
                  xml:space="preserve"
                  height="80"
                  width="80"
                  fill="#1b9142"
                  class="succes-svg-padding"
                >
                  <g>
                    <g>
                      <path
                        d="M50.2,99.7c-27,0-49-21.9-49-48.9S23.2,2,50.2,2s49,21.9,49,48.9S77.2,99.7,50.2,99.7z M50.2,7.1
			C26,7.1,6.4,26.7,6.4,50.8S26,94.6,50.2,94.6S94.1,75,94.1,50.8S74.4,7.1,50.2,7.1z"
                      />
                    </g>
                    <g>
                      <polygon
                        points="47.4,69.2 29,52.3 32.5,48.5 46.6,61.4 67,31.7 71.3,34.7 		"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div class="col-4"></div>
            </div>
            <div class="d-block text-center">
              <label class="success-popup-style">Success!</label>
            </div>
            <div class="success-bottom-spacing"></div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import api from "../../api/registration.js";
export default {
  name: "EnterOTP",

  data() {
    return {
      otp: "",
      codesentFlag: false,
      otpColor: "",
      error: "",
      verifiedOTP: "",
      timerCount : 60,
      resentOtp:1,
      
    };
  },
  props: [
    "SessionID",
    "PhoneNumber",
    "Fname",
    "Mname",
    "Lname",
    "errorProp",
    "verifiedOTPProp",
  ],
  mounted() {
    var self = this;
    self.error = self.errorProp;
    self.verifiedOTP = self.verifiedOTPProp;
  },
    
  watch: {
    verifiedOTP: function(val) {
      if (this.verifiedOTP == true) {
        this.otp = "";
        this.showModal();
      }
    },
    verifiedOTPProp: function(val) {
      this.verifiedOTP = val;
    },
    errorProp: function(val) {
      this.error = val;
    },
    timerCount: {
                handler(value) {

                    if (value > 0) {
                        setTimeout(() => {
                          // alert();
                            this.timerCount--;
                        }, 1000);
                    }
                    else
                    {
                       this.codesentFlag = false;
                    }

                },
                immediate: true // This ensures the watcher is triggered upon creation
            }
  },
  methods: {
    showModal() {
      let self = this;
      this.$refs["my-modal"].show();
      setTimeout(function() {
        self.hideModal();
      }, 1000);
    },
    hideModal() {
      this.$refs["my-modal"].hide();
      this.$root.$emit("verified_OTP", [""]);
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    addEvent() {
      this.otpColor = "";
      this.error = false;
    },
    enterOTP() {
      this.codesentFlag = false;
      if (this.otp == "") {
        this.otpColor = "red";
        return false;
      } else {
        this.$root.$emit("EnterOtp", ["otp", this.otp]);
      }
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    verifyPhoneNumber: function() {
      if(this.resentOtp==1)
      {
    this.timerCount = 120 ;
      this.PhoneNumber = this.PhoneNumber.replace(/[^a-zA-Z0-9]/g, "");
      this.codesentFlag = false;
      this.otp = "";
      let self = this;
      var request = {
        sessionId: this.SessionID,
        phoneNo: this.PhoneNumber,
        firstName: this.Fname,
        middleName: this.Mname,
        lastName: this.Lname,
        resend:1
      };
      api
        .verifyPhonenumber(request)
        .then((response) => {
          if (response.code == 200) {
            this.codesentFlag = true;
            this.resentOtp++;
          }
        })
        .catch((err) => {});
        
      }
      else if(this.resentOtp==2)
      {
        this.timerCount = 180 ;
      this.PhoneNumber = this.PhoneNumber.replace(/[^a-zA-Z0-9]/g, "");
      this.codesentFlag = false;
      this.otp = "";
      let self = this;
      var request = {
        sessionId: this.SessionID,
        phoneNo: this.PhoneNumber,
        firstName: this.Fname,
        middleName: this.Mname,
        lastName: this.Lname,
      };
      api
        .verifyPhonenumber(request)
        .then((response) => {
          if (response.code == 200) {
            this.codesentFlag = true;
            this.resentOtp++;
          }
        })
        .catch((err) => {});
      }

  
    },
 
  },

};
</script>
<style>
::placeholder {
  color: rgb(0, 0, 0);
  opacity: 1;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
}
#pay-modal-center___BV_modal_content_ {
  border-radius: 10px;
  margin: 10px;
  background-color: #ffffff;
}
#pay-modal-center___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
</style>
