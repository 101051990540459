<template>
  <div class="container splash">
    <cp-onboardingheader></cp-onboardingheader>
    <div class="row">
      <div class="col-12 text-center">
        <img
          class="register-phone-image"
          src="../../assets/images/terms-and-condition.png"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 register-phone-number-text">
        <label>We've updated CanPay's <br />Terms and Conditions </label>
      </div>
    </div>
    <div class="row mt-5 p-3">
      <div class="col-02 squaredOne" style="margin-top: 0.3rem;">
        <input
          type="checkbox"
          v-model="checked"
          id="squaredOne"
          v-on:click="addBoxShadow"
        />
        <label for="squaredOne"></label>
      </div>
      <div class="col-10 terms-checkbox-onboard-position text-left align-self-center">
        <label
          >I have read and agreed to the
          <span
            class="terms-checkbox-onboard"
            v-on:click="redirectToTermsAndCondition"
            ><b>Terms of Service End User License Agreement</b></span
          >
          ,
          <span
            class="terms-checkbox-onboard"
            v-on:click="redirectToPrivacyPolicy"
          >
            <b>Privacy Policy</b></span
          >, and to receive messages from CanPay.
        </label>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <button
          type="button"
          class="btn-login btn-next-checkbox"
          v-on:click="next"
        >
          Next
        </button>
      </div>
    </div>
    <!---- MODAL FOR  Error Modal ---->

    <div>
      <b-modal
        ref="zero-pp-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="zero-pp-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="col-12 text-center">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="120"
                height="120"
                viewBox="0 0 100 125"
                style="enable-background: new 0 0 100 125"
                xml:space="preserve"
                fill="#e14343"
                class="on-boarding-upgrade-alert-icon"
              >
                <path
                  d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
                />
              </svg>
            </div>
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                {{ pp_alert_message }}
              </label>
            </div>
            <br />
            <br />
            <div class="text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="hideModal('zero-pp-modal')"
              >
                <span class="purchasepower-modal-ok-label">Close</span>
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import OnboardingHeader from "./OnboardingHeader.vue";
import api from "../../api/onboarding.js";
import constants from "../Common/onboaringconstant.js";
export default {
  /**
   * write a component's description
   * this component is use to Upgrade to the new canpay the user's email as a consumer
   */
  name: "TermsAndCondition",
  /**
   * @description-

   * @returns {any}
   */

  data() {
    let self = this;
    return {
      checked: false,
      pp_alert_message: "",
      sessionId:null,
      email:null
    };
  },
  components: {
    "cp-onboardingheader": OnboardingHeader,
  },
  destroyed() {
    document
      .getElementById("app")
      .style.setProperty("background-color", "#149240");
  },
  created() {},
  mounted() {
    this.$root.$emit("show_header", false);
    this.$root.$emit("changeWhiteBackground", [true, false, ""]);
    document
      .getElementById("app")
      .style.setProperty("background-color", "#ffffff");

    var element = document.getElementsByClassName("wrapper");
    element[0].style.setProperty("background-color", "#ffffff");
    this.sessionId =  atob(window.location.href.split("/").splice(-3, 1));
    this.email = atob(window.location.href.split("/").splice(-4, 1));
    console.log("session",atob(window.location.href.split("/").splice(-3, 1)));
    console.log("email",atob(window.location.href.split("/").splice(-4, 1)));

  },
  methods: {
    addBoxShadow() {
      let self = this;
      if (self.checked) {
        var element = document.getElementsByClassName("squaredOne");
        element[0].style.setProperty("box-shadow", "0 0 0 1.5px #eeeeee");
      } else {
        var element = document.getElementsByClassName("squaredOne");
        element[0].style.setProperty("box-shadow", "0 0 0 1.5px #179346");
      }
    },
    next() {
      let self = this;
      if (self.checked) {
        this.redirectValidStep();
      } else {
        self.pp_alert_message =
          "Please accept Terms and Conditions and Privacy Policy.";
        this.showModal("zero-pp-modal");
      }
    },
    redirectValidStep() {
      var request = {
        email: this.email,
        session_id: this.sessionId,
      };
      api
        .RedirectToValidStep(request)
        .then((response) => {
          if (response.code == 200) {
            if (response.data == constants.enter_phone) {
              this.$router.push("/existingphonenumberverification/"+btoa(this.sessionId)+"/existingphonenumberverification");
            } else if (response.data == constants.enter_quick_access_pin) {
              this.$router.push("/quickaccesspinset/"+btoa(this.sessionId)+"/quickaccesspinset");
            } else if (
              response.data ==
              constants.bank_details_from_ach_or_finicity_or_manual
            ) {
              this.$router.push("/bankaccountregistration/"+btoa(this.sessionId)+"/bankaccountregistration");
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.pp_alert_message = err.response.data.message;
          this.showModal("zero-pp-modal");

          if (err.response.data.code == 401) {
          console.log("test");
          this.pp_alert_message = err.response.data.message;
          this.showModal("zero-pp-modal");
          }
        });
    },
    showModal(modal) {
      this.$refs[modal].show();
    },
    hideModal(modal) {
      this.$refs[modal].hide();
    },
    redirectToTermsAndCondition() {
      this.$router.push("/onboardingtermsandcondition");
    },
    redirectToPrivacyPolicy() {
      this.$router.push("/onboardingprivacypolicy");
    },
  },
};
</script>
<style lang="scss">
#zero-pp-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
</style>
