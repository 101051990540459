<template>
  <div class="container">
    <!-- text row -->
    <div class="row ssn-row">
      <div class="col-12">
        <span class="text-center">
          <label class="ssn-heading">
            Enter the last four digits of your
            <br />Social Security Number
          </label>
        </span>
      </div>
    </div>
    <!-- label 1 -->
    <div class="row justify-content-center align-self-center">
      <div class="col-1"></div>
      <div class="col-10">
        <label class="digit-text-style">Last four digits</label>
      </div>
      <div class="col-1"></div>
    </div>
    <!-- ssn row 1 -->
    <div class="row">
      <div class="col-1"></div>
      <div id="ssn_div" class="col-10 m-l-7">
        <input
          id="ssn1"
          autocomplete="off"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          v-on:keyup="isNumber($event)"
          maxlength="1"
          inputmode="numeric"
        />
        <input
          id="ssn2"
          autocomplete="off"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          v-on:keyup="isNumber($event)"
          maxlength="1"
          inputmode="numeric"
        />
        <input
          id="ssn3"
          autocomplete="off"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          v-on:keyup="isNumber($event)"
          maxlength="1"
          inputmode="numeric"
        />
        <input
          id="ssn4"
          autocomplete="off"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          v-on:keyup="isNumber($event)"
          maxlength="1"
          inputmode="numeric"
        />
        <span
          class="input-group-addon"
          style="vertical-align: super"
          v-on:click="switchVisibility"
        >
          <a>
            <i
              class="fa fa-eye-slash eye-icon"
              aria-hidden="true"
              v-if="!show"
            ></i>
            <i class="fa fa-eye eye-icon" aria-hidden="true" v-if="show"></i>
          </a>
        </span>
      </div>
      <div id="ssn_view_div" class="col-10 m-l-7 visibility">
        <input
          id="ssn1_view"
          v-model="ssn1"
          class="form-control ssn-text ssn-input-box-padding"
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <input
          id="ssn2_view"
          v-model="ssn2"
          class="form-control ssn-text ssn-input-box-padding"
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <input
          id="ssn3_view"
          v-model="ssn3"
          class="form-control ssn-text ssn-input-box-padding"
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <input
          id="ssn4_view"
          v-model="ssn4"
          class="form-control ssn-text ssn-input-box-padding"
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <span class="input-group-addon" style="vertical-align: super">
          <a v-on:click="switchVisibility">
            <i
              class="fa fa-eye-slash eye-icon"
              aria-hidden="true"
              v-if="!show"
            ></i>
            <i class="fa fa-eye eye-icon" aria-hidden="true" v-if="show"></i>
          </a>
        </span>
      </div>
      <div class="col-1 eye-icon-div">
        <!-- <a v-on:click="switchVisibility">
          <i
            class="fa fa-eye-slash eye-icon"
            aria-hidden="true"
            v-if="!show"
          ></i>
          <i class="fa fa-eye eye-icon" aria-hidden="true" v-if="show"></i>
        </a> -->
      </div>
      <input type="hidden" id="ssn_current_state" value="0" />
    </div>
    <!-- label 2 -->
    <div class="row justify-content-center align-self-center">
      <div class="col-1"></div>
      <div class="col-10">
        <label class="reenter-digit-text-style">Re-enter last 4 digits</label>
      </div>
      <div class="col-1"></div>
    </div>
    <!-- ssn row 2 -->
    <div class="row">
      <div class="col-1"></div>
      <div id="re_enter_ssn_div" class="col-10 m-l-7">
        <input
          id="re_enter_ssn1"
          autocomplete="off"
          ref="reenterssn1"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          v-on:keyup="isNumber($event)"
          maxlength="1"
          inputmode="numeric"
        />
        <input
          id="re_enter_ssn2"
          autocomplete="off"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          v-on:keyup="isNumber($event)"
          maxlength="1"
          inputmode="numeric"
        />
        <input
          id="re_enter_ssn3"
          autocomplete="off"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          v-on:keyup="isNumber($event)"
          maxlength="1"
          inputmode="numeric"
        />
        <input
          id="re_enter_ssn4"
          autocomplete="off"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          v-on:keyup="isNumber($event)"
          @keyup.enter="continueIdvalidation"
          maxlength="1"
          inputmode="numeric"
        />
        <span class="input-group-addon" style="vertical-align: super">
          <a v-on:click="switchReEnterVisibility">
            <i
              class="fa fa-eye-slash eye-icon"
              aria-hidden="true"
              v-if="!reshow"
            ></i>
            <i class="fa fa-eye eye-icon" aria-hidden="true" v-if="reshow"></i>
          </a>
        </span>
      </div>
      <div id="re_enter_ssn_view_div" class="col-10 m-l-7 visibility">
        <input
          id="re_enter_ssn1_view"
          v-model="re_enter_ssn1"
          class="form-control ssn-text ssn-input-box-padding"
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <input
          id="re_enter_ssn2_view"
          v-model="re_enter_ssn2"
          class="form-control ssn-text ssn-input-box-padding"
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <input
          id="re_enter_ssn3_view"
          v-model="re_enter_ssn3"
          class="form-control ssn-text ssn-input-box-padding"
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <input
          id="re_enter_ssn4_view"
          v-model="re_enter_ssn4"
          class="form-control ssn-text ssn-input-box-padding"
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <span class="input-group-addon" style="vertical-align: super">
          <a v-on:click="switchReEnterVisibility">
            <i
              class="fa fa-eye-slash eye-icon"
              aria-hidden="true"
              v-if="!reshow"
            ></i>
            <i class="fa fa-eye eye-icon" aria-hidden="true" v-if="reshow"></i>
          </a>
        </span>
      </div>
      <div class="col-1 eye-icon-div">
      </div>
      <input type="hidden" id="re_enter_ssn_current_state" value="0" />
    </div>
    <div class="row justify-content-center align-self-center"><div class="col-1"></div><div class="col-10"><label class="pin-link-text-style" @click="showPinTextbox">Having trouble entering your SSN?</label></div><div class="col-1"></div></div>
    <!-- btn row -->
    <div class="row button-space">
      <div class="col-12 col-padding btn-space">
        <button type="button" class="btn-login" @click="continueIdvalidation">
          Next
        </button>
      </div>
    </div>

    <!-- Validation modal -->
    <b-modal
      ref="validation-modal"
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      hide-header
      id="validation-modal"
      centered
    >
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              {{ error_message }}
            </label>
          </div>
          <br />
          <br />
          <div class="text-center">
            <button
              type="button"
              class="mx-auto col-10 offset-1 btn-black"
              style="height: 60px"
              @click="hidevalidationModal"
            >
              <label class="purchasepower-modal-ok-label">OK</label>
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- SSN Textbox modal -->
    <b-modal
      ref="ssn-textbox-modal"
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      no-close-on-backdrop
      hide-header
      id="ssn-textbox-modal"
      centered
    >
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div class="d-block"><label class="update-modal-title">Last four digits</label></div>
          <div class="row"><div class="col-10 text-center"><input type="password" name="modal_ssn" id="modal_ssn" maxlength="4" v-model="modal_ssn" class="form-control"></div><div class="col-2"><span class="input-group-addon" style="vertical-align: middle;" @click="toggleSsnInputType('modal_ssn');"><a><i aria-hidden="true" :class="inputSsnCls"></i></a></span></div></div>
          <div class="d-block"><label class="update-modal-title">Re-enter last 4 digits</label></div>
          <div class="row"><div class="col-10 text-center"><input type="password" name="re_enter_modal_ssn" id="re_enter_modal_ssn" maxlength="4" v-model="re_enter_modal_ssn" class="form-control"/></div><div class="col-2"><span class="input-group-addon" style="vertical-align: middle;" @click="toggleSsnInputType('re_enter_modal_ssn');"><a><i aria-hidden="true" :class="inputSsnCls1"></i></a></span></div></div>
          <div class="row justify-content-center mt-3">
            <div class="col-10 col-md-8 row">
              <div class="col-6">
                <button class="profile-btn-cancel w-100" @click="hidePinTextbox"><span class="forgetpassword-ok-label">Cancel</span></button>
              </div>
              <div class="col-6">
                <button class="profile-btn-ok w-100" @click="continueIdvalidationModal"><span class="forgetpassword-ok-label">Next</span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
/**
 * write a component's description
 * this component is use to login the user as a consumer
 */
export default {
  name: "EnterSSN",
  /**
   * @description-
   * ssn => this will take the consumer's phone no
   * ssn => this will take the consumer's password
   * pinColor => use to validate the password field
   * ssnColor => use to validate the phoneno field
   * token => use to store the user's token
   * @returns {any}
   */
  data() {
    let self = this;
    return {
      show: false,
      reshow: false,
      error_message: "",
      ssn1: "",
      ssn2: "",
      ssn3: "",
      ssn4: "",
      re_enter_ssn1: "",
      re_enter_ssn2: "",
      re_enter_ssn3: "",
      re_enter_ssn4: "",
      ssnColor: "",
      modal_ssn: "",
      re_enter_modal_ssn: "",
      inputSsnCls: "fa fa-eye-slash onboarding-color-black",
      inputSsnCls1: "fa fa-eye-slash onboarding-color-black"
    };
  },
  created() {
    this.$root.$emit("changeWhiteBackground", [false, true, "common"]);
  },
  mounted() {
    var self = this;
    this.$root.$emit("changeWhiteBackground", [false, true, "common"]);
    self.maskSSN();
    self.maskReEnterSSN();
  },
  methods: {
    showValidationModal(msg) {
      this.error_message = msg;
      this.$refs["validation-modal"].show();
    },
    hidevalidationModal() {
      this.error_message = "";
      this.$refs["validation-modal"].hide();
    },
    showPinTextbox(){
      this.inputSsnCls = 'fa fa-eye-slash onboarding-color-black';
      this.inputSsnCls1 = 'fa fa-eye-slash onboarding-color-black';
      this.$refs["ssn-textbox-modal"].show();
    },
    hidePinTextbox() {
      this.modal_ssn = "";
      this.re_enter_modal_ssn = "";
      this.$refs["ssn-textbox-modal"].hide();
    },
    toggleSsnInputType(id){
      var self = this;
      var inputType = document.getElementById(id);
      if (inputType.type === "password") {
        inputType.type = "text";
        if(id == 'modal_ssn'){
          self.inputSsnCls = 'fa fa-eye onboarding-color-black';
        }else{
          self.inputSsnCls1 = 'fa fa-eye onboarding-color-black';
        }
      } else {
        inputType.type = "password";
        if(id == 'modal_ssn'){
          self.inputSsnCls = 'fa fa-eye-slash onboarding-color-black';
        }else{
          self.inputSsnCls1 = 'fa fa-eye-slash onboarding-color-black';
        }
      }
    },
    maskSSN() {
      var self = this;
      var ysp1 = new YouShallPass("*");
      var ysp2 = new YouShallPass("*");
      var ysp3 = new YouShallPass("*");
      var ysp4 = new YouShallPass("*");
      ysp1.fixIE9();
      ysp2.fixIE9();
      ysp3.fixIE9();
      ysp4.fixIE9();
      document
        .querySelector("#ssn1")
        .addEventListener("input", ysp1.keyboardInputHandle.bind(ysp1));
      document.querySelector("#ssn1").addEventListener("input", function () {
        self.ssn1 = ysp1.realText;
      });
      document
        .querySelector("#ssn2")
        .addEventListener("input", ysp2.keyboardInputHandle.bind(ysp2));
      document.querySelector("#ssn2").addEventListener("input", function () {
        self.ssn2 = ysp2.realText;
      });
      document
        .querySelector("#ssn3")
        .addEventListener("input", ysp3.keyboardInputHandle.bind(ysp3));
      document.querySelector("#ssn3").addEventListener("input", function () {
        self.ssn3 = ysp3.realText;
      });
      document
        .querySelector("#ssn4")
        .addEventListener("input", ysp4.keyboardInputHandle.bind(ysp4));
      document.querySelector("#ssn4").addEventListener("input", function () {
        self.ssn4 = ysp4.realText;
      });
    },
    maskReEnterSSN() {
      var self = this;
      var ysp1 = new YouShallPass("*");
      var ysp2 = new YouShallPass("*");
      var ysp3 = new YouShallPass("*");
      var ysp4 = new YouShallPass("*");
      ysp1.fixIE9();
      ysp2.fixIE9();
      ysp3.fixIE9();
      ysp4.fixIE9();
      document
        .querySelector("#re_enter_ssn1")
        .addEventListener("input", ysp1.keyboardInputHandle.bind(ysp1));
      document
        .querySelector("#re_enter_ssn1")
        .addEventListener("input", function () {
          self.re_enter_ssn1 = ysp1.realText;
        });
      document
        .querySelector("#re_enter_ssn2")
        .addEventListener("input", ysp2.keyboardInputHandle.bind(ysp2));
      document
        .querySelector("#re_enter_ssn2")
        .addEventListener("input", function () {
          self.re_enter_ssn2 = ysp2.realText;
        });
      document
        .querySelector("#re_enter_ssn3")
        .addEventListener("input", ysp3.keyboardInputHandle.bind(ysp3));
      document
        .querySelector("#re_enter_ssn3")
        .addEventListener("input", function () {
          self.re_enter_ssn3 = ysp3.realText;
        });
      document
        .querySelector("#re_enter_ssn4")
        .addEventListener("input", ysp4.keyboardInputHandle.bind(ysp4));
      document
        .querySelector("#re_enter_ssn4")
        .addEventListener("input", function () {
          self.re_enter_ssn4 = ysp4.realText;
        });
    },
    switchVisibility() {
      var self = this;
      if ($("#ssn_current_state").val() == 0) {
        $("#ssn_current_state").val("1");
        self.show = true;
        $("#ssn_div").addClass("visibility");
        $("#ssn_view_div").removeClass("visibility");
      } else {
        $("#ssn_current_state").val("0");
        self.show = false;
        $("#ssn_view_div").addClass("visibility");
        $("#ssn_div").removeClass("visibility");
      }
    },
    switchReEnterVisibility() {
      var self = this;
      if ($("#re_enter_ssn_current_state").val() == 0) {
        $("#re_enter_ssn_current_state").val("1");
        self.reshow = true;
        $("#re_enter_ssn_div").addClass("visibility");
        $("#re_enter_ssn_view_div").removeClass("visibility");
      } else {
        $("#re_enter_ssn_current_state").val("0");
        self.reshow = false;
        $("#re_enter_ssn_view_div").addClass("visibility");
        $("#re_enter_ssn_div").removeClass("visibility");
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var self = this;
      if (evt.keyCode == 8 || evt.keyCode == 46) {
        if (evt.target.previousElementSibling != null) {
          evt.target.previousElementSibling.focus();
        }
      } else {
        if (evt.target.nextElementSibling != null) {
          evt.target.nextElementSibling.focus();
        }
        if (evt.target.id === "ssn4") {
          self.$refs.reenterssn1.focus();
        }
      }
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    continueIdvalidation() {
      self = this;
      var ssn = self.ssn1 + self.ssn2 + self.ssn3 + self.ssn4;
      var confirm_ssn =
        self.re_enter_ssn1 +
        self.re_enter_ssn2 +
        self.re_enter_ssn3 +
        self.re_enter_ssn4;
      if (ssn != confirm_ssn) {
        self.showValidationModal("Both of SSN number does not match.");
        return false;
      }
      this.$root.$emit("emit_ssn", ssn);
    },
    continueIdvalidationModal() {
      self = this;
      var ssn = self.modal_ssn;
      var confirm_ssn =
        self.re_enter_modal_ssn;
      if (ssn != confirm_ssn) {
        self.showValidationModal("Both of SSN number does not match.");
        return false;
      }
      this.$root.$emit("emit_ssn", ssn);
    },
  },
};
</script>
