
<template>
  <div class="pre-header">
    <div class="col-12">
      <img
        class="canpay-logo-onboard"
        src="../../assets/images/canpay-logo.png"
      />
    </div>

    <div class="col-12 get-the-canpay-app-label">
      <label>Get the CanPay App</label>
    </div>
    <div class="col-12 get-the-canpay-app-description-label">
      <label
        >In your <strong>Safari</strong> web browser, <br />simply tap the
        {{ space }}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          hieght="14"
          width="14"
          fill="#179346"
          viewBox="0 0 66.6 78.625"
          style="margin-right: 0.5rem; margin-left: 0.5rem"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <g>
                <path
                  d="M36.3,53.925v-44l11.6,10.8,4.1-4.4L35.3.825a2.95947,2.95947,0,0,0-4.1,0l-16.6,15.5,4.1,4.4,11.6-10.8v44Z"
                />
                <path
                  d="M0,31.325v41.3a6.01763,6.01763,0,0,0,6,6H60.6a6.01763,6.01763,0,0,0,6-6v-41.3a6.01763,6.01763,0,0,0-6-6H47.3v6H60.6v41.3H6v-41.3H19.3v-6H6A6.01764,6.01764,0,0,0,0,31.325Z"
                />
              </g>
            </g>
          </g></svg
        >{{ space }}button in the <br />
        tool bar, scroll down, and choose <br />
        <strong>“Add to Home Screen”.</strong>
      </label>
    </div>
    <div class="col-12">
      <img
        class="canpay-moble-screen"
        src="../../assets/images/smart-phone.png"
      />
    </div>
    <div class="next-button-color-onboardingsingin">
      <div class="row input-box-row">
        <button
          type="button"
          class="btn-login onboard-btn-sign-in"
          v-on:click="next"
        >
          Sign In
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  /**
   * write a component's description
   * this component is use to Upgrade to the new canpay the user's email as a consumer
   */
  name: "UpgradeCanpayWithEmail",
  /**
   * @description-

   * @returns {any}
   */

  data() {
    let self = this;
    return {
      email: "",
      emailColor: "",
      error: false,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      space: " ",
    };
  },
  destroyed() {
    document
      .getElementById("app")
      .style.setProperty("background-color", "#149240");
  },
  created() {},
  mounted() {
    this.$root.$emit("show_header", false);
    this.$root.$emit("changeWhiteBackground", [true, false, ""]);
    document
      .getElementById("app")
      .style.setProperty("background-color", "#ffffff");

    var element = document.getElementsByClassName("wrapper");
    element[0].style.setProperty("background-color", "#ffffff");
  },
  methods: {
    next() {
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss"></style>
