<template>
<div>
  <div v-if="isLoading">
   <CanPayLoader/>
  </div>
  <div class="container splash">
    <cp-onboardingheader></cp-onboardingheader>

    <div v-if="!isLoading">
      <div class="row">
        <div class="col-12 text-center">
          <img
            class="upgrade-image"
            src="../../assets/images/upgrade-icon.png"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12 upgrade-to-the-new-CanPay">
          <label>Update to the new CanPay</label>
        </div>
      </div>

      <div class="row">
        <div
          class="
            col-12
            as-an-existing-customer-there-are-just-a-few-easy-steps-to-get
          "
        >
          <label
            >As an existing customer, there are<br />
            just a few easy steps to get you
            <br />launched.
          </label>
        </div>
      </div>
      <div class="row mt-4">
        <div
          class="
            col-12
            as-an-existing-customer-there-are-just-a-few-easy-steps-to-get
          "
        >
          <label @click="gotologin()"
            ><u>If you already updated Login here.</u>
          </label>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 enter-the-email-you-use-to-sign-in-to-CanPay mt-4">
          <label class="float-left"
            >Enter the email you use to sign in to CanPay
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group no-border">
            <span class="form-control-icon">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 32 40"
                style="enable-background: new 0 0 32 40"
                xml:space="preserve"
                height="20"
                width="20"
              >
                <g>
                  <g>
                    <path
                      d="M30,28H2c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1h28c0.6,0,1,0.4,1,1v22C31,27.6,30.6,28,30,28z M3,26h26V6H3V26z"
                    />
                  </g>
                  <g>
                    <path
                      d="M16,20.4c-0.2,0-0.4-0.1-0.5-0.2l-14-9C1.2,11.1,1,10.7,1,10.4V5c0-0.6,0.4-1,1-1h28c0.6,0,1,0.4,1,1v5.4
			c0,0.3-0.2,0.7-0.5,0.8l-14,9C16.4,20.4,16.2,20.4,16,20.4z M3,9.9l13,8.4l13-8.4V6H3V9.9z"
                    />
                  </g>
                </g>
              </svg>
            </span>
            <input
              v-model="email"
              @keyup.enter="submitEmail()"
              inputmode="email"
              type="text"
              class="
                form-control
                input-box-padding
                email-address
                text-lowercase
              "
              placeholder="Email address"
              v-bind:style="{ 'border-color': emailColor }"
            />
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <button
            type="button"
            class="btn-login btn-get-started"
            v-on:click="submitEmail"
          >
            Get Started
          </button>
        </div>
      </div>
    </div>

    <!-----------------------  MODAL FOR sverification code sent modal  !---------------->

    <div>
      <b-modal
        ref="email-success-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        no-close-on-backdrop
        id="email-success-modal"
        centered
        title="BootstrapVue"
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="pin-success-top-spacing"></div>
            <div class="row" style="margin-bottom: 5px">
              <div class="col-4"></div>
              <div class="col-4">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 100 125"
                  style="enable-background: new 0 0 100 125"
                  xml:space="preserve"
                  height="80"
                  width="80"
                  fill="#1b9142"
                  class="succes-svg-padding"
                >
                  <g>
                    <g>
                      <path
                        d="M50.2,99.7c-27,0-49-21.9-49-48.9S23.2,2,50.2,2s49,21.9,49,48.9S77.2,99.7,50.2,99.7z M50.2,7.1
			C26,7.1,6.4,26.7,6.4,50.8S26,94.6,50.2,94.6S94.1,75,94.1,50.8S74.4,7.1,50.2,7.1z"
                      />
                    </g>
                    <g>
                      <polygon
                        points="47.4,69.2 29,52.3 32.5,48.5 46.6,61.4 67,31.7 71.3,34.7 		"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div class="col-4"></div>
            </div>
            <div class="d-block text-center">
              <label class="success-popup-style"
                >Verification email sent!</label
              >
            </div>
            <div class="success-bottom-spacing"></div>
          </div>
        </div>
      </b-modal>
    </div>
    <!---- MODAL FOR  Error Modal ---->

    <div>
      <b-modal
        ref="zero-pp-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="zero-pp-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="col-12 text-center">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="120"
                height="120"
                viewBox="0 0 100 125"
                style="enable-background: new 0 0 100 125"
                xml:space="preserve"
                fill="#e14343"
                class="on-boarding-upgrade-alert-icon"
              >
                <path
                  d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
                />
              </svg>
            </div>
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                {{ pp_alert_message }}
              </label>
            </div>
            <br />
            <br />
            <div class="text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="hideModal('zero-pp-modal')"
              >
                <label class="purchasepower-modal-ok-label">OK</label>
              </button>
            </div>
          </div>
        </div>
      </b-modal>

      <!-- Validation modal  redirect step -->
      <b-modal
        ref="validation-redirect-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        no-close-on-backdrop
        id="validation-redirect-modal"
        centered
        title="BootstrapVue"
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                {{ pp_alert_message }}
              </label>
            </div>
            <br />
            <br />
            <div class="text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="hideredirectvalidationModal()"
              >
                <label class="purchasepower-modal-ok-label">OK</label>
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <!-- modal for already onboarded user -->
    <b-modal
      ref="user-already-upgraded"
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      hide-header
      no-close-on-backdrop
      id="user-already-upgraded"
      centered
      title="BootstrapVue"
    >
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              Looks like you have already updated. Please login.
            </label>
          </div>
          <br />
          <br />
          <div class="text-center">
            <button
              type="button"
              class="mx-auto col-10 offset-1 btn-black"
              style="height: 60px"
              @click="gotologin()"
            >
              <label class="purchasepower-modal-ok-label">Login</label>
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</div>
</template>
<script>
/**
 * write a component's description
 * this component is use to Upgrade to the new canpay the user's email as a consumer
 */
import OnboardingHeader from "./OnboardingHeader.vue";
import api from "../../api/onboarding.js";
import constants from "../Common/constant.js";
import Loading from "vue-loading-overlay";
import CanPayLoader from "../CustomLoader/CanPayLoader.vue"
export default {
  name: "UpgradeCanpayWithEmail",
  /**
   * @description-

   * @returns {any}
   */

  data() {
    let self = this;
    return {
      email: "",
      emailColor: "",
      error: false,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      sessionID: "",
      priviousPage: [],
      pp_alert_message: "",
      isLoading: false,
      fullPage: true,
      sessionIDEncode:null,
      emailEncode:null
    };
  },
  destroyed() {
    document
      .getElementById("app")
      .style.setProperty("background-color", "#149240");
  },
  created() {},
  components: {
    "cp-onboardingheader": OnboardingHeader,
    Loading,
    CanPayLoader
  },
  mounted() {
    let self = this;
    self.sessionID = new Date().getTime();
    self.sessionIDEncode=btoa(self.sessionID)
    console.log('encode',self.sessionIDEncode);
    if (localStorage.getItem("sessionId" != null)) {
      localStorage.removeItem("sessionId");
    }
    console.log(self.sessionID);
    localStorage.removeItem("is_duplicate");
    localStorage.removeItem("user_id");
    localStorage.removeItem("status");

    this.$root.$emit("show_header", false);
    this.$root.$emit("changeWhiteBackground", [true, false, ""]);
    document
      .getElementById("app")
      .style.setProperty("background-color", "#ffffff");

    var element = document.getElementsByClassName("wrapper");
    element[0].style.setProperty("background-color", "#ffffff");

    var elementHtml = document.getElementsByTagName("html")[0];
    elementHtml.style.setProperty("background-color", "#ffffff");

    this.email = localStorage.getItem("email");
  },
  methods: {
    showredirectValidationModal(msg) {
      this.error_message = msg;
      this.$refs["validation-redirect-modal"].show();
    },
    hideredirectvalidationModal() {
      this.error_message = "";
      this.$refs["validation-redirect-modal"].hide();
      this.enterConsumerDetails();
    },
    showModal(modal) {
      this.$refs[modal].show();
    },
    hideModal(modal) {
      this.$refs[modal].hide();
    },
    showPinSuccessModal() {
      var self = this;
      self.$refs["email-success-modal"].show();
      setTimeout(function () {
        self.$refs["email-success-modal"].hide();
        self.$router.push("/emailverificationcode/"+self.emailEncode+"/"+self.sessionIDEncode+"/emailverificationcode");
      }, 900);
    },
    isEmailValid: function () {
      return this.email == ""
        ? ""
        : this.reg.test(this.email)
        ? "has-success"
        : "has-error";
    },
    submitEmail() {
      let self = this;
      if (self.email.length == 0) {
        self.emailColor = "red";
      } else if (self.isEmailValid() == "has-error") {
        this.error = true;
      } else {
        self.emailColor = "";
        self.checkEmail();
      }
    },
    /**
     * @description-
     * checkEmail => this function will validate email
     * @returns {any}
     */
    //API call to verify email
    checkEmail() {
      let self = this;
      this.isLoading = true;
      var request = {
        email: this.email.toLowerCase(),
        session_id: this.sessionID,
        phone: null,
      };
      api
        .EmailVerification(request)
        .then((response) => {
          this.isLoading = false;
          if (response.data == 1) {
            //show already updated login modal
            self.$refs["user-already-upgraded"].show();
          } else {
            localStorage.setItem("email", self.email);
            localStorage.setItem("sessionId", self.sessionID);
            localStorage.setItem("onboarding_session_state",response.data);
            self.email = localStorage.getItem("email");
            self.emailEncode =  btoa(self.email);
            self.showPinSuccessModal();
          }
        })
        .catch((err) => {
          self.isLoading = false;
          console.log(err);
          if (err.response.data.code == 598) {
            localStorage.setItem("email", self.email);
            localStorage.setItem("sessionId", self.sessionID);
            self.pp_alert_message = err.response.data.message;
            self.showredirectValidationModal(self.pp_alert_message);
          } else {
            self.pp_alert_message = err.response.data.message;
            self.showModal("zero-pp-modal");
          }
        });
    },

    enterConsumerDetails: function () {
      let self = this;
      this.isLoading = true;
      var request = {
        email: this.email.toLowerCase(),
        session_id: this.sessionID,
      };
      api
        .checkV1ConsumerManualReviewStatus(request)
        .then((response) => {
          this.isLoading = false;
          if (response.code == 200) {
            if (response.data.redirect_to == "1") {
              localStorage.setItem("is_duplicate", response.data.is_duplicate);
              localStorage.setItem("user_id", response.data.user_id);
              localStorage.setItem("status", response.data.status);
              self.$router.push("/enterconsumeraddress");
            } else if (response.data.redirect_to == "5") {
              localStorage.setItem(
                "manualReviewId",
                response.data.manual_review_id
              );
              self.$router.push("/uploadconsumerdocument");
            }
          }
        })
        .catch((err) => {
          self.isLoading = false;
          self.pp_alert_message = err.response.data.message;
          self.showModal("zero-pp-modal");
        });
    },
    gotologin() {
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss">
#zero-pp-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#validation-redirect-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#user-already-upgraded___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
</style>
