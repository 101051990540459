import { reward_wheel_axios } from "../main";


const sponsorList = (params) => {
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("consumer_token");
        reward_wheel_axios.post("/api/consumer/sponsorlists", params)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const linkedSponsorAccounts = (params) => {
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("consumer_token");
        reward_wheel_axios.post("/api/consumer/linkedsponsoraccounts", params)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const linkSponsorAccounts = (params) => {
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("consumer_token");
        reward_wheel_axios.post("/api/consumer/linksponsoraccounts", params)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const unlinkSponsorAccounts = (params) => {
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("consumer_token");
        reward_wheel_axios.post("/api/consumer/unlinksponsoraccounts", params)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const transferSponsorFunds = (params) => {
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("consumer_token");
        reward_wheel_axios.post("/api/consumer/transfersponsorfunds", params)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

export default {
    sponsorList,
    linkedSponsorAccounts,
    linkSponsorAccounts,
    unlinkSponsorAccounts,
    transferSponsorFunds,
};