var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('cp-onboardingheader'),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24),_vm._m(25),_vm._m(26),_vm._m(27),_vm._m(28),_vm._m(29),_vm._m(30),_vm._m(31),_vm._m(32)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-3 ml-3"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"onboarding-terms-and-condition-header"},[_c('label',[_vm._v("CONSUMER PRIVACY NOTICE")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-fact"},[_vm._v(" Facts ")]),_c('div',{staticClass:"col-sm-9 ml-1 onboadring-consumer-privacy-notice-contain"},[_c('strong',[_vm._v("What does CanPay do with your personal information? ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-header"},[_vm._v(" Why? ")]),_c('div',{staticClass:"col-sm-9 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" Financial companies choose how they share your personal information. Federal law gives consumers the right to limit some but not all of this sharing. Federal law also requires us to tell you how we collect, share, and protect your personal information. Please read this notice carefully to understand what we do. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-header"},[_vm._v(" What? ")]),_c('div',{staticClass:"col-sm-9 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" The types of personal information we collect, and share depend on the product or service you have with us. This information can include: "),_c('br'),_c('br'),_c('ul',[_c('li',[_vm._v(" Social Security number and account balances ")]),_c('li',[_vm._v(" Payment history or transaction history ")]),_c('li',[_vm._v(" Credit history or credit scores ")])]),_vm._v(" When you are no longer our customer, we continue to share your information as described in this notice. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-header"},[_vm._v(" How? ")]),_c('div',{staticClass:"col-sm-9 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" All financial companies need to share customers’ personal information to run their everyday business. In the section below, we list the reasons financial companies can share their customers’ personal information; the reasons CanPay chooses to share; and whether you can limit this sharing. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-6 ml-1 onboadring-consumer-privacy-notice-sub-header"},[_c('strong',[_vm._v("Reasons we can share your personal information")])]),_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-header"},[_c('strong',[_vm._v("Does CanPay share?")])]),_c('div',{staticClass:"col-sm-3 ml-1 onboadring-consumer-privacy-notice-sub-header"},[_c('strong',[_vm._v("Can you limit this sharing?")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-6 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_c('strong',[_vm._v("For our everyday business purposes—")]),_c('br'),_vm._v(" such as to process your transactions, maintain your account(s), respond to court orders and legal investigations, or report to credit bureaus ")]),_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" Yes ")]),_c('div',{staticClass:"col-sm-3 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" No ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-6 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_c('strong',[_vm._v("For our marketing purposes—")]),_c('br'),_vm._v(" to offer our products and services to you ")]),_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" Yes ")]),_c('div',{staticClass:"col-sm-3 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" No ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-6 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_c('strong',[_vm._v("For joint marketing with other financial companies")])]),_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" Yes ")]),_c('div',{staticClass:"col-sm-3 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" No ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-6 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_c('strong',[_vm._v("For our affiliates’ everyday business purposes—")]),_c('br'),_vm._v(" information about your transactions and experiences ")]),_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" Yes ")]),_c('div',{staticClass:"col-sm-3 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" No ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-6 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_c('strong',[_vm._v("For our affiliates’ everyday business purposes—")]),_c('br'),_vm._v(" information about your creditworthiness ")]),_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" No ")]),_c('div',{staticClass:"col-sm-3 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" We don’t share ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-6 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_c('strong',[_vm._v("For our affiliates to market to you")])]),_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" No ")]),_c('div',{staticClass:"col-sm-3 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" We don’t share ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-6 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_c('strong',[_vm._v("For nonaffiliates to market to you")])]),_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" No ")]),_c('div',{staticClass:"col-sm-3 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" We don’t share ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-header"},[_vm._v(" To Limit our sharing ")]),_c('div',{staticClass:"col-sm-9 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_c('ul',[_c('li',[_vm._v(" Call 888-395-4992 – our menu will prompt you through your choice(s) ")]),_c('li',[_c('strong',[_vm._v("Email ")]),_vm._v(" us at "),_c('a',{staticClass:"onboarding-terms-and-condition-ancher",attrs:{"href":"mailto:iCanPay@canpaydebit.com"}},[_vm._v("iCanPay@canpaydebit.com ")])]),_c('li',[_vm._v(" Mail the "),_c('strong',[_vm._v("form ")]),_vm._v(" below ")])]),_c('strong',[_vm._v("Please note: ")]),_vm._v(" If you are a"),_c('i',[_vm._v(" new customer ")]),_vm._v(", we can begin sharing your information 30 days from the date we sent this notice. When you are "),_c('i',[_vm._v("no longer ")]),_vm._v(" our customer, we continue to share your information as described in this notice. "),_c('br'),_vm._v(" However, you can contact us at any time to limit our sharing. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-header"},[_vm._v(" Questions? ")]),_c('div',{staticClass:"col-sm-9 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" Call 888-395-4992 or email us at iCanPay@canpaydebit.com. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12",staticStyle:{"font-size":"1.5rem"}},[_vm._v(" -------------------------------------------------------------------------------------------- ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-11 ml-1 onboadring-consumer-privacy-notice-sub-header"},[_vm._v(" Mail-in Form ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-11 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" Mark any/all you want to limit: "),_c('br'),_c('br'),_c('ul',{staticStyle:{"list-style-type":"none"}},[_c('li',[_c('input',{attrs:{"type":"checkbox","readonly":""}}),_vm._v("   Do not share information about my creditworthiness with your affiliates for their everyday business purposes.")]),_c('br'),_c('li',[_c('input',{attrs:{"type":"checkbox","readonly":""}}),_vm._v("   Do not allow your affiliates to use my personal information to market to me.")]),_c('br'),_c('li',[_c('input',{attrs:{"type":"checkbox","readonly":""}}),_vm._v("   Do not share my personal information with nonaffiliates to market their products and services to me.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row ml-3"},[_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-header"},[_vm._v(" Name ")]),_c('div',{staticClass:"col-sm-7 ml-1 onboadring-consumer-privacy-notice-sub-contain"}),_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-contain"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row ml-3"},[_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-header"},[_vm._v(" Address City, State, Zip ")]),_c('div',{staticClass:"col-sm-7 ml-1 onboadring-consumer-privacy-notice-sub-contain"}),_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-contain"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row ml-3"},[_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-header"},[_vm._v(" Account # ")]),_c('div',{staticClass:"col-sm-7 ml-1 onboadring-consumer-privacy-notice-sub-contain"}),_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-contain"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-11 ml-1 onboadring-consumer-privacy-notice-sub-header"},[_vm._v(" Who we are ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-4 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_c('strong',[_vm._v("Who is providing this"),_c('br'),_vm._v(" Privacy Policy? ")])]),_c('div',{staticClass:"col-sm-7 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" This privacy notice is provided by CanPay, and is applicable to your personal CanPay account. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-11 ml-1 onboadring-consumer-privacy-notice-sub-header"},[_vm._v(" What we do ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-4 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_c('strong',[_vm._v("How does CanPay"),_c('br'),_vm._v(" protect my personal"),_c('br'),_vm._v(" information? ")])]),_c('div',{staticClass:"col-sm-7 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" To protect your personal information from unauthorized access and use, we use security measures that comply with federal law. These measures include computer safeguards and secured files and buildings. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-4 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_c('strong',[_vm._v("How does CanPay"),_c('br'),_vm._v(" collect my personal"),_c('br'),_vm._v(" information? ")])]),_c('div',{staticClass:"col-sm-7 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" We collect your personal information, for example, when you "),_c('ul',[_c('li',[_vm._v(" open an account or provide account information ")]),_c('li',[_vm._v(" link your checking or savings account to your CanPay account or give us your contact information ")]),_c('li',[_vm._v(" use your CanPay account to pay for services ")])]),_vm._v(" We also collect your personal information from others, such as our verification service providers, affiliates, or other companies. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-4 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_c('strong',[_vm._v("Why can’t I limit all"),_c('br'),_vm._v(" sharing? ")])]),_c('div',{staticClass:"col-sm-7 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" Federal law gives you the right to limit only "),_c('ul',[_c('li',[_vm._v(" sharing for affiliates’ everyday business purposes— information about your creditworthiness ")]),_c('li',[_vm._v(" affiliates from using your information to market to you ")]),_c('li',[_vm._v(" sharing for nonaffiliates to market to you ")])]),_vm._v(" State laws and individual companies may give you additional rights to limit sharing. See our "),_c('a',{staticClass:"onboarding-terms-and-condition-ancher",attrs:{"href":"https://www.canpaydebit.com/about-us/privacy/","target":"_blank"}},[_vm._v("Privacy Policy")]),_vm._v(" and "),_c('a',{staticClass:"onboarding-terms-and-condition-ancher",attrs:{"href":"#"}},[_vm._v("Supplemental Notice for California Residents")]),_vm._v(" for more information on your rights under state law. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-11 ml-1 onboadring-consumer-privacy-notice-sub-header"},[_vm._v(" What we do ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_c('strong',[_vm._v("Affiliates ")])]),_c('div',{staticClass:"col-sm-9 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" Companies related by common ownership or control. They can be financial and"),_c('br'),_vm._v(" nonfinancial companies. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_c('strong',[_vm._v("Nonaffiliates ")])]),_c('div',{staticClass:"col-sm-9 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" Companies not related by common ownership or control. They can be financial and nonfinancial companies. "),_c('ul',[_c('li',[_vm._v(" Nonaffiliates with which we share personal information include Merchants and service providers that perform services or functions on our behalf. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_c('strong',[_vm._v("Joint Marketing ")])]),_c('div',{staticClass:"col-sm-9 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" A formal agreement between nonaffiliated financial companies that together market financial products or services to you. "),_c('ul',[_c('li',[_vm._v(" Our joint marketing partners include Merchants. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-11 ml-1 onboadring-consumer-privacy-notice-sub-header"},[_vm._v(" Other important information ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-1 ml-3"},[_c('div',{staticClass:"col-sm-11 ml-1 onboadring-consumer-privacy-notice-sub-contain"},[_vm._v(" We may transfer personal information to other countries, for example, for customer service or to process transactions. "),_c('br'),_c('br'),_c('ul',[_c('li',[_c('strong',[_vm._v("California:")]),_vm._v(" If your CanPay account has a California mailing address, we will not share personal information we collect about you except to the extent permitted under California law. ")]),_c('br'),_c('li',[_c('strong',[_vm._v("Vermont:")]),_vm._v(" If your CanPay account has a Vermont mailing address, we will not share personal information we collect about you with non-affiliates unless the law allows, or you provide"),_c('br'),_vm._v(" authorization. ")])])])])
}]

export { render, staticRenderFns }