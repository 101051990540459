<template>
  <div class="container splash">
    <cp-onboardingheader></cp-onboardingheader>
             <div class="row p-3">
      <div class="col-md-12">
    <div class="onboarding-terms-and-condition-header">
      <label>CanPay Privacy Policy</label>
    </div>
</div>
</div>

        <div class="row p-3">
      <div class="col-md-12">
    <div class="onboarding-terms-and-condition-pragrapgh">
      <label
        >In order to provide the CanPay service to merchants and consumers,
        CanPay has partnered with several industry leading companies. One such
        company is ZipLine and you will see them mentioned throughout our
        website, privacy policy, and terms and conditions. ZipLine’s established
        technology is being used to help operate the CanPay network and manage
        CanPay consumer accounts. ZipLine also provides the CanPay consumer
        support.
      </label>

      <label>
      Note: The information we collect from you, what we use it for and to whom we disclose it depends on
whether you are an individual consumer who uses CanPay to make purchases (a “Customer”), or a
merchant customer who uses CanPay to process payments (a “Merchant”). We refer to the term “you” in
this policy where our data collection practices apply equally to both Customers and Merchants.
      </label>
    </div>
    </div>
    </div>

        <div class="row p-3">
      <div class="col-md-12" >
    <ol class= "onboarding-terms-and-condition-ol">
    <li style="text-align: left;">
    SCOPE
    </li>
      <li style="text-align: left;">
 CHANGES TO OUR PRIVACY POLICY
    </li>
         <li style="text-align: left;">
PERSONAL INFORMATION WE COLLECT
    </li>
             <li style="text-align: left;">
HOW WE USE YOUR INFORMATION
    </li>
        </li>
             <li style="text-align: left;">
HOW WE DISCLOSE YOUR INFORMATION
    </li>

            </li>
             <li style="text-align: left;">
YOUR PRIVACY CHOICES AND RIGHTS
    </li>
       <li style="text-align: left;">
SECURITY OF YOUR INFORMATION
    </li>
           <li style="text-align: left;">
INTERNATIONAL DATA TRANSFERS
    </li>
               <li style="text-align: left;">
RETENTION OF PERSONAL INFORMATION
    </li>

    <li style="text-align: left;">
CHILDREN’S INFORMATION
    </li>
      <li style="text-align: left;">
THIRD-PARTY WEBSITES/APPLICATIONS
    </li>
    <li style="text-align: left;">
CONTACT US
    </li>
    </br>
    <div style="text-align: left;">
       CONSUMER PRIVACY NOTICE </br>
SUPPLEMENTAL NOTICE FOR CALIFORNIA RESIDENTS</br>
SUPPLEMENTAL NOTICE FOR NEVADA RESIDENTS
</div>
    </ol>
  
      </div>
      </div>

     <div class="row p-3">
      <div class="col-md-12">
    <ol class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
    SCOPE
    </li>
    </ol>
<div class="onboarding-terms-and-condition-pragrapgh">
<label>
  This Privacy Policy applies to personal information processed by us, including on our website at
<a
          class="onboarding-terms-and-condition-ancher"
          href="https://www.canpaydebit.com/"
          target="_blank"
        >https://www.canpaydebit.com</a>, the CanPay mobile applications available at <a
          class="onboarding-terms-and-condition-ancher"
          href="https://www.CanPayApp.com/"
          target="_blank"
        >CanPayApp.com</a>, and other
online or offline offerings. To make this Privacy Policy easier to read, our websites, mobile applications,
and other offerings are collectively referred to as the “<strong>Services.</strong>”
</label>
<label>
<strong><u> An Important Note:&nbsp;&nbsp;</u></strong>This Privacy Policy does not apply to any of the personal information that our
Merchants may collect, use, process and disclose using CanPay’s Services (“<strong>Merchant-Owned Data </strong>”).
Our Merchants’ privacy policies govern the collection and use of Merchant-Owned Data. If you are a
Customer who is accessing our Services via a Merchant’s service, please carefully review the applicable
Merchant’s privacy policy before using its services. Any questions or requests relating to Merchant-
Owned Data should be directed to the Merchant you transact with.
</label>
  </div>
  </div>
  </div>


   <div class="row p-3">
      <div class="col-md-12">
    <ol start="2" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two" id ="section2">
    CHANGES TO OUR PRIVACY POLICY
    </li>
    </ol>
    <div class="onboarding-terms-and-condition-pragrapgh">
<label>
We may revise this Privacy Policy from time to time in our sole discretion. If there are any material
changes to this Privacy Policy, we will notify you as required by applicable law. You understand and
agree that you will be deemed to have accepted the updated Privacy Policy if you continue to use our
Services after the new Privacy Policy takes effect.
</label>

    </div>

    </div>
    </div>



   <div class="row p-3">
      <div class="col-md-12">
    <ol start="3" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
 PERSONAL INFORMATION WE COLLECT
    </li>
    </ol>
    <div class="onboarding-terms-and-condition-pragrapgh">
<label>
The categories of personal information we collect depend on how you interact with us, our Services and
the requirements of applicable law. We collect information that you provide to us, information we obtain
automatically when you use our Services, and information from other third-party services and
organizations, as described below.
</label>

    </div>
   <div class="col-md-12">
   
    <div class="onboarding-terms-and-condition-sub-two">
 A. &nbsp;Information You Provide to Us Directly

 </div>
   </div>
       <div class="onboarding-terms-and-condition-pragrapgh">
   We may collect the following personal information that you provide to us.
</div>
      <div class="col-md-12">
      <ul  class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Account Creation.
</li>
</ul>

         <div class="col-md-12">
          <ul style="list-style-type:circle;" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-three">
 <u> Customers:</u> &nbsp;To create an account, we may collect information such as your name, email
address, a valid driver’s license or state identification card, checking and savings account
information, phone number, birthday, address, last 4 digits of your social security number
or other government-issued identification number, security questions and answers, any
loyalty card number, and a unique PIN.
    </li>
      <li class="onboarding-terms-and-condition-sub-three">
 <u> Merchants:</u> &nbsp;To create an account, you must provide your contact name, email address,
phone number, title, business and legal entity names and addresses, legal entity’s federal
tax identification number, corporate contact name and contact information (title, email
address, and phone number), the state in which you do business, average monthly
revenue and ticket sales, POS provider, cannabis business license details (if applicable),
banking details (financial institution name, contact person’s name and email address,
routing number, and account number), and documentation for underwriting purposes that
may include, but are not limited to the following: driver’s licenses, voided checks and
bank statements, legal entity documents, bank letters, and other business licenses.
    </li>
         <li class="onboarding-terms-and-condition-sub-three">
         <strong>Purchases.</strong>&nbsp;&nbsp;&nbsp; We may collect personal information and details associated with the
purchases Customers make using our Services.
         </li>
    </ul>
</div>
      <ul  class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Your Communications with Us.
</li>
       <div class="onboarding-terms-and-condition-pragrapgh">
   When you request information about our Services, (such as
Merchants who inquire about using CanPay in their business), register for our newsletter, request
customer or technical support, apply for a job, or otherwise communicate with us, we may collect
personal information that you elect to provide to us, such as your email address, phone number,
name, employer, and/or mailing address.
</div>
</ul>


    <ul  class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Share Content with Friends or Colleagues.
</li>
<div class="onboarding-terms-and-condition-pragrapgh">
   Our Services may offer various tools and
functionalities that enable you to communicate with others. For example, we may allow
Customers to provide information about their friends through our referral services. Our referral
services may allow you to forward or share certain content with a friend or colleague, such as an
email inviting your friend to use our Services. We may also allow you to suggest businesses to us
who you would like to use CanPay. If you elect to do so, we may ask you to provide the name,
email address and phone number from the referred business’ representative.
</div>
</ul>


    <ul  class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Surveys.
</li>
<div class="onboarding-terms-and-condition-pragrapgh">
 We may contact you to participate in surveys. If you decide to participate, you may be
asked to provide certain information, which may include personal information.
</div>
</ul>



    <ul  class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Sweepstakes, Contests or Games of Skill.
</li>
<div class="onboarding-terms-and-condition-pragrapgh">
We may collect personal information from Customers
they provide for any sweepstakes, contests or games of skill that we offer. In some jurisdictions,
we are required to publicly share information of our sweepstakes and contest winners.
</div>
</ul>



  <ul  class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Conferences, Trade Shows, and Other Events.
</li>
<div class="onboarding-terms-and-condition-pragrapgh">
We may collect personal information from
individuals when we attend conferences, trade shows, and other events.
</div>
</ul>


</div>
  <div class="col-md-12">
     <div class="onboarding-terms-and-condition-sub-two">
 B. &nbsp;Information Collected Automatically
    </div>
       <div class="onboarding-terms-and-condition-pragrapgh">
   We may collect the following personal information that you provide to us.
</div>
</div>

     <div class="col-md-12">
      <ul  class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Automatic Data Collection.
</li>
   <div class="onboarding-terms-and-condition-pragrapgh">
We may collect certain information automatically when you use our
Services, such as your Internet protocol (IP) address, user settings, MAC address, cookie
identifiers, mobile carrier, mobile advertising and other unique identifiers, browser or device
information, general location information (derived from IP address), and Internet service
provider. We may also automatically collect information regarding your use of our Services, such
as pages that you visit before, during and after using our Services, information about the links you
click, the types of content you interact with, the frequency and duration of your activities, and
other information about how you use our Services.
</div>
</ul>

 <ul  class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Geolocation.
</li>
   <div class="onboarding-terms-and-condition-pragrapgh">
If a Customer uses a location-enabled device with our Service, we may collect
geographical location data or use various means to determine their location, such as sensor data,
GPS, Wi-Fi, or cell site triangulation. Note that if Customers do not agree to our collection of
Geolocation Information, some of our Services may not function properly. Customers may learn
more about their ability to restrict the collection and use of Geolocation Information by referring
to the controls available on their devices.
</div>
</ul>

 <ul  class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Cookies, Pixel Tags/Web Beacons, and Other Technologies.
</li>
   <div class="onboarding-terms-and-condition-pragrapgh">
We, as well as third parties that
provide content, advertising, or other functionality on our Services, may use cookies, pixel tags,
local storage, and other technologies (“Technologies”) to automatically collect information
through your use of our Services.
</div>
    <ul  class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
    Cookies.
    </li>
       <div class="onboarding-terms-and-condition-pragrapgh">
Cookies are small text files placed in device browsers that store preferences and
facilitate and enhance your experience.
</div>
    </ul>

      <ul  class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Pixel Tags/Web Beacons.
    </li>
       <div class="onboarding-terms-and-condition-pragrapgh">
A pixel tag (also known as a web beacon) is a piece of code
embedded in our Services that collects information about engagement on our Services. The
use of a pixel tag allows us to record, for example, that a user has visited a particular web
page or clicked on a particular advertisement. We may also include web beacons in e-mails to
understand whether messages have been opened, acted on, or forwarded.
</div>

    </ul>
        <div class="onboarding-terms-and-condition-pragrapgh">
  <i> See <a
          class="onboarding-terms-and-condition-ancher"
          href="#Section6"
        >Section 6 below</a> to understand your choices regarding these Technologies.</i>
</div>
</ul>


 <ul  class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Analytics.
</li>
   <div class="onboarding-terms-and-condition-pragrapgh">
We may use Technologies and other third-party tools to process analytics information
on our Services. Some of our analytics partners include:
</div>
 <ul  class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Google Analytics.
    </li>
       <div class="onboarding-terms-and-condition-pragrapgh">
For more information, please visit <a
          class="onboarding-terms-and-condition-ancher"
          href="https://policies.google.com/technologies/partner-sites"
          target="_blank"
        >Google Analytics’ Privacy Policy</a>, . To
learn more about how to opt-out of Google Analytics’ use of your information, please click
<a
          class="onboarding-terms-and-condition-ancher"
          href="https://tools.google.com/dlpage/gaoptout"
          target="_blank"
        >here.</a>
</div>

    </ul>

     <ul  class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Twitter Analytics.
    </li>
       <div class="onboarding-terms-and-condition-pragrapgh">
    For more information, please visit    <a
          class="onboarding-terms-and-condition-ancher"
          href=" https://help.twitter.com/en/rules-and-policies/twitter-cookies"
          target="_blank"
        >Twitter’s help page.</a>
</div>

    </ul>
</ul>

 <ul  class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
Social Media Platforms.
</li>
   <div class="onboarding-terms-and-condition-pragrapgh">
   Our Services may contain social media buttons such as Twitter,
Facebook and LinkedIn (that might include widgets such as the “share this” button or other
interactive mini programs). These features may collect your IP address, which page you are

4

visiting on our Services, and may set a cookie to enable the feature to function properly. Your
interactions with these platforms are governed by the privacy policy of the company providing it.
   </div>
   </ul>
</div>
  <div class="col-md-12">
     <div class="onboarding-terms-and-condition-sub-two">
 C. &nbsp;Information We Collect from Others.
    </div>
       <div class="onboarding-terms-and-condition-pragrapgh">
  We may obtain information about you from other sources, including through referrals, third-party
services and organizations who provide services on our behalf, such as service providers who
verify the information you provide to us and assess your eligibility to use our Services.
</div>
</div>


</div>
</div>

 <div class="row p-3">
      <div class="col-md-12">
    <ol start="4" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
HOW WE USE YOUR INFORMATION
    </li>
    </ol>
     <div class="onboarding-terms-and-condition-pragrapgh">
We use your information for a variety of business purposes, including to provide our Services, for
administrative purposes, and to market our products and Services, as described below.
</div>
 <div class="col-md-12">
 <div class="onboarding-terms-and-condition-sub-two">
 A. Provide Our Services
 </div>

     <div class="onboarding-terms-and-condition-pragrapgh">
We use your information to fulfil our contract with you and provide you with our Services, such as:
</div>
</br>
</br>
 <ul>
<li style="text-align: left;">
Managing your information and accounts;
</li>
<li style="text-align: left;">
Providing access to certain areas, functionalities, and features of our Services;
</li>
<li style="text-align: left;">
Answering requests for customer or technical support;
</li>
<li style="text-align: left;">
Communicating with you about your account, activities on our Services, and policy changes;
</li>
<li style="text-align: left;">
Processing your financial information and other payment methods for products or Services
purchased;
</li>
<li style="text-align: left;">
Processing applications if you apply for a job we post on our Services; and
</li>
<li style="text-align: left;">
Allowing you to register for events.
</li>
 
 </ul>
    <div class="onboarding-terms-and-condition-pragrapgh">
     <label>
We may use Customers’ phone number to send important text messages regarding their use of the
Services (such as purchase receipts, verification codes, and similar transactional messages); standard
messaging and data rates apply.
</label>
     <label>
We may collect Customers’ precise geographic location to protect against fraud and other security
risks. In addition, we may use location information in order to enhance our Services, and to provide
offers and discounts in connection with Services that Customers consent to use.
</label>
</div>

 <div class="onboarding-terms-and-condition-sub-two">
B. Administrative Purposes
 </div>
     <div class="onboarding-terms-and-condition-pragrapgh">
We use your information for various administrative purposes, such as:
</div>
</br>
</br>

 <ul>
<li style="text-align: left;">
Pursuing our legitimate interests such as direct marketing, research and development (including
marketing research), network and information security, and fraud prevention;
</li>
<li style="text-align: left;">
Detecting security incidents, protecting against malicious, deceptive, fraudulent or illegal activity,
<br>
and prosecuting those responsible for that activity;
</li>
<li style="text-align: left;">
Measuring interest and engagement in our Services;
</li>
<li style="text-align: left;">
Short-term, transient use, such as contextual customization of ads;
</li>
<li style="text-align: left;">
Improving, upgrading or enhancing our Services;
</li>
<li style="text-align: left;">
Developing new products and Services;
</li>
<li style="text-align: left;">
Ensuring internal quality control and safety;
</li>
 
 <li style="text-align: left;">
Authenticating and verifying individual identities, including verifying your identity if you contact
us to exercise your legal rights;
</li>
<li style="text-align: left;">
Debugging to identify and repair errors with our Services;
</li>
<li style="text-align: left;">
Auditing relating to interactions, transactions and other compliance activities;
</li>
<li style="text-align: left;">
Enforcing our agreements and policies; and
</li>
 <li style="text-align: left;">
Complying with our legal obligations.
</li>
 </ul>
  <div class="onboarding-terms-and-condition-sub-two">
C. Marketing and Advertising our Products and Services
 </div>
     <div class="onboarding-terms-and-condition-pragrapgh">
<label>We may use personal information to tailor and provide you with content and advertisements. We may
provide you with these materials as permitted by applicable law.
</label>
</br>
<label>
Some of the ways we market to you include email campaigns, custom audiences advertising, and
“interest-based” or “personalized advertising,” including through cross-device tracking.
</label>
</br>
<label>
If you have any questions about our marketing practices, or if you would like to opt out of the use of your
personal information for marketing purposes, you may contact us as set forth below.
</label>
</br>
</div>
<div class="onboarding-terms-and-condition-sub-two">
D. Other Purposes
 </div>
     <div class="onboarding-terms-and-condition-pragrapgh">
We also use your information for other purposes as requested by you or as permitted by applicable law.
</div>
<ul>
<li style="text-align: left;">
<strong>Consent.</strong>&nbsp;&nbsp; We may use personal information for other purposes that are clearly disclosed to you at
the time you provide personal information or with your consent.
</li>

<li style="text-align: left;">
<strong>Automated Decision Making.</strong>&nbsp;&nbsp; We may engage in automated decision making, including
profiling. For example, our algorithms may automatically prevent Customers from making certain
purchases if we suspect fraudulent activity on their account. CanPay’s processing of Customers’
personal information will not result in a decision based solely on automated processing that
affects the Customer’s legal rights, unless such a decision is necessary as part of a contract we
have with the Customer, (such as to prevent fraud), we have a Customer’s consent, or we are
permitted by law to engage in such automated decision making. If you have questions about our
automated decision making, you may contact us as set forth below.
</li>

<li style="text-align: left;">
<strong>De-identified and Aggregated Information.</strong>&nbsp;&nbsp; We may use personal information and other
information about you to create de-identified and/or aggregated information, such as de-identified
demographic information, de-identified location information, information about the device from
which you access our Services, or other analyses we create.
</li>
</ul>
 </div>
 </div>
  </div>


 <div class="row p-3">
      <div class="col-md-12">
    <ol start="5" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
HOW WE DISCLOSE YOUR INFORMATION
    </li>
    </ol>
     <div class="onboarding-terms-and-condition-pragrapgh">
We disclose your information to third parties for a variety of business purposes, as described below.
</div>

 <div class="col-md-12">
 <div class="onboarding-terms-and-condition-sub-two">
 A. Disclosures to Provide our Services
 </div>
      <div class="onboarding-terms-and-condition-pragrapgh">
We use your information to fulfil our contract with you and provide you with our Services, such as:
</div>
<ul>
<li style="text-align: left;">
<strong>Disclosures to Merchants.</strong> We disclose Customers’ names and CanPay-specific identifiers to
Merchants, who may use this information to provide their services to Customers, including providing
Customers with loyalty programs and/or advertising.
</li>


<li style="text-align: left;">
<strong>Service Providers.</strong> We may share your personal information with our third-party service providers
who use that information to help us provide our Services. This includes service providers that provide
us with IT support, hosting, payment processing, customer service, and related services. We also
share Customers’ account information (as described in “Personal Information We Collect” above)

6

with verification service providers, who combine this information with information they obtain from
public records, (including a Customer’s name, social security number, etc.), to verify Customers’
identities and ensure they are eligible to use our Services.
</li>


<li style="text-align: left;">
<strong>Business Partners</strong>. We may share your personal information with business partners to provide you
with a product or service you have requested. We may also share your personal information to
business partners with whom we jointly offer products or services.
</li>
<li style="text-align: left;">
<strong>Affiliates</strong>. We may share your personal information with our company affiliates.
</li>

<li style="text-align: left;">
<strong>Advertising Partners.</strong> We may share Customers’ personal information with third-party advertising
partners. These third-party advertising partners may set Technologies and other tracking tools on our
Services to collect information regarding Customers’ activities and their devices (e.g., Customers’ IP
address, cookie identifiers, page(s) visited, location, time of day). These advertising partners may use
this information (and similar information collected from other services) for purposes of delivering
personalized advertisements to Customers when they visit digital properties within their networks.
This practice is commonly referred to as “interest-based advertising” or “personalized advertising.”
</li>

<li style="text-align: left;">
<strong>APIs/SDKs.</strong> We may use third-party Application Program Interfaces (“APIs”) and software
development kits (“SDKs”) as part of the functionality of our Services. For more information about
our use of APIs and SDKs, please contact us as set forth below.
</li>
</ul>
</div>

<div class="col-md-12">
 <div class="onboarding-terms-and-condition-sub-two">
B. Disclosures to Protect Us or Others
 </div>
      <div class="onboarding-terms-and-condition-pragrapgh">
We may access, preserve, and disclose any information we store associated with you to external parties if
we, in good faith, believe doing so is required or appropriate to: comply with law enforcement or national
security requests and legal process, such as a court order or subpoena; protect your, our, or others’ rights,
property, or safety; enforce our policies or contracts; collect amounts owed to us; or assist with an
investigation or prosecution of suspected or actual illegal activity.
</div>
</div>

<div class="col-md-12">
 <div class="onboarding-terms-and-condition-sub-two">
C. Disclosure in the Event of Merger, Sale, or Other Asset Transfers
 </div>
      <div class="onboarding-terms-and-condition-pragrapgh">
If we are involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy,
receivership, purchase or sale of assets, or transition of service to another provider, your information may
be sold or transferred as part of such a transaction, as permitted by law and/or contract.
</div>
</div>

</div>
</div>

<div class="row p-3">
      <div class="col-md-12" id="Section6" >
    <ol start="6" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
YOUR PRIVACY CHOICES AND RIGHTS
    </li>
    </ol>  
     <div class="onboarding-terms-and-condition-pragrapgh">
<strong>Your Privacy Choices.</strong> The privacy choices you may have about your personal information are
determined by applicable law and are described below.
</div>
  <ul>

<li style="text-align: left;">
  <strong>Email and Telephone Communications.</strong> If you receive an unwanted email from us, you can use
the unsubscribe link found at the bottom of the email to opt out of receiving future emails. Note
that you will continue to receive transaction-related emails regarding products or Services you
have requested. We may also send you certain non-promotional communications regarding us and
our Services, including by text message, and you will not be able to opt out of those
communications (e.g., communications regarding our Services or updates to our Terms or this
Privacy Policy).
  </li>



<li style="text-align: left;">
<strong>Mobile Devices</strong>. We may send you push notifications through our mobile applications. You may
opt out from receiving these push notifications by changing the settings on your mobile device.

7

With your consent, we may also collect precise location-based information if you use our mobile
application. You may opt out of this collection by changing the settings on your mobile device.
  </li>


<li style="text-align: left;">
“<strong>Do Not Track</strong>.” Do Not Track (“DNT”) is a privacy preference that users can set in certain web
browsers. Please note that we do not respond to or honor DNT signals or similar mechanisms
transmitted by web browsers.
  </li>

<li style="text-align: left;">
<strong>Cookies and Interest-Based Advertising </strong>. You may stop or restrict the placement of
Technologies on your device or remove them by adjusting your preferences as your browser or
device permits. However, if you adjust your preferences, our Services may not work properly.
Please note that cookie-based opt-outs are not effective on mobile applications. However, you
may opt-out of personalized advertisements on some mobile applications by following the
instructions for <a
          class="onboarding-terms-and-condition-ancher"
          href="https://support.google.com/googleplay/android-developer/answer/6048248?hl=en/"
          target="_blank"
        >Android</a>,<a
          class="onboarding-terms-and-condition-ancher"
          href="https://support.apple.com/en-us/HT202074/"
          target="_blank"
        >iOS</a>  and <a
          class="onboarding-terms-and-condition-ancher"
          href="https://www.networkadvertising.org/mobile-choice/"
          target="_blank"
        >others</a>.

</br>

The online advertising industry also provides websites from which you may opt out of receiving
targeted ads from data partners and other advertising partners that participate in self-regulatory
programs. You can access these and learn more about targeted advertising and consumer choice
and privacy by visiting the <a
          class="onboarding-terms-and-condition-ancher"
          href="https://optout.networkadvertising.org/?c=1"
          target="_blank"
        >Network Advertising Initiative</a>, <a
          class="onboarding-terms-and-condition-ancher"
          href="https://optout.aboutads.info/?c=2&lang=EN"
          target="_blank"
        >the Digital Advertising Alliance </a>, <a
          class="onboarding-terms-and-condition-ancher"
          href="https://www.youronlinechoices.eu/"
          target="_blank"
        >the
European Digital Advertising Alliance </a>, and <a
          class="onboarding-terms-and-condition-ancher"
          href="https://youradchoices.ca/en/tools"
          target="_blank"
        >the Digital Advertising Alliance of Canada </a>.

</br>
Please note you must separately opt out in each browser and on each device.
  </li>
  </ul>
     <div class="onboarding-terms-and-condition-pragrapgh">
<strong>Your Privacy Rights.</strong> Where provided under applicable law, you may have the right to:
</div>
</br>
</br>
<ul>

<li style="text-align: left;"><strong>Access Your Personal Information </strong>, including: (i) confirming whether we are processing your
personal information; (ii) obtaining access to or a copy of your personal information; and (iii)
receiving an electronic copy of personal information that you have provided to us, or asking us to
send that information to another company (the “right of data portability”);
</li>

<li style="text-align: left;"><strong>Request Correction</strong> of your personal information where it is inaccurate or incomplete. In some
cases, we may provide self-service tools that enable you to update your personal information;
</li>

<li style="text-align: left;"><strong>Request Deletion </strong> of your personal information;
</li>


<li style="text-align: left;"><strong>Request Restriction of or Object to</strong>   our processing of your personal information; and
</li>


<li style="text-align: left;"><strong>Withdraw your Consent </strong> to our processing of your personal information.
</li>

</ul>
  <div class="onboarding-terms-and-condition-pragrapgh">
If you would like to exercise any of these rights, please contact us as set forth below. We will process
such requests in accordance with applicable laws.
</div>
</div>
</div>


 <div class="row p-3">
      <div class="col-md-12">
    <ol start="7" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
SECURITY OF YOUR INFORMATION
    </li>
    </ol>
     <div class="onboarding-terms-and-condition-pragrapgh">
To protect your personal information from unauthorized access and use, we use security measures that
comply with federal law. These measures include computer safeguards and secured files and buildings.
Unfortunately, no system is 100% secure, and we cannot ensure or warrant the security of any
information you provide to us. To the fullest extent permitted by applicable law, we do not accept liability
for unauthorized disclosure.
 </br>
 </br>
 By using our Services or providing personal information to us, you agree that we may communicate with
you electronically regarding security, privacy, and administrative issues relating to your use of our

8

Services. If we learn of a security system’s breach, we may attempt to notify you electronically by posting
a notice on our Services, by mail or by sending an email to you.
</div>

</div>
</div>





 <div class="row p-3">
      <div class="col-md-12">
    <ol start="8" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
INTERNATIONAL DATA TRANSFERS
    </li>
    </ol>
     <div class="onboarding-terms-and-condition-pragrapgh">
All information processed by us may be transferred, processed, and stored anywhere in the world,
including, but not limited to, the United States or other countries, which may have data protection laws
that are different from the laws where you live. We endeavor to safeguard your information consistent
with the requirements of applicable laws.
</div>

</div>
</div>


 <div class="row p-3">
      <div class="col-md-12">
    <ol start="9" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
RETENTION OF PERSONAL INFORMATION
    </li>
    </ol>
     <div class="onboarding-terms-and-condition-pragrapgh">
We store the personal information we collect as described in this Privacy Policy for as long as you use our
Services or as necessary to fulfill the purpose(s) for which it was collected, provide our Services, resolve
disputes, establish legal defenses, conduct audits, pursue legitimate business purposes (such as combating
fraud), enforce our agreements, and comply with applicable laws.
</div>

</div>
</div>


 <div class="row p-3">
      <div class="col-md-12">
    <ol start="10" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
CHILDREN’S INFORMATION
    </li>
    </ol>
     <div class="onboarding-terms-and-condition-pragrapgh">
The Services are not directed to children under 18, and we do not knowingly collect personal information
from children, other than with the parent’s consent (in the case of a parent creating a sub-account for their
minor child).
</br>
If you are a parent or guardian and wish to review information collected from your child, or have that
information modified or deleted, you may contact us as described below. If we become aware that a child
has provided us with personal information in violation of applicable law, we will delete any personal
information we have collected, unless we have a legal obligation to keep it, and terminate the child’s
account as applicable.
</div>

</div>
</div>

 <div class="row p-3">
      <div class="col-md-12">
    <ol start="11" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
THIRD-PARTY WEBSITES/APPLICATIONS
    </li>
    </ol>
     <div class="onboarding-terms-and-condition-pragrapgh">
The Services may contain links to other websites/applications and other websites/applications may
reference or link to our Services. These third-party services are not controlled by us. We encourage you
to read the privacy policies of each website and application with which they interact. We do not endorse,
screen or approve, and are not responsible for, the privacy practices or content of such other websites or
applications. Providing personal information to third-party websites or applications is at your own risk.
</div>

</div>
</div>

 <div class="row p-3">
      <div class="col-md-12" >
    <ol start="12" class= "onboarding-terms-and-condition-ol">
    <li class="onboarding-terms-and-condition-sub-two">
CONTACT US
    </li>
    </ol>
     <div class="onboarding-terms-and-condition-pragrapgh">
If you have any questions about our privacy practices or this Privacy Policy, or to exercise your rights as
detailed in this Privacy Policy, please contact us at:

<br>
<br>
Trusted Debit LLC (“CanPay”)
</br>
PO Box 856, Mercer Island, Washington 98040
</br>
Email: <a
          class="onboarding-terms-and-condition-ancher"
        href="mailto:iCanPay@canpaydebit.com">iCanPay@canpaydebit.com </a>
</br>
  Phone: 888-395-4992
</div>


</div>
</div>

</br>
</br>
</br>



<div class="row p-3 ml-sm-3">
      <div class="col-md-12" id="section12">
    <div class="onboarding-terms-and-condition-header">
      <label>CONSUMER PRIVACY NOTICE</label>
    </div>
</div>
</div>

<div class="row  p-1 ml-sm-3">
    <div class="col-sm-2  onboadring-consumer-privacy-notice-fact" >
      Facts
    </div>
    <div class="col-sm-9  onboadring-consumer-privacy-notice-contain" >
      <strong>What does CanPay do with your personal
information? </strong>
    </div>
  
  </div>


<div class="row  p-1 ml-sm-3">
    <div class="col-sm-2  onboadring-consumer-privacy-notice-sub-header" >
     Why?
    </div>
    <div class="col-sm-9  onboadring-consumer-privacy-notice-sub-contain" >
    Financial companies choose how they share your personal information. Federal law
gives consumers the right to limit some but not all of this sharing. Federal law also
requires us to tell you how we collect, share, and protect your personal information.
Please read this notice carefully to understand what we do.
    </div>
  
  </div>


<div class="row  p-1 ml-sm-3">
    <div class="col-sm-2  onboadring-consumer-privacy-notice-sub-header" >
  What?
    </div>
    <div class="col-sm-9  onboadring-consumer-privacy-notice-sub-contain" >
  The types of personal information we collect, and share depend on the product or
service you have with us. This information can include:
</br>
</br>
<ul>
<li>
Social Security number and account balances
</li>
<li>
Payment history or transaction history
</li>
<li>
Credit history or credit scores
</li>
</ul>
When you are no longer our customer, we continue to share your information as
described in this notice.
    </div>
  
  </div>

  <div class="row  p-1 ml-sm-3">
    <div class="col-sm-2  onboadring-consumer-privacy-notice-sub-header" >
How?
    </div>
    <div class="col-sm-9  onboadring-consumer-privacy-notice-sub-contain" >
 All financial companies need to share customers’ personal information to run their
everyday business. In the section below, we list the reasons financial companies can
share their customers’ personal information; the reasons CanPay chooses to share; and
whether you can limit this sharing.
    </div>
  </div>



    <div class="row  p-1 ml-sm-3">
    <div class="col-sm-6  onboadring-consumer-privacy-notice-sub-header" >
<strong>Reasons we can share your personal
information</strong>
    </div>
      <div class="col-sm-2  onboadring-consumer-privacy-notice-sub-header" >
<strong>Does
CanPay
share?</strong>
    </div>
         <div class="col-sm-3  onboadring-consumer-privacy-notice-sub-header" >
<strong>Can you limit
this sharing?</strong>
    </div>
  </div>

    <div class="row  p-0 ml-sm-3">
    <div class="col-sm-6   onboadring-consumer-privacy-notice-sub-contain" >
    <strong>For our everyday business purposes—</strong></br>
such as to process your transactions, maintain your account(s), respond to court orders and legal investigations, or report to credit bureaus
    </div>
      <div class="col-sm-2   onboadring-consumer-privacy-notice-sub-contain" >
Yes
    </div>
         <div class="col-sm-3   onboadring-consumer-privacy-notice-sub-contain" >
No
    </div>
  </div>
  

   <div class="row  p-0 ml-sm-3">
    <div class="col-sm-6   onboadring-consumer-privacy-notice-sub-contain" >
    <strong>For our marketing purposes—</strong></br>
to offer our products and services to you
    </div>
      <div class="col-sm-2   onboadring-consumer-privacy-notice-sub-contain" >
Yes
    </div>
         <div class="col-sm-3   onboadring-consumer-privacy-notice-sub-contain" >
No
    </div>
  </div>


     <div class="row  p-0 ml-sm-3">
    <div class="col-sm-6   onboadring-consumer-privacy-notice-sub-contain" >
    <strong>For joint marketing with other financial companies</strong>
    </div>
      <div class="col-sm-2   onboadring-consumer-privacy-notice-sub-contain" >
Yes
    </div>
         <div class="col-sm-3   onboadring-consumer-privacy-notice-sub-contain" >
No
    </div>
  </div>

     <div class="row  p-0 ml-sm-3">
    <div class="col-sm-6   onboadring-consumer-privacy-notice-sub-contain" >
    <strong>For our affiliates’ everyday business purposes—</strong>
    </br>
    information about your transactions and experiences
    </div>
      <div class="col-sm-2   onboadring-consumer-privacy-notice-sub-contain" >
Yes
    </div>
         <div class="col-sm-3   onboadring-consumer-privacy-notice-sub-contain" >
No
    </div>
  </div>


 <div class="row  p-0 ml-sm-3">
    <div class="col-sm-6   onboadring-consumer-privacy-notice-sub-contain" >
    <strong>For our affiliates’ everyday business purposes—</strong>
    </br>
  information about your creditworthiness
    </div>
      <div class="col-sm-2   onboadring-consumer-privacy-notice-sub-contain" >
No
    </div>
         <div class="col-sm-3   onboadring-consumer-privacy-notice-sub-contain" >
We don’t share
    </div>
  </div>


 <div class="row  p-0 ml-sm-3">
    <div class="col-sm-6   onboadring-consumer-privacy-notice-sub-contain" >
    <strong>For our affiliates to market to you</strong>

    </div>
      <div class="col-sm-2   onboadring-consumer-privacy-notice-sub-contain" >
No
    </div>
         <div class="col-sm-3   onboadring-consumer-privacy-notice-sub-contain" >
We don’t share
    </div>
  </div>

   <div class="row  p-0 ml-sm-3">
    <div class="col-sm-6   onboadring-consumer-privacy-notice-sub-contain" >
    <strong>For nonaffiliates to market to you</strong>

    </div>
      <div class="col-sm-2   onboadring-consumer-privacy-notice-sub-contain" >
No
    </div>
         <div class="col-sm-3   onboadring-consumer-privacy-notice-sub-contain" >
We don’t share
    </div>
  </div>



<div class="row  p-1 ml-sm-3">
    <div class="col-sm-2  onboadring-consumer-privacy-notice-sub-header" >
   To Limit our sharing
    </div>
    <div class="col-sm-9  onboadring-consumer-privacy-notice-sub-contain" >
<ul>
<li>
Call 888-395-4992 – our menu will prompt you through your choice(s)
</li>
<li>
<strong>Email </strong> us at <a
          class="onboarding-terms-and-condition-ancher"
        href="mailto:iCanPay@canpaydebit.com">iCanPay@canpaydebit.com </a>
</li>
<li>
Mail the <strong>form </strong> below
</li>
</ul>
<strong>Please note: </strong>
If you are a<i> new customer </i>, we can begin sharing your information 30 days from the
date we sent this notice. When you are <i>no longer </i> our customer, we continue to share
your information as described in this notice.
</br>
However, you can contact us at any time to limit our sharing.
    </div>
  
  </div>


<div class="row  p-1 ml-sm-3">
    <div class="col-sm-2  onboadring-consumer-privacy-notice-sub-header" >
Questions?
    </div>
    <div class="col-sm-9  onboadring-consumer-privacy-notice-sub-contain" >
Call 888-395-4992 or email us at iCanPay@canpaydebit.com.

    </div>
  
  </div>

<div class="row ">
    <div class="col-sm-12 " style=" font-size:1.5rem; float:left" >
 <i class="fa fa-scissors" aria-hidden="true"></i> --------------------------------------------------------------------------------------------
    </div>
  </div>

<div class="row  p-1 ml-sm-3">
    <div class="col-sm-11  onboadring-consumer-privacy-notice-sub-header" >
      Mail-in Form
    </div>
    </div>
<div class="row  p-1 ml-sm-3">
    <div class="col-sm-11  onboadring-consumer-privacy-notice-sub-contain" >
      Mark any/all you want to limit:
</br>
</br>
<ul style="list-style-type:none;">
  <li><input type="checkbox" readonly>&nbsp;&nbsp; Do not share information about my creditworthiness with your affiliates for their
everyday business purposes.</li>
</br>
  <li><input type="checkbox" readonly>&nbsp;&nbsp; Do not allow your affiliates to use my personal information to market to me.</li>
  </br>
    <li><input type="checkbox" readonly>&nbsp;&nbsp; Do not share my personal information with nonaffiliates to market their products and
services to me.</li>
</ul>

    </div>
  
  </div>



<div class="row  ml-sm-3">
 <div class="col-sm-12">
<div  class="row" >

<div class="col-sm-8">
<div  class="row" >
    <div class="col-sm-4   onboadring-consumer-privacy-notice-sub-header " style= "background-color:#538cd5" >
    Name

    </div>
      <div class="col-sm-8   onboadring-consumer-privacy-notice-sub-contain" >
         <div class="row">

 <div class="col-sm-12 p-3 " >

 </div>
         </div>
    </div>
</div>



<div  class="row">

    <div class="col-sm-4   onboadring-consumer-privacy-notice-sub-header"style= "background-color:#538cd5" >
Address
</br>
</br>
</br>
</br>
City, State,
Zip
    </div>
    
      <div class="col-sm-8   onboadring-consumer-privacy-notice-sub-contain" >
  <div class="row mt-n3">
  <div class="col-sm-12   onboadring-consumer-privacy-notice-sub-contain" >
</br>
  </div>

  <div class="col-sm-12   onboadring-consumer-privacy-notice-sub-contain" >
</br>
  </div>

 
  </div>
   </br>
  </br> </br>
  </br>
    </div>
  </div>
 



  <div class="row">
  
    <div class="col-sm-4  onboadring-consumer-privacy-notice-sub-header" style= "background-color:#538cd5">
Account #
    </div>
    
      <div class="col-sm-8   onboadring-consumer-privacy-notice-sub-contain" >

    </div>

</div>

</div>

 <div class="col-sm-3  onboadring-consumer-privacy-notice-sub-contain">
<div class= "row align-middle float-left">
</br>
</br>
</br>
<strong>Mail to:</strong>
</br>
Trusted Debit</br>
LLC (“CanPay”)</br>
PO Box 856,</br>
Mercer Island,</br>
Washington</br>
98040
</div>
</div>

</div>
</div>


  </div>



  <div class="row  p-0 ml-sm-3">
    <div class="col-sm-11   onboadring-consumer-privacy-notice-sub-header" >
Who we are
    </div>
  </div>

 <div class="row  p-0 ml-sm-3">
    <div class="col-sm-4   onboadring-consumer-privacy-notice-sub-contain" >
<strong>Who is providing this</br>
Privacy Policy? </strong>
    </div>
        <div class="col-sm-7   onboadring-consumer-privacy-notice-sub-contain" >
This privacy notice is provided by CanPay, and is applicable to your
personal CanPay account.
    </div>
  </div>


    <div class="row  p-0 ml-sm-3">
    <div class="col-sm-11   onboadring-consumer-privacy-notice-sub-header" >
What we do
    </div>
  </div>

 <div class="row  p-0 ml-sm-3">
    <div class="col-sm-4   onboadring-consumer-privacy-notice-sub-contain" >
<strong>How does CanPay</br>
protect my personal</br>
information? </strong>
    </div>
        <div class="col-sm-7   onboadring-consumer-privacy-notice-sub-contain" >
To protect your personal information from unauthorized access and use,
we use security measures that comply with federal law. These measures
include computer safeguards and secured files and buildings.
    </div>
  </div>


 <div class="row  p-0 ml-sm-3">
    <div class="col-sm-4   onboadring-consumer-privacy-notice-sub-contain" >
<strong>How does CanPay</br>
collect my personal</br>
information? </strong>
    </div>
        <div class="col-sm-7   onboadring-consumer-privacy-notice-sub-contain" >
We collect your personal information, for example, when you
<ul>
<li>
open an account or provide account information
</li>
<li>
link your checking or savings account to your CanPay account or
give us your contact information
</li>
<li>
use your CanPay account to pay for services
</li>
</ul>

We also collect your personal information from others, such as our
verification service providers, affiliates, or other companies.
    </div>
  </div>



 <div class="row  p-0 ml-sm-3">
    <div class="col-sm-4   onboadring-consumer-privacy-notice-sub-contain" >
<strong>Why can’t I limit all</br>
sharing? </strong>
    </div>
        <div class="col-sm-7   onboadring-consumer-privacy-notice-sub-contain" >
Federal law gives you the right to limit only
<ul>
<li>
sharing for affiliates’ everyday business purposes— information
about your creditworthiness
</li>
<li>
affiliates from using your information to market to you
</li>
<li>
sharing for nonaffiliates to market to you
</li>
</ul>

State laws and individual companies may give you additional rights to
limit sharing. See our <a
          class="onboarding-terms-and-condition-ancher"
          href="https://www.canpaydebit.com/about-us/privacy/"
          target="_blank"
        >Privacy Policy</a> and  <a
          class="onboarding-terms-and-condition-ancher"
          href="#supplemental-notice"
      
        >Supplemental Notice for
California Residents</a> for more information on your rights under state law.
    </div>
  </div>




     <div class="row  p-0 ml-sm-3">
    <div class="col-sm-11   onboadring-consumer-privacy-notice-sub-header" >
What we do
    </div>
  </div>
 <div class="row  p-0 ml-sm-3">
    <div class="col-sm-2   onboadring-consumer-privacy-notice-sub-contain" >
<strong>Affiliates </strong>
    </div>
        <div class="col-sm-9   onboadring-consumer-privacy-notice-sub-contain" >
Companies related by common ownership or control. They can be financial and</br>
nonfinancial companies.
    </div>
  </div>


 <div class="row  p-0 ml-sm-3">
    <div class="col-sm-2   onboadring-consumer-privacy-notice-sub-contain" >
<strong>Nonaffiliates </strong>
    </div>
        <div class="col-sm-9   onboadring-consumer-privacy-notice-sub-contain" >
Companies not related by common ownership or control. They can be financial and
nonfinancial companies.
<ul>
<li>
Nonaffiliates with which we share personal information include Merchants
and service providers that perform services or functions on our behalf.
</li>
</ul>
    </div>
  </div>

 <div class="row  p-0 ml-sm-3">
    <div class="col-sm-2   onboadring-consumer-privacy-notice-sub-contain" >
<strong>Joint
Marketing </strong>
    </div>
        <div class="col-sm-9   onboadring-consumer-privacy-notice-sub-contain" >
A formal agreement between nonaffiliated financial companies that together market
financial products or services to you.
<ul>
<li>
Our joint marketing partners include Merchants.
</li>
</ul>
    </div>
  </div>

      <div class="row  p-0 ml-sm-3">
    <div class="col-sm-11   onboadring-consumer-privacy-notice-sub-header" >
Other important information
    </div>
  </div>

      <div class="row  p-0 ml-sm-3">
    <div class="col-sm-11   onboadring-consumer-privacy-notice-sub-contain" >
We may transfer personal information to other countries, for example, for customer service or to
process transactions.
</br>
</br>
<ul>
<li>
<strong>California:</strong> If your CanPay account has a California mailing address, we will not share personal
information we collect about you except to the extent permitted under California law.
</li>
</br>
<li>
<strong>Vermont:</strong> If your CanPay account has a Vermont mailing address, we will not share personal
information we collect about you with non-affiliates unless the law allows, or you provide
authorization.
</li>
</ul>

    </div>
  </div>
  </br>
     <div class="row p-3 ml-sm-3">
      <div class="col-md-12">
    <div class="onboarding-terms-and-condition-header" id = "supplemental-notice">
      <label>SUPPLEMENTAL NOTICE FOR CALIFORNIA RESIDENTS</label>
    </div>
</div>
</div>

   <div class="row p-3">
      <div class="col-md-12">
    <div class="onboarding-terms-and-condition-pragrapgh">
      <label
        >This Supplemental California Privacy Notice only applies to our processing of personal information that
is subject to the California Consumer Privacy Act of 2018 (“CCPA”). The CCPA provides California
residents with the right to know what categories of personal information CanPay has collected about
them, and whether CanPay disclosed that personal information for a business purpose (e.g., to a service
provider) in the preceding 12 months.
      </label>

     <label
        ><strong>Information Collected.</strong> TThe categories of personal information collected by CanPay in the preceding 12
       months is described in  <a
          class="onboarding-terms-and-condition-ancher"
        href="#section2">Section 2 of the Privacy Notice above. </a>
      </label>
      <label>
      <strong>Information Disclosed.</strong> The categories of personal information shared by CanPay in the preceding 12
months are described below:
      </label>
    </div>
    </div>
    </div>

      <div class="row  ml-sm-3">
      <div class="col-sm-8 onboadring-consumer-privacy-notice-sub-contain color-onboarding-privacy-note-header-color">
    <strong>  Category of Personal Information Disclosed by CanPay</strong>
      </div>
            <div class="col-sm-4 onboadring-consumer-privacy-notice-sub-contain color-onboarding-privacy-note-header-color">
         <strong>   Category of Third Parties
Information is Disclosed to for
a Business Purpose</strong>
      </div>
      </div>

 <div class="row   ml-sm-3">
      <div class="col-sm-8 onboadring-consumer-privacy-notice-sub-contain color-onboarding-privacy-note-header-color-two">
<strong>Identifiers.</strong></br>
A real name, alias, postal address, unique personal identifier, online
identifier, Internet Protocol address, email address, account name, Social
Security number, or other similar identifiers.
      </div>
            <div class="col-sm-4 onboadring-consumer-privacy-notice-sub-contain" style="font-size:0.9rem">
            <ul>
            <li>
            Data analytics providers
            </li>
            <li>
            Service providers
            </li>
            <li>
            We may share Customer names
and CanPay unique identifiers
with Merchants
            </li>
            </ul>
            
      </div>
      </div>

 <div class="row   ml-sm-3">
      <div class="col-sm-8 onboadring-consumer-privacy-notice-sub-contain color-onboarding-privacy-note-header-color-two">
<strong>Personal information categories listed in the California Customer
Records statute (Cal. Civ. Code § 1798.80(e))</strong></br>
A name, signature, Social Security number, address, telephone number,
driver&#39;s license or state identification card number, bank account number,
or any other financial information.
      </div>
            <div class="col-sm-4 onboadring-consumer-privacy-notice-sub-contain" style="font-size:0.9rem">
            <ul>
            <li>
      Data analytics providers
            </li>
            <li>
            Service providers
            </li>
            </ul>
            
      </div>
      </div>

 <div class="row   ml-sm-3">
      <div class="col-sm-8 onboadring-consumer-privacy-notice-sub-contain color-onboarding-privacy-note-header-color-two">
<strong>Protected classification characteristics under California or federal law</strong>
Age (40 years or older).</br>
      </div>
            <div class="col-sm-4 onboadring-consumer-privacy-notice-sub-contain" style="font-size:0.9rem">
            <ul>
            <li>
      Data analytics providers
            </li>
            <li>
            Service providers
            </li>
            </ul>
            
      </div>
      </div>



 <div class="row   ml-sm-3">
      <div class="col-sm-8 onboadring-consumer-privacy-notice-sub-contain color-onboarding-privacy-note-header-color-two">
<strong>Commercial information</strong>
</br>
Records of personal property, products or services purchased, obtained, or
considered, or other purchasing or consuming histories or tendencies.
      </div>
            <div class="col-sm-4 onboadring-consumer-privacy-notice-sub-contain"style="font-size:0.9rem">
            <ul>
            <li>
      Data analytics providers
            </li>
            <li>
            Service providers
            </li>
            </ul>
            
      </div>
      </div>


 <div class="row   ml-sm-3">
      <div class="col-sm-8 onboadring-consumer-privacy-notice-sub-contain color-onboarding-privacy-note-header-color-two">
<strong>Internet or other electronic network activity</strong></br>
Browsing history, information on a consumer&#39;s interaction with an internet
website, application, or advertisement.
      </div>
            <div class="col-sm-4 onboadring-consumer-privacy-notice-sub-contain" style="font-size:0.9rem">
            <ul>
            <li>
      Data analytics providers
            </li>
            <li>
            Service providers
            </li>
               <li>
            Advertising networks
            </li>
            </ul>
            
      </div>
      </div>

 <div class="row   ml-sm-3">
      <div class="col-sm-8 onboadring-consumer-privacy-notice-sub-contain color-onboarding-privacy-note-header-color-two">
<strong>Geolocation data</strong></br>
Physical location or movements.
      </div>
            <div class="col-sm-4 onboadring-consumer-privacy-notice-sub-contain " style="font-size:0.9rem">
            <ul>
            <li>
      Data analytics providers
            </li>
            <li>
            Service providers
            </li>

            </ul>
            
      </div>
      </div>

      <div class="row  ml-sm-3">
      <div class="col-sm-8 onboadring-consumer-privacy-notice-sub-contain color-onboarding-privacy-note-header-color-two">
<strong>Inferences drawn from other personal information to create a profile
about a consumer</strong></br>
Profile reflecting a consumer&#39;s preferences, characteristics, psychological
trends, predispositions, behavior, attitudes, intelligence, abilities, and
aptitudes.
      </div>
            <div class="col-sm-4 onboadring-consumer-privacy-notice-sub-contain" style="font-size:0.9rem">
            <ul>
            <li>
      Data analytics providers
            </li>
            <li>
            Service providers
            </li>
               <li>
            Advertising networks
            </li>
            </ul>
            
      </div>
      </div>


<div class="row p-3">
      <div class="col-md-12">
    <div class="onboarding-terms-and-condition-pragrapgh">
      <label
        > The categories of sources from which we collect personal information and our business and commercial
purposes for using personal information are set forth in <a
          class="onboarding-terms-and-condition-ancher"
        href="#section2">Section 2 of the Privacy Notice above. </a>
      </label>
    </div>
    </div>
    </div>


    <div class="row p-3">
      <div class="col-md-12">
    <div class="onboarding-terms-and-condition-sub-two">
      <label>“Sales” of Personal Information under the CCPA</label>
    </div>
</div>
</div>

 <div class="row p-3">
      <div class="col-md-12">
    <div class="onboarding-terms-and-condition-pragrapgh">
      <label
        >For purposes of the CCPA, CanPay does not “sell” personal information, nor do we have actual
knowledge of any “sale” of personal information of minors under 16 years of age.
      </label>



    </div>
    </div>
    </div>

    <div class="row p-3">
      <div class="col-md-12">
    <div class="onboarding-terms-and-condition-sub-two">
      <label>Additional Privacy Rights for California Residents</label>
    </div>
</div>
</div>

<div class="row p-3">
      <div class="col-md-12">
    <div class="onboarding-terms-and-condition-pragrapgh">
      <label
        ><strong>Non-Discrimination.</strong> California residents have the right not to receive discriminatory treatment by us for
the exercise of their rights conferred by the CCPA.
      </label>



    </div>
    </div>
    </div>



    <div class="row p-3 ">
      <div class="col-md-12">
    <div class="onboarding-terms-and-condition-pragrapgh">
      <label
        ><strong>Authorized Agent.</strong> Only you, or someone legally authorized to act on your behalf, may make a verifiable
consumer request related to your personal information. You may also make a verifiable consumer request
on behalf of your minor child. To designate an authorized agent, please contact us as set forth in 
         <a class="onboarding-terms-and-condition-ancher"
        href="#section12">Section
12 of the Privacy Notice above,</a> and provide written confirmation via a scanned document containing your
signature and the name of your authorized agent.
      </label>



    </div>
    </div>
    </div>

        <div class="row p-3 ">
      <div class="col-md-12">
    <div class="onboarding-terms-and-condition-pragrapgh">
      <label
        ><strong>Verification.</strong> To protect your privacy, we will take steps the following steps to verify your identity before
fulfilling your request. When you make a request, we will ask you to provide sufficient information that
allows us to reasonably verify you are the person about whom we collected personal information or an
authorized representative, which may include asking you to verify information you have given us to set
up your account, or to provide details regarding recent transactions.
      </label>
 <label>
 If you are a California resident and would like to exercise any of your rights under the CCPA, please
contact us as set forth in       <a class="onboarding-terms-and-condition-ancher"
        href="#section12">Section
12 of the Privacy Notice above.</a> We will process such requests in
accordance with applicable laws.
 </label>


    </div>
    </div>
    </div>



  <div class="row p-3">
      <div class="col-md-12">
    <div class="onboarding-terms-and-condition-sub-two ">
      <label class= "color-onboarding-privacy-note-header-color">Financial Incentives.</label>
    </div>
</div>
</div>
     
      <div class="row p-3 ">
      <div class="col-md-12">
    <div class="onboarding-terms-and-condition-pragrapgh">
        <ul>
        <li>
        <strong>Personalized discounts.</strong> We may offer personalized discounts and financial incentives to
Customers based on information we retain regarding Customers’ individual spending habits.
Customers may opt out of receiving these personalized discounts at any time by adjusting their
account settings. These programs allow us to deliver additional value to Customers and generate
additional profit from our arrangements with the Merchants who provide the discounts. The value
of each Customer’s data varies depending on the Merchant providing the promotion and the
details of each promotion, and said value will be reflected in the discount offered to each
Customer.
        </li>

<li>
<strong>Refer-a-friend programs.</strong> As described above in Information You Provide to Us Directly
(“Share Content with Friends or Colleagues”), we may offer incentives to you such as
discounts or promotional credit in connection with programs that we may run, wherein you
provide personal information regarding their friends or colleagues (such as their email address)
and receive rewards when they sign up to use our Services. (The referred party may also receive
rewards for signing up via your referral.) These referral programs are entirely voluntary and allow
us to grow our business and provide additional benefits to you. The value of the referred party’s
data to us depends on whether the referred party ultimately becomes a Customer or Merchant and
uses our Services. Said value will be reflected in the incentive offered for each referral.
</li>
        </ul>

<label>
<strong>Accessibility.</strong> This Privacy Policy uses industry-standard technologies and was developed in line with the
World Wide Web Consortium’s Web Content Accessibility Guidelines, version 2.1. If you wish to print
this policy, please do so from your web browser or by saving the page as a PDF.
</label>
    </div>
    </div>
    </div>

  <div class="row p-3 ">
      <div class="col-md-12">
    <div class="onboarding-terms-and-condition-header">
      <label>SUPPLEMENTAL NOTICE FOR NEVADA RESIDENTS</label>
    </div>
</div>
</div>


<div class="row p-3">
      <div class="col-md-12">
    <div class="onboarding-terms-and-condition-pragrapgh">
      <label
        > If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal Information to
third parties who intend to license or sell that Personal Information. You can exercise this right by
contacting us at <a
          class="onboarding-terms-and-condition-ancher"
        href="mailto:iCanPay@canpaydebit.com">iCanPay@canpaydebit.com</a> with the subject line “Nevada Do Not Sell Request” and
providing us with your name and the email address associated with your account. Please note that we do
not currently sell your Personal Information as sales are defined in Nevada Revised Statutes Chapter
603A. 
      </label>
    </div>
    </div>
    </div>
  </div>
</template>

<script>
import OnboardingHeader from "../OnBoardScreeen/OnboardingHeader.vue";
export default {
  /**
   * write a component's description
   * this component is use to Upgrade to the new canpay the user's email as a consumer
   */
  name: "OnboardingPrivacyPolicy",
  /**
   * @description-

   * @returns {any}
   */
  data() {
    let self = this;
    return {};
  },
  components: {
    "cp-onboardingheader": OnboardingHeader,
  },
  destroyed() {
    document
      .getElementById("app")
      .style.setProperty("background-color", "#149240");
  },
  created() {},
  mounted() {
    this.$root.$emit("show_header", false);
    this.$root.$emit("changeWhiteBackground", [true, false, ""]);
    document
      .getElementById("app")
      .style.setProperty("background-color", "#ffffff");

    var element = document.getElementsByClassName("wrapper");
    element[0].style.setProperty("background-color", "#ffffff");

    var elementHtml = document.getElementsByTagName("html")[0];
    elementHtml.style.setProperty("background-color", "#ffffff");

    this.phone = localStorage.getItem("phone");
  },
  methods: {},
};
</script>
<style lang="scss">
</style>
