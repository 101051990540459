<template>
  <div class="container">
 <cp-onboardingheader></cp-onboardingheader>
      <div class="row p-3 ml-3">
      <div class="col-md-12">
    <div class="onboarding-terms-and-condition-header">
      <label>CONSUMER PRIVACY NOTICE</label>
    </div>
</div>
</div>

<div class="row  p-1 ml-3">
    <div class="col-sm-2 ml-1 onboadring-consumer-privacy-notice-fact" >
      Facts
    </div>
    <div class="col-sm-9 ml-1 onboadring-consumer-privacy-notice-contain" >
      <strong>What does CanPay do with your personal
information? </strong>
    </div>
  
  </div>


<div class="row  p-1 ml-3">
    <div class="col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-header" >
     Why?
    </div>
    <div class="col-sm-9 ml-1 onboadring-consumer-privacy-notice-sub-contain" >
    Financial companies choose how they share your personal information. Federal law
gives consumers the right to limit some but not all of this sharing. Federal law also
requires us to tell you how we collect, share, and protect your personal information.
Please read this notice carefully to understand what we do.
    </div>
  
  </div>


<div class="row  p-1 ml-3">
    <div class="col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-header" >
  What?
    </div>
    <div class="col-sm-9 ml-1 onboadring-consumer-privacy-notice-sub-contain" >
  The types of personal information we collect, and share depend on the product or
service you have with us. This information can include:
</br>
</br>
<ul>
<li>
Social Security number and account balances
</li>
<li>
Payment history or transaction history
</li>
<li>
Credit history or credit scores
</li>
</ul>
When you are no longer our customer, we continue to share your information as
described in this notice.
    </div>
  
  </div>

  <div class="row  p-1 ml-3">
    <div class="col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-header" >
How?
    </div>
    <div class="col-sm-9 ml-1 onboadring-consumer-privacy-notice-sub-contain" >
 All financial companies need to share customers’ personal information to run their
everyday business. In the section below, we list the reasons financial companies can
share their customers’ personal information; the reasons CanPay chooses to share; and
whether you can limit this sharing.
    </div>
  </div>



    <div class="row  p-1 ml-3">
    <div class="col-sm-6 ml-1 onboadring-consumer-privacy-notice-sub-header" >
<strong>Reasons we can share your personal
information</strong>
    </div>
      <div class="col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-header" >
<strong>Does
CanPay
share?</strong>
    </div>
         <div class="col-sm-3 ml-1 onboadring-consumer-privacy-notice-sub-header" >
<strong>Can you limit
this sharing?</strong>
    </div>
  </div>

    <div class="row  p-1 ml-3">
    <div class="col-sm-6 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
    <strong>For our everyday business purposes—</strong></br>
such as to process your transactions, maintain your account(s), respond to court orders and legal investigations, or report to credit bureaus
    </div>
      <div class="col-sm-2 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
Yes
    </div>
         <div class="col-sm-3 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
No
    </div>
  </div>
  

   <div class="row  p-1 ml-3">
    <div class="col-sm-6 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
    <strong>For our marketing purposes—</strong></br>
to offer our products and services to you
    </div>
      <div class="col-sm-2 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
Yes
    </div>
         <div class="col-sm-3 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
No
    </div>
  </div>


     <div class="row  p-1 ml-3">
    <div class="col-sm-6 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
    <strong>For joint marketing with other financial companies</strong>
    </div>
      <div class="col-sm-2 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
Yes
    </div>
         <div class="col-sm-3 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
No
    </div>
  </div>

     <div class="row  p-1 ml-3">
    <div class="col-sm-6 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
    <strong>For our affiliates’ everyday business purposes—</strong>
    </br>
    information about your transactions and experiences
    </div>
      <div class="col-sm-2 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
Yes
    </div>
         <div class="col-sm-3 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
No
    </div>
  </div>


 <div class="row  p-1 ml-3">
    <div class="col-sm-6 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
    <strong>For our affiliates’ everyday business purposes—</strong>
    </br>
  information about your creditworthiness
    </div>
      <div class="col-sm-2 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
No
    </div>
         <div class="col-sm-3 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
We don’t share
    </div>
  </div>


 <div class="row  p-1 ml-3">
    <div class="col-sm-6 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
    <strong>For our affiliates to market to you</strong>

    </div>
      <div class="col-sm-2 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
No
    </div>
         <div class="col-sm-3 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
We don’t share
    </div>
  </div>

   <div class="row  p-1 ml-3">
    <div class="col-sm-6 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
    <strong>For nonaffiliates to market to you</strong>

    </div>
      <div class="col-sm-2 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
No
    </div>
         <div class="col-sm-3 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
We don’t share
    </div>
  </div>



<div class="row  p-1 ml-3">
    <div class="col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-header" >
   To Limit our sharing
    </div>
    <div class="col-sm-9 ml-1 onboadring-consumer-privacy-notice-sub-contain" >
<ul>
<li>
Call 888-395-4992 – our menu will prompt you through your choice(s)
</li>
<li>
<strong>Email </strong> us at <a
          class="onboarding-terms-and-condition-ancher"
        href="mailto:iCanPay@canpaydebit.com">iCanPay@canpaydebit.com </a>
</li>
<li>
Mail the <strong>form </strong> below
</li>
</ul>
<strong>Please note: </strong>
If you are a<i> new customer </i>, we can begin sharing your information 30 days from the
date we sent this notice. When you are <i>no longer </i> our customer, we continue to share
your information as described in this notice.
</br>
However, you can contact us at any time to limit our sharing.
    </div>
  
  </div>


<div class="row  p-1 ml-3">
    <div class="col-sm-2 ml-1 onboadring-consumer-privacy-notice-sub-header" >
Questions?
    </div>
    <div class="col-sm-9 ml-1 onboadring-consumer-privacy-notice-sub-contain" >
Call 888-395-4992 or email us at iCanPay@canpaydebit.com.

    </div>
  
  </div>

<div class="row ">
    <div class="col-12 " style=" font-size:1.5rem;" >
--------------------------------------------------------------------------------------------
    </div>
  </div>

<div class="row  p-1 ml-3">
    <div class="col-sm-11 ml-1 onboadring-consumer-privacy-notice-sub-header" >
      Mail-in Form
    </div>
    </div>
<div class="row  p-1 ml-3">
    <div class="col-sm-11 ml-1 onboadring-consumer-privacy-notice-sub-contain" >
      Mark any/all you want to limit:
</br>
</br>
<ul style="list-style-type:none;">
  <li><input type="checkbox" readonly>&nbsp;&nbsp; Do not share information about my creditworthiness with your affiliates for their
everyday business purposes.</li>
</br>
  <li><input type="checkbox" readonly>&nbsp;&nbsp; Do not allow your affiliates to use my personal information to market to me.</li>
  </br>
    <li><input type="checkbox" readonly>&nbsp;&nbsp; Do not share my personal information with nonaffiliates to market their products and
services to me.</li>
</ul>

    </div>
  
  </div>



<div class="row  ml-3">
    <div class="col-sm-2 ml-1  onboadring-consumer-privacy-notice-sub-header" >
    Name

    </div>
      <div class="col-sm-7 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
         
    </div>
         <div class="col-sm-2 ml-1  onboadring-consumer-privacy-notice-sub-contain" >

    </div>
  </div>
  <div class="row  ml-3">
    <div class="col-sm-2 ml-1  onboadring-consumer-privacy-notice-sub-header" >
Address

City, State,
Zip

    </div>
      <div class="col-sm-7 ml-1  onboadring-consumer-privacy-notice-sub-contain" >

    </div>
         <div class="col-sm-2 ml-1  onboadring-consumer-privacy-notice-sub-contain" >

    </div>
  </div>
  
  <div class="row   ml-3">
    <div class="col-sm-2 ml-1  onboadring-consumer-privacy-notice-sub-header" >
Account #
    </div>
      <div class="col-sm-7 ml-1  onboadring-consumer-privacy-notice-sub-contain" >

    </div>
         <div class="col-sm-2 ml-1  onboadring-consumer-privacy-notice-sub-contain" >

    </div>
  </div>



  <div class="row  p-1 ml-3">
    <div class="col-sm-11 ml-1  onboadring-consumer-privacy-notice-sub-header" >
Who we are
    </div>
  </div>

 <div class="row  p-1 ml-3">
    <div class="col-sm-4 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
<strong>Who is providing this</br>
Privacy Policy? </strong>
    </div>
        <div class="col-sm-7 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
This privacy notice is provided by CanPay, and is applicable to your
personal CanPay account.
    </div>
  </div>


    <div class="row  p-1 ml-3">
    <div class="col-sm-11 ml-1  onboadring-consumer-privacy-notice-sub-header" >
What we do
    </div>
  </div>

 <div class="row  p-1 ml-3">
    <div class="col-sm-4 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
<strong>How does CanPay</br>
protect my personal</br>
information? </strong>
    </div>
        <div class="col-sm-7 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
To protect your personal information from unauthorized access and use,
we use security measures that comply with federal law. These measures
include computer safeguards and secured files and buildings.
    </div>
  </div>


 <div class="row  p-1 ml-3">
    <div class="col-sm-4 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
<strong>How does CanPay</br>
collect my personal</br>
information? </strong>
    </div>
        <div class="col-sm-7 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
We collect your personal information, for example, when you
<ul>
<li>
open an account or provide account information
</li>
<li>
link your checking or savings account to your CanPay account or
give us your contact information
</li>
<li>
use your CanPay account to pay for services
</li>
</ul>

We also collect your personal information from others, such as our
verification service providers, affiliates, or other companies.
    </div>
  </div>



 <div class="row  p-1 ml-3">
    <div class="col-sm-4 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
<strong>Why can’t I limit all</br>
sharing? </strong>
    </div>
        <div class="col-sm-7 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
Federal law gives you the right to limit only
<ul>
<li>
sharing for affiliates’ everyday business purposes— information
about your creditworthiness
</li>
<li>
affiliates from using your information to market to you
</li>
<li>
sharing for nonaffiliates to market to you
</li>
</ul>

State laws and individual companies may give you additional rights to
limit sharing. See our <a
          class="onboarding-terms-and-condition-ancher"
          href="https://www.canpaydebit.com/about-us/privacy/"
          target="_blank"
        >Privacy Policy</a> and  <a
          class="onboarding-terms-and-condition-ancher"
          href="#"
      
        >Supplemental Notice for
California Residents</a> for more information on your rights under state law.
    </div>
  </div>




     <div class="row  p-1 ml-3">
    <div class="col-sm-11 ml-1  onboadring-consumer-privacy-notice-sub-header" >
What we do
    </div>
  </div>
 <div class="row  p-1 ml-3">
    <div class="col-sm-2 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
<strong>Affiliates </strong>
    </div>
        <div class="col-sm-9 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
Companies related by common ownership or control. They can be financial and</br>
nonfinancial companies.
    </div>
  </div>


 <div class="row  p-1 ml-3">
    <div class="col-sm-2 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
<strong>Nonaffiliates </strong>
    </div>
        <div class="col-sm-9 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
Companies not related by common ownership or control. They can be financial and
nonfinancial companies.
<ul>
<li>
Nonaffiliates with which we share personal information include Merchants
and service providers that perform services or functions on our behalf.
</li>
</ul>
    </div>
  </div>

 <div class="row  p-1 ml-3">
    <div class="col-sm-2 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
<strong>Joint
Marketing </strong>
    </div>
        <div class="col-sm-9 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
A formal agreement between nonaffiliated financial companies that together market
financial products or services to you.
<ul>
<li>
Our joint marketing partners include Merchants.
</li>
</ul>
    </div>
  </div>

      <div class="row  p-1 ml-3">
    <div class="col-sm-11 ml-1  onboadring-consumer-privacy-notice-sub-header" >
Other important information
    </div>
  </div>

      <div class="row  p-1 ml-3">
    <div class="col-sm-11 ml-1  onboadring-consumer-privacy-notice-sub-contain" >
We may transfer personal information to other countries, for example, for customer service or to
process transactions.
</br>
</br>
<ul>
<li>
<strong>California:</strong> If your CanPay account has a California mailing address, we will not share personal
information we collect about you except to the extent permitted under California law.
</li>
</br>
<li>
<strong>Vermont:</strong> If your CanPay account has a Vermont mailing address, we will not share personal
information we collect about you with non-affiliates unless the law allows, or you provide</br>
authorization.
</li>
</ul>

    </div>
  </div>
  </div>
  </template>
  
<script>
import OnboardingHeader from "../OnBoardScreeen/OnboardingHeader.vue";
export default {
  /**
   * write a component's description
   * this component is use to Upgrade to the new canpay the user's email as a consumer
   */
  name: "OnboardingPrivacyPolicy",
  /**
   * @description-

   * @returns {any}
   */
  data() {
    let self = this;
    return {};
  },
  components: {
    "cp-onboardingheader": OnboardingHeader,
  },
  destroyed() {
    document
      .getElementById("app")
      .style.setProperty("background-color", "#149240");
  },
  created() {},
  mounted() {
    this.$root.$emit("show_header", false);
    this.$root.$emit("changeWhiteBackground", [true, false, ""]);
    document
      .getElementById("app")
      .style.setProperty("background-color", "#ffffff");

    var element = document.getElementsByClassName("wrapper");
    element[0].style.setProperty("background-color", "#ffffff");

    var elementHtml = document.getElementsByTagName("html")[0];
    elementHtml.style.setProperty("background-color", "#ffffff");

    this.phone = localStorage.getItem("phone");
  },
  methods: {},
};
</script>

<style lang="scss">

.onboadring-consumer-privacy-notice-fact {

  font-size:1.6rem;
 background-color:#4497dc; 
 border: 0.15rem solid #ededed; 
 text-align:left;
  padding: 0.9rem;
  color: white; 
   float:left;
}

.onboadring-consumer-privacy-notice-contain {
border: 0.1rem solid #ededed;
 text-align:left;
  float: left;
  padding: 0.9rem;
  font-size:1.5rem;

}

.onboadring-consumer-privacy-notice-sub-header {
 font-size:1.0rem;
 background-color:#4497dc; 
 border: 0.15rem solid #ededed; 
 text-align:left;
  padding: 0.9rem;
  color: white; 
   float:left;
}

.onboadring-consumer-privacy-notice-sub-contain {
border: 0.1rem solid #ededed;
 text-align:left;
  float: left;
  padding: 0.9rem;
  font-size:1.0rem;
}
</style>