<template>
<div>
  <div v-if="isLoading">
  <CanPayLoader/>
  </div>
  <div class="container splash">
    <cp-onboardingheader />
    <div v-if="!isLoading">
      <div class="row">
        <div class="col-12 text-center">
          <img
            class="upgrade-image"
            src="../../assets/images/mail-varification.png"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 verification-name-label text-center">
          <label>We sent a verification email to you at {{ email }} </label>
        </div>
      </div>
      <div class="row">
        <div class="col-12 verification-code-description-label">
          <label
            >Please enter the verification <br />
            code below.
          </label>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12 verification-code-label">
          <label class="float-left">Enter verification code </label>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group no-border">
            <span class="form-control-icon">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 125"
                style="enable-background: new 0 0 100 125"
                xml:space="preserve"
                height="30"
                width="30"
                class="lockkey-icon"
              >
                <path
                  d="M77.1,81.4H38.5c-4.8,0-8.6-3.9-8.6-8.6v-6.7c0-4.8,3.9-8.6,8.6-8.6h38.6c4.8,0,8.6,3.9,8.6,8.6v6.7
    C85.8,77.5,81.9,81.4,77.1,81.4z M38.5,62c-2.3,0-4.1,1.9-4.1,4.1v6.7c0,2.3,1.9,4.1,4.1,4.1h38.6c2.3,0,4.1-1.9,4.1-4.1v-6.7
    c0-2.3-1.9-4.1-4.1-4.1H38.5z"
                />
                <circle cx="43" cy="69.4" r="3.1" />
                <circle cx="52.5" cy="69.4" r="3.1" />
                <circle cx="62" cy="69.4" r="3.1" />
                <path
                  d="M71.6,66.3c1.7,0,3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1c-1.7,0-3.1-1.4-3.1-3.1C68.4,67.7,69.8,66.3,71.6,66.3z"
                />
                <path
                  d="M43.4,43.5c-1.2,0-2.3-1-2.3-2.3v-9.9c0-4.5-3.7-8.2-8.2-8.2s-8.2,3.7-8.2,8.2v9.9c0,1.2-1,2.3-2.3,2.3s-2.3-1-2.3-2.3v-9.9
    c0-7,5.7-12.7,12.7-12.7s12.7,5.7,12.7,12.7v9.9C45.7,42.5,44.7,43.5,43.4,43.5z"
                />
                <path
                  d="M25.5,72.4h-4.8c-3.6,0-6.5-2.9-6.5-6.5V45.5c0-3.6,2.9-6.5,6.5-6.5h25.8c3.6,0,6.5,2.9,6.5,6.5v11.4c0,1.2-1,2.3-2.3,2.3
    s-2.3-1-2.3-2.3V45.5c0-1.1-0.9-2-2-2H20.7c-1.1,0-2,0.9-2,2v20.4c0,1.1,0.9,2,2,2h4.8c1.2,0,2.3,1,2.3,2.3S26.8,72.4,25.5,72.4z"
                />
              </svg>
            </span>
            <input
              v-model="emailVerificationCode"
              @keyup.enter="submitCode"
              inputmode="numeric"
              type="text"
              class="form-control input-box-padding email-address"
              placeholder="Enter code"
              v-bind:style="{ 'border-color': color }"
            />
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <button
            type="button"
            class="btn-login btn-get-started"
            v-on:click="submitCode"
          >
            Next
          </button>
        </div>
      </div>
      <div
        class="input-box-row re-send-verification"
        v-if="!codesentFlag && timerCount == 0 && resentOtp != 4"
      >
        <label v-on:click="resendOtpCountCheck" class="cursor-pointer"
          ><u>Re-send verification code</u></label
        >
      </div>

      <div
        class="input-box-row re-send-verification"
        v-else-if="timerCount != 0 && resentOtp != 4"
      >
        <label>
          Resend Code After &nbsp; &nbsp; &nbsp; &nbsp;
          {{ secondsToTime(timerCount) }} Seconds</label
        >
      </div>
      <div class="input-box-row re-send-verification" v-else>
        <label class="cursor-pointer"> Try Again Some Other Time</label>
      </div>
    </div>
    <!-----------------------  MODAL FOR success modal  !---------------->
    <div>
      <b-modal
        ref="email-success-verification-code-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        no-close-on-backdrop
        id="email-success-verification-code-modal"
        centered
        title="BootstrapVue"
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="pin-success-top-spacing"></div>
            <div class="row" style="margin-bottom: 5px">
              <div class="col-4"></div>
              <div class="col-4 text-center">
                <img
                  class="onboarding-success-modal-image"
                  src="../../assets/images/success-image.png"
                />
              </div>
              <div class="col-4"></div>
            </div>
            <div class="d-block text-center">
              <label class="success-popup-style">Success!</label>
            </div>

            <div class="d-block text-center">
              <label class="onboarding-success-popup-style"
                >Your email is verified.</label
              >
            </div>
            <div class="success-bottom-spacing"></div>
          </div>
        </div>
      </b-modal>
    </div>
    <!---- MODAL FOR  Error Modal  ---->
    <div>
      <b-modal
        ref="zero-pp-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="zero-pp-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="col-12 text-center">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="120"
                height="120"
                viewBox="0 0 100 125"
                style="enable-background: new 0 0 100 125"
                xml:space="preserve"
                fill="#e14343"
              >
                <path
                  d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
                />
              </svg>
            </div>
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                {{ pp_alert_message }}
              </label>
            </div>
            <br />
            <br />
            <div class="text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="hideModal('zero-pp-modal')"
              >
                <label class="purchasepower-modal-ok-label">OK</label>
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <!-----------------------  MODAL FOR verification code sent modal  !---------------->

    <div>
      <b-modal
        ref="email-success-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        no-close-on-backdrop
        id="email-success-modal"
        centered
        title="BootstrapVue"
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="pin-success-top-spacing"></div>
            <div class="row" style="margin-bottom: 5px">
              <div class="col-4"></div>
              <div class="col-4">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 100 125"
                  style="enable-background: new 0 0 100 125"
                  xml:space="preserve"
                  height="80"
                  width="80"
                  fill="#1b9142"
                  class="succes-svg-padding"
                >
                  <g>
                    <g>
                      <path
                        d="M50.2,99.7c-27,0-49-21.9-49-48.9S23.2,2,50.2,2s49,21.9,49,48.9S77.2,99.7,50.2,99.7z M50.2,7.1
			C26,7.1,6.4,26.7,6.4,50.8S26,94.6,50.2,94.6S94.1,75,94.1,50.8S74.4,7.1,50.2,7.1z"
                      />
                    </g>
                    <g>
                      <polygon
                        points="47.4,69.2 29,52.3 32.5,48.5 46.6,61.4 67,31.7 71.3,34.7 		"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div class="col-4"></div>
            </div>
            <div class="d-block text-center">
              <label class="success-popup-style"
                >Verification email sent!</label
              >
            </div>
            <div class="success-bottom-spacing"></div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</div>
</template>
<script>
import OnboardingHeader from "./OnboardingHeader.vue";
import api from "../../api/onboarding.js";
import constants from "../Common/onboaringconstant.js";
import Loading from "vue-loading-overlay";
import CanPayLoader from "../CustomLoader/CanPayLoader.vue";
export default {
  name: "EmailVerificationCode",
  /**
   * @description-

   * @returns {any}
   */

  data() {
    return {
      emailVerificationCode: "",
      color: "",
      error: false,
      email: "",
      showButton: true,
      pp_alert_message: "",
      isLoading: false,
      fullPage: true,
      timerCount: 900,
      resentOtp: 1,
      codesentFlag: false,
      is_link : 0,
      sessionId : null
    };
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
          this.codesentFlag = false;
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }
  },
  components: {
    "cp-onboardingheader": OnboardingHeader,
    Loading,
    CanPayLoader
  },

  destroyed() {
    document
      .getElementById("app")
      .style.setProperty("background-color", "#149240");
  },
  created() {},
  mounted() {
    let self = this;

    this.$root.$emit("show_header", false);
    this.$root.$emit("changeWhiteBackground", [true, false, ""]);
    document
      .getElementById("app")
      .style.setProperty("background-color", "#ffffff");

    var element = document.getElementsByClassName("wrapper");
    element[0].style.setProperty("background-color", "#ffffff");

    var elementHtml = document.getElementsByTagName("html")[0];
    elementHtml.style.setProperty("background-color", "#ffffff");
    let param;
    let sessionId;
    let email;
    let verificationCode;
    param = window.location.href.split("/");
    sessionId = param.pop();
    if (sessionId != "emailverificationcode") {
      self.is_link = 1;
      verificationCode = param.splice(-1, 1);
      console.log(verificationCode[0]);
  
      self.emailVerificationCode = verificationCode[0];
      email = param.splice(-1, 1);
      console.log(email[0]);
      self.email= Buffer.from(email[0], 'base64').toString('ascii');
      self.sessionId = sessionId;
      self.submitCode();
    } else {
      self.sessionId = atob(param.splice(-1, 1)[0]);
      self.email = atob(param.splice(-1, 1)[0]);
      console.log(self.sessionId);
      console.log(self.email);
    }
  },
  methods: {
    showModal(modal) {
      this.$refs[modal].show();
    },
    hideModal(modal) {
      this.$refs[modal].hide();
    },
    submitCode() {
      let self = this;
      this.isLoading = true;
      var request = {
        email: self.email,
        session_id: self.sessionId,
        verification_code: this.emailVerificationCode,
        is_link: this.is_link
      };
      if (self.emailVerificationCode.length == 0) {
        self.color = "red";
      } else {
        self.color = "";
        api
          .EmailVerificationCode(request)
          .then(response => {
            self.isLoading = false;
            if (response.code == 200) {
              self.sessionId = response.data.session_id;
              self.email = response.data.email;
              this.showSuccessCodeModal();
            }
          })
          .catch(err => {
            self.isLoading = false;
            self.pp_alert_message = err.response.data.message;
            this.showModal("zero-pp-modal");
            if (err.response.data.code == 401) {
            }
          });
      }
    },

    showSuccessCodeModal() {
      var self = this;
      this.$refs["email-success-verification-code-modal"].show();
      setTimeout(function() {
      self.$router.push("/termsandcondition/"+btoa(self.email)+"/"+btoa(self.sessionId)+"/termsandcondition/");
      }, 4000);
    },
    hideSuccessCodeModal() {
      this.$refs["email-success-verification-code-modal"].hide();
    },
    showPinSuccessModal() {
      var self = this;
      self.$refs["email-success-modal"].show();
      setTimeout(function() {
        self.$refs["email-success-modal"].hide();
      }, 900);
    },

    resendOtpCountCheck() {
      let self = this;
      if (self.resentOtp == 1) {
        self.timerCount = 900;
        self.codesentFlag = false;
        self.resendVerificationCode();
      } else if (self.resentOtp == 2) {
        self.timerCount = 900;
        self.codesentFlag = false;
        self.resendVerificationCode();
      } else if (self.resentOtp == 3) {
        self.codesentFlag = false;
        self.resendVerificationCode();
      }
    },
    resendVerificationCode() {
      let self = this;
      self.isLoading = true;
      var request = {
         email: self.email,
         session_id: self.sessionId,
        phone: null,
        resend:1
      };
      api
        .EmailVerification(request)
        .then(response => {
          self.isLoading = false;
          self.codesentFlag = true;
          self.resentOtp++;
          self.showPinSuccessModal();
        })
        .catch(err => {
          self.isLoading = false;
          self.pp_alert_message = err.response.data.message;
          this.showModal("zero-pp-modal");
          //  localStorage.setItem("email",this.email);
          //     this.$router.push("/emailverificationcode");
          if (err.response.data.code == 401) {
          }
        }); 
    },
     secondsToTime(e){
    var h = Math.floor(e / 3600).toString().padStart(2,'0'),
        m = Math.floor(e % 3600 / 60).toString().padStart(2,'0'),
        s = Math.floor(e % 60).toString().padStart(2,'0');
    
    return h + ':' + m + ':' + s;
},
  }
};
</script>
<style lang="scss">
#zero-pp-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
</style>
