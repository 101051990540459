<template>
  <div class="container next-btn-padding">
    <!-------------------------------EMAIL AND PHONE CODE SVG------------------------------>

    <div class="row forgot-password-top-row margin-lock-icon" v-if="!quickaccessPin">
      <!--Login to use CanPay-->
      <div class="col-2"></div>
      <div class="col-8">
        <span class="text-center">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 80 100.5"
            style="enable-background: new 0 0 90 112.5"
            xml:space="preserve"
            height="80"
            width="100"
            fill="#ffffff"
          
          >
            <g>
              <path
                d="M76.2,56.2h-17v-22h-9.4v-7.9c0-11.2-9.1-20.2-20.2-20.2S9.4,15.2,9.4,26.4v7.9H0v40.5h28.1c1.9,5.3,7,9.1,12.9,9.1h35.1
		C83.8,83.8,90,77.6,90,70S83.8,56.2,76.2,56.2z M12.4,26.4c0-9.5,7.7-17.2,17.2-17.2s17.2,7.7,17.2,17.2v7.9H12.4V26.4z M3,71.8
		V37.3h53.2v19H41.1c-2.2,0-4.3,0.5-6.1,1.4L34.4,54c1-1.1,1.5-2.6,1.5-4.1c0-3.5-2.8-6.3-6.3-6.3s-6.3,2.8-6.3,6.3
		c0,1.5,0.5,3,1.5,4.1l-1.5,9.6l1.5,1.7h3.3c-0.5,1.5-0.8,3-0.8,4.7c0,0.6,0,1.1,0.1,1.7C27.4,71.7,3,71.7,3,71.8z M29.6,62.4h-3.1
		l1.3-8.6l-0.5-1.4c-0.7-0.6-1.1-1.5-1.1-2.5c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3c0,0.9-0.4,1.8-1.1,2.5l-0.5,1.3l0.9,5.7
		C31.2,60.3,30.3,61.3,29.6,62.4z M76.2,80.8H41.1c-6,0-10.8-4.8-10.8-10.8s4.8-10.8,10.8-10.8h35.1c6,0,10.8,4.8,10.8,10.8
		S82.2,80.8,76.2,80.8z"
              />
              <path
                d="M44.9,67.1c-1.6,0-2.9,1.3-2.9,2.9s1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9S46.5,67.1,44.9,67.1z"
              />
              <path
                d="M54.1,67.1c-1.6,0-2.9,1.3-2.9,2.9s1.3,2.9,2.9,2.9S57,71.6,57,70S55.7,67.1,54.1,67.1z"
              />
              <circle cx="63.2" cy="70" r="2.9" />
              <path
                d="M72.3,67.1c-1.6,0-2.9,1.3-2.9,2.9s1.3,2.9,2.9,2.9s2.9-1.3,2.9-2.9S73.9,67.1,72.3,67.1z"
              />
            </g>
          </svg>
         
        </span>
      </div>
      <div class="col-2"></div>
    </div>

    <!-------------------------------QUICK Access PIN SVG------------------------------>

    <div class="row fortgot-passord-top-space" v-if="quickaccessPin">
      <div class="col-2"></div>
      <div class="col-8">
        <span class="text-center">
          <svg
            class="pin-svg"
            height="70"
            viewBox="0 0 480 480"
            width="70"
            fill="#ffffff"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m32 384h208v16h-208zm0 0" />
            <path
              d="m136 456c-13.253906 0-24-10.746094-24-24s10.746094-24 24-24 24 10.746094 24 24-10.746094 24-24 24zm0-32c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8 8-3.582031 8-8-3.582031-8-8-8zm0 0"
            />
            <path
              d="m256 440c0 13.253906-10.746094 24-24 24h-192c-13.253906 0-24-10.746094-24-24v-400c0-13.253906 10.746094-24 24-24h16v8c0 13.253906 10.746094 24 24 24h112c13.253906 0 24-10.746094 24-24v-8h16c13.253906 0 24 10.746094 24 24v56h16v-56c-.027344-22.082031-17.917969-39.9726562-40-40h-192c-22.082031.0273438-39.9726562 17.917969-40 40v400c.0273438 22.082031 17.917969 39.972656 40 40h192c22.082031-.027344 39.972656-17.917969 40-40v-184h-16zm-184-424h128v8c0 4.417969-3.582031 8-8 8h-112c-4.417969 0-8-3.582031-8-8zm0 0"
            />
            <path d="m112 112v128h368v-128zm352 112h-336v-96h336zm0 0" />
            <path
              d="m152.289062 198.929688 15.710938-9.074219v18.144531h16v-18.144531l15.710938 9.074219 8-13.859376-15.710938-9.070312 15.710938-9.070312-8-13.859376-15.710938 9.074219v-18.144531h-16v18.144531l-15.710938-9.074219-8 13.859376 15.710938 9.070312-15.710938 9.070312zm0 0"
            />
            <path
              d="m232.289062 198.929688 15.710938-9.074219v18.144531h16v-18.144531l15.710938 9.074219 8-13.859376-15.710938-9.070312 15.710938-9.070312-8-13.859376-15.710938 9.074219v-18.144531h-16v18.144531l-15.710938-9.074219-8 13.859376 15.710938 9.070312-15.710938 9.070312zm0 0"
            />
            <path
              d="m312.289062 198.929688 15.710938-9.074219v18.144531h16v-18.144531l15.710938 9.074219 8-13.859376-15.710938-9.070312 15.710938-9.070312-8-13.859376-15.710938 9.074219v-18.144531h-16v18.144531l-15.710938-9.074219-8 13.859376 15.710938 9.070312-15.710938 9.070312zm0 0"
            />
            <path
              d="m392.289062 198.929688 15.710938-9.074219v18.144531h16v-18.144531l15.710938 9.074219 8-13.859376-15.710938-9.070312 15.710938-9.070312-8-13.859376-15.710938 9.074219v-18.144531h-16v18.144531l-15.710938-9.074219-8 13.859376 15.710938 9.070312-15.710938 9.070312zm0 0"
            />
          </svg>
        </span>
      </div>
      <div class="col-2"></div>
    </div>
    <!-------------------------------EMAIL CODE TITLE------------------------------>
    <div class="row icon-space" v-if="!quickaccessPin">
      <div class="col-1"></div>
      <div class="col-10">
        <span class="text-center">
          <label class="enter-code-text-style"
            >Enter the Verification code sent to you over Phone and Email
          </label
          >
        </span>
      </div>
      <div class="col-1"></div>
    </div>

    
    <!-------------------------------Quick access CODE TITLE------------------------------>

    <div class="row icon-space" v-if="quickaccessPin">
      <div class="col-2"></div>
      <div class="col-8">
        <span class="text-center">
          <label class="quick-pin-style"
            >Create your 4-digit Quick Access PIN</label
          >
        </span>
      </div>
      <div class="col-2"></div>
    </div>

    <!-------------------------------EMAIL CODE code title------------------------------>

    <div
      class="row forgotpin-middle-space"
      v-if="!phoneCode && !quickaccessPin"
    >
      <div class="col-12">
        <input
          v-model="pin1"
          placeholder="Enter Code"
          class="form-control otp-curser code-input"
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="6"
          inputmode="numeric"
          v-bind:style="{ 'border-color': pin1_color }"
          @change="addEvent"
        />
      </div>
    </div>

    <!-------------------------------PHONE CODE------------------------------>

    <div
      class="row forgotpin-middle-space code-row"
      v-if="phoneCode && !quickaccessPin"
    >
      <div class="col-12">
        <input
          v-model="phone_pin1"
          placeholder="Enter Code"
          class="form-control otp-curser code-input"
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="4"
          inputmode="numeric"
          v-bind:style="{ 'border-color': phone_pin1_color }"
          @change="addEvent"
        />
      </div>
    </div>

    <!-------------------------------QUICK ACcess PIN------------------------------>

    <div
      class="row justify-content-center align-self-center"
      v-if="quickaccessPin"
    >
      <div class="col-1"></div>
      <div class="col-10">
        <label class="reenter-digit-text-style-new"
          >Please enter a 4 digit PIN</label
        >
      </div>
      <div class="col-1"></div>
    </div>

    <div class="row" v-if="quickaccessPin">
      <div class="col-1"></div>
      <div id="pin_div" class="col-10 m-l-7">
        <input
          id="pin1"
          class="form-control ssn-text ssn-input-box-padding margin-pin-asterisk"
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="1"
          inputmode="numeric"
          autocomplete="off"
        />
        <input
          id="pin2"
          class="form-control ssn-text ssn-input-box-padding margin-pin-asterisk"
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="1"
          inputmode="numeric"
          autocomplete="off"
        />
        <input
          id="pin3"
          class="form-control ssn-text ssn-input-box-padding margin-pin-asterisk"
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="1"
          inputmode="numeric"
          autocomplete="off"
        />
        <input
          id="pin4"
          class="form-control ssn-text ssn-input-box-padding margin-pin-asterisk"
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="1"
          inputmode="numeric"
          autocomplete="off"
        />
        <span
          class="input-group-addon"
          style="vertical-align: super"
          v-on:click="switchVisibility"
        >
          <a>
            <i
              class="fa fa-eye-slash eye-icon"
              aria-hidden="true"
              v-if="!show"
            ></i>
            <i class="fa fa-eye eye-icon" aria-hidden="true" v-if="show"></i>
          </a>
        </span>
      </div>
      <div id="pin_view_div" class="col-10 m-l-7 visibility">
        <input
          id="pin1_view"
          v-model="quickaccess_pin1"
          class="form-control ssn-text ssn-input-box-padding"
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <input
          id="pin2_view"
          v-model="quickaccess_pin2"
          class="form-control ssn-text ssn-input-box-padding"
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <input
          id="pin3_view"
          v-model="quickaccess_pin3"
          class="form-control ssn-text ssn-input-box-padding"
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <input
          id="pin4_view"
          v-model="quickaccess_pin4"
          class="form-control ssn-text ssn-input-box-padding"
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <span class="input-group-addon" style="vertical-align: super">
          <a v-on:click="switchVisibility">
            <i
              class="fa fa-eye-slash eye-icon"
              aria-hidden="true"
              v-if="!show"
            ></i>
            <i class="fa fa-eye eye-icon" aria-hidden="true" v-if="show"></i>
          </a>
        </span>
      </div>
      <input type="hidden" id="pin_current_state" value="0" />
    </div>
    <!-------------------------- Reenter QUICK ACcess PIN--------------------->
    <div
      class="row justify-content-center align-self-center"
      v-if="quickaccessPin"
    >
      <div class="col-1"></div>
      <div class="col-10">
        <label class="reenter-digit-text-style">Re-enter 4 digit PIN</label>
      </div>
      <div class="col-1"></div>
    </div>

    <div class="row" v-if="quickaccessPin">
      <div class="col-1"></div>
      <div id="re_enter_pin_div" class="col-10 m-l-7">
        <input
          id="re_enter_pin1"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="1"
          inputmode="numeric"
          autocomplete="off"
        />
        <input
          id="re_enter_pin2"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="1"
          inputmode="numeric"
          autocomplete="off"
        />
        <input
          id="re_enter_pin3"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="1"
          inputmode="numeric"
          autocomplete="off"
        />
        <input
          id="re_enter_pin4"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="1"
          inputmode="numeric"
          autocomplete="off"
        />
        <span class="input-group-addon" style="vertical-align: super">
          <a v-on:click="switchReEnterVisibility">
            <i
              class="fa fa-eye-slash eye-icon"
              aria-hidden="true"
              v-if="!re_entershow"
            ></i>
            <i
              class="fa fa-eye eye-icon"
              aria-hidden="true"
              v-if="re_entershow"
            ></i>
          </a>
        </span>
      </div>
      <div
        id="re_enter_pin_view_div"
        class="col-10 m-l-7 visibility"
      >
        <input
          id="re_enter_pin1_view"
          v-model="re_enter_quickaccess_pin1"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <input
          id="re_enter_pin2_view"
          v-model="re_enter_quickaccess_pin2"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <input
          id="re_enter_pin3_view"
          v-model="re_enter_quickaccess_pin3"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <input
          id="re_enter_pin4_view"
          v-model="re_enter_quickaccess_pin4"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <span class="input-group-addon" style="vertical-align: super">
          <a v-on:click="switchReEnterVisibility">
            <i
              class="fa fa-eye-slash eye-icon"
              aria-hidden="true"
              v-if="!re_entershow"
            ></i>
            <i
              class="fa fa-eye eye-icon"
              aria-hidden="true"
              v-if="re_entershow"
            ></i>
          </a>
        </span>
      </div>
      <input type="hidden" id="re_enter_pin_current_state" value="0" />
    </div>

    <div class="row justify-content-center align-self-center" v-if="quickaccessPin"><div class="col-1"></div><div class="col-10"><label class="pin-link-text-style" @click="showPinTextbox">Having trouble entering your PIN?</label></div><div class="col-1"></div></div>

    <div class="row forgotpin-bottom-space">
      <div class="col-12">
        <button type="button" class="btn-login" @click="clickPhonePin">
          Next
        </button>
      </div>
    </div>

    <!-------------------------------MODAL------------------------------>
    <div>
      <b-modal
        ref="pin-success-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        no-close-on-backdrop
        id="pay-modal-center"
        centered
        title="BootstrapVue"
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="success-top-spacing"></div>
            <div class="row" style="margin-bottom: 5px">
              <div class="col-4"></div>
              <div class="col-4" style="text-align: center">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 100 125"
                  style="enable-background: new 0 0 1024 1280"
                  xml:space="preserve"
                  height="60"
                  width="60"
                  fill="#1b9142"
                >
                  <g>
                    <g>
                      <path
                        d="M50.2,99.7c-27,0-49-21.9-49-48.9S23.2,2,50.2,2s49,21.9,49,48.9S77.2,99.7,50.2,99.7z M50.2,7.1
			C26,7.1,6.4,26.7,6.4,50.8S26,94.6,50.2,94.6S94.1,75,94.1,50.8S74.4,7.1,50.2,7.1z"
                      />
                    </g>
                    <g>
                      <polygon
                        points="47.4,69.2 29,52.3 32.5,48.5 46.6,61.4 67,31.7 71.3,34.7 		"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div class="col-4"></div>
            </div>
            <div class="d-block text-center">
              <label class="quick-access-success">
                Your Quick Access PIN was successfully changed
              </label>
            </div>
            <div class="success-bottom-spacing"></div>
            <div class="text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="hideModal"
              >
                <label class="purchasepower-modal-ok-label">Login</label>
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <!-----------------------  MODAL FOR SUCCESS  !---------------->

    <div>
      <b-modal
        ref="success-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        no-close-on-backdrop
        id="success-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="pin-success-top-spacing"></div>
            <div class="row" style="margin-bottom: 5px">
              <div class="col-12 text-center">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 100 125"
                  style="enable-background: new 0 0 100 125"
                  xml:space="preserve"
                  height="80"
                  width="80"
                  fill="#1b9142"
                  class="succes-svg-padding"
                >
                  <g>
                    <g>
                      <path
                        d="M50.2,99.7c-27,0-49-21.9-49-48.9S23.2,2,50.2,2s49,21.9,49,48.9S77.2,99.7,50.2,99.7z M50.2,7.1
			C26,7.1,6.4,26.7,6.4,50.8S26,94.6,50.2,94.6S94.1,75,94.1,50.8S74.4,7.1,50.2,7.1z"
                      />
                    </g>
                    <g>
                      <polygon
                        points="47.4,69.2 29,52.3 32.5,48.5 46.6,61.4 67,31.7 71.3,34.7 		"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div class="d-block text-center">
              <label class="success-popup-style">Success!</label>
            </div>
            <div class="success-bottom-spacing"></div>
          </div>
        </div>
      </b-modal>
    </div>

    <!-- MODAL FOR VALIDATION ERROR MESSAGES -->
    <div>
      <b-modal
        ref="validation-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="validation-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                {{ error_message }}
              </label>
            </div>
            <br />
            <br />
            <div class="text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="hidevalidationModal"
              >
                <label class="purchasepower-modal-ok-label">OK</label>
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>

    <!-- PIN Textbox modal -->
    <div>
    <b-modal
      ref="pin-textbox-modal"
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      no-close-on-backdrop
      hide-header
      id="pin-textbox-modal"
      centered
    >
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div class="d-block"><label class="update-modal-title">Please enter a 4 digit PIN.</label></div>
          <div class="row"><div class="col-10 text-center"><input type="password" name="modal_pin" id="modal_pin" maxlength="4" v-model="modal_pin" class="form-control"></div><div class="col-2"><span class="input-group-addon" style="vertical-align: middle;" @click="togglePinInputType('modal_pin');"><a><i aria-hidden="true" :class="inputPinCls"></i></a></span></div></div>
          <div class="d-block"><label class="update-modal-title">Re-enter 4 digit PIN.</label></div>
          <div class="row"><div class="col-10 text-center"><input type="password" name="re_enter_modal_pin" id="re_enter_modal_pin" maxlength="4" v-model="re_enter_modal_pin" class="form-control"/></div><div class="col-2"><span class="input-group-addon" style="vertical-align: middle;" @click="togglePinInputType('re_enter_modal_pin');"><a><i aria-hidden="true" :class="inputPinCls1"></i></a></span></div></div>
          <div class="row"><div class="col-12 text-center"><button class="btn btn-danger btn-md center-block tip-btn" @click="hidePinTextbox"><span class="forgetpassword-ok-label">Cancel</span></button><button class="btn btn-danger btn-md center-block tip-ok-btn" @click="clickPhonePinModal"><span class="forgetpassword-ok-label">Next</span></button></div></div>
        </div>
      </div>
    </b-modal>
    </div>
  </div>
</template>

<script>
/**
 * write a component's description
 */
import api from "../../api/forgotpassword.js";

export default {
  name: "EnterForgotPin",
  components: {},
  /**
   * @description
   * @returns {any}
   */
  data() {
    return {
      show: false,
      re_entershow: false,
      error_message: "",
      phoneCode: false,
      quickaccessPin: false,
      inputtype: "password",
      quick_inputtype: "password",
      loginPin: "",
      pin1: "",
      pin1_color: "",
      phone_pin1: "",
      phone_pin1_color: "",
      quickaccess_pin1: "",
      quickaccess_pin2: "",
      quickaccess_pin3: "",
      quickaccess_pin4: "",

      re_enter_quickaccess_pin1: "",
      re_enter_quickaccess_pin2: "",
      re_enter_quickaccess_pin3: "",
      re_enter_quickaccess_pin4: "",

      UserModel: {
        login_pin: "",
        loginpinColor: "",
        confirmloginPin: "",
        confirmloginPinColor: "",
      },
      resetLink: false,
      modal_pin: "",
      re_enter_modal_pin: "",
      inputPinCls: "fa fa-eye-slash onboarding-color-black",
      inputPinCls1: "fa fa-eye-slash onboarding-color-black"
    };
  },
  /**
   * @description
   */
  mounted: function () {
    let self = this;
    this.$root.$emit("show_header", true);
    var param;
    var token;
    var code;
    param = window.location.href.split("/");
    token = param.pop();
    if (token != "enterforgotpin") {
      self.resetLink = true;
      code = param.splice(-1,1);
      localStorage.setItem("verify_token", token);
      self.verifyCode(code[0], self.resetLink,token);
    }
  },
  methods: {
    showPinTextbox(){
      this.inputPinCls = 'fa fa-eye-slash onboarding-color-black';
      this.inputPinCls1 = 'fa fa-eye-slash onboarding-color-black';
      this.$refs["pin-textbox-modal"].show();
    },
    hidePinTextbox() {
      this.modal_pin = "";
      this.re_enter_modal_pin = "";
      this.$refs["pin-textbox-modal"].hide();
    },
    togglePinInputType(id){
      var self = this;
      var inputType = document.getElementById(id);
      if (inputType.type === "password") {
        inputType.type = "text";
        if(id == 'modal_pin'){
          self.inputPinCls = 'fa fa-eye onboarding-color-black';
        }else{
          self.inputPinCls1 = 'fa fa-eye onboarding-color-black';
        }
      } else {
        inputType.type = "password";
        if(id == 'modal_pin'){
          self.inputPinCls = 'fa fa-eye-slash onboarding-color-black';
        }else{
          self.inputPinCls1 = 'fa fa-eye-slash onboarding-color-black';
        }
      }
    },
    maskPin() {
      var self = this;
      var ysp1 = new YouShallPass("*");
      var ysp2 = new YouShallPass("*");
      var ysp3 = new YouShallPass("*");
      var ysp4 = new YouShallPass("*");
      ysp1.fixIE9();
      ysp2.fixIE9();
      ysp3.fixIE9();
      ysp4.fixIE9();
      document
        .querySelector("#pin1")
        .addEventListener("input", ysp1.keyboardInputHandle.bind(ysp1));
      document.querySelector("#pin1").addEventListener("input", function () {
        self.quickaccess_pin1 = ysp1.realText;
        console.log(ysp1.realText);
        console.log(self.quickaccess_pin1);
      });
      document
        .querySelector("#pin2")
        .addEventListener("input", ysp2.keyboardInputHandle.bind(ysp2));
      document.querySelector("#pin2").addEventListener("input", function () {
        self.quickaccess_pin2 = ysp2.realText;
      });
      document
        .querySelector("#pin3")
        .addEventListener("input", ysp3.keyboardInputHandle.bind(ysp3));
      document.querySelector("#pin3").addEventListener("input", function () {
        self.quickaccess_pin3 = ysp3.realText;
      });
      document
        .querySelector("#pin4")
        .addEventListener("input", ysp4.keyboardInputHandle.bind(ysp4));
      document.querySelector("#pin4").addEventListener("input", function () {
        self.quickaccess_pin4 = ysp4.realText;
      });
    },
    maskReEnterPin() {
      var self = this;
      var ysp1 = new YouShallPass("*");
      var ysp2 = new YouShallPass("*");
      var ysp3 = new YouShallPass("*");
      var ysp4 = new YouShallPass("*");
      ysp1.fixIE9();
      ysp2.fixIE9();
      ysp3.fixIE9();
      ysp4.fixIE9();
      document
        .querySelector("#re_enter_pin1")
        .addEventListener("input", ysp1.keyboardInputHandle.bind(ysp1));
      document
        .querySelector("#re_enter_pin1")
        .addEventListener("input", function () {
          self.re_enter_quickaccess_pin1 = ysp1.realText;
        });
      document
        .querySelector("#re_enter_pin2")
        .addEventListener("input", ysp2.keyboardInputHandle.bind(ysp2));
      document
        .querySelector("#re_enter_pin2")
        .addEventListener("input", function () {
          self.re_enter_quickaccess_pin2 = ysp2.realText;
        });
      document
        .querySelector("#re_enter_pin3")
        .addEventListener("input", ysp3.keyboardInputHandle.bind(ysp3));
      document
        .querySelector("#re_enter_pin3")
        .addEventListener("input", function () {
          self.re_enter_quickaccess_pin3 = ysp3.realText;
        });
      document
        .querySelector("#re_enter_pin4")
        .addEventListener("input", ysp4.keyboardInputHandle.bind(ysp4));
      document
        .querySelector("#re_enter_pin4")
        .addEventListener("input", function () {
          self.re_enter_quickaccess_pin4 = ysp4.realText;
        });
    },
    switchVisibility() {
      var self = this;
      if ($("#pin_current_state").val() == 0) {
        $("#pin_current_state").val("1");
        self.show = true;
        $("#pin_div").addClass("visibility");
        $("#pin_view_div").removeClass("visibility");
      } else {
        $("#pin_current_state").val("0");
        self.show = false;
        $("#pin_view_div").addClass("visibility");
        $("#pin_div").removeClass("visibility");
      }
    },
    switchReEnterVisibility() {
      var self = this;
      if ($("#re_enter_pin_current_state").val() == 0) {
        $("#re_enter_pin_current_state").val("1");
        self.re_entershow = true;
        $("#re_enter_pin_div").addClass("visibility");
        $("#re_enter_pin_view_div").removeClass("visibility");
      } else {
        $("#re_enter_pin_current_state").val("0");
        self.re_entershow = false;
        $("#re_enter_pin_view_div").addClass("visibility");
        $("#re_enter_pin_div").removeClass("visibility");
      }
    },
    showValidationModal(msg) {
      this.error_message = msg;
      this.$refs["validation-modal"].show();
    },
    hidevalidationModal() {
      this.error_message = "";
      this.$refs["validation-modal"].hide();
    },
    showModal() {
      let self = this;
      this.$refs["pin-success-modal"].show();
    },
    /**
     * @description
     */
    hideModal() {
      this.$refs["pin-success-modal"].hide();
      this.$router.push("/login");
    },
    /**
     * @description
     * @returns {any}
     */
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["pin-success-modal"].toggle("#toggle-btn");
    },
    /**
     * @description
     */
    clickPrevious() {
      this.$root.$emit("show_Registration_step2", []);
    },
    /**
     * @description
     */
    addEvent() {
      this.pin1_color = "";
      this.phone_pin1_color = "";

      this.re_enter_quickaccess_pin1_color = "";
      this.re_enter_quickaccess_pin2_color = "";
      this.re_enter_quickaccess_pin3_color = "";
      this.re_enter_quickaccess_pin4_color = "";
      this.quickaccess_pin1_color = "";
      this.quickaccess_pin2_color = "";
      this.quickaccess_pin3_color = "";
      this.quickaccess_pin4_color = "";
    },
    //API call to verify phone number
    /**
     * @description
     * @param {any} evt
     * @returns {any}
     */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      if (evt.keyCode == 8 || evt.keyCode == 46) {
        if (evt.target.previousElementSibling != null) {
          evt.target.previousElementSibling.focus();
        }
      } else {
        if (evt.target.nextElementSibling != null) {
          evt.target.nextElementSibling.focus();
        }
      }
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    clickPhonePin() {
      let self = this;
      if (self.quickaccessPin) {
        if (self.quickaccess_pin1 == "") {
          this.quickaccess_pin4_color = "red";
          this.quickaccess_pin2_color = "red";
          this.quickaccess_pin3_color = "red";
          this.quickaccess_pin1_color = "red";
          return false;
        } else if (self.quickaccess_pin2 == "") {
          this.quickaccess_pin4_color = "red";
          this.quickaccess_pin1_color = "";
          this.quickaccess_pin3_color = "red";
          this.quickaccess_pin2_color = "red";
          return false;
        } else if (self.quickaccess_pin3 == "") {
          this.quickaccess_pin4_color = "red";
          this.quickaccess_pin2_color = "";
          this.quickaccess_pin3_color = "red";
          this.quickaccess_pin1_color = "";
          return false;
        } else if (self.quickaccess_pin4 == "") {
          this.quickaccess_pin4_color = "red";
          this.quickaccess_pin2_color = "";
          this.quickaccess_pin3_color = "";
          this.quickaccess_pin1_color = "";
          return false;
        } else if (self.re_enter_quickaccess_pin1 == "") {
          this.re_enter_quickaccess_pin1_color = "red";
          this.re_enter_quickaccess_pin2_color = "red";
          this.re_enter_quickaccess_pin3_color = "red";
          this.re_enter_quickaccess_pin4_color = "red";
          return false;
        }
        if (self.re_enter_quickaccess_pin2 == "") {
          this.re_enter_quickaccess_pin1_color = "";
          this.re_enter_quickaccess_pin2_color = "red";
          this.re_enter_quickaccess_pin3_color = "red";
          this.re_enter_quickaccess_pin4_color = "red";
          return false;
        } else if (self.re_enter_quickaccess_pin3 == "") {
          this.re_enter_quickaccess_pin1_color = "";
          this.re_enter_quickaccess_pin2_color = "";
          this.re_enter_quickaccess_pin3_color = "red";
          this.re_enter_quickaccess_pin4_color = "red";
          return false;
        }
        if (self.re_enter_quickaccess_pin4 == "") {
          this.re_enter_quickaccess_pin1_color = "";
          this.re_enter_quickaccess_pin2_color = "";
          this.re_enter_quickaccess_pin3_color = "";
          this.re_enter_quickaccess_pin4_color = "red";
          return false;
        } else if (
          self.quickaccess_pin1 +
            self.quickaccess_pin2 +
            self.quickaccess_pin3 +
            self.quickaccess_pin4 !==
          self.re_enter_quickaccess_pin1 +
            self.re_enter_quickaccess_pin2 +
            self.re_enter_quickaccess_pin3 +
            self.re_enter_quickaccess_pin4
        ) {
          self.showValidationModal(pinNotMatched);
          return false;
        } else if (
          isNaN(
            self.quickaccess_pin1 +
              self.quickaccess_pin2 +
              self.quickaccess_pin3 +
              self.quickaccess_pin4
          ) ||
          isNaN(
            self.re_enter_quickaccess_pin1 +
              self.re_enter_quickaccess_pin2 +
              self.re_enter_quickaccess_pin3 +
              self.re_enter_quickaccess_pin4
          )
        ) {

          self.showValidationModal("Pin Must be Numeric");
          return false;
        } else {
          self.setPin(
            self.quickaccess_pin1 +
              self.quickaccess_pin2 +
              self.quickaccess_pin3 +
              self.quickaccess_pin4
          );
        }
      } else if (self.phoneCode) {
        if (self.phone_pin1 == "") {
          this.pin1_color = "";
          this.phone_pin1_color = "red";
          return false;
        } else if (self.phone_pin1.length != 6) {
          self.showValidationModal("Pin must be 6 digit ");
        } else {
          self.resetLink = false;
          this.verifyCode(self.phone_pin1, self.resetLink,null);
        }
      } else {
        let self = this;
        if (self.pin1 == "") {
          this.pin1_color = "red";
          return false;
        }
        else if (self.pin1.length != 6) {
          self.showValidationModal("Pin must be 6 digit");
        }
        else {
          this.pin1_color = "";
          this.phone_pin1_color = "";
          self.resetLink = false;
          this.verifyCode(self.pin1, self.resetLink,null);
        }
      }
    },

    verifyCode(code, resetLink,token) {
      let self = this;
      let codetype = "";
      if (self.resetLink) {
        var request = {
          token: token,
          type: codetype,
          code: code,
          phone_code: "",
          resetLink: self.resetLink,
        };
      } else {
        var request = {
          token: localStorage.getItem("verify_token"),
          type: codetype,
          code: code,
          phone_code: "",
          resetLink: self.resetLink,
        };
      }

      api
        .verifyCode(request)
        .then((response) => {
          if (response.code == 200) {
            this.$refs["success-modal"].show();
            setTimeout(function () {
              self.$refs["success-modal"].hide();
            }, 2000);

              this.show = false;
              this.re_entershow = false;
              this.inputtype = "password";
              this.quickaccessPin = true;
              setTimeout(function () {
                self.maskPin();
                self.maskReEnterPin();
              }, 300);
            
          }
        })
        .catch(function (error) {
          self.showValidationModal(error.response.data.message);
        });
    },
    setPin(code) {
      let self = this;

      var request = {
        token: localStorage.getItem("verify_token"),
        pin: code,
        resetLink: self.resetLink,
      };
      

      api
        .changePIN(request)
        .then((response) => {
          if (response.code == 200) {
            this.showModal();
          }
        })
        .catch(function (error) {
          self.showValidationModal(error.response.data.message);
        });
    },
    clickEye() {
      if (this.show == false) {
        this.show = true;
        this.inputtype = "text";
      } else {
        this.show = false;
        this.inputtype = "password";
      }
    },
    clickQuickEye() {
      if (this.re_entershow == false) {
        this.re_entershow = true;
        this.quick_inputtype = "text";
      } else {
        this.re_entershow = false;
        this.quick_inputtype = "password";
      }
    },
    clickPhonePinModal() {
      let self = this;
      if (self.quickaccessPin) {
        if (
          self.modal_pin !== self.re_enter_modal_pin
        ) {
          self.showValidationModal(pinNotMatched);
          return false;
        } else if (isNaN(self.modal_pin)||(isNaN( self.re_enter_modal_pin))) {
          self.showValidationModal("Pin Must be Numeric");
          return false;
        } else {
          self.setPin(self.modal_pin);
        }
      } else if (self.phoneCode) {
        if (self.phone_pin1 == "") {
          this.pin1_color = "";
          this.phone_pin1_color = "red";
          return false;
        } else if (self.phone_pin1.length != 6) {
          self.showValidationModal("Pin must be 6 digit ");
        } else {
          self.resetLink = false;
          this.verifyCode(self.phone_pin1, self.resetLink,null);
        }
      } else {
        let self = this;
        if (self.pin1 == "") {
          this.pin1_color = "red";
          return false;
        }
        else if (self.pin1.length != 6) {
          self.showValidationModal("Pin must be 6 digit");
        }
        else {
          this.pin1_color = "";
          this.phone_pin1_color = "";
          self.resetLink = false;
          this.verifyCode(self.pin1, self.resetLink,null);
        }
      }
    },
  },
};
</script>
<style>
::placeholder {
  color: rgb(0, 0, 0);
  opacity: 1;
  font-size: 14px;
  font-weight: bold;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}
#pay-modal-center___BV_modal_content_ {
  border-radius: 10px;
  margin: 10px;
  background-color: #ffffff;
}
#pay-modal-center___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#success-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
</style>
