<template>
<div>
<div v-if="isLoading">
  <CanPayLoader/>
</div>
  <div class="container">
    <div class="row">
      <div
        class="col-2 content"
        slot="content"
        style="background-color: #149240; margin-top: 40px"
      >
        <a href="javascript:void(0)" @click="clickBack">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 125"
            style="enable-background: new 0 0 100 125"
            xml:space="preserve"
            height="40"
            width="30"
            fill="#ffffff"
          >
            <g id="Layer_2" />
            <g id="Layer_1">
              <path
                d="M12.1,47.1C12.1,47.1,12.1,47.1,12.1,47.1l29.5-29.5c1.6-1.6,4.2-1.6,5.8,0c1.6,1.6,1.6,4.2,0,5.8L24.9,45.9h61.8
		c2.3,0,4.1,1.8,4.1,4.1s-1.8,4.1-4.1,4.1H24.9l22.5,22.5c1.6,1.6,1.6,4.2,0,5.8c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2
		L12.1,52.9c0,0,0,0,0,0L9.2,50L12.1,47.1z"
              />
            </g>
          </svg>

          <!-- <i class="fa fa-3x fa-angle-left icon"  style="color: #000000" aria-hidden="true" v-show="!showmenuicon"></i> -->
        </a>
      </div>
      <div class="col-6"></div>
      <div class="col-4">
        <button class="btn-save btn-save-padding" @click="saveProfile">
          Save
        </button>
      </div>
    </div>
    <div v-if="!isLoading">
      <div class="row top-row">
        <div class="col-2">
          <div class="dark-icon-box-edit">
            <svg
              class="svg-icon-position-edit"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 32 40"
              style="enable-background: new 0 0 32 40"
              xml:space="preserve"
              height="20"
              width="20"
              fill="#ffffff"
            >
              <g>
                <g>
                  <path
                    d="M30,28H2c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1h28c0.6,0,1,0.4,1,1v22C31,27.6,30.6,28,30,28z M3,26h26V6H3V26z"
                  />
                </g>
                <g>
                  <path
                    d="M16,20.4c-0.2,0-0.4-0.1-0.5-0.2l-14-9C1.2,11.1,1,10.7,1,10.4V5c0-0.6,0.4-1,1-1h28c0.6,0,1,0.4,1,1v5.4
			c0,0.3-0.2,0.7-0.5,0.8l-14,9C16.4,20.4,16.2,20.4,16,20.4z M3,9.9l13,8.4l13-8.4V6H3V9.9z"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div class="col-10 left-padding">
          <a v-on:click="clickchange(updateEmail)">
            <span class="left-span">
              <label class="edit-profile-text-style">{{ email }}</label>
            </span>
          </a>
        </div>
      </div>

      <div class="row all-text-margin">
        <!--Login to use CanPay-->
        <div class="col-2">
          <div class="dark-icon-box-edit">
            <svg
              class="svg-icon-position-edit"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 72 90"
              style="enable-background: new 0 0 72 90"
              xml:space="preserve"
              height="20"
              width="20"
              fill="#ffffff"
            >
              >
              <path
                d="M48,70H24c-5.5,0-10-4.5-10-10V12c0-5.5,4.5-10,10-10h24c5.5,0,10,4.5,10,10v48C58,65.5,53.5,70,48,70z M24,6
	c-3.3,0-6,2.7-6,6v48c0,3.3,2.7,6,6,6h24c3.3,0,6-2.7,6-6V12c0-3.3-2.7-6-6-6H24z"
              />
              <path
                d="M40,14h-8c-1.1,0-2-0.9-2-2s0.9-2,2-2h8c1.1,0,2,0.9,2,2S41.1,14,40,14z"
              />
              <circle cx="36" cy="56.4" r="4" />
            </svg>
          </div>
        </div>

        <div class="col-10 left-padding">
          <a v-on:click="clickchange(updatePhone)">
            <span class="left-span">
              <label class="edit-profile-text-style">{{ phone_no }}</label>
            </span>
          </a>
        </div>
      </div>

      <div class="row all-text-margin">
        <!--Login to use CanPay-->
        <div class="col-2">
          <div class="dark-icon-box-edit">
            <svg
              class="svg-icon-position-edit"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 291 488.8"
              style="enable-background: new 0 0 291 488.8"
              xml:space="preserve"
              height="20"
              width="20"
              fill="#ffffff"
            >
              <g>
                <path
                  d="M146,72c40,0,73,33,73,74c0,40-33,73-73,73c-41,0-74-33-74-73C72,105,105,72,146,72z M146,93c-29,0-53,24-53,53
		c0,28,24,52,53,52c28,0,52-24,52-52C198,117,174,93,146,93z"
                />
                <path
                  d="M146,21C77,21,21,77,21,146c0,19,7,40,16,57c21,46,48,91,74,134l13,21c10,16,33,16,43,0l11-19c26-43,53-87,74-131
		c9-19,18-41,18-62C270,77,214,21,146,21z M146,0c80,0,145,65,145,146c0,24-9,49-20,71c-22,45-48,90-75,133l-11,19
		c-18,29-61,29-79,0l-13-21c-26-44-54-90-76-136c-9-20-17-44-17-66C0,65,65,0,146,0z"
                />
              </g>
            </svg>
          </div>
        </div>

        <div class="col-10 left-padding">
          <span class="left-span" style="width: 100% !important">
            <a v-if="!show_address_input" v-on:click="showAddressInput()">
              <span class="left-span">
                <label class="edit-profile-text-style">{{
                  full_address
                }}</label>
              </span>
            </a>
            <vue-google-autocomplete
              v-else
              ref="address_input"
              types
              id="address"
              class="address-style form-control edit-profile-text-style"
              v-model="full_address"
              placeholder
              v-on:placechanged="getAddressData"
            ></vue-google-autocomplete>
          </span>
        </div>
      </div>

      <div class="row all-text-margin">
        <div class="col-2">
          <div class="dark-icon-box-edit">
            <svg
              class="svg-icon-position-edit"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              height="20"
              width="20"
              viewBox="0 0 100 125"
              style="enable-background: new 0 0 100 125"
              xml:space="preserve"
              fill="#ffffff"
            >
              <title>Straight_Line</title>
              <path
                d="M25,87.5c-1.4,0-2.5-1.1-2.5-2.5V15c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v70C27.5,86.4,26.4,87.5,25,87.5z"
              />
              <path
                d="M75,17.5H25c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h50c1.4,0,2.5,1.1,2.5,2.5S76.4,17.5,75,17.5z"
              />
              <path
                d="M75,87.5c-1.4,0-2.5-1.1-2.5-2.5V15c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v70C77.5,86.4,76.4,87.5,75,87.5z"
              />
              <path
                d="M75,87.5H25c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h50c1.4,0,2.5,1.1,2.5,2.5S76.4,87.5,75,87.5z"
              />
              <path
                d="M35,87.5c-1.4,0-2.5-1.1-2.5-2.5V65c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v20C37.5,86.4,36.4,87.5,35,87.5z"
              />
              <path
                d="M45,67.5H35c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5C47.5,66.4,46.4,67.5,45,67.5z"
              />
              <path
                d="M45,87.5c-1.4,0-2.5-1.1-2.5-2.5V65c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v20C47.5,86.4,46.4,87.5,45,87.5z"
              />
              <path
                d="M45,27.5H35c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S46.4,27.5,45,27.5z"
              />
              <path
                d="M45,37.5c-1.4,0-2.5-1.1-2.5-2.5V25c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v10C47.5,36.4,46.4,37.5,45,37.5z"
              />
              <path
                d="M45,37.5H35c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S46.4,37.5,45,37.5z"
              />
              <path
                d="M35,37.5c-1.4,0-2.5-1.1-2.5-2.5V25c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v10C37.5,36.4,36.4,37.5,35,37.5z"
              />
              <path
                d="M65,27.5H55c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S66.4,27.5,65,27.5z"
              />
              <path
                d="M65,37.5c-1.4,0-2.5-1.1-2.5-2.5V25c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5v10C67.5,36.4,66.4,37.5,65,37.5z"
              />
              <path
                d="M65,37.5H55c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S66.4,37.5,65,37.5z"
              />
              <path
                d="M55,37.5c-1.4,0-2.5-1.1-2.5-2.5V25c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v10C57.5,36.4,56.4,37.5,55,37.5z"
              />
              <path
                d="M45,47.5H35c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S46.4,47.5,45,47.5z"
              />
              <path
                d="M45,57.5c-1.4,0-2.5-1.1-2.5-2.5V45c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v10C47.5,56.4,46.4,57.5,45,57.5z"
              />
              <path
                d="M45,57.5H35c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S46.4,57.5,45,57.5z"
              />
              <path
                d="M35,57.5c-1.4,0-2.5-1.1-2.5-2.5V45c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v10C37.5,56.4,36.4,57.5,35,57.5z"
              />
              <path
                d="M65,47.5H55c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S66.4,47.5,65,47.5z"
              />
              <path
                d="M65,57.5c-1.4,0-2.5-1.1-2.5-2.5V45c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5v10C67.5,56.4,66.4,57.5,65,57.5z"
              />
              <path
                d="M65,57.5H55c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S66.4,57.5,65,57.5z"
              />
              <path
                d="M55,57.5c-1.4,0-2.5-1.1-2.5-2.5V45c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v10C57.5,56.4,56.4,57.5,55,57.5z"
              />
              <path
                d="M65,67.5H55c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5C67.5,66.4,66.4,67.5,65,67.5z"
              />
              <path
                d="M65,77.5c-1.4,0-2.5-1.1-2.5-2.5V65c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5v10C67.5,76.4,66.4,77.5,65,77.5z"
              />
              <path
                d="M65,77.5H55c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S66.4,77.5,65,77.5z"
              />
              <path
                d="M55,77.5c-1.4,0-2.5-1.1-2.5-2.5V65c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v10C57.5,76.4,56.4,77.5,55,77.5z"
              />
            </svg>
          </div>
        </div>
        <div class="col-10 align-middle left-padding">
          <span class="text-center">
            <a v-if="!show_apt_input" v-on:click="showAptInput()">
              <span class="left-span">
                <label class="edit-profile-text-style">{{ apt_number }}</label>
              </span>
            </a>
            <input
              v-else
              ref="apt_input"
              v-on:blur="aptBlur()"
              v-model="apt_number"
              class="edit-profile-text-style form-control edit-apt-number"
              placeholder="Apt or Unit #"
            />
          </span>
        </div>
      </div>
      <div class="row all-text-margin">
        <!--Login to use CanPay-->
        <div class="col-2">
          <div class="dark-icon-box-edit">
            <svg
              class="svg-icon-position-edit"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 58 58"
              enable-background="new 0 0 58 58"
              xml:space="preserve"
              height="20"
              width="20"
            >
              <g>
                <path
                  fill="none"
                  stroke="#ffffff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  d="
		M46.707,37.266H11.239v-9.4c0-2.778,2.252-5.03,5.031-5.03h25.406c2.779,0,5.031,2.252,5.031,5.03V37.266z"
                />
                <path
                  fill="none"
                  stroke="#ffffff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  d="
		M8.477,37.266c0,2.6,2.651,4.707,5.922,4.707h29.201c3.271,0,5.923-2.107,5.923-4.707H8.477z"
                />
                <path
                  fill="none"
                  stroke="#ffffff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  d="
		M11.239,31.033c0,0,5.28,2.045,9.898-0.748c1.084-0.656,2.479-0.549,3.426,0.293c1.532,1.363,4.248,3.018,8.386,2.981
		c0.932-0.008,1.799-0.493,2.324-1.263c1.625-2.376,5.865-7.243,11.434-3.473"
                />
                <path
                  fill="none"
                  stroke="#ffffff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  d="
		M29.525,16.733h-1.104c-1.114,0-2.018,0.903-2.018,2.017v4.085h5.14V18.75C31.543,17.636,30.64,16.733,29.525,16.733z"
                />
                <path
                  fill="none"
                  stroke="#ffffff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  d="
		M28.729,4.428c0,0-1.587,2.874-2.101,5.184c-0.356,1.6,0.487,3.277,2.056,3.752l0.045,0.014c0,0,0.076,0.006,0.205,0.002
		c1.981-0.055,3.116-2.286,2.069-3.967C30.265,8.229,29.215,6.329,28.729,4.428z"
                />

                <line
                  fill="none"
                  stroke="#ffffff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  x1="28.973"
                  y1="11.54"
                  x2="28.973"
                  y2="16.733"
                />
              </g>
            </svg>
          </div>
        </div>
        <div class="col-5" style="margin-top: -4px">
          <div class="btn-group">
            <button
              disabled
              id="edit-btn-month"
              class="birth-input birth-input-month"
            >
              <span class="edit-month-text">{{ dob_month }}</span>
            </button>
          </div>
        </div>
        <!-- element day -->
        <div class="col-2" style="margin-top: -4px">
          <input
            disabled
            v-model="dob_day"
            class="birth-input form-control edit-dob-day"
            placeholder="Day"
            v-on:keyup="dobValidation($event)"
            v-on:blur="addZero"
            inputmode="numeric"
            name="phone"
            maxlength="2"
          />
        </div>
        <!-- element year -->
        <div class="col-3" style="margin-top: -4px">
          <input
            disabled
            v-model="dob_year"
            class="birth-input form-control edit-dob-year"
            placeholder="Year"
            v-on:keyup="isNumber($event)"
            v-on:blur="checkValidYear"
            type="text"
            inputmode="numeric"
            maxlength="4"
          />
        </div>
      </div>

      <div class="row all-text-margin">
        <div class="col-2">
          <div class="dark-icon-box-edit">
            <svg
              class="svg-icon-position-edit"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 1024 1280"
              style="enable-background: new 0 0 1024 1280"
              xml:space="preserve"
              height="20"
              width="20"
              fill="#ffffff"
            >
              <g>
                <g>
                  <g>
                    <path
                      d="M904.2,390.1c-7,0-14,0-21,0c-19,0-37.9,0-56.9,0c-28.2,0-56.3,0-84.5,0c-34.2,0-68.4,0-102.7,0c-37.6,0-75.3,0-112.9,0
				c-38,0-76,0-114,0c-35.3,0-70.6,0-105.8,0c-29.9,0-59.9,0-89.8,0c-21.4,0-42.9,0-64.3,0c-10.2,0-20.4-0.3-30.6,0
				c-0.4,0-0.9,0-1.3,0c6.7,6.7,13.3,13.3,20,20c0-28.2,0-56.4,0-84.6c-3.3,5.8-6.6,11.5-9.9,17.3c12.9-6.7,25.8-13.5,38.7-20.2
				c31-16.1,62-32.3,92.9-48.4c37.3-19.5,74.7-38.9,112-58.4c32.5-17,65-33.9,97.6-50.9c15.6-8.2,31.7-15.8,47.1-24.5
				c0.2-0.1,0.5-0.2,0.7-0.4c-6.7,0-13.5,0-20.2,0c13.1,6.7,26.1,13.4,39.2,20.1c31.5,16.2,63,32.3,94.5,48.5
				c38,19.5,76,39,114,58.5c33,16.9,65.9,33.8,98.9,50.7c15.9,8.1,31.6,17.1,47.8,24.5c0.2,0.1,0.4,0.2,0.7,0.3
				c-3.3-5.8-6.6-11.5-9.9-17.3c0,28.2,0,56.4,0,84.6c0,10.5,9.2,20.5,20,20s20-8.8,20-20c0-28.2,0-56.4,0-84.6
				c0-6.7-3.7-14.1-9.9-17.3c-13.1-6.7-26.1-13.4-39.2-20.1c-31.2-16-62.5-32.1-93.7-48.1c-37.8-19.4-75.7-38.8-113.5-58.3
				c-33-16.9-66-33.9-99-50.8c-16.3-8.4-32.4-17-48.9-25.1c-7.1-3.5-14-3.9-21.3-0.3c-1.5,0.7-2.9,1.5-4.4,2.3
				c-7.7,4-15.4,8-23.1,12c-29.1,15.2-58.2,30.3-87.3,45.5c-37.7,19.6-75.3,39.3-113,58.9c-34,17.7-68.1,35.5-102.1,53.2
				c-18.7,9.8-37.5,19.5-56.2,29.3c-0.9,0.4-1.7,0.9-2.6,1.3c-6.2,3.2-9.9,10.5-9.9,17.3c0,28.2,0,56.4,0,84.6c0,10.8,9.2,20,20,20
				c7,0,14,0,21,0c19,0,37.9,0,56.9,0c28.2,0,56.3,0,84.5,0c34.2,0,68.4,0,102.7,0c37.6,0,75.3,0,112.9,0c38,0,76,0,114,0
				c35.3,0,70.6,0,105.8,0c29.9,0,59.9,0,89.8,0c21.4,0,42.9,0,64.3,0c10.2,0,20.4,0.2,30.6,0c0.4,0,0.9,0,1.3,0
				c10.5,0,20.5-9.2,20-20C923.7,399.3,915.4,390.1,904.2,390.1z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M924,881.1c-7.4,0-14.8,0-22.1,0c-20,0-40,0-59.9,0c-29.5,0-59,0-88.6,0c-36,0-72,0-108,0c-39.6,0-79.2,0-118.8,0
				c-39.8,0-79.6,0-119.5,0c-37.1,0-74.3,0-111.4,0c-31.4,0-62.8,0-94.2,0c-22.7,0-45.3,0-68,0c-10.7,0-21.4-0.2-32.1,0
				c-0.5,0-0.9,0-1.4,0c-10.5,0-20.5,9.2-20,20s8.8,20,20,20c7.4,0,14.8,0,22.1,0c20,0,40,0,59.9,0c29.5,0,59,0,88.6,0
				c36,0,72,0,108,0c39.6,0,79.2,0,118.8,0c39.8,0,79.6,0,119.5,0c37.1,0,74.3,0,111.4,0c31.4,0,62.8,0,94.2,0c22.7,0,45.3,0,68,0
				c10.7,0,21.4,0.2,32.1,0c0.5,0,0.9,0,1.4,0c10.5,0,20.5-9.2,20-20C943.5,890.3,935.2,881.1,924,881.1L924,881.1z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M391.3,510.1c0,12.9,0,25.9,0,38.8c0,31.1,0,62.2,0,93.3c0,37.6,0,75.3,0,112.9c0,32.6,0,65.1,0,97.7
				c0,15.9-0.4,31.8,0,47.7c0,0.2,0,0.4,0,0.7c0,10.5,9.2,20.5,20,20s20-8.8,20-20c0-12.9,0-25.9,0-38.8c0-31.1,0-62.2,0-93.3
				c0-37.6,0-75.3,0-112.9c0-32.6,0-65.1,0-97.7c0-15.9,0.4-31.8,0-47.7c0-0.2,0-0.4,0-0.7c0-10.5-9.2-20.5-20-20
				C400.5,490.6,391.3,498.9,391.3,510.1L391.3,510.1z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M230,901.1c0-12.9,0-25.9,0-38.8c0-31.1,0-62.2,0-93.3c0-37.6,0-75.3,0-112.9c0-32.6,0-65.1,0-97.7
				c0-15.9,0.4-31.8,0-47.7c0-0.2,0-0.4,0-0.7c0-10.5-9.2-20.5-20-20s-20,8.8-20,20c0,12.9,0,25.9,0,38.8c0,31.1,0,62.2,0,93.3
				c0,37.6,0,75.3,0,112.9c0,32.6,0,65.1,0,97.7c0,15.9-0.4,31.8,0,47.7c0,0.2,0,0.4,0,0.7c0,10.5,9.2,20.5,20,20
				C220.8,920.7,230,912.4,230,901.1L230,901.1z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M794,510.1c0,12.9,0,25.9,0,38.8c0,31.1,0,62.2,0,93.3c0,37.6,0,75.3,0,112.9c0,32.6,0,65.1,0,97.7
				c0,15.9-0.4,31.8,0,47.7c0,0.2,0,0.4,0,0.7c0,10.5,9.2,20.5,20,20s20-8.8,20-20c0-12.9,0-25.9,0-38.8c0-31.1,0-62.2,0-93.3
				c0-37.6,0-75.3,0-112.9c0-32.6,0-65.1,0-97.7c0-15.9,0.4-31.8,0-47.7c0-0.2,0-0.4,0-0.7c0-10.5-9.2-20.5-20-20
				C803.2,490.6,794,498.9,794,510.1L794,510.1z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M592.7,510.1c0,12.9,0,25.9,0,38.8c0,31.1,0,62.2,0,93.3c0,37.6,0,75.3,0,112.9c0,32.6,0,65.1,0,97.7
				c0,15.9-0.4,31.8,0,47.7c0,0.2,0,0.4,0,0.7c0,10.5,9.2,20.5,20,20s20-8.8,20-20c0-12.9,0-25.9,0-38.8c0-31.1,0-62.2,0-93.3
				c0-37.6,0-75.3,0-112.9c0-32.6,0-65.1,0-97.7c0-15.9,0.4-31.8,0-47.7c0-0.2,0-0.4,0-0.7c0-10.5-9.2-20.5-20-20
				C601.8,490.6,592.7,498.9,592.7,510.1L592.7,510.1z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M537.9,286.4c0,1.1-0.1,2.3-0.1,3.5c0,2.9,1.1-5,0.1-0.6c-0.4,1.7-0.7,3.5-1.3,5.2c-0.1,0.4-0.3,0.8-0.4,1.3
				c-0.6,1.6-0.6,1.7,0.1,0.1c0.2-0.4,0.3-0.8,0.5-1.2c-0.4,0.8-0.7,1.6-1.1,2.4c-0.2,0.4-3,5.7-3.4,5.6c-0.2-0.1,2.8-3.2,0.7-1
				c-0.7,0.8-1.4,1.6-2.2,2.4c-0.4,0.4-3.7,4.1-4.3,3.9c-0.2-0.1,3.6-2.4,0.9-0.8c-0.8,0.4-1.5,0.9-2.2,1.4c-0.9,0.6-1.9,1-2.9,1.5
				c-0.6,0.3-1.2,0.6-1.8,0.8c2.6-1.1,3.1-1.3,1.7-0.8c-1.9,0.2-3.9,1.2-5.8,1.6c0.1,0-2.6,0.6-2.7,0.5c0.3,0.4,4.7-0.4,0.5-0.3
				c-1.7,0.1-4.6-0.6-6.2-0.1c2.4-0.7,3.5,0.7,1.3,0.2c-1.3-0.3-2.6-0.5-3.9-0.9c-1-0.3-2.2-0.9-3.2-1c-2.6-0.3,4.2,2.2,0.5,0.3
				c-1.5-0.8-3.1-1.6-4.6-2.5c-0.4-0.2-0.7-0.5-1.1-0.7c-1.4-1-1.4-1-0.1,0c0.3,0.3,0.7,0.5,1,0.8c-0.7-0.5-1.3-1.1-2-1.7
				c-1.6-1.4-3.1-3-4.5-4.6c-2.6-2.8,0.9,0.9,0.7,1c-0.1,0.1-1.6-2.4-1.8-2.7c-0.2-0.4-3.1-4.7-2.7-5.2c0.2-0.3,1.4,4.2,0.6,1.1
				c-0.3-1.1-0.7-2.1-0.9-3.2c-0.3-1.1-0.5-2.2-0.7-3.3c-0.9-3.9,0,4.2,0.1-0.1c0-1.8,0-3.7,0-5.6c0.1-4.1-0.1,1.4-0.3,1.2
				c-0.2-0.2,0.5-2.9,0.6-3.3c0.1-0.3,1.3-5.8,1.7-5.7c0.2,0-1.9,3.9-0.5,1.1c0.6-1.2,1.2-2.3,1.8-3.5c0.5-0.9,1.4-1.8,1.7-2.7
				c0.9-2.4-3.2,3.5-0.4,0.4c1.2-1.3,2.3-2.6,3.6-3.8c0.8-0.7,1.7-1.4,2.4-2.1c1.8-1.9-4.1,2.5-0.4,0.4c1.9-1.1,3.7-2.2,5.7-3.2
				c3.4-1.8-1.1,0.6-1.1,0.5c0-0.2,2.8-0.9,3.1-1c1.9-0.6,4-0.7,5.9-1.3c-4.2,1.2-2.8,0.3-1.2,0.3c1.2,0,2.3-0.1,3.5-0.1
				c0.4,0,3.4,0,3.5,0.2c-0.1-0.3-4.9-1,0,0.1c0.5,0.1,6.4,1.4,6.4,1.9c0,0.2-3.9-1.9-1.1-0.5c1.2,0.6,2.3,1.2,3.5,1.8
				c0.7,0.4,1.5,1,2.2,1.4c3.6,2-2.7-2.6,0.1,0c1.8,1.6,3.4,3.3,5.1,5c2.8,3-0.4-0.5-0.3-0.5c0.2-0.1,2,3.1,2.1,3.3
				c0.4,0.8,0.9,1.5,1.3,2.3c0.3,0.6,0.6,1.2,0.9,1.8c0.6,1.4,0.4,0.8-0.8-1.7c0.5,0,1.7,5.9,1.9,6.4c0.1,0.4,0.2,0.9,0.3,1.3
				c0.3,1.8,0.3,1.8,0,0c-0.2-1.8-0.3-1.7-0.1,0.1C537.9,284.6,537.9,285.5,537.9,286.4c0.2,10.5,9.1,20.5,20,20
				c10.7-0.5,20.2-8.8,20-20c-0.4-28.1-18-52.4-44.3-62c-24.6-8.9-54.5-0.7-71,19.5c-17.6,21.5-21.1,52-6.5,76.1
				c14.6,24.2,42.4,35.4,69.8,30.5c30.3-5.5,51.6-34.1,52.1-64.1c0.2-10.5-9.3-20.5-20-20C546.9,266.9,538,275.2,537.9,286.4z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>

        <div class="col-6 left-padding">
          <span class="left-span">
            <label v-if="show_bank" class="edit-profile-text-style">
              {{ bank_name }} - {{ account_no }}
              <br />
              {{ account_type }}
            </label>
            <label v-else class="edit-profile-text-style">
              {{ account_no }}
            </label>
          </span>
        </div>
        <div class="col-4">
          <button type="button" class="btn-update" @click="changeBankAccount">
            Update
          </button>
        </div>
      </div>
    </div>
    <!-----------------------  MODAL FOR EMAIL AND PHONE NUMBER CHANGE  !----------------->
    <b-modal
      ref="edit-profile-verify"
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      no-close-on-backdrop
      hide-header
      id="edit-profile-verify"
      centered
    >
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="update-modal-title">{{ alertTitle }}</label>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <input
                :type="updateType == 'email' ? 'email' : 'text'"
                :inputmode="keyboadeType"
                v-model="changeemail"
                class="form-control"
                v-bind:style="{ 'border-color': phoneNumberColor }"
                @change="addEvent"
                v-mask="vmaskType"
                @input="addEvent"
                v-if="!(updateType == 'email')"
              />
              <input
                :type="updateType == 'email' ? 'email' : 'text'"
                :maxlength="maxlengthvalue"
                :inputmode="keyboadeType"
                v-model="changeemail"
                class="form-control text-lowercase"
                v-bind:style="{ 'border-color': phoneNumberColor }"
                @change="addEvent"
                @input="addEvent"
                v-if="updateType == 'email'"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <button
                @click="hideModal"
                class="btn btn-danger btn-md center-block tip-btn"
              >
                <span class="forgetpassword-ok-label">Cancel</span>
              </button>
              <button
                @click="clickverify"
                class="btn btn-danger btn-md center-block tip-ok-btn"
              >
                <span class="forgetpassword-ok-label">{{
                  sendButtonTitle
                }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Month dropdown modal -->
    <b-modal
      ref="edit-month-modal"
      hide-footer
      hide-header
      size="sm"
      centered
      variant="primary"
      id="edit-month-modal"
    >
      <div>
        <center>
          <div
            v-for="month in months"
            class="row suffix-option"
            @click="setMonth(month)"
            :key="month.key"
          >
            {{ month.title }}
          </div>
        </center>
      </div>
    </b-modal>
    <!-- End of Month dropdown modal -->
    <!-- MODAL FOR VALIDATION ERROR MESSAGES -->
    <b-modal
      ref="validation-modal"
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      hide-header
      id="validation-modal"
      centered
    >
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              {{ error_message }}
            </label>
          </div>
          <br />
          <br />
          <div class="text-center">
            <button
              type="button"
              class="mx-auto col-10 offset-1 btn-black"
              style="height: 60px"
              @click="hidevalidationModal"
            >
              <span class="purchasepower-modal-ok-label">OK</span>
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="delink-modal"
      hide-footer
      no-close-on-backdrop
      modal-backdrop
      hide-header
      id="delink-modal"
      centered
    >
      <div class="color">
        <div class="col-12 text-center">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="120"
            height="120"
            viewBox="0 0 100 125"
            style="enable-background: new 0 0 100 125"
            xml:space="preserve"
            fill="#e14343"
          >
            <path
              d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
            />
          </svg>
        </div>
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              The connection to your bank/credit union has disconnected. Please relink your banking to continue.
            </label>
          </div>
          <br />
          <br />
          <div class="row">
            <div class="col-12 text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="changeBankAccount()"
              >
                <span class="purchasepower-modal-ok-label"
                  >Add Primary Account</span
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</div>
</template>

<script>
import api from "../../api/account.js";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import moment from "moment";
import Loading from "vue-loading-overlay";
import CanPayLoader from '../CustomLoader/CanPayLoader.vue'
/**
 * write a component's description
 * this component is use to login the user as a consumer
 */
export default {
  name: "EditProfile",
  components: {
    Loading,
    VueGoogleAutocomplete,
    CanPayLoader
    
  },
  /**
   * @description-
   * phoneNumber => this will take the consumer's phone no
   * phoneNumber => this will take the consumer's password
   * pinColor => use to validate the password field
   * phoneNumberColor => use to validate the phoneno field
   * token => use to store the user's token
   * @returns {any}
   */
  data() {
    let self = this;
    return {
      isLoading: false,
      show_address_input: false,
      show_apt_input: false,
      email: "",
      token: "",
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      vmaskType: "(###) ###-####",
      maxlengthvalue: "",
      changePhone: false,
      keyboadeType: "",
      updateType: "",
      newPhonenumber: "",
      fullPage: true,
      sendButtonTitle: "Verify",
      updatePhone: "phone",
      updateEmail: "email",
      phoneNumberColor: "",
      changeemail: "",
      changePhone: "",
      alertTitle: "",
      error_message: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      phone_no: "",
      full_address: "",
      street_address: "",
      state: "",
      zip: "",
      city: "",
      dob_month: "Month",
      dob_day: "Day",
      dob_year: "Year",
      dob_edit: false,
      bank_name: "",
      account_type: "",
      account_no: "",
      show_bank: false,
      apt_number: "",
      months: [
        { title: "Month", key: "" },
        { title: "January", key: 1 },
        { title: "February", key: 2 },
        { title: "March", key: 3 },
        { title: "April", key: 4 },
        { title: "May", key: 5 },
        { title: "June", key: 6 },
        { title: "July", key: 7 },
        { title: "August", key: 8 },
        { title: "September", key: 9 },
        { title: "October", key: 10 },
        { title: "November", key: 11 },
        { title: "December", key: 12 },
      ],
    };
  },
  created() {
    this.$root.$emit("changeWhiteBackground", [
      false,
      false,
      "EditProfileHeader",
    ]);
    this.getUserDetails();
  },
  mounted() {
    let self = this;
    var element = document.getElementsByClassName("common-nav");
    if (element[0]) {
      element[0].style.setProperty("display", "none");
    }
    //catching the phone otp pin data when emitted
    self.$root.$on("edit profile back clicked", function (data) {
      console.log("edit");
      setTimeout(function () {
        //self.clickBack();
      }, 300);
    });
  },
  methods: {
    showValidationModal(msg) {
      this.error_message = msg;
      this.$refs["validation-modal"].show();
    },
    hidevalidationModal() {
      this.error_message = "";
      this.$refs["validation-modal"].hide();
    },
    showAddressInput() {
      var self = this;
      this.show_address_input = true;
      setTimeout(() => {
        self.$refs.address_input.focus();
      }, 100);
    },
    aptBlur() {
      this.show_apt_input = false;
      if (this.apt_number != "") {
        this.apt_number = this.apt_number;
      } else {
        this.apt_number = "Apt or Unit #";
      }
    },
    showAptInput() {
      var self = this;
      this.show_apt_input = true;
      this.apt_number = "";
      setTimeout(() => {
        self.$refs.apt_input.focus();
      }, 100);
    },
    getUserDetails() {
      var self = this;
      self.isLoading = true;
      api
        .getUserdetails()
        .then(function (response) {
          self.isLoading = false;
          if (response.code == 200) {
            localStorage.setItem(
              "consumer_login_response",
              JSON.stringify(response.data)
            );
            self.setUserDetails(response);
          } else if (response.code == 401) {
            this.$router.push("/login");
          }
        })
        .catch(function (error) {
          if (error.response.data.code == 598) {
            self.$refs["delink-modal"].show();
            self.isLoading = false;
          }
        });
    },
    //this function validated dob day just like a datepicker
    dobValidation(evt) {
      if (this.isNumber(evt)) {
        // case 1: if month is february then maximum 29 should be day limit (Leap year vallidation is handled in a different function)
        // case 2: months with 31 days
        //case 3: months with 30 days
        var limit = this.month_number % 2 == 0 ? 30 : 31;
        if (this.month_number == 8) {
          limit = 31;
        }
        limit = this.month_number == 2 ? 29 : limit;
        if (this.dob_day > limit) {
          this.dob_day = this.day;
          if (this.dob_day.length < 2) {
            this.dob_day = 0 + this.dob_day;
          }
        }
        this.day = this.dob_day;
      }
    },
    //checks if proper year format is entered or not
    checkValidYear() {
      if (this.dob_year.length < 4 && this.dob_year.length != 0) {
        this.showValidationModal("Please enter a four digit year.");
      }
    },
    //adds zero to the day
    addZero() {
      if (this.dob_day.length < 2 && this.dob_day.length != 0) {
        this.dob_day = 0 + this.dob_day;
      }
    },
    //sets the value of month variable according to the value selected in the month option modal
    setMonth(month) {
      this.dob_month = month.title;
      this.month_number = month.key;
      this.hideMonthModal();
    },
    //opens month option modal
    openMonthModal() {
      this.$refs["edit-month-modal"].show();
      setTimeout(function () {
        var element = document.getElementsByClassName("modal-sm")[0];
        element.classList.add("edit-month-modal-sm");
      }, 10);
    },
    //closes month option modal
    hideMonthModal() {
      this.$refs["edit-month-modal"].hide();
    },
    /**
     * @description-
     * This function is use to call the login api
     * @returns {login rsponse}
     */
    clickBack() {
      this.$router.push("/profile");
    },
    formatPhoneNumber(str) {
      var res = str.substring(0, 3) + ".";
      var res1 = str.substring(3, 6) + ".";
      var res2 = str.substring(6, 10);
      str = res + res1 + res2;
      return str;
    },
    setUserDetails(response) {
      let self = this;
      self.first_name = response.data.first_name;
      self.middle_name = response.data.middle_name;
      self.last_name = response.data.last_name;
      self.phone_no = self.formatPhoneNumber(response.data.phone);
      self.email = response.data.email;
      self.date_of_birth = response.data.date_of_birth;
      self.street_address = response.data.street_address;
      self.city = response.data.city;
      self.state = response.data.state;
      self.zipcode = response.data.zipcode;
      self.driving_license = response.data.driving_license;
      self.state_issued = response.data.state_issued;
      self.ssn_number = response.data.ssn_number;
      self.bank_name = response.data.bank_name;
      self.account_type = response.data.account_type;
      self.account_no = response.data.account_no;
      self.apt_number =
        response.data.apt_number != null
          ? response.data.apt_number
          : "Apt or Unit #";
      if (response.data.bank_link_type == 1) {
        self.show_bank = true;
      }
      self.full_address =
        self.street_address +
        " " +
        self.city +
        "," +
        self.state +
        " " +
        self.zipcode;
      self.month_number = moment(String(self.date_of_birth)).format("MM");
      self.dob_month = moment(String(self.date_of_birth)).format("MMMM");
      self.dob_day = moment(String(self.date_of_birth)).format("DD");
      self.dob_year = moment(String(self.date_of_birth)).format("YYYY");
    },

    saveProfile() {
      this.date_of_birth = moment(
        String(this.dob_month + "-" + this.dob_day + "-" + this.dob_year)
      ).format("MM-DD-YYYY");
      let self = this;
      var request = {
        dateOfBirth: self.date_of_birth,
        address: self.street_address,
        zip: self.zip,
        state: self.state,
        city: self.city,
        apt_number: self.apt_number,
      };
      this.isLoading = true;
      api
        .saveDetails(request)
        .then((response) => {
          if (response.code == 200) {
            self.getUserDetails();
          }
        })
        .catch(function (error) {
          self.showValidationModal(error.response.data.message);
          self.isLoading = false;
          self.showloader = false;
        });
    },
    //gets the address from google api
    getAddressData: function (addressData, placeResultData, id) {
      this.show_address_input = false;
      var street_number =
        typeof addressData.street_number != "undefined"
          ? addressData.street_number
          : "";
      var route =
        typeof addressData.route != "undefined" ? addressData.route : "";
      this.street_address = street_number + " " + route;
      this.state = addressData.administrative_area_level_1;
      this.city = addressData.locality;
      this.zip = addressData.postal_code;
      if (this.street_address.trim() == "") {
        this.showValidationModal("Please enter your street address.");
        return false;
      }
      if (this.state.trim() == "") {
        this.showValidationModal("Please enter your state/locality");
        return false;
      }
      if (this.city.trim() == "") {
        this.showValidationModal("Please enter your city");
        return false;
      }
      if (this.zip.trim() == "") {
        this.showValidationModal("Please enter your zipcode");
        return false;
      }
      this.full_address =
        this.street_address +
        " " +
        this.city +
        "," +
        this.state +
        " " +
        this.zipcode;
    },
    clickBack() {
      this.$router.push("/profile");
    },
    selectMonth(data) {
      this.dob_month = data;
    },
    clickchange(type) {
      if (type == "phone") {
        this.maxlengthvalue = "10";
        this.keyboadeType = "";
        this.alertTitle =
          "Enter verification code sent on your phone and email.";
        this.vmaskType = "XXXXXX";
      } else {
        this.maxlengthvalue = "6";
        this.keyboadeType = "email";
        this.alertTitle = "Enter verification code sent on your phone.";
      }
      this.updateType = type;
      this.sendVerificationCode_Phone_Email(type);
    },
    /**
     * @description
     * @param {any} type
     */
    clickverify() {
      let self = this;
      if (self.changePhone == true) {
        self.updateType = "new phone";
        self.sendOTPNewPhoneNumber();
      }
      if (self.sendButtonTitle == "Verify" && self.updateType == "phone") {
        if (self.changeemail == "") {
          self.phoneNumberColor = "red";
          return false;
        } else {
          self.verifyVerificationCode_Phone_Email();
        }
      } else if (
        self.sendButtonTitle == "Verify" &&
        self.updateType == "email"
      ) {
        if (self.changeemail == "") {
          self.phoneNumberColor = "red";
          return false;
        } else {
          self.verifyVerificationCode_Phone_Email();
        }
      } else if (
        self.sendButtonTitle == "Submit" &&
        self.updateType == "email"
      ) {
        if (self.changeemail.length == 0) {
          self.phoneNumberColor = "red";
          return false;
        } else if (self.isEmailValid() == "has-error") {
          self.phoneNumberColor = "red";
          this.showValidationModal("Please enter valid email address.");
          return false;
        } else {
          self.phoneNumberColor = "";
          self.modifyEmail();
        }
      } else if (
        self.sendButtonTitle == "Submit" &&
        self.updateType == "phone"
      ) {
        if (self.changeemail == "") {
          self.phoneNumberColor = "red";
          return false;
        } else {
          self.updateType = "new phone";
          self.sendOTPNewPhoneNumber();
        }
      } else if (
        self.sendButtonTitle == "Verify" &&
        self.updateType == "new phone"
      ) {
        if (self.changeemail == "") {
          self.phoneNumberColor = "red";
          return false;
        } else {
          self.verifyNewPhoneNumber();
        }
      }
    },
    /**
     * @description
     */
    showModal() {
      this.$refs["edit-profile-verify"].show();
    },
    /**
     * @description
     */
    hideModal() {
      this.changeemail = "";
      this.newPhonenumber = "";
      this.$refs["edit-profile-verify"].hide();
    },
    /**
     * @description
     * @returns {any}
     */
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["edit-profile-verify"].toggle("#toggle-btn");
    },
    /**
     * @description
     * @returns {any}
     */
    isEmailValid: function () {
      return this.changeemail == ""
        ? ""
        : this.reg.test(this.changeemail)
        ? "has-success"
        : "has-error";
    },
    /**
     * @description
     * @param {any} evt
     * @returns {any}
     */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    /**
     * @description
     */
    addEvent() {
      this.phoneNumberColor = "";
    },
    /**
     * @description
     */
    sendVerificationCode_Phone_Email(updatetype) {
      let self = this;
      var request = {
        change_request_type: updatetype,
      };
      self.phoneNumberColor = "";
      self.pinColor = "";
      api
        .sendVerificationCode(request)
        .then((response) => {
          if (response.code == 200) {
            self.sendButtonTitle = "Verify";
            this.showModal();
          }
        })
        .catch(function (error) {
          self.showValidationModal(error.response.data.message);
        });
    },

    /**
     * @description
     */
    verifyVerificationCode_Phone_Email() {
      let self = this;
      self.phone_no = self.phone_no.replace(/[^a-zA-Z0-9]/g, "");
      var request = {
        phone_no: this.phone_no,
        verification_code: this.changeemail,
      };
      self.phoneNumberColor = "";
      self.pinColor = "";
      api
        .verifyVerificationCode(request)
        .then((response) => {
          if (response.code == 200) {
            self.changeemail = "";
            if (self.updateType == "email") {
              self.alertTitle = "Enter new email";
              self.keyboadeType = "email";
              self.maxlengthvalue = "50";
              self.sendButtonTitle = "Submit";
              this.vmaskType =
                "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
            } else if (self.updateType == "phone") {
              self.alertTitle = "Enter new phone number";
              self.keyboadeType = "numeric";
              self.maxlengthvalue = "10";
              self.sendButtonTitle = "Submit";
              self.vmaskType = "(###) ###-####";
            }
          }
        })
        .catch(function (error) {
          self.showValidationModal(error.response.data.message);
        });
    },
    /**
     * @description
     */
    modifyEmail() {
      let self = this;
      var request = {
        email: this.changeemail.toLowerCase(),
      };
      self.phoneNumberColor = "";
      self.pinColor = "";
      api
        .updateNewEmail(request)
        .then((response) => {
          if (response.code == 200) {
            self.changeemail = "";
            self.hideModal();
            self.getUserDetails();
          }
        })
        .catch(function (error) {
          self.showValidationModal(error.response.data.message);
        });
    },
    /**
     * @description
     */
    sendOTPNewPhoneNumber() {
      let self = this;
      var user_details = localStorage.getItem("consumer_login_response")
      ? JSON.parse(localStorage.getItem("consumer_login_response"))
      : null;
      self.changeemail = self.changeemail.replace(/[^a-zA-Z0-9]/g, "");
      var request = {
        phoneNo: this.changeemail,
        sessionId: Math.floor(Math.random() * (99999999 - 100000) + 100000),
        user_id:user_details.user_id,
      };
      self.phoneNumberColor = "";
      self.pinColor = "";
      api
        .sendOtpyNewPhone(request)
        .then((response) => {
          if (response.code == 200) {
            self.newPhonenumber = this.changeemail;
            self.changeemail = "";
            self.updateType = "new phone";
            self.alertTitle =
              "Enter verification code sent on your new phone number.";
            this.vmaskType = "XXXXXX";

            self.keyboadeType = "";
            self.sendButtonTitle = "Verify";
            self.changePhone = false;
          }
        })
        .catch(function (error) {
          self.showValidationModal(error.response.data.message);
        });
    },
    /**
     * @description
     */
    verifyNewPhoneNumber() {
      let self = this;
      self.newPhonenumber = self.newPhonenumber.replace(/[^a-zA-Z0-9]/g, "");
      var request = {
        phoneNo: this.newPhonenumber,
        otp: this.changeemail,
      };
      self.phoneNumberColor = "";
      self.pinColor = "";
      api
        .verifyNewPhone(request)
        .then((response) => {
          if (response.code == 200) {
            self.changeemail = "";
            this.newPhonenumber = "";
            self.hideModal();
            self.getUserDetails();
          }
        })
        .catch(function (error) {
          self.showValidationModal(error.response.data.message);
        });
    },
    //calls the api to change bank account through finicity portal
    changeBankAccount() {
      let self = this;
      self.$refs["delink-modal"].hide();
      self.$router.push("/banklinking");
    },
    getConnectLink() {
      var self = this;
      self.$refs["delink-modal"].hide();
      self.isLoading = true;
      api
        .updateLinkGenerate()
        .then((response) => {
          if (response.code == 200) {
            const finicityConnectUrl = response.data.link;
            window.finicityConnect.launch(finicityConnectUrl, {
              selector: "#connect-container",
              overlay: "rgba(255,255,255, 0)",
              success: function (data) {
                console.log("Yay! We got data", data);
                if (data.code == 200) {
                  //now store the details at canpay end
                  self.storeBankDetails();
                }
              },
              cancel: function () {
                self.isLoading = false;
                console.log("The user cancelled the iframe");
              },
              error: function (err) {
                self.isLoading = false;
                console.error(
                  "Some runtime error was generated during Finicity Connect",
                  err
                );
              },
              loaded: function () {
                self.isLoading = false;
                console.log(
                  "This gets called only once after the iframe has finished loading"
                );
              },
              route: function (event) {
                self.isLoading = false;
                console.log(event);
                console.log(
                  "This is called as the user progresses through Connect"
                );
              },
            });
          } else {
            self.isLoading = false;
          }
        })
        .catch((err) => {
          self.isLoading = false;
          console.log(err);
        });
    },
    //stores the bank details into canpay end
    storeBankDetails() {
      var self = this;
      this.isLoading = true;
      api
        .updateBank()
        .then((response) => {
          if (response.code == 200) {
            this.getUserDetails();
          }
        })
        .catch(function (error) {
          self.isLoading = false;
          if (error.response.data.code == 598) {
            self.$refs["delink-modal"].show();
          }else if(error.response.data.code == 597){
            self.generateConnectFix(
              error.response.data.data.institutionId
            );
          } else {
            self.showValidationModal(error.response.data.message);
          }
        });
    },
    generateConnectFix(id) {
      let self = this;
      this.isLoading = true;
      var request = {
        institution_id: id,
      };
      api
        .generateConnectFix(request)
        .then((response) => {
          if (response.code == 200) {
            const finicityConnectUrl = response.data.link;
            window.finicityConnect.launch(finicityConnectUrl, {
              selector: "#connect-container",
              overlay: "rgba(255,255,255, 0)",
              success: function (data) {
                console.log("Yay! We got data", data);
                if (data.code == 200) {
                  //now store the details at canpay end
                  self.storeBankDetails();
                }
              },
              cancel: function () {
                self.isLoading = false;
                console.log("The user cancelled the iframe");
              },
              error: function (err) {
                console.log(err);
                self.isLoading = false;
                console.error(
                  "Some runtime error was generated during Finicity Connect",
                  err
                );
              },
              loaded: function () {
                self.isLoading = false;
                console.log(
                  "This gets called only once after the iframe has finished loading"
                );
              },
              route: function (event) {
                self.isLoading = false;
                console.log(
                  "This is called as the user progresses through Connect"
                );
              },
              user: function (event) {
                if (event.data.errorCode) {
                  setTimeout(() => {
                    window.finicityConnect.destroy();
                    //if error code is present then call the connect fix api
                    self.generateConnectFix(event.data.data.institutionId);
                  }, 2000);
                }
              },
            });
          } else {
            self.isLoading = false;
          }
        })
        .catch((err) => {
          self.isLoading = false;
          console.log(err);
        });
    },
    gotoBankListing() {
      this.$router.push("/banklisting");
    },
  },
};
</script>

<style lang="scss">
.b-button {
  background-color: transparent;
}

#pay-modal-center___BV_modal_content_ {
  border-radius: 10px;
  margin: 10px;
  background-color: #ffffff;
}
#pay-modal-center___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}

.content-wrap {
  overflow: visible;
  background-color: #149240;
}
::placeholder {
  color: #ffffff;
  opacity: 1;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000000 !important;
  font-family: "Montserrat" !important;
  font-size: 12px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffffff !important;
  font-family: "Montserrat" !important;
  font-size: 12px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #ffffff !important;
  font-family: "Montserrat" !important;
  font-size: 12px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #ffffff !important;
  font-family: "Montserrat" !important;
  font-size: 12px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
}
#edit-month-modal___BV_modal_body_ {
  background-color: #ffffff;
  margin: 20px;
}
#edit-month-modal___BV_modal_content_ {
  background-color: #ffffff;
}
.edit-month-modal-sm {
  float: left !important;
}
@media only screen and (width: 600px) and (height: 960px) {
  .edit-month-modal-sm {
    margin-left: 100px;
  }
}
@media only screen and (min-width: 768px) and (min-height: 1024px) {
  .edit-month-modal-sm {
    margin-left: 120px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .edit-month-modal-sm {
    margin-left: 120px;
  }
}
@media (min-width: 1281px) {
  .edit-month-modal-sm {
    margin-left: 293px;
    margin-top: 14px;
  }
}
#delink-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
</style>
