
<template>
  <div class="pre-header">
    <div class="row">
      <div class="col-12 text-center">
        <img
          class="canpay-logo-onboard"
          src="../../assets/images/canpay-logo.png"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12 get-the-canpay-app-label">
        <label>Get the CanPay App</label>
      </div>
    </div>

    <div class="row">
      <div class="col-12 get-the-canpay-app-description-label">
        <label
          >Simply tap the button &ensp;
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 51 204"
            hieght="4"
            width="4"
            style="margin-right: 0.5rem; margin-left: 0.5rem"
            xml:space="preserve"
            fill="#179346"
          >
            <g>
              <g>
                <path
                  d="M25.5,51C39.5,51,51,39.5,51,25.5S39.5,0,25.5,0S0,11.5,0,25.5S11.5,51,25.5,51z M25.5,76.5C11.5,76.5,0,88,0,102
			s11.5,25.5,25.5,25.5S51,116,51,102S39.5,76.5,25.5,76.5z M25.5,153C11.5,153,0,164.5,0,178.5S11.5,204,25.5,204
			S51,192.5,51,178.5S39.5,153,25.5,153z"
                />
              </g>
            </g></svg
          >&ensp; or open<br />
          your browser menu and select <br /><strong>“Install App”</strong> or
          <strong
            >“Add to Home<br />
            Screen"</strong
          >
        </label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 text-center">
        <img
          class="canpay-moble-screen"
          src="../../assets/images/smart-phone.png"
        />
      </div>
    </div>
    <div class="row next-button-color-onboardingsingin">
      <div class="col-12 m-auto">
        <button
          type="button"
          class="btn-login onboard-btn-sign-in"
          v-on:click="next"
        >
          Sign In
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  /**
   * write a component's description
   * this component is use to Upgrade to the new canpay the user's email as a consumer
   */
  name: "UpgradeCanpayWithEmail",
  /**
   * @description-

   * @returns {any}
   */

  data() {
    let self = this;
    return {
      email: "",
      emailColor: "",
      error: false,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  destroyed() {
    document
      .getElementById("app")
      .style.setProperty("background-color", "#149240");
  },
  created() {},
  mounted() {
    this.$root.$emit("show_header", false);
    this.$root.$emit("changeWhiteBackground", [true, false, ""]);
    document
      .getElementById("app")
      .style.setProperty("background-color", "#ffffff");

    var element = document.getElementsByClassName("wrapper");
    element[0].style.setProperty("background-color", "#ffffff");
    localStorage.removeItem("phone");
    localStorage.removeItem("email");
  },
  methods: {
    next() {
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss"></style>
