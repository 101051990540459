<template>
<div>
  <div  v-if="isLoading">
   <CanPayLoader/>
  </div>
  <div class="container splash">
    <cp-onboardingheader></cp-onboardingheader>
    <div v-if="!isLoading">
      <div class="row">
        <div class="col-12 text-center mt-3 mb-3">
          <img
            class="mobile-number-verification-image"
            src="../../assets/images/existing-mobile.png"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 pls-enter-you-number-label text-center">
          <label>Please Enter your mobile <br />number to validate </label>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-12 mobile-phone-number-label mt-3">
          <label class="float-left">Mobile phone number</label>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group no-border">
            <span class="form-control-icon">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 72 90"
                style="enable-background: new 0 0 72 90"
                xml:space="preserve"
                height="20"
                width="20"
              >
                <path
                  d="M48,70H24c-5.5,0-10-4.5-10-10V12c0-5.5,4.5-10,10-10h24c5.5,0,10,4.5,10,10v48C58,65.5,53.5,70,48,70z M24,6
    c-3.3,0-6,2.7-6,6v48c0,3.3,2.7,6,6,6h24c3.3,0,6-2.7,6-6V12c0-3.3-2.7-6-6-6H24z"
                />
                <path
                  d="M40,14h-8c-1.1,0-2-0.9-2-2s0.9-2,2-2h8c1.1,0,2,0.9,2,2S41.1,14,40,14z"
                />
                <circle cx="36" cy="56.4" r="4" />
              </svg>
            </span>
            <input
              v-model="phone"
              inputmode="numeric"
              @keyup.enter="submitNext"
              name="phone"
              @keypress="isNumber($event)"
              class="form-control input-box-padding phone-number"
              placeholder="Mobile number"
              v-bind:style="{ 'border-color': phoneColor }"
              v-mask="'(###) ###-####'"
            />
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <button
            type="button"
            class="btn-login btn-get-started"
            v-on:click="submitNext"
          >
            Next
          </button>
        </div>
      </div>
      <div class="input-box-row re-send-verification">
        <label v-on:click="registerPrimaryPhoneNumber"
          ><u class="cursor-pointer"
            >I don't have a mobile phone number</u
          ></label
        >
      </div>
    </div>

    <!---- MODAL FOR  Error Modal  ---->

    <div>
      <b-modal
        ref="zero-pp-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="zero-pp-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="col-12 text-center">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="120"
                height="120"
                viewBox="0 0 100 125"
                style="enable-background: new 0 0 100 125"
                xml:space="preserve"
                fill="#e14343"
                class="on-boarding-upgrade-alert-icon"
              >
                <path
                  d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
                />
              </svg>
            </div>
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                {{ pp_alert_message }}
              </label>
            </div>
            <br />
            <br />
            <div class="text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="hideModal('zero-pp-modal')"
              >
                <label class="purchasepower-modal-ok-label">OK</label>
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</div>
</template>
<script>
import OnboardingHeader from "./OnboardingHeader.vue";
import api from "../../api/onboarding.js";
import constants from "../Common/constant.js";
import Loading from "vue-loading-overlay";
import CanPayLoader from "../CustomLoader/CanPayLoader.vue"
export default {
  /**
   * write a component's description
   * this component is use to Upgrade to the new canpay the user's email as a consumer
   */
  name: "ExistingPhoneNumberVerification",
  /**
   * @description-

   * @returns {any}
   */

  data() {
    let self = this;
    return {
      phone: "",
      phoneColor: "",
      error: false,
      pp_alert_message: "",
      isLoading: false,
      fullPage: true,
      sessionId:null
    };
  },
  components: {
    "cp-onboardingheader": OnboardingHeader,
    Loading,
    CanPayLoader
  },
  destroyed() {
    document
      .getElementById("app")
      .style.setProperty("background-color", "#149240");
  },
  created() {},
  mounted() {
    this.$root.$emit("show_header", false);
    this.$root.$emit("changeWhiteBackground", [true, false, ""]);
    document
      .getElementById("app")
      .style.setProperty("background-color", "#ffffff");

    var element = document.getElementsByClassName("wrapper");
    element[0].style.setProperty("background-color", "#ffffff");

    var elementHtml = document.getElementsByTagName("html")[0];
    elementHtml.style.setProperty("background-color", "#ffffff");
    this.phone = localStorage.getItem("phone");
    this.sessionId = atob(window.location.href.split("/").splice(-2, 1)[0]);
    console.log(this.sessionId,window.location.href.split("/"));
  },
  methods: {
    showModal(modal) {
      this.$refs[modal].show();
    },
    hideModal(modal) {
      this.$refs[modal].hide();
    },

    submitNext() {
      let self = this;
      if (self.phone.length == 0) {
        self.phoneColor = "red";
      } else {
        self.phoneColor = "";
        self.isLoading = true;
        var request = {
          phone: self.phone.replace(/[^a-zA-Z0-9]/g, ""),
          session_id: this.sessionId,
          phone_checking_required: 1,
        };
        api
          .DuplicateMobileNumber(request)
          .then((response) => {
            self.isLoading = false;
            if (response.code == 200) {
              localStorage.setItem("phone", self.phone);
              this.$router.push("/PhoneNumberVerificationCode/"+btoa(self.phone.replace(/[^a-zA-Z0-9]/g, ""))+"/"+btoa(self.sessionId)+"/PhoneNumberVerificationCode");
            }
          })
          .catch((err) => {
            self.isLoading = false;
            if (err.response.data.code == 599) {
              self.pp_alert_message = err.response.data.message;
              this.showModal("zero-pp-modal");
            } else {
              self.pp_alert_message = err.response.data.message;
              this.showModal("zero-pp-modal");
            }
          });
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    registerPrimaryPhoneNumber() {
      this.$router.push("/registerprimaryphonenumber/"+btoa(this.sessionId)+"/registerprimaryphonenumber");
    },
  },
};
</script>
<style lang="scss">
#zero-pp-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
</style>
