<template>
  <div class="container">
    <!-- bank icon row -->
    <div class="row ">
      <div class="col-12">
        <span class="text-center">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="90"
              height="90"
              viewBox="0 0 100 125"
              style="enable-background:new 0 0 100 125;"
              xml:space="preserve"
              fill="#ffffff"
            >
              <path
                d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
              />
            </svg>
          </span>
      </div>
    </div>
    <!-- connect to your bank text -->
    <div class="row">
      <div class="col-12">
        <span class="connect-bank-text"> This bank account is restricted to a spending limit of ${{purchasePower}}. 
        You may continue with this account with this limit or select other options given below</span>
      </div>
    </div>
      <!-- manual bank linking with code 29 button row -->
    <div class="row btn-row-space padding">
      <div class="col-12">
        <button type="button" class="manual-btn btn-width" @click="bankLinkManualWithCodeTwintynine">
          Continue with ${{purchasePower}} limit.
        </button>
      </div>
    </div>
  
     <!-- link checking account (finicity bank linking) button row -->
    <div class="row  padding margin-button">
      <div class="col-12">
        <button
          type="button"
          class="btn-login "
          @click="bankLinkFinicity"
        >Direct Link Your Bank</button>
      </div>
    </div>

    <!-- manual bank linking button row -->
    <div class="row  padding margin-button">
      <div class="col-12">
        <button
          type="button"
          class="btn-login"
          @click="bankLinkManual"
        >Link Another Bank</button>
      </div>
    </div>

  </div>
</template>
<script>
/**
 * write a component's description
 * this component is use to login the user as a consumer
 */
import constants from "../Common/constant.js";
export default {
  name: "ManualBankLinkWithCodeTwintynine",
  /**
   * @description-
   * routingNumber => this will take the consumer's phone no
   * routingNumber => this will take the consumer's accountnumber
   * pinColor => use to validate the accountnumber field
   * routingNumberColor => use to validate the phoneno field
   * token => use to store the user's token
   * @returns {any}
   */
  data() {
    let self = this;
    return {
      constants: constants
    };
  },
   props: {
    purchasePower: ""
  },
  created() {
    this.$root.$emit("changeWhiteBackground", [false, true, "common"]);
  },
  mounted() {
    this.$root.$emit("changeWhiteBackground", [false, true, "common"]);
    
  },
  methods: {
    /**
     * @description-
     * This function is use to call the login api
     * @returns {login rsponse}
     */

    bankLinkFinicity() {
      this.$root.$emit("bank_link", constants.finicity);
    },
    bankLinkManual() {
      this.$root.$emit("bank_link", constants.manual);
    },
    bankLinkManualWithCodeTwintynine() {
      this.$root.$emit("microbilt_responce_code_29", constants.microbiltResponceCode29);
    }
  }
};
</script>
