<template>
    <div>
        <div class="merchant-header">
            <div class="container">
                <div class="d-flex mb-3">
                    <slot name="backbtn"></slot>
                    <p class="merchant-header-title ml-2">{{ selectedMerchant.merchant_id ? 'Change Merchant' : 'Select Merchant'}}</p>
                </div>
                <div class="search-merchant-input">
                    <svg width="42" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M41.303 38.0504L33.7652 30.5642C39.6509 23.0264 39.0313 12.2876 32.268 5.52428C28.7056 1.96189 23.9557 0 18.8961 0C13.8365 0 9.08666 1.96189 5.52428 5.52428C1.96189 9.08666 0 13.8365 0 18.8961C0 23.9557 1.96189 28.7056 5.52428 32.268C9.08666 35.8304 13.8365 37.7923 18.8961 37.7923C23.0781 37.7923 27.2084 36.3983 30.5126 33.7652L37.9988 41.3546C38.4634 41.8193 39.0313 42.0258 39.6509 42.0258C40.2704 42.0258 40.8384 41.7677 41.303 41.3546C42.2323 40.4769 42.2323 38.9797 41.303 38.0504ZM33.1457 18.8961C33.1457 22.7167 31.6484 26.279 28.9637 28.9637C26.279 31.6484 22.665 33.1457 18.8961 33.1457C15.1272 33.1457 11.5132 31.6484 8.82852 28.9637C6.14382 26.279 4.64659 22.665 4.64659 18.8961C4.64659 15.0756 6.14382 11.5132 8.82852 8.82852C11.5132 6.14382 15.1272 4.64659 18.8961 4.64659C22.7167 4.64659 26.279 6.14382 28.9637 8.82852C31.6484 11.5132 33.1457 15.0756 33.1457 18.8961Z" fill="black"/>
                    </svg>
                    <input class="form-control" type="text" placeholder="Search" v-model="searchTerm">
                </div>
            </div>
        </div>
        <div class="merchant-body mt-3">
            <div class="container">
                <div class="row mx-0">
                    <div class="col-12 mb-3">
                        <div class="text-left text-white">Points can be only be used at the merchant brand where they were awarded.</div>
                    </div>
                </div>
                <div class="row mx-0">
                    <div v-for="(merchant, i) in filteredMerchants" :key="i" class="col-12 mb-3 px-2">
                        <a class="select-merchant-btn" href="javascript:void(0)" @click="selectMerchant(merchant)">
                            <div :class="selectedMerchant.merchant_id == merchant.merchant_id ? 'merchant-card active' : 'merchant-card'">
                                <div class="row mx-0">
                                    <div class="text-left col-8">
                                        <div style="width: 130px;top:" v-if="merchant.logo_url!=null && merchant.logo_url!=''">
                                            <img :src="merchant.logo_url" :alt="merchant.merchant_name" v-if="merchant.logo_url!=null && merchant.logo_url!=''">
                                        </div>
                                        <div class="tran-store-name mb-0" style="font-weight: 600;font-size: 16px;text-align: left;" v-else>{{ merchant.merchant_name }}</div>
                                    </div>
                                    <div class="align-items-center col-4">
                                        <label class="switch">
                                            <input type="checkbox" :checked="selectedMerchant.merchant_id === merchant.merchant_id" :disabled="true">
                                            <span class="slider round"></span>
                                        </label>
                                        <br><span>{{ selectedMerchant.merchant_id === merchant.merchant_id ? 'Active' : 'Activate' }}</span>
                                    </div>
                                
                                </div>
                                <hr class="my-2">
                                <div class="row mx-0">
                                    <div class="text-left col-12">
                                        <!---->
                                        <svg width="30" height="30" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
                                            <path d="M43.6484 32.2123C45.9758 27.2992 45.0233 22.6013 44.7537 21.3551C44.4482 19.9476 43.6754 16.6214 40.8627 13.6359C39.5231 12.233 37.9321 11.0927 36.1719 10.2738C33.7996 9.17106 31.7777 8.95589 30.6724 8.8483C26.1164 8.40003 22.3512 9.72692 20.2485 10.6773C21.2723 9.69865 22.4425 8.88501 23.7171 8.26555C24.8436 7.72841 26.0375 7.34542 27.2667 7.12693C29.2792 6.74323 31.3425 6.70382 33.3683 7.01038C34.3028 7.15383 38.2028 7.82624 42.0938 10.8386C47.7641 15.2228 50.0915 21.3103 48.7705 28.3123C46.7576 39.0261 36.4595 45.3019 26.0176 42.5406C22.9443 41.7247 19.7093 39.7971 18.2715 37.9144C18.3434 37.9144 18.4243 37.8964 18.4692 37.9233C18.8287 38.1564 19.1791 38.4075 19.5476 38.6406C25.3886 42.2985 31.3733 42.3254 37.3222 39.053C38.0754 38.6376 38.791 38.1577 39.4609 37.6185C42.0399 35.5385 43.2171 33.1178 43.6484 32.2123Z" fill="black"></path>
                                            <path d="M49.2647 32.8578C49.2018 33.2344 49.094 33.7723 48.9232 34.4088C47.6831 38.9633 44.8525 41.8412 43.6394 43.0426C39.2002 47.4446 34.0332 48.7177 31.6698 49.2736C26.8083 50.4122 22.8814 49.9281 21.4706 49.7039C16.3934 48.897 12.9067 46.8529 11.8374 46.1805C9.65575 44.8138 7.70037 43.117 6.04133 41.1509C4.1037 38.8639 2.58573 36.2538 1.55724 33.4406C1.29664 32.7233 0.308168 29.9082 0.0565558 26.053C-0.177084 22.3772 0.380057 19.6337 0.514849 18.9972C0.954323 16.9176 1.66425 14.9043 2.62659 13.0083C3.12982 12.04 5.69087 7.30621 11.3521 3.77381C12.6641 2.94899 16.0968 1.01244 20.9583 0.277275C22.2434 0.0800349 33.3233 -1.4441 41.2581 5.48622C44.9334 8.69586 47.099 12.9903 47.4405 13.6807C47.9662 14.747 48.4225 15.8461 48.8064 16.971C48.6806 16.8006 47.746 15.4917 47.7191 15.4558C44.2684 10.3545 40.2606 7.95172 40.2606 7.95172C38.7451 7.06349 37.1157 6.38487 35.4171 5.93449C30.0164 4.48208 25.4694 5.77311 24.3911 6.09587C18.0648 7.98758 14.3176 13.0172 14.1019 13.3131C10.2828 18.5668 10.3637 24.0537 10.4356 25.5778C10.6782 30.7061 12.9247 34.3281 13.9491 35.7895C14.03 35.8881 14.1468 36.0406 14.2906 36.2199C14.3985 36.3633 14.5063 36.5068 14.6141 36.6502C17.292 40.1647 20.8415 42.6123 24.7864 43.8316C27.7263 44.7285 30.8328 44.9427 33.8684 44.4579C36.9039 43.9731 39.7882 42.802 42.3004 41.0343C44.511 39.4743 45.85 37.8516 46.6767 36.8474C47.773 35.5206 48.5728 34.2116 48.8334 33.6916C48.8693 33.6288 49.2467 32.8578 49.2647 32.8578Z" fill="#ECB800"></path>
                                            <path d="M20.3742 32.9385C15.6295 32.9385 13.2571 29.8096 13.2571 25.0131C13.2571 20.0552 15.7553 17.0697 20.6438 17.0697C22.5309 17.0697 24.0495 17.5179 25.2177 18.4683C26.314 19.4455 26.9251 20.7724 27.0778 22.4579H25.1728C24.4898 22.4579 23.9956 22.1441 23.6991 21.5255C23.1779 20.4227 22.1534 19.8579 20.6527 19.8579C17.7233 19.8579 16.4203 21.92 16.4203 25.022C16.4203 28.0344 17.6693 30.1592 20.5539 30.1592C22.5309 30.1592 23.6631 29.0744 24.0046 27.371H27.0689C26.7903 30.9213 24.2832 32.9385 20.3742 32.9385Z" fill="black"></path>
                                            <path d="M36.756 17.1055H31.3733C30.4657 17.1055 29.7468 17.8944 29.7468 18.7999V32.8847H32.9818V27.2634H36.9896C40.1617 27.2634 41.8601 25.291 41.8601 22.1799C41.8601 18.8986 40.1168 17.1055 36.756 17.1055ZM36.3876 24.4123H33.2334V19.8399H36.5493C38.059 19.8399 38.8318 20.602 38.8318 22.1351C38.8408 23.6682 38.032 24.4392 36.3876 24.4123Z" fill="#ECB800"></path>
                                        </svg>
                                        <span style="font-size: 22px;font-weight: 800;">{{ pointNumberFormatter(merchant.reward_point) }} {{ parseInt(merchant.reward_point) > 1 ? 'pts' : 'pt'}}</span>
                                    </div>
                                </div>
                                
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        value: Object,
        merchantPoints: Array,
        searchTerm: String
    },
    data(){
        return{
            selectedMerchant: {},
        }
    },
    mounted(){
        this.selectedMerchant = this.value
    },
    methods:{
        selectMerchant(merchant){
            this.selectedMerchant = merchant
            this.$emit('input', merchant)
            this.$parent.redirectBackToDashboard();
        }
    },
    watch: {
        "value": function(){
            if(this.value){
                this.selectedMerchant = this.value
            }else{
                this.selectedMerchant = {}
            }

        }
    },
    computed: {
        filteredMerchants() {
            return this.merchantPoints.filter((merchant) => {
                return merchant.merchant_name ? merchant.merchant_name.toLowerCase().includes(this.searchTerm.toLowerCase()) : null;
            });
        }
    }
}

</script>

<style>
.merchant-header{
    background: #fff;
    padding: 50px 0 18px 0;
    border-radius: 0 0 20px 20px;
}
.merchant-header-title{
    margin: 0;
    font-size: 18px;
    font-weight: 700;
}

.search-merchant-input{
    background-color: #ECECEC;
    padding: 5px 15px;
    display: flex;
    border-radius: 6px;
}
.search-merchant-input svg{
    width: 17px;
}
.search-merchant-input input{
    border: 0;
    width: 100%;
    background-color: #ECECEC;
}
.search-merchant-input input:active, .search-merchant-input input:focus, .search-merchant-input input:focus-visible{
    border: 0;
    box-shadow: none;
    background-color: #ECECEC;
}
.search-merchant-input input::placeholder{
    color: black;
    font-weight: 500;
}
.search-merchant-input input:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: black;
 font-weight: 500;
}

.search-merchant-input input::-ms-input-placeholder { /* Microsoft Edge */
 color: black;
 font-weight: 500;
}

.merchant-body{
    height: calc(100vh - 175px);
    overflow-y: scroll;
}
.merchant-card{
    position: relative;
    padding: 15px 18px;
    border-radius: 7px;
    background-color: #fff;
}
.merchant-card.active{
    border: 2px solid;
}
.merchant-card img{
    width: 100%;
}
.select-merchant-btn, .select-merchant-btn:hover{
    color: black;
    text-decoration: none;
}
.switch {
  position: relative;
  display: inline-block;
  width: 55px;  /* Adjust width to maintain aspect ratio */
  height: 28px; /* Slightly larger height */
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border: 1px solid #999; /* Add border */
  border-radius: 28px; /* Adjust to match new height */
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px; /* Adjust button size */
  width: 22px;
  left: 3px;
  bottom: 2px;
  background-color: black; /* Default button color when unchecked */
  transition: .4s;
}

input:checked + .slider {
  background-color: green; /* Background color when checked */
  border-color: green; /* Change border color when checked */
}

input:checked + .slider:before {
  transform: translateX(27px); /* Adjust translation based on new size */
  background-color: white; /* Button color when checked */
}

.slider.round {
  border-radius: 28px; /* Adjust border-radius for the round shape */
}

.slider.round:before {
  border-radius: 50%;
}
</style>