<template>
    <div class="container py-3">
        <h1 class="tc-head text-center">CANPAY SPIN TO WIN <br> SWEEPSTAKES OFFICIAL RULES</h1>
        <p class="tc-desc text-center mb-4">Sponsored by Trusted Debit Inc. dba “CanPay” (<strong>“Sponsor”</strong>)</p>

        <p class="tc-para bold text-left  mb-5">NO ENTRY FEE. NO PURCHASE OR OBLIGATION NECESSARY TO ENTER OR WIN. VOID WHERE PROHIBITED.
        THIS PROMOTION IS IN NO WAY SPONSORED, ENDORSED OR ADMINISTERED BY, OR ASSOCIATED WITH
        FACEBOOK, INSTAGRAM, TWITTER OR ANY OTHER SOCIAL MEDIA SITES WHERE THIS SWEEPSTAKES MAY BE
        COMMUNICATED.</p>

        <p class="tc-para text-left  mb-5">
            The CanPay Spin to Win (the <strong>“Sweepstakes”</strong>) begins on March 1, 2023, at 12:00AM Pacific Time (<strong>“PT”</strong>) and will
            continue until such time that Sponsor terminates the Spin to Win Sweepstakes or otherwise modifies it (the
            <strong>“Sweepstakes Period”</strong>). Sponsor’s clock will be the official timekeeper for the Sweepstakes.
        </p>

        <p class="tc-para text-left  mb-5">
            <strong>1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Agreement to Official Rules.</strong> By entering or participating in the Sweepstakes, you [(and, if you are a
            Minor (as defined below) your parent or legal guardian)] accept and agree to be bound by these <strong>“Official
            Rules,”</strong> the CanPay Terms of Service, which can be found at <a class="tc-link" href="https://www.canpaydebit.com/about-us/terms-conditions/">here</a> (the <strong>“CanPay Terms”</strong>), and all other terms
            associated with the CanPay Terms (both of which are herein incorporated by reference). All capitalized terms
            used but not defined herein shall have the respective meaning given to them in CanPay Terms. The CanPay
            Terms apply only to a winner’s use of the Prize after the Prize has been fulfilled by Sponsor
        </p>

        <p class="tc-para text-left  mb-5">
            <strong>2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Eligibility and How to Enter.</strong> Log into your CanPay account during the Sweepstakes Period and make
            an Eligible Transaction. You will then have the option to click <strong>“Spin”</strong> to enter into the Sweepstakes. You will
            receive one instant win entry opportunity per Eligible Transaction, and will be immediately notified of a
            winning outcome, subject to verification of eligibility as defined in Section 7. To participate, you must agree to
            the CanPay Terms of Service located at <a class="tc-link" href="https://www.canpaydebit.com/about-us/terms-conditions/">here</a> and Privacy Policy located at <a class="tc-link" href="https://www.canpaydebit.com/about-us/privacy/">here</a>.
            <br>
            <br>
            All entries become property of Sponsor, and none will be acknowledged or returned. Entries generated by
            script, macro or other automated or mechanical means or with the intent to subvert the entry process are
            void. Entrants submitting multiple entries or violating any of these Official Rules may be disqualified. Proof of
            submission of an entry does not constitute proof of receipt.
        </p>

        <p class="tc-para text-left  mb-5">
            <strong>3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Prize Drawing.</strong> All Sweepstakes entries meeting the requirements of these Official Rules will be
            immediately entered into the Sweepstakes drawing in effect at the time the entry is received as described in
            Section 2. The prize drawing for each Spin is provided to you immediately following the Spin. The drawing will
            be conducted by a computer program that randomly chooses the potential prize per Spin and all eligible
            entries received and the random selection is final and binding. Each winner is considered a potential winner
            pending verification of his/her eligibility and compliance with these Official Rules. If a Prize is won by a Minor,
            it will be awarded to a parent or guardian on the Minor’s behalf.
        </p>

        <p class="tc-para text-left  mb-5">
            <strong>4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Prizes and Odds.</strong> The value of Prizes are presented to you at the time of Spin or in such manner as
            Sponsor determines, and odds of winning are variable and provided to you at the time of the Spin in such
            manner as solely determined by Sponsor. All Prizes are non-transferable, and no cash or other substitution of
            Prizes is permitted, except at the sole discretion of Sponsor for a prize of equal or greater value. Sponsor will
            not replace any lost or stolen Prizes. Winners are solely responsible for any and all federal, state, provincial
            and local taxes, if any, that apply to Prizes. You (and, if you are a Minor, your parent or legal guardian) agree to
            provide Sponsor with any additional information and complete any required tax or other forms relating to your
            receipt of a Prize.
        </p>

        <p class="tc-para text-left  mb-5">
            <strong>5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Notification of Winners.</strong> Potential winners will be notified immediately via the CanPay account
            following each Spin, and as a condition of being awarded a Prize. Sponsor will attempt to notify winners as
            provided above, however, without limiting any other terms of these Official Rules, Sponsor assumes no responsibility for undeliverable prize notifications. If a potential winner (and if entrant is a Minor, potential
            winner’s parent or legal guardian, as applicable) cannot be contacted, is ineligible, fails to claim a Prize, fails to
            complete any required affidavit of eligibility or publicity/liability release within the days specified in the
            notification or thirty (30) days after such notification, whichever comes first, and/or fails to comply with any of
            the requirements, a Prize may be forfeited and an alternate potential winner may be randomly selected from
            remaining valid, eligible entries received.
        </p>

        <p class="tc-para text-left  mb-5">
            <strong>6. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Delivery of Prizes.</strong> Each winner (and, if a winner is a Minor, such winner’s parent or legal guardian) is
            responsible for maintain a CanPay account in good standing to ensure proper delivery of a Prize. Sponsor,
            without limiting any other terms of these Official Rules, assumes no responsibility for a winner’s failure to
            comply with the eligibility requirements of the Official Rules that would prevent or delay Sponsor’s delay to
            deliver the Prize. Prizes are delivered once the underlying CanPay enabled transaction and/or purchase with
            the third-party merchant is considered cleared by us (e.g., typically 5 business days) (“Eligible Transaction”).
            For avoidance of doubt, Eligible Transactions do not include any CanPay enabled purchase/transaction that
            was reversed or otherwise failed to settle using CanPay’s banking partners. If a winner does not maintain a
            CanPay account in good standing or does not have an Eligible Transaction, the winner will forfeit the Prize.
            Prizes will be transferred to the winner’s CanPay account immediately upon winner verification. Upon Prize
            forfeiture, or inability to use a Prize or portion thereof by an entrant, no compensation will be given, and
            Sponsor will have no further obligation to such entrant.
        </p>

        <p class="tc-para text-left  mb-5">
            <strong>7. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Disclaimers and Limitations on Liability.</strong>
            <ul class="tc-list">
                <li class="my-3">Sponsor is not responsible for (i) late, lost, delayed, damaged, incomplete, incorrect, illegible,
                misdirected or undeliverable entries, responses, or other correspondence, whether by email
                or otherwise; (ii) theft, destruction, unauthorized access to or alterations of entry materials; or
                (iii) phone, electrical, network, computer, hardware, software program or transmission
                malfunctions, failures or difficulties or any technical hardware or software failures of any kind,
                which may limit a person’s ability to participate in the Sweepstakes.</li>

                <li>THE PRIZES ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR
                IMPLIED, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN NO EVENT WILL SPONSOR
                BE LIABLE TO YOU FOR ANY DIRECT, SPECIAL, INCIDENTAL, EXEMPLARY, PUNITIVE OR
                CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF USE, DATA, BUSINESS OR PROFITS) ARISING
                OUT OF OR IN CONNECTION WITH YOUR PARTICIPATION IN THE SWEEPSTAKES, WHETHER
                SUCH LIABILITY ARISES FROM ANY CLAIM BASED UPON CONTRACT, WARRANTY, TORT
                (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, AND WHETHER OR NOT
                SPONSOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE. Some
                jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential
                damages, so the above limitation or exclusion may not apply to you.</li>
            </ul>
        </p>

        <p class="tc-para text-left  mb-5">
            <strong>8. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Indemnification.</strong> You [(and, if you are a Minor, your parent or legal guardian)] agree to release,
            indemnify, defend and hold Sponsor and its parent, affiliates, subsidiaries, directors, officers, employees,
            sponsors and agents, including advertising and promotion agencies, and assigns, and any other organizations
            related to the Sweepstakes, harmless, from any and all claims, injuries, damages, expenses or losses to person
            or property and/or liabilities of any nature that in any way arise from participation in this Sweepstakes or
            acceptance or use of a Prize or parts thereof, including, without limitation, (i) any condition caused by events
            beyond Sponsor’s control that may cause the Sweepstakes to be disrupted or corrupted; (ii) a Prize, or
            acceptance, possession, or use of a Prize, or from participation in the Sweepstakes; and (iii) any printing or
            typographical errors in any materials associated with the Sweepstakes.
        </p>

        <p class="tc-para text-left  mb-5">
            <strong>9. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Publicity.</strong> With your affirmative consent, we may use of your name, photo and/or likeness,
            biographical information, entry and statements attributed to you (if true) for advertising and promotional
            purposes, including without limitation, inclusion in Sponsor’s newsletters, homepages located at <a class="tc-link" href="https://www.canpaydebit.com/">here</a> , and
            social media channels without additional compensation. We will not otherwise use the above stated personal
            information to advertise or otherwise broadcast your participation in the Sweepstakes unless otherwise
            obligated by applicable law.
        </p>

        <p class="tc-para text-left  mb-5">
            <strong>10. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Additional Conditions.</strong> Sponsor reserves the right, in its sole discretion, to terminate, modify or
            suspend the Sweepstakes in whole or in part, if in Sponsor’s opinion (a) the Sweepstakes is not capable of
            running as planned by reason of the occurrence of any event beyond its control including, but not limited
            to, fire, flood, epidemic, pandemic, earthquake, explosion, labor dispute or strike, act of God or public enemy,
            communications, equipment failure, utility or service interruptions, riot or civil disturbance, terrorist threat or
            activity, war (declared or undeclared), interference with the Sweepstakes by any party, or any federal, state,
            local or provincial government law, order, or regulation, order of any court or jurisdiction, or other cause not
            reasonably within Sponsor’s control, (b) any other factors including as a result of a unintentional error in
            design or execution of the Sweepstakes or any features thereof, and (c) beyond Sponsor’s reasonable control
            corrupt or affect the administration, security, fairness, integrity, or proper conduct of the Sweepstakes, in all
            instances without liability to the entrants. Sponsor also reserves the right to disqualify any entrant or winner,
            as determined by Sponsor, in its sole discretion.
        </p>

        <p class="tc-para text-left  mb-5">
            <strong>11. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Governing Law and Choice of Forum.</strong> These Official Rules are governed by the laws of the State of
            Washington, in the United States of America without reference to rules governing choice of laws. Any action,
            suit or case arising out of, or in connection with, this Sweepstakes or these Official Rules must be brought in
            either the federal or state courts located in Washington, or the state courts located in King County,
            Washington.
        </p>

        <p class="tc-para text-left  mb-5">
            <strong>12. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Personal Information.</strong> Sponsor may use and collect personal data about entrants in accordance with
            its Privacy Policy and as may be more specifically set forth in these Official Rules, including without limitation,
            administering and promoting the Sweepstakes for marketing purposes. Please review these Official Rules and
            Sponsor’s Privacy Policy <a class="tc-link" href="https://www.canpaydebit.com/about-us/privacy/">here</a>.
        </p>

        <p class="tc-para text-left  mb-5"><strong>Sponsor’s Email Address:</strong> Legal@canpaydebit.com</p>

    </div>
</template>
<script>
export default {
    name: "termsandconditions",
    data() {
        return {};
    },
    methods: {},
    mounted() {
        document
            .getElementsByClassName("content-wrap")[0]
            .style.setProperty("background-color", "#ffffff");
        this.$root.$emit("loginapp", [""]);
        this.$root.$emit("changeWhiteBackground", [
            true,
            false,
            "TermsandConditionHeader"
        ]);
    }
};
</script>

<style>
.tc-head{
    font-size: 17px;
    font-weight: 700;
}
.tc-para{
    font-size: 14px;
}
.tc-para.bold{
    font-weight: 700;
}
.tc-link, .tc-link:hover{
    text-decoration: underline;
    color: blue;
}
.tc-list{
    list-style: lower-alpha;
    padding-left: 71px;
}
</style>