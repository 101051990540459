<template> 
    <div>
        <div>
        <b-modal
          ref="upload-documents-modal"
          hide-footer
          hide-header
          header-class = "p-2 border-bottom-0"
          id="upload-documents-modal"
          @hidden="clickAskMeLater('upload-documents-modal')"
          centered
        >
          <div class="color">
            <div class="col-12 text-center">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="120"
                height="120"
                viewBox="0 0 100 125"
                style="enable-background: new 0 0 100 125"
                xml:space="preserve"
                fill="#e14343"
              >
                <path
                  d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
        h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
         M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
        c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
        c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
                />
              </svg>
            </div>
            <div class="purchaserpower-modal-text">
              <div class="d-block text-center">
                Your current spending limit is set to $25 based on the banking connection and your transaction history. If you would like to request an increase to this limit, please select the “Upload Bank Statement” option and upload the requested statements from the bank account that is currently linked to CanPay. Thank you.
                <div class="input-box-row re-send-verification">
                
              </div>
              </div>
              <br />
              <br />
              <div class="row" style="padding-left:1em">
                <b-form-checkbox
                  v-model="notShowAgain"
                >
                Don't show me again.
                </b-form-checkbox>
              </div>
              <div class="row mt-5">
                <div class="col-12 text-center">
                  <button
                    type="button"
                    class="btn-black"
                    style="
                      height: 60px;
                      background: #149240 !important;
                      border: none !important;
                    "
                    @click="uploadDocument"
                  >
                    <span class="purchasepower-modal-ok-label"
                      >Upload Bank Statement</span
                    >
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <button
                    type="button"
                    class="btn-black"
                    style="
                      height: 60px;
                      background: #000000 !important;
                      border: none !important;
                    "
                    @click="clickAskMeLater('upload-documents-modal')"
                  >
                    <span class="purchasepower-modal-ok-label">Close</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
          </div>
    </div>
        
    </template>
    <script>
    import api from "../../api/account.js";
    import Loading from "vue-loading-overlay";
      export default {
        name: "NoPrimaryAccountModal",
        components: {
          Loading
        },
        data() {
            return {
                is_loading:false,
                currentUser: JSON.parse(
                    localStorage.getItem("consumer_login_response")
                ),
                notShowAgain: false,
            };
        },
        methods: {
            clickAskMeLater(modal){
              var self = this;
              if(self.notShowAgain == true)
              {
                api
                .dontShowUploadBankStatement()
                .then(function (response) {
                    if (response.code == 200) {
                        if (localStorage.getItem("show_upload_documents_modal")) {
                          localStorage.removeItem("show_upload_documents_modal");
                        }
                        self.$refs[modal].hide();
                    }
                })
                .catch(function (error) {
                });
              } else {
                if (localStorage.getItem("show_upload_documents_modal")) {
                  localStorage.removeItem("show_upload_documents_modal");
                }
                self.$refs[modal].hide();
              }
              if(self.$route.name == 'pay'){
                self.$parent.getModifyData();
              }
            },
            
            showModal(){
                this.$refs["upload-documents-modal"].show();
            },
            uploadDocument() {
                var self = this;
                self.$router.push("/uploaddocument").catch((err) => {});
            }
        }, 
        props: ['page_name'],
        mounted: function () {
        }
      };
    </script>
    <style lang="scss">
    .b-button {
      background-color: transparent;
    }
    #upload-documents-modal___BV_modal_body_ {
      background-color: #ffffff;
      border-radius: 12px;
      margin: 10px;
    }
    #error-message-modal___BV_modal_body_ {
      background-color: #ffffff;
      border-radius: 12px;
      margin: 10px;
    }
    .separator {
      display: flex;
      align-items: center;
      text-align: center;
    }
    
    .separator::before,
    .separator::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid #000;
    }
    
    .separator:not(:empty)::before {
      margin-right: .25em;
    }
    
    .separator:not(:empty)::after {
      margin-left: .25em;
    }
    </style>