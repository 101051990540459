<template>
  <div class="container">
    <!-- heading row -->
    <div class="row heading-row">
      <div class="col-2"></div>
      <div class="col-8">
        <label class="enter-quick-pin">
          Create your 4-digit
          <br />Quick Access PIN
        </label>
      </div>
      <div class="col-2"></div>
    </div>
    <!-- sub heading row -->
    <div class="row sub-heading-row">
      <div class="col-1"></div>
      <div class="col-10">
        <label class="access-pin-description">
          Your PIN is a quick and secure way to
          <br />sign in to your CanPay account
        </label>
      </div>
      <div class="col-1"></div>
    </div>
    <!-- label 1 -->
    <div class="row justify-content-center align-self-center">
      <div class="col-1"></div>
      <div class="col-10">
        <label class="reenter-digit-text-style-new"
          >Please enter a 4 digit PIN</label
        >
      </div>
      <div class="col-1"></div>
    </div>

    <!-- pin row -->
    <div class="row">
      <div class="col-1"></div>
      <div id="pin_div" class="col-10 m-l-7">
        <input
          id="pin1"
          autocomplete="off"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          v-on:keyup="isNumber($event)"
          maxlength="1"
          inputmode="numeric"
        />
        <input
          id="pin2"
          autocomplete="off"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          v-on:keyup="isNumber($event)"
          maxlength="1"
          inputmode="numeric"
        />
        <input
          id="pin3"
          autocomplete="off"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          v-on:keyup="isNumber($event)"
          maxlength="1"
          inputmode="numeric"
        />
        <input
          id="pin4"
          autocomplete="off"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          v-on:keyup="isNumber($event)"
          maxlength="1"
          inputmode="numeric"
        /><span
          class="input-group-addon"
          style="vertical-align: super"
          v-on:click="switchVisibility"
        >
          <a>
            <i
              class="fa fa-eye-slash eye-icon"
              aria-hidden="true"
              v-if="!show"
            ></i>
            <i class="fa fa-eye eye-icon" aria-hidden="true" v-if="show"></i>
          </a>
        </span>
      </div>
      <div id="pin_view_div" class="col-10 m-l-7 visibility">
        <input
          id="pin1_view"
          v-model="pin1"
          class="form-control ssn-text ssn-input-box-padding"
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <input
          id="pin2_view"
          v-model="pin2"
          class="form-control ssn-text ssn-input-box-padding"
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <input
          id="pin3_view"
          v-model="pin3"
          class="form-control ssn-text ssn-input-box-padding"
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <input
          id="pin4_view"
          v-model="pin4"
          class="form-control ssn-text ssn-input-box-padding"
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <span class="input-group-addon" style="vertical-align: super">
          <a v-on:click="switchVisibility">
            <i
              class="fa fa-eye-slash eye-icon"
              aria-hidden="true"
              v-if="!show"
            ></i>
            <i class="fa fa-eye eye-icon" aria-hidden="true" v-if="show"></i>
          </a>
        </span>
      </div>
      <div
        class="
          col-1
          eye-icon-div
          d-flex
          align-items-center
          justify-content-center
        "
      >
        <!-- <a v-on:click="switchVisibility">
          <i class="fa fa-eye-slash eye-icon" aria-hidden="true" v-if="!show"></i>
          <i class="fa fa-eye eye-icon" aria-hidden="true" v-if="show"></i>
        </a> -->
      </div>
      <input type="hidden" id="pin_current_state" value="0" />
    </div>
    <!-- label 2 -->
    <div class="row justify-content-center align-self-center">
      <div class="col-1"></div>
      <div class="col-10">
        <label class="reenter-digit-text-style">Re-enter 4 digit PIN</label>
      </div>
      <div class="col-1"></div>
    </div>
    <!-- pin row 2 -->
    <div class="row">
      <div class="col-1"></div>
      <div id="re_enter_pin_div" class="col-10 m-l-7">
        <input
          id="re_enter_pin1"
          ref="reenterpin1"
          autocomplete="off"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="1"
          inputmode="numeric"
        />
        <input
          id="re_enter_pin2"
          autocomplete="off"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          v-on:keyup="isNumber($event)"
          maxlength="1"
          inputmode="numeric"
        />
        <input
          id="re_enter_pin3"
          autocomplete="off"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          v-on:keyup="isNumber($event)"
          maxlength="1"
          inputmode="numeric"
        />
        <input
          id="re_enter_pin4"
          autocomplete="off"
          class="
            form-control
            ssn-text ssn-input-box-padding
            margin-pin-asterisk
          "
          v-on:keyup="isNumber($event)"
          @keyup.enter="clickLoginPin"
          maxlength="1"
          inputmode="numeric"
        />
        <span class="input-group-addon" style="vertical-align: super">
          <a v-on:click="switchReEnterVisibility">
            <i
              class="fa fa-eye-slash eye-icon"
              aria-hidden="true"
              v-if="!reshow"
            ></i>
            <i class="fa fa-eye eye-icon" aria-hidden="true" v-if="reshow"></i>
          </a>
        </span>
      </div>
      <div id="re_enter_pin_view_div" class="col-10 m-l-7 visibility">
        <input
          id="re_enter_pin1_view"
          v-model="re_enter_pin1"
          class="form-control ssn-text ssn-input-box-padding"
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <input
          id="re_enter_pin2_view"
          v-model="re_enter_pin2"
          class="form-control ssn-text ssn-input-box-padding"
          v-on:keyup="isNumber($event)"
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <input
          id="re_enter_pin3_view"
          v-model="re_enter_pin3"
          class="form-control ssn-text ssn-input-box-padding"
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <input
          id="re_enter_pin4_view"
          v-model="re_enter_pin4"
          class="form-control ssn-text ssn-input-box-padding"
          type="text"
          maxlength="1"
          inputmode="numeric"
          readonly
        />
        <span class="input-group-addon" style="vertical-align: super">
          <a v-on:click="switchReEnterVisibility">
            <i
              class="fa fa-eye-slash eye-icon"
              aria-hidden="true"
              v-if="!reshow"
            ></i>
            <i class="fa fa-eye eye-icon" aria-hidden="true" v-if="reshow"></i>
          </a>
        </span>
      </div>
      <div
        class="
          col-1
          eye-icon-div
          d-flex
          align-items-center
          justify-content-center
        "
      ></div>
      <input type="hidden" id="re_enter_pin_current_state" value="0" />
    </div>
    <div class="row justify-content-center align-self-center"><div class="col-1"></div><div class="col-10"><label class="pin-link-text-style" @click="showPinTextbox">Having trouble entering your PIN?</label></div><div class="col-1"></div></div>
    <!-- btn row -->
    <div class="row button-space">
      <div class="col-12 col-padding btn-space">
        <button type="button" class="btn-login" @click="clickLoginPin">
          Next
        </button>
      </div>
    </div>
    <!-- Validation modal -->
    <b-modal
      ref="validation-modal"
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      hide-header
      id="validation-modal"
      centered
    >
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              {{ error_message }}
            </label>
          </div>
          <br />
          <br />
          <div class="text-center">
            <button
              type="button"
              class="mx-auto col-10 offset-1 btn-black"
              style="height: 60px"
              @click="hidevalidationModal"
            >
              <label class="purchasepower-modal-ok-label">OK</label>
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- PIN Textbox modal -->
    <b-modal
      ref="pin-textbox-modal"
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      no-close-on-backdrop
      hide-header
      id="pin-textbox-modal"
      centered
    >
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div class="d-block"><label class="update-modal-title">Please enter a 4 digit PIN.</label></div>
          <div class="row"><div class="col-10 text-center"><input type="password" name="modal_pin" id="modal_pin" maxlength="4" v-model="modal_pin" class="form-control"></div><div class="col-2"><span class="input-group-addon" style="vertical-align: middle;" @click="togglePinInputType('modal_pin');"><a><i aria-hidden="true" :class="inputPinCls"></i></a></span></div></div>
          <div class="d-block"><label class="update-modal-title">Re-enter 4 digit PIN.</label></div>
          <div class="row"><div class="col-10 text-center"><input type="password" name="re_enter_modal_pin" id="re_enter_modal_pin" maxlength="4" v-model="re_enter_modal_pin" class="form-control"/></div><div class="col-2"><span class="input-group-addon" style="vertical-align: middle;" @click="togglePinInputType('re_enter_modal_pin');"><a><i aria-hidden="true" :class="inputPinCls1"></i></a></span></div></div>
          <div class="row justify-content-center mt-3">
            <div class="col-10 col-md-8 row">
              <div class="col-6">
                <button class="profile-btn-cancel w-100" @click="hidePinTextbox"><span class="forgetpassword-ok-label">Cancel</span></button>
              </div>
              <div class="col-6">
                <button class="profile-btn-ok w-100" @click="clickLoginPinModal"><span class="forgetpassword-ok-label">Next</span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "EnterQuickAccessPin",
  components: {},
  data() {
    return {
      show: false,
      error_message: "",
      reshow: false,
      loginPin: "",
      pin1: "",
      pin2: "",
      pin3: "",
      pin4: "",
      re_enter_pin1: "",
      re_enter_pin2: "",
      re_enter_pin3: "",
      re_enter_pin4: "",
      modal_pin: "",
      re_enter_modal_pin: "",
      inputPinCls: "fa fa-eye-slash onboarding-color-black",
      inputPinCls1: "fa fa-eye-slash onboarding-color-black"
    };
  },
  mounted: function () {
    let self = this;
    self.maskPin();
    self.maskReEnterPin();
  },
  methods: {
    showValidationModal(msg) {
      this.error_message = msg;
      this.$refs["validation-modal"].show();
    },
    hidevalidationModal() {
      this.error_message = "";
      this.$refs["validation-modal"].hide();
    },
    showPinTextbox(){
      this.inputPinCls = 'fa fa-eye-slash onboarding-color-black';
      this.inputPinCls1 = 'fa fa-eye-slash onboarding-color-black';
      this.$refs["pin-textbox-modal"].show();
    },
    hidePinTextbox() {
      this.modal_pin = "";
      this.re_enter_modal_pin = "";
      this.$refs["pin-textbox-modal"].hide();
    },
    togglePinInputType(id){
      var self = this;
      var inputType = document.getElementById(id);
      if (inputType.type === "password") {
        inputType.type = "text";
        if(id == 'modal_pin'){
          self.inputPinCls = 'fa fa-eye onboarding-color-black';
        }else{
          self.inputPinCls1 = 'fa fa-eye onboarding-color-black';
        }
      } else {
        inputType.type = "password";
        if(id == 'modal_pin'){
          self.inputPinCls = 'fa fa-eye-slash onboarding-color-black';
        }else{
          self.inputPinCls1 = 'fa fa-eye-slash onboarding-color-black';
        }
      }
    },
    maskPin() {
      var self = this;
      var ysp1 = new YouShallPass("*");
      var ysp2 = new YouShallPass("*");
      var ysp3 = new YouShallPass("*");
      var ysp4 = new YouShallPass("*");
      ysp1.fixIE9();
      ysp2.fixIE9();
      ysp3.fixIE9();
      ysp4.fixIE9();
      document
        .querySelector("#pin1")
        .addEventListener("input", ysp1.keyboardInputHandle.bind(ysp1));
      document.querySelector("#pin1").addEventListener("input", function () {
        self.pin1 = ysp1.realText;
      });
      document
        .querySelector("#pin2")
        .addEventListener("input", ysp2.keyboardInputHandle.bind(ysp2));
      document.querySelector("#pin2").addEventListener("input", function () {
        self.pin2 = ysp2.realText;
      });
      document
        .querySelector("#pin3")
        .addEventListener("input", ysp3.keyboardInputHandle.bind(ysp3));
      document.querySelector("#pin3").addEventListener("input", function () {
        self.pin3 = ysp3.realText;
      });
      document
        .querySelector("#pin4")
        .addEventListener("input", ysp4.keyboardInputHandle.bind(ysp4));
      document.querySelector("#pin4").addEventListener("input", function () {
        self.pin4 = ysp4.realText;
      });
    },
  maskReEnterPin() {
      var self = this;
      var ysp1 = new YouShallPass("*");
      var ysp2 = new YouShallPass("*");
      var ysp3 = new YouShallPass("*");
      var ysp4 = new YouShallPass("*");
      ysp1.fixIE9();
      ysp2.fixIE9();
      ysp3.fixIE9();
      ysp4.fixIE9();
      document
        .querySelector("#re_enter_pin1")
        .addEventListener("input", ysp1.keyboardInputHandle.bind(ysp1));
      document
        .querySelector("#re_enter_pin1")
        .addEventListener("input", function () {
          self.re_enter_pin1 = ysp1.realText;
        });
      document
        .querySelector("#re_enter_pin2")
        .addEventListener("input", ysp2.keyboardInputHandle.bind(ysp2));
      document
        .querySelector("#re_enter_pin2")
        .addEventListener("input", function () {
          self.re_enter_pin2 = ysp2.realText;
        });
      document
        .querySelector("#re_enter_pin3")
        .addEventListener("input", ysp3.keyboardInputHandle.bind(ysp3));
      document
        .querySelector("#re_enter_pin3")
        .addEventListener("input", function () {
          self.re_enter_pin3 = ysp3.realText;
        });
      document
        .querySelector("#re_enter_pin4")
        .addEventListener("input", ysp4.keyboardInputHandle.bind(ysp4));
      document
        .querySelector("#re_enter_pin4")
        .addEventListener("input", function () {
          self.re_enter_pin4 = ysp4.realText;
        });
    },
    switchVisibility() {
      var self = this;
      if ($("#pin_current_state").val() == 0) {
        $("#pin_current_state").val("1");
        self.show = true;
        $("#pin_div").addClass("visibility");
        $("#pin_view_div").removeClass("visibility");
      } else {
        $("#pin_current_state").val("0");
        self.show = false;
        $("#pin_view_div").addClass("visibility");
        $("#pin_div").removeClass("visibility");
      }
    },
    switchReEnterVisibility() {
      var self = this;
      if ($("#re_enter_pin_current_state").val() == 0) {
        $("#re_enter_pin_current_state").val("1");
        self.reshow = true;
        $("#re_enter_pin_div").addClass("visibility");
        $("#re_enter_pin_view_div").removeClass("visibility");
      } else {
        $("#re_enter_pin_current_state").val("0");
        self.reshow = false;
        $("#re_enter_pin_view_div").addClass("visibility");
        $("#re_enter_pin_div").removeClass("visibility");
      }
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
   isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var self = this;
      if (evt.keyCode == 8 || evt.keyCode == 46) {
        if (evt.target.previousElementSibling != null) {
          evt.target.previousElementSibling.focus();
        }
      } else {
        if (evt.target.nextElementSibling != null) {
          evt.target.nextElementSibling.focus();
        }
        if (evt.target.id === "pin4") {
          self.$refs.reenterpin1.focus();
        }
      }
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    clickLoginPin() {
      let self = this;
      const regex = /[0-9]/g;
      if (self.pin1 == "") {
        this.pin1_color = "red";
        this.pin2_color = "red";
        this.pin3_color = "red";
        this.pin4_color = "red";
        return false;
      } else if (self.pin2 == "") {
        this.pin1_color = "";
        this.pin2_color = "red";
        return false;
      } else if (self.pin3 == "") {
        this.pin1_color = "";
        this.pin2_color = "";
        this.pin3_color = "red";
        return false;
      } else if (self.pin4 == "") {
        this.pin1_color = "";
        this.pin2_color = "";
        this.pin3_color = "";
        this.pin4_color = "red";
        return false;
      } else if (self.re_enter_pin1 == "") {
        this.pin1_color = "";
        this.pin2_color = "";
        this.pin3_color = "";
        this.pin4_color = "";
        this.re_enter_pin1_color = "red";
        this.re_enter_pin2_color = "red";
        this.re_enter_pin3_color = "red";
        this.re_enter_pin4_color = "red";
        return false;
      } else if (self.re_enter_pin2 == "") {
        this.pin1_color = "";
        this.pin2_color = "";
        this.pin3_color = "";
        this.pin4_color = "";
        this.re_enter_pin1_color = "";
        this.re_enter_pin2_color = "red";
        return false;
      } else if (self.re_enter_pin3 == "") {
        this.pin1_color = "";
        this.pin2_color = "";
        this.pin3_color = "";
        this.pin4_color = "";
        this.re_enter_pin1_color = "";
        this.re_enter_pin2_color = "";
        this.re_enter_pin3_color = "red";
        return false;
      } else if (self.re_enter_pin4 == "") {
        this.pin1_color = "";
        this.pin2_color = "";
        this.pin3_color = "";
        this.pin4_color = "";
        this.re_enter_pin1_color = "";
        this.re_enter_pin2_color = "";
        this.re_enter_pin3_color = "";
        this.re_enter_pin4_color = "red";
        return false;
      } else if (
        self.pin1 + self.pin2 + self.pin3 + self.pin4 !==
        self.re_enter_pin1 +
          self.re_enter_pin2 +
          self.re_enter_pin3 +
          self.re_enter_pin4
      ) {
        this.pin1_color = "";
        this.pin2_color = "";
        this.pin3_color = "";
        this.pin4_color = "";
        this.re_enter_pin1_color = "";
        this.re_enter_pin2_color = "";
        this.re_enter_pin3_color = "";
        this.re_enter_pin4_color = "";
        self.showValidationModal("Pin does not match.");
        return false;
      } 
      else if (isNaN(self.pin1 + self.pin2 + self.pin3 + self.pin4)||(isNaN( self.re_enter_pin1 +
          self.re_enter_pin2 +
          self.re_enter_pin3 +
          self.re_enter_pin4))
      ) {

        self.showValidationModal("Pin Must be Numeric");
        return false;
      } 
      else {
        this.pin1_color = "";
        this.pin2_color = "";
        this.pin3_color = "";
        this.pin4_color = "";
        this.re_enter_pin1_color = "";
        this.re_enter_pin2_color = "";
        this.re_enter_pin3_color = "";
        this.re_enter_pin4_color = "";
        this.loginPin = self.pin1 + self.pin2 + self.pin3 + self.pin4;
        this.$root.$emit("loginPin", [self.loginPin]);
      }
    },
    clickLoginPinModal(){
      let self = this;
      if (self.modal_pin == "") {
        return false;
      } else if (self.re_enter_modal_pin == "") {
        return false;
      } else if (
        self.modal_pin !== self.re_enter_modal_pin
      ) {
        self.showValidationModal("Pin does not match.");
        return false;
      } 
      else if (isNaN(self.modal_pin)||(isNaN( self.re_enter_modal_pin))
      ) {
        self.showValidationModal("Pin Must be Numeric");
        return false;
      } else {
        this.$root.$emit("loginPin", [self.modal_pin]);
      }
    }
  },
};
</script>