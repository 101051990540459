<template>
  <div class="container">

    <div
      class="one mx-auto history-div"
      style="height: 1000px"
      v-if="terminalTypes.length && !showEnterTip"
    >
        <div class="row botton-gap"><div class="col-11 d-flex flex-column" style="margin: auto;"><button type="button" class="btn-black btn-verify" v-on:click="transactionExport()">Download Transaction Report</button></div></div>
      <div
        class="accordion accordian-style"
        id="accordionExample"
        v-for="(terminals, index) in terminalTypes"
        :key="index"
      >
        <div class="card side-black">
          <div
            class="card-header"
            id="headingTwo"
            style="
              margin-left: 10px;
              background-color: #ffffff;
              border-bottom: transparent !important;
            "
          >
            <a v-on:click="clickTransaction(terminals.id)">
              <div class="row">
                <label class="transaction-amount-heading"
                  >${{ transactionamount(index) }}</label
                >
              </div>
              <div class="row border-bottom h-25">
                <label class="store-name-label">{{
                  terminals.store_name
                }}</label>
              </div>
            </a>
            <div
              class="row h-25"
              style="margint-top: 5px; margint-bottom: 5px; margin-top: 5px"
            >
              <!----1-->
              <div class="col-10" style="padding-right: 0px; padding-left: 0px">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 90 112.5"
                  style="
                    enable-background: new 0 0 90 112.5;
                    margin-left: -8px;
                    margin-right: -10px;
                    float: left;
                  "
                  xml:space="preserve"
                  height="30"
                  width="30"
                  fill="#7f7f7f"
                >
                  <path
                    d="M63,25h-2v-2c0-1.1-0.9-2-2-2s-2,0.9-2,2v2H33v-2c0-1.1-0.9-2-2-2s-2,0.9-2,2v2h-2c-3.3,0-6,2.7-6,6v32c0,3.3,2.7,6,6,6h36
	            c3.3,0,6-2.7,6-6V31C69,27.7,66.3,25,63,25z M65,63c0,1.1-0.9,2-2,2H27c-1.1,0-2-0.9-2-2V31c0-1.1,0.9-2,2-2h2v2c0,1.1,0.9,2,2,2
	          c1.1,0,2-0.9,2-2v-2h24v2c0,1.1,0.9,2,2,2s2-0.9,2-2v-2h2c1.1,0,2,0.9,2,2V63z"
                  />
                  <path
                    d="M59,39H31c-1.1,0-2,0.9-2,2s0.9,2,2,2h28c1.1,0,2-0.9,2-2S60.1,39,59,39z"
                  />
                </svg>
                <!---2-->
                <label class="transaction-date">{{
                  transactiontime(index)
                }}</label>
              </div>
              <!---3-->
              <div
                class="col-2"
                style="float: right"
                data-toggle="collapse"
                :data-target="'#collapse' + index"
                aria-expanded="false"
                :aria-controls="'collapse' + index"
              >

              </div>
            </div>
          </div>


        </div>
      </div>
    </div>

    <div
      class="one mx-auto history-div"
      v-if="!terminalTypes.length && this.showloader && !showEnterTip"
    >
      <DefaultLoader :color="'#fffff'" />
      <br />
      <h3 style="color: #ffffff">Loading</h3>
    </div>
    <div
      class="one mx-auto history-div"
      v-if="!terminalTypes.length && !this.showloader"
    >
      <span class="success-text">No historical transactions to display.</span>
      <br />
      
    </div>
    <!-- As long as both v1 and v2 type of transactions are taking place from the PoS system tip feature will be disabled -->
    <!-- <div v-if="showEnterTip">
      <enter-tip :transactiondetails="transactiondetails"></enter-tip>
    </div> -->
  </div>
</template>
<script>
import api from "../api/transactiondetails.js";
import { DefaultLoader } from "vue-spinners-css";
import tip from "./Payment/Tip.vue";
import moment from "moment";
import HistoricalTransactionHeader from "./Layouts/Headers/HistoricalTransactionHeader.vue";
import { saveAs } from "file-saver";
export default {
  name: "HistoricalTransactionDetails",
  data() {
    return {
      showloader: false,
      showEnterTip: false,
      transactiondetails: {},
      setupterminalModel: {
        type: "",
        transaction_type_id: "",
        terminal_name: "",
        tip_amount: "",
        searchKeyword: "0.01",
      },
      terminalTypes: [],
    };
  },
  watch: {
    //the reason this code segment was added is when clicked into transaction details from tip page the page body used to remain white
    showEnterTip: function (newval, oldval) {
      if (newval == false) {
        document
          .getElementsByClassName("content-wrap")[0]
          .style.setProperty("background-color", "#149240");
      }
    },
  },
  created() {
    this.transactionHistory();
  },
  components: {
    DefaultLoader,
    "enter-tip": tip,
    "HistoricalTransactionHeader":HistoricalTransactionHeader
  },

  methods: {
    clickTransaction(transactionId) {
      let self = this;
      var header = {
        "Content-type": "application/json",
      };
      var request = {
        transaction_id: transactionId,
      };
      api
        .getparticularTransaction(request)
        .then((response) => {
          if (response.code == 200) {
            this.transactiondetails = response.data;
            // this.showEnterTip = true;
            self.showloader = false;
            self.$forceUpdate();
          } else {
            self.showloader = false;
          }
        })
        .catch(function (error) {
          self.showloader = false;
        });
    },
    transactionamount(data) {
      if (parseFloat(this.terminalTypes[data].tip_amount) !== 0.0) {
        this.tip_amount = (
          parseFloat(this.terminalTypes[data].amount) +
          parseFloat(this.terminalTypes[data].tip_amount)
        ).toFixed(2);
        return this.tip_amount;
      } else {
        this.tip_amount = parseFloat(this.terminalTypes[data].amount).toFixed(
          2
        );
        return this.tip_amount;
      }
    },
    transactiontime(data) {
      return moment
        .utc(this.terminalTypes[data].local_transaction_time)
        .local()
        .format(" DD MMM, YYYY | hh:m A");
    },
    transactionHistory: function () {
      this.showloader = true;
      var self = this;
      api
        .getHistoricalTransaction()
        .then(function (response) {
          if (response.code == 200) {
            self.terminalTypes = response.data;
            console.info(response.data);
            self.showloader = false;
          } else {
            alert(response.message);
            self.showloader = false;
          }
        })
        .catch(function (error) {
          self.showloader = false;
        });
    },
    searchedTransactionHistory(data) {
      let self = this;
      var header = {
        "Content-type": "application/json",
      };
      var request = {
        search_keyword: data,
      };
      api
        .searchHistoricaltransactionHistory(request)
        .then((response) => {
          if (response.code == 200) {
            self.terminalTypes = response.data;
            
            self.$forceUpdate();
            self.showloader = false;
          } else {
            self.showloader = false;
          }
        })
        .catch(function (error) {
          self.showloader = false;
        });
    },

    transactionExport()
    {
      this.showloader = true;
      var self = this;
      console.log(self.terminalTypes);
      var request = {
        report: self.terminalTypes,
      };
        api
        .exportHistoricalTransaction(request)
        .then((response) => {
         
         var FileSaver = require("file-saver");
          var blob = new Blob([response], {
            type: "application/xlsx",
          });
          FileSaver.saveAs(
            blob,
            moment().format("MM/DD/YYYY") +
              "_Historical_Transaction__Details.xlsx"
          );
          self.loading = false;

        })
        .catch(function (error) {
          self.showloader = false;
        });
    }
  },
  mounted() {
    var self = this;
    self.showEnterTip = false;
    var element = document.getElementsByClassName("content-wrap");
    if (element[0]) {
      element[0].style.setProperty("background-color", "#149240");
      element[0].style.height = "114vh";
      if(window.innerWidth>1200){
        element[0].style.height = "121vh";
      }
    }
    self.$root.$on("search-keyword", function (data) {
      self.showloader = true;
      if (data == "") {
        self.showloader = true;
        setTimeout(function () {
          self.transactionHistory();
        }, 3000);
      } else {
        setTimeout(function () {
          self.searchedTransactionHistory(data);
        }, 3000);
      }
    });

    self.$root.$emit("changeWhiteBackground", [
      false,
      false,
      "TransactionHistoryHeader",
    ]);

    self.$root.$on("TransactionHistory", function (data) {
      self.showEnterTip = false;
    });
  },
  beforeDestroy() {
    this.$root.$off("search-keyword");
  },
};
</script>
