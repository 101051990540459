

const forgotpasswordEmail = (request) => {
    return new Promise((res, rej) => {
        axios.defaults.headers.common["Authorization"] = "" 
        axios.post('forgotPassword/sendVerificationCode', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const verifyCode = (request) => {
    return new Promise((res, rej) => {
        axios.defaults.headers.common["Authorization"] = "" 
        axios.post('forgotPassword/verifyCode', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const changePIN = (request) => {
    return new Promise((res, rej) => {
        axios.defaults.headers.common["Authorization"] = "" 
        axios.post('forgotPassword/changepin', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

export default {
    forgotpasswordEmail,
    verifyCode,
    changePIN
};