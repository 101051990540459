<template>
  <div class="container splash">
    <cp-onboardingheader></cp-onboardingheader>

    <div class="col-12">
      <img
        class="duplicate-mobile-number-verification-image"
        src="../../assets/images/duplicate-mobole-number.png"
      />
    </div>
    <div class="col-12 register-phone-number-text">
      <label
        >The phone number you<br />
        entered <b> {{ phone }}</b> is <br />already associated with a<br />
        CanPay account
      </label>
    </div>
    <div class="col-12 primary-phone-number-registration-information">
      <label>
        Please enter an alternate phone<br />
        number to use to sign in to CanPay.
      </label>
    </div>

    <div class="col-12 primary-phone-number-label">
      <label>Enter phone number </label>
    </div>
    <div class="row input-box-row row-for-input">
      <div class="form-group no-border">
        <span class="form-control-icon">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="20"
            height="20"
            viewBox="0 0 91.9 92.4"
            style="enable-background: new 0 0 91.9 92.4"
            xml:space="preserve"
          >
            <path
              d="M88.7,66.9L77.3,55.4c-2-2.1-4.3-3.1-6.8-3.1s-4.9,1.1-6.9,3.1l-6.7,6.7c-0.6-0.3-1.2-0.6-1.8-0.9c-0.8-0.4-1.5-0.7-2.1-1.1
	c-6.2-3.9-11.8-9.1-17.2-15.7c-2.7-3.4-4.5-6.2-5.8-9.2c1.8-1.6,3.4-3.3,5-4.9c0.6-0.6,1.2-1.2,1.8-1.8c2.1-2.1,3.2-4.5,3.2-7
	s-1.1-4.9-3.2-7l-5.7-5.7c-0.7-0.7-1.3-1.3-1.9-2c-1.3-1.3-2.6-2.6-3.9-3.8c-2-2-4.3-3-6.8-3s-4.8,1-6.9,3l-7.1,7.3
	c-2.6,2.6-4.1,5.8-4.4,9.5c-0.4,4.6,0.5,9.4,2.7,15.3c3.3,9.1,8.4,17.5,15.9,26.5C27.8,72.4,38.7,81,51.2,87
	c4.8,2.3,11.1,4.9,18.2,5.4c0.4,0,0.9,0,1.3,0c4.7,0,8.7-1.7,11.8-5.1l0.1-0.1c1.1-1.3,2.3-2.5,3.7-3.8c0.9-0.9,1.8-1.8,2.7-2.7
	C92.9,76.5,92.9,71,88.7,66.9z M85.1,77.4c-0.8,0.9-1.6,1.7-2.5,2.5c-1.3,1.3-2.7,2.6-4,4.1c-2.2,2.3-4.7,3.4-8.1,3.4
	c-0.3,0-0.7,0-1,0c-6.2-0.4-12-2.8-16.4-4.9c-11.8-5.7-22.2-13.8-30.8-24.1c-7.1-8.5-11.9-16.5-15-25c-1.9-5.2-2.7-9.3-2.4-13.2
	c0.2-2.5,1.2-4.6,3-6.4L15,6.7c1.1-1,2.3-1.6,3.4-1.6s2.3,0.5,3.3,1.6c1.3,1.2,2.5,2.4,3.8,3.7c0.7,0.7,1.3,1.3,2,2l5.7,5.7
	c1.2,1.2,1.7,2.3,1.7,3.4s-0.6,2.3-1.7,3.4c-0.6,0.6-1.2,1.2-1.8,1.8c-1.8,1.8-3.4,3.5-5.2,5.1l-0.1,0.1c-1.6,1.6-1.4,3.2-1,4.4
	c0,0.1,0,0.1,0.1,0.2c1.5,3.5,3.5,6.9,6.7,10.9c5.7,7.1,11.8,12.5,18.4,16.8c0.8,0.5,1.7,1,2.5,1.4c0.8,0.4,1.5,0.7,2.1,1.1
	c0.1,0,0.1,0.1,0.2,0.1c0.7,0.3,1.3,0.5,1.9,0.5c1.6,0,2.6-1,2.9-1.4l7.1-7.1c1.1-1.1,2.2-1.6,3.4-1.6c1.4,0,2.5,0.9,3.3,1.6
	l11.5,11.5C88,73.2,86.7,75.7,85.1,77.4z"
            />
          </svg>
        </span>
        <input
          v-model="phoneNumber"
          class="form-control input-box-padding email-address"
          placeholder="Phone number"
          @keypress="isNumber($event)"
          @keyup.enter="submitPhoneNumber"
          type="pin"
          inputmode="numeric"
          name="phone"
          v-mask="'(###) ###-####'"
          v-bind:style="{ 'border-color': phoneNumberColor }"
        />
      </div>
    </div>
    <div class="row input-box-row row-for-input">
      <button
        type="button"
        class="btn-login btn-get-started"
        v-on:click="submitPhoneNumber"
      >
        Next
      </button>
    </div>

    <!---- MODAL FOR 0 Error Modal ---->

    <div>
      <b-modal
        ref="zero-pp-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="zero-pp-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="col-12 text-center">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="120"
                height="120"
                viewBox="0 0 100 125"
                style="enable-background: new 0 0 100 125"
                xml:space="preserve"
                fill="#e14343"
                class="on-boarding-upgrade-alert-icon"
              >
                <path
                  d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
                />
              </svg>
            </div>
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                {{ pp_alert_message }}
              </label>
            </div>
            <br />
            <br />
            <div class="text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="hideModal('zero-pp-modal')"
              >
                <label class="purchasepower-modal-ok-label">OK</label>
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import OnboardingHeader from "./OnboardingHeader.vue";
import api from "../../api/onboarding.js";
import constants from "../Common/constant.js";
export default {
  /**
   * write a component's description
   * this component is use to Upgrade to the new canpay the user's email as a consumer
   */
  name: "UpgradeCanpayWithEmail",
  /**
   * @description-
   * phoneNumberColor => use to validate the phoneno field
   * @returns {any}
   */

  data() {
    let self = this;
    return {
      phoneNumberColor: "",
      phoneNumber: "",
      existingPhone: "",
      phone: "",
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      pp_alert_message: "",
    };
  },

  components: {
    "cp-onboardingheader": OnboardingHeader,
  },
  destroyed() {
    document
      .getElementById("app")
      .style.setProperty("background-color", "#149240");
  },
  created() {},
  mounted() {
    this.$root.$emit("show_header", false);
    this.$root.$emit("changeWhiteBackground", [true, false, ""]);
    document
      .getElementById("app")
      .style.setProperty("background-color", "#ffffff");

    var element = document.getElementsByClassName("wrapper");
    element[0].style.setProperty("background-color", "#ffffff");
    this.phone = localStorage.getItem("phone");
    this.existingPhone = localStorage.getItem("existing_phone");
    var elementHtml = document.getElementsByTagName("html")[0];
    elementHtml.style.setProperty("background-color", "#ffffff");
  },
  methods: {
    showModal(modal) {
      this.$refs[modal].show();
    },
    hideModal(modal) {
      this.$refs[modal].hide();
    },
    addEvent() {
      this.phoneNumberColor = "";
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    submitPhoneNumber: function () {
      let self = this;
      self.phoneNumber = self.phoneNumber.replace(/[^a-zA-Z0-9]/g, "");
      if (self.phoneNumber == "") {
        self.phoneNumberColor = "red";
        self.pp_alert_message = RequiredField;
        this.showModal("zero-pp-modal");

        return false;
      } else {
        if (this.existingPhone == 1) {
          var request = {
            phone: self.phoneNumber.replace(/[^a-zA-Z0-9]/g, ""),
            session_id: localStorage.getItem("sessionId"),
            phone_checking_required: 1,
          };
        } else {
          var request = {
            phone: self.phoneNumber.replace(/[^a-zA-Z0-9]/g, ""),
            session_id: localStorage.getItem("sessionId"),
            phone_checking_required: 0,
          };
        }

        api
          .DuplicateMobileNumber(request)
          .then((response) => {
            if (response.code == 200) {
              // self.makeidvalidation();
              if (this.existingPhone == 1) {
                localStorage.removeItem("existing_phone");
                localStorage.setItem("phone", this.phoneNumber);
                this.$router.push("/phonenumberverificationcode");
              } else {
                localStorage.removeItem("existing_phone");
                localStorage.setItem("phone", this.phoneNumber);
                this.$router.push("/primaryphonenumberregistrationsuccess");
              }
            }
          })
          .catch((err) => {
            if (err.response.data.code == 599) {
              if (this.existingPhone == 1) {
                localStorage.setItem("phone", this.phone);
                localStorage.setItem("existing_phone", 1);
                this.$router.push("/duplicatemobilenumber");
              } else {
                localStorage.setItem("phone", this.phone);
                localStorage.setItem("existing_phone", 0);
                this.$router.push("/duplicatemobilenumber");
              }
            } else {
              self.pp_alert_message = err.response.data.message;
              this.showModal("zero-pp-modal");
            }
          });
      }
    },
  },
};
</script>
<style lang="scss">
#zero-pp-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
</style>
