<template>
  <div class="container">
    <div class="row input-box-row" v-if="!emailsentFlag">
      <a
        class="nav-link"
        style="text-align: left; margin-left: -15px"
        v-on:click="clickBack"
      >
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100 125"
          style="
            enable-background: new 0 0 100 125;
            margin-left: -10px !important;
          "
          xml:space="preserve"
          fill="#ffffff"
          height="30"
          width="40"
        >
          <g id="Layer_2" />
          <g id="Layer_1">
            <path
              d="M12.1,47.1C12.1,47.1,12.1,47.1,12.1,47.1l29.5-29.5c1.6-1.6,4.2-1.6,5.8,0c1.6,1.6,1.6,4.2,0,5.8L24.9,45.9h61.8
		c2.3,0,4.1,1.8,4.1,4.1s-1.8,4.1-4.1,4.1H24.9l22.5,22.5c1.6,1.6,1.6,4.2,0,5.8c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2
		L12.1,52.9c0,0,0,0,0,0L9.2,50L12.1,47.1z"
            />
          </g>
        </svg>
      </a>
    </div>
    <div class="row emailsent-top-space" v-if="emailsentFlag"></div>

    <div class="row forgot-password-top-row margin-lock-icon">
      <!--Login to use CanPay-->
      <div class="col-2"></div>
      <div class="col-8">
        <span class="text-center">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 80 100.5"
            style="enable-background: new 0 0 90 112.5"
            xml:space="preserve"
            height="80"
            width="100"
            fill="#ffffff"
            v-if="!emailsentFlag"
          >
            <g>
              <path
                d="M76.2,56.2h-17v-22h-9.4v-7.9c0-11.2-9.1-20.2-20.2-20.2S9.4,15.2,9.4,26.4v7.9H0v40.5h28.1c1.9,5.3,7,9.1,12.9,9.1h35.1
		C83.8,83.8,90,77.6,90,70S83.8,56.2,76.2,56.2z M12.4,26.4c0-9.5,7.7-17.2,17.2-17.2s17.2,7.7,17.2,17.2v7.9H12.4V26.4z M3,71.8
		V37.3h53.2v19H41.1c-2.2,0-4.3,0.5-6.1,1.4L34.4,54c1-1.1,1.5-2.6,1.5-4.1c0-3.5-2.8-6.3-6.3-6.3s-6.3,2.8-6.3,6.3
		c0,1.5,0.5,3,1.5,4.1l-1.5,9.6l1.5,1.7h3.3c-0.5,1.5-0.8,3-0.8,4.7c0,0.6,0,1.1,0.1,1.7C27.4,71.7,3,71.7,3,71.8z M29.6,62.4h-3.1
		l1.3-8.6l-0.5-1.4c-0.7-0.6-1.1-1.5-1.1-2.5c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3c0,0.9-0.4,1.8-1.1,2.5l-0.5,1.3l0.9,5.7
		C31.2,60.3,30.3,61.3,29.6,62.4z M76.2,80.8H41.1c-6,0-10.8-4.8-10.8-10.8s4.8-10.8,10.8-10.8h35.1c6,0,10.8,4.8,10.8,10.8
		S82.2,80.8,76.2,80.8z"
              />
              <path
                d="M44.9,67.1c-1.6,0-2.9,1.3-2.9,2.9s1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9S46.5,67.1,44.9,67.1z"
              />
              <path
                d="M54.1,67.1c-1.6,0-2.9,1.3-2.9,2.9s1.3,2.9,2.9,2.9S57,71.6,57,70S55.7,67.1,54.1,67.1z"
              />
              <circle cx="63.2" cy="70" r="2.9" />
              <path
                d="M72.3,67.1c-1.6,0-2.9,1.3-2.9,2.9s1.3,2.9,2.9,2.9s2.9-1.3,2.9-2.9S73.9,67.1,72.3,67.1z"
              />
            </g>
          </svg>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 -20 105 125"
            style="
              margin-left: -6px;
              enable-background: new 0 0 100 125;
              fill: none;
              stroke: #ffffff;
              stroke-width: 2;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
            xml:space="preserve"
            height="120"
            width="120"
            fill="#ffffff"
            v-if="emailsentFlag"
          >
            <path
              class="st0"
              d="M33,28.4h56.9c2.5,0,4.6,2,4.6,4.6v35.2c0,2.5-2,4.6-4.6,4.6H33c-2.5,0-4.6-2-4.6-4.6V33
	C28.4,30.4,30.5,28.4,33,28.4z"
            />
            <path
              class="st0"
              d="M91.2,31.5L65.7,57c-2.4,2.4-6.2,2.4-8.5,0L31.6,31.4"
            />
            <line class="st0" x1="72.5" y1="50.6" x2="91.2" y2="69.3" />
            <line class="st0" x1="31.6" y1="69.2" x2="50.2" y2="50.6" />
            <line class="st0" x1="5.7" y1="41.3" x2="19.6" y2="41.3" />
            <line class="st0" x1="9.1" y1="50.6" x2="19.6" y2="50.6" />
            <line class="st0" x1="13.6" y1="59.8" x2="19.6" y2="59.8" />
          </svg>
        </span>
      </div>
      <div class="col-2"></div>
    </div>
    <div class="row input-box-row label-margin">
      <!--Login to use CanPay-->
      <div class="col-1"></div>
      <div class="col-10">
        <span class="text-center">
          <label class="forgot-pin-text-style" v-if="!emailsentFlag">
            Forgot Your
            <br />Quick Access PIN?
          </label>
          <label class="forgot-pin-text-style" v-if="emailsentFlag"
            >Thanks!</label
          >
        </span>
      </div>
      <div class="col-1"></div>
    </div>
    <div class="row input-box-row label-margin">
      <!--Login to use CanPay-->
      <div class="col-12 forgot-password-description-label-styel">
        <span class="text-center">
          <label class="forgpt-pin-desc" v-if="!emailsentFlag">
            Enter the email associated with your
            <br />CanPay account and we’ll send the <br />instructions to reset
            your PIN
          </label>
          <label class="forgpt-pin-desc" v-if="emailsentFlag">
            If there is an account associated with
            <br />this email, we’ll send the Quick <br />Access PIN reset
            instructions
          </label>
        </span>
      </div>
    </div>
    <div class="row input-box-row input-email-row" v-if="!emailsentFlag">
      <div
        v-bind:class="[
          error == true ? 'form-group red-border' : ' form-group no-border',
        ]"
      >
        <span class="form-control-icon">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 32 40"
            style="enable-background: new 0 0 32 40"
            xml:space="preserve"
            height="20"
            width="20"
          >
            <g>
              <g>
                <path
                  d="M30,28H2c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1h28c0.6,0,1,0.4,1,1v22C31,27.6,30.6,28,30,28z M3,26h26V6H3V26z"
                />
              </g>
              <g>
                <path
                  d="M16,20.4c-0.2,0-0.4-0.1-0.5-0.2l-14-9C1.2,11.1,1,10.7,1,10.4V5c0-0.6,0.4-1,1-1h28c0.6,0,1,0.4,1,1v5.4
			c0,0.3-0.2,0.7-0.5,0.8l-14,9C16.4,20.4,16.2,20.4,16,20.4z M3,9.9l13,8.4l13-8.4V6H3V9.9z"
                />
              </g>
            </g>
          </svg>
        </span>
        <input
          v-model="email"
          inputmode="email"
          type="text"
          class="form-control input-box-padding"
          placeholder="Email"
          v-bind:style="{ 'border-color': emailColor }"
          @change="addEvent"
          @input="addEvent"
        />
      </div>
    </div>
    <div class="error-div-style" v-if="error">
      <label class="error-text-style">{{ error_msg }}</label>
    </div>

    <div class="row emailsent-bottom-space" v-if="emailsentFlag"></div>

    <div class="row input-box-row">
      <!-- <button type="button" class="btn-login" v-on:click="submitEmail">
        OK
      </button> -->
      <TimerValidation
      class="w-100"
      v-model="timeLeft"
      label="OK"
      counter-label="SEND AFTER"
      :onClick="submitEmail"
      />
    </div>
    <!-- V1 onboarding modal -->
    <div>
      <b-modal
        ref="onboarding-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        no-close-on-backdrop
        id="onboarding-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="row">
              <div class="col-12 text-center">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="120"
                  height="120"
                  viewBox="0 0 100 125"
                  style="enable-background: new 0 0 100 125"
                  xml:space="preserve"
                  fill="#e14343"
                >
                  <path
                    d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
                  />
                </svg>
              </div>
            </div>
            <div class="d-block text-center">
              <label class="success-popup-style onboarding-modal-message">{{
                modalMessage
              }}</label>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <button
                type="button"
                class="btn-login btn-get-started"
                @click="updateAccount"
              >
                Update Account
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import api from "../../api/forgotpassword.js";
import TimerValidation from './TimerValidation.vue'
/**
 * write a component's description
 * this component is use to login the user as a consumer
 */
export default {
  name: "ForgotPassword",
  /**
   * @description-
   * pinColor => use to validate the password field
   * emailColor => use to validate the phoneno field
   * token => use to store the user's token
   * @returns {any}
   */
  data() {
    let self = this;
    return {
      emailsentFlag: false,
      modalMessage: "",
      error: false,
      error_msg: "Invalid Email Format",
      email: "",
      password: "",
      show: false,
      passwordtype: "password",
      showbuttontitle: "Show",
      emailColor: "",
      pinColor: "",
      token: "",
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      timeLeft: null,
      timer: null
    };
  },
  components:{
    TimerValidation
  },
  mounted() {
    this.$root.$emit("changeWhiteBackground", [false, true, "common"]);
    this.$root.$emit("show_header", true);
    var el = document.getElementsByClassName("wrapper");
    if (el.length != 0) {
      el[0].style.setProperty("background-color", "#149240");
    }
  },
  methods: {
    /**
     * @description-
     * This function is use to call the login api
     * @returns {login rsponse}
     */
    addEvent() {
      this.error = false;
      this.emailColor = "";
    },
    clickBack() {
      this.$router.push("/login");
    },

    isEmailValid: function () {
      return this.email == ""
        ? ""
        : this.reg.test(this.email)
        ? "has-success"
        : "has-error";
    },
    submitEmail() {
      this.error = false
      if (this.emailsentFlag == true) {
        this.$router.push("/enterforgotpin");
      } else {
        let self = this;
        if (self.email.length == 0) {
          self.emailColor = "red";
        } else if (!self.reg.test(self.email)) {
          this.error = true;
          this.error_msg = "Invalid Email Format";
        } else {
          self.emailColor = "";
          self.forgotPasswordEmailSend();
        }
      }
    },

    forgotPasswordEmailSend() {
      let self = this;
      var request = {
        email: self.email,
      };
      api
        .forgotpasswordEmail(request)
        .then((response) => {
          localStorage.setItem("verify_token", response.data.token);
          self.emailsentFlag = true;
        })
        .catch(function (error) {
          if (error.response.data.data == "is_v1") {
            self.modalMessage = error.response.data.message;
            self.$refs["onboarding-modal"].show();
            return false;
          }
          if(error.response.data.data != null && error.response.data.data.time_left){
            self.timeLeft = error.response.data.data.time_left
            self.timer = error.response.data.data.time_left
          }
          self.emailColor = "red";
          self.error = true;
          self.error_msg = error.response.data.message;
        });
    },
    updateAccount() {
      this.$router.push("/upgradecanpaywithemail");
    },
  },
  watch: {
    timer: {
      handler(val) {
          if (val > 0) {
              setTimeout(() => {
                  this.timer--;
              }, 1000);
          }else{
              this.error = false
          }
      },
      immediate: false, // This ensures the watcher is triggered upon creation
    },
  }
};
</script>

<style>
::placeholder {
  color: rgb(0, 0, 0);
  opacity: 1;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
}
#pay-modal-center___BV_modal_content_ {
  border-radius: 10px;
  margin: 10px;
  background-color: #ffffff;
}
#pay-modal-center___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#onboarding-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
</style>
