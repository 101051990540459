<template>
  <div class="row">
    <div class="col-6 align-self-center">
      <svg
        @click="clickBack"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 125"
        style="
          enable-background: new 0 0 100 125;
          margin-left: -0.4rem;
          width: 2rem;
        "
        xml:space="preserve"
        class="back-btn-onboarding-header cursor-pointer float-left"
      >
        <g id="Layer_2" />
        <g id="Layer_1">
          <path
            d="M12.1,47.1C12.1,47.1,12.1,47.1,12.1,47.1l29.5-29.5c1.6-1.6,4.2-1.6,5.8,0c1.6,1.6,1.6,4.2,0,5.8L24.9,45.9h61.8
		c2.3,0,4.1,1.8,4.1,4.1s-1.8,4.1-4.1,4.1H24.9l22.5,22.5c1.6,1.6,1.6,4.2,0,5.8c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2
		L12.1,52.9c0,0,0,0,0,0L9.2,50L12.1,47.1z"
          />
        </g>
      </svg>
    </div>
    <div class="col-6">
      <img
        style="width: 7rem; margin-right: -0.7rem"
        class="float-right"
        src="../../assets/images/canpay-logo.png"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "OnboardingHeader",
  props: {
    msg: String,
  },
  data() {
    let self = this;
    return {
      priviousPage: "",
      pageHistry: [],
    };
  },
  methods: {
    clickBack() {
      var param;
      var lastParam;
      param = window.location.href.split("/");
      lastParam = param.pop();

      if (lastParam == "termsandcondition") {
        this.$router.go(-2);
      } else if (lastParam == "existingphonenumberverificationsuccess") {
        this.$router.go(-2);
      } else if (lastParam == "uploadconsumerdocument") {
        this.$router.go(-2);
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
