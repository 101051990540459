const commonConstants = {
    
    //registration steps
    email_validate: 1,
    enter_phone: 2,
    enter_quick_access_pin: 3,
    bank_details_from_ach_or_finicity_or_manual: 4,
};


export default commonConstants;