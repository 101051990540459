<template>
  <div class="container">
          <div class="no-account-top-space"></div>

    <div class="row row-gap">
      <div class="col-12 text-center">
        <span class="user-block">
          <img
            src="../../assets/images/user.png"
            class="rounded mx-auto d-block imgframe"
          />
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <label class="usernamelabel">
          Hello,
          {{
            currentUser.first_name.charAt(0).toUpperCase() +
              currentUser.first_name.slice(1)
          }}!
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-1"></div>
      <div class="col-10">
        <label class="purchasepower-box-title"
          >Complete your account setup to get started</label
        >
      </div>
      <div class="col-1"></div>
    </div>
    <div class="no-account-bottom-space"></div>
    <div class="row btn-gap">
      <div class="col-1"></div>
      <div class="col-10">
        <button type="button" class=" btn-verify">
          Connect Your Bank Account
        </button>
      </div>
      <div class="col-1"></div>
    </div>
  </div>
</template>
<script>
import api from "../../api/payment.js";

export default {
  name: "InCompleteAccount",
  methods: {
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    clickPayNow() {
      this.getLastTransactionList();
    },
    getPurchasePower: function() {
      var self = this;
      api
        .getPurchasePower()
        .then(function(response) {
          if (response.code == 200) {
            self.nopurchasepower = false;
            self.purchasepower = "$" + response.data;
          } else if (response.code == 401) {
            this.$router.push("/login");
          } else {
            self.nopurchasepower = true;
            self.purchasepower = response.message;
          }
        })
        .catch(function(error) {
        });
    },
    getLastTransactionList: function() {
      var self = this;
      api
        .getLastTransaction()
        .then(function(response) {
          if (response.code == 200) {
            if (response.data == null) {
              self.$router.push("/dashboard");
            } else {
              self.getConfirmationForQRCode();
            }
          } else if (response.code == 401) {
            this.$router.push("/login");
          } else {
            self.nopurchasepower = true;
            self.purchasepower = response.message;
          }
        })
        .catch(function(error) {
        });
    },

    getConfirmationForQRCode() {
      var retVal = confirm(
        "You have made one transaction 5 minutes ago. Do you want to continue?"
      );
      if (retVal == true) {
        this.$router.push("/dashboard");
        return true;
      } else {
        return false;
      }
    },
  },

  data() {
    let self = this;
    return {
      purchasepower: "",
      currentUser: {},
      nopurchasepower: false,
    };
  },
  created() {
    this.currentUser = localStorage.getItem("consumer_login_response")
      ? JSON.parse(localStorage.getItem("consumer_login_response"))
      : null;

    this.$root.$emit("loginapp", [""]);
    // this.getPurchasePower();
  },
  mounted() {
    var element = document.getElementsByClassName("content-wrap");
    element[0].style.setProperty("background-color", "#149240");
    element[0].style.height = "114vh";
      if(window.innerWidth>1200){
        element[0].style.height = "121vh";
      }
    this.$root.$emit("loginapp", [""]);
    this.$root.$emit("changeWhiteBackground", [false, true, "common"]);
  },
};
</script>

<style lang="scss">
.b-button {
  background-color: transparent;
}
#pay-modal-center___BV_modal_content_ {
  border-radius: 10px;
  margin: 10px;
  background-color: #ffffff;
}
#pay-modal-center___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
</style>
