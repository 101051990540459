<template>
  <div class="container">
    <div class="row login-row">
      <!--Login to use CanPay-->
      <!-- <div class="col-2"></div> -->
      <div class="col-12">
        <span class="text-center">
          <label class="welcome-text-style">Welcome to CanPay</label>
        </span>
      </div>
      <!-- <div class="col-2"></div> -->
    </div>
    <!-- Input textbox for Phone Number-->
    <div class="row input-box-row">
      <div class="col-12 login-col">
        <div class="form-group">
          <span class="form-control-icon">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 72 90"
              style="enable-background: new 0 0 72 90"
              xml:space="preserve"
              height="20"
              width="20"
            >
              <path
                d="M48,70H24c-5.5,0-10-4.5-10-10V12c0-5.5,4.5-10,10-10h24c5.5,0,10,4.5,10,10v48C58,65.5,53.5,70,48,70z M24,6
    c-3.3,0-6,2.7-6,6v48c0,3.3,2.7,6,6,6h24c3.3,0,6-2.7,6-6V12c0-3.3-2.7-6-6-6H24z"
              />
              <path
                d="M40,14h-8c-1.1,0-2-0.9-2-2s0.9-2,2-2h8c1.1,0,2,0.9,2,2S41.1,14,40,14z"
              />
              <circle cx="36" cy="56.4" r="4" />
            </svg>
          </span>
          <input
            v-model="phoneNumber"
            autocomplete="off"
            class="form-control login-input-box-padding"
            placeholder="Mobile Number"
            style="height: 50px"
            @keypress="isNumber($event)"
            type="pin"
            inputmode="numeric"
            name="phone"
            v-mask="'(###) ###-####'"
            v-bind:style="{ 'border-color': phoneNumberColor }"
          />
        </div>
      </div>
    </div>

    <!-- Input textbox for Password-->
    <div class="row input-box-row mt-3">
      <div class="col-12 login-col">
        <div class="form-group">
          <span class="form-control-icon mli-4">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 125"
              style="enable-background: new 0 0 100 125"
              xml:space="preserve"
              height="30"
              width="30"
            >
              <path
                d="M77.1,81.4H38.5c-4.8,0-8.6-3.9-8.6-8.6v-6.7c0-4.8,3.9-8.6,8.6-8.6h38.6c4.8,0,8.6,3.9,8.6,8.6v6.7
    C85.8,77.5,81.9,81.4,77.1,81.4z M38.5,62c-2.3,0-4.1,1.9-4.1,4.1v6.7c0,2.3,1.9,4.1,4.1,4.1h38.6c2.3,0,4.1-1.9,4.1-4.1v-6.7
    c0-2.3-1.9-4.1-4.1-4.1H38.5z"
              />
              <circle cx="43" cy="69.4" r="3.1" />
              <circle cx="52.5" cy="69.4" r="3.1" />
              <circle cx="62" cy="69.4" r="3.1" />
              <path
                d="M71.6,66.3c1.7,0,3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1c-1.7,0-3.1-1.4-3.1-3.1C68.4,67.7,69.8,66.3,71.6,66.3z"
              />
              <path
                d="M43.4,43.5c-1.2,0-2.3-1-2.3-2.3v-9.9c0-4.5-3.7-8.2-8.2-8.2s-8.2,3.7-8.2,8.2v9.9c0,1.2-1,2.3-2.3,2.3s-2.3-1-2.3-2.3v-9.9
    c0-7,5.7-12.7,12.7-12.7s12.7,5.7,12.7,12.7v9.9C45.7,42.5,44.7,43.5,43.4,43.5z"
              />
              <path
                d="M25.5,72.4h-4.8c-3.6,0-6.5-2.9-6.5-6.5V45.5c0-3.6,2.9-6.5,6.5-6.5h25.8c3.6,0,6.5,2.9,6.5,6.5v11.4c0,1.2-1,2.3-2.3,2.3
    s-2.3-1-2.3-2.3V45.5c0-1.1-0.9-2-2-2H20.7c-1.1,0-2,0.9-2,2v20.4c0,1.1,0.9,2,2,2h4.8c1.2,0,2.3,1,2.3,2.3S26.8,72.4,25.5,72.4z"
              />
            </svg>
          </span>
          <input
            id="password"
            class="form-control login-input-box-padding"
            autocomplete="off"
            inputmode="numeric"
            placeholder="Quick Access PIN"
            type="text"
            style="height: 50px"
            maxlength="4"
            minlength="4"
            @keypress="isNumber($event)"
            v-bind:style="{ 'border-color': pinColor }"
          />
          <input
            type="text"
            v-model="password"
            class="form-control login-input-box-padding"
            name="password_view"
            id="password_view"
            style="height: 50px"
            maxlength="4"
            minlength="4"
            @keypress="isNumber($event)"
            v-bind:style="{ 'border-color': pinColor }"
            inputmode="numeric"
            placeholder="Quick Access PIN"
            readonly
          />
          <div class="icon-inside-input-right">
            <a v-on:click="switchVisibility">
              <i class="fa fa-eye-slash" aria-hidden="true" v-if="!eyeshow"></i>
              <i class="fa fa-eye" aria-hidden="true" v-if="eyeshow"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--Login Button-->

    <div class="row input-box-row">
      <div class="col-12 login-col">
        <button type="button" class="btn-login" v-on:click="login" :disabled="isButtonDisabled">
          Log In
        </button>
      </div>
    </div>

    <div class="row input-box-row">
      <div class="col-7 remember-me-row-style">
        <div class="checkbox">
          <input
            type="checkbox"
            id="checkbox"
            v-model="remember_me"
            value
            style="display: none"
          />
          <label class="remember-me-checkbox" for="checkbox">
            Remember Me
          </label>
        </div>

        <span v-show="remember_me" class="">
          <svg
            @click="openInfoModal"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 125"
            style="
              enable-background: new 0 0 100 125;
              margin-top: 2px;
              float: left;
            "
            xml:space="preserve"
            width="15"
            height="25"
            fill="#ffffff"
          >
            <title>A__64</title>
            <path
              d="M50,5C25.1,5,5,25.1,5,50s20.1,45,45,45s45-20.1,45-45C95,25.2,74.8,5,50,5z M50,87c-20.4,0-37-16.6-37-37s16.6-37,37-37
                  s37,16.6,37,37C87,70.4,70.4,87,50,87z M45,25c0-2.8,2.2-5,5-5s5,2.2,5,5s-2.2,5-5,5S45,27.8,45,25z M61,70.4V73c0,1.1-0.9,2-2,2H41
                  c-1.1,0-2-0.9-2-2v-2.6c0-0.9,0.6-1.6,1.4-1.9l4.1-1.3c0.3-0.1,0.5-0.4,0.5-0.7V46h-3.1c-1.6,0-2.9-1.3-2.9-2.9c0-1.2,0.8-2.3,2-2.7
                  l11.4-3.6c1.1-0.3,2.2,0.3,2.5,1.3c0.1,0.2,0.1,0.4,0.1,0.6v27.8c0,0.3,0.2,0.6,0.5,0.7l4.1,1.3C60.4,68.7,61,69.5,61,70.4z"
            />
          </svg>
        </span>
      </div>
      <div class="col-5 forgotpin-me-row-style">
        <div class="form-check float-right">
          <label class="remember-me-checkbox" v-on:click="clicforgotPin">
            Forgot PIN?</label
          >
        </div>
      </div>
    </div>
    <!-- <div class="align-items-end fixed-bottom position-sticky"> -->
    <div class="row input-box-row">
      <!--Button for Create/Manage Account-->
      <div class="col-12 login-col login-top-panel-margin">
        <button type="button" class="btn-login-signup" v-on:click="register">
          Sign Up
        </button>
      </div>
      <div
        class="col-12 login-bottom-panel-margin login-top-panel-margin"
        v-on:click="clickRelocator"
      >
        <span class="text-center">
          <label class="login-locator-text-style"
            ><svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 291 488.8"
              style="enable-background: new 0 0 291 488.8; margin-top: 2px"
              xml:space="preserve"
              height="20"
              width="30"
              fill="white"
            >
              <g>
                <path
                  d="M146,72c40,0,73,33,73,74c0,40-33,73-73,73c-41,0-74-33-74-73C72,105,105,72,146,72z M146,93c-29,0-53,24-53,53
		c0,28,24,52,53,52c28,0,52-24,52-52C198,117,174,93,146,93z"
                />
                <path
                  d="M146,21C77,21,21,77,21,146c0,19,7,40,16,57c21,46,48,91,74,134l13,21c10,16,33,16,43,0l11-19c26-43,53-87,74-131
		c9-19,18-41,18-62C270,77,214,21,146,21z M146,0c80,0,145,65,145,146c0,24-9,49-20,71c-22,45-48,90-75,133l-11,19
		c-18,29-61,29-79,0l-13-21c-26-44-54-90-76-136c-9-20-17-44-17-66C0,65,65,0,146,0z"
                />
              </g></svg>Store Locator</label
          >
        </span>
      </div>
    </div>
    <!-- v2 update  modal -->
    <!-----------------------  MODAL FOR UPDATE  !---------------->

    <div>
      <b-modal
        ref="email-success-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        no-close-on-backdrop
        id="email-success-modal"
        centered
        title="BootstrapVue"
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="pin-success-top-spacing"></div>
            <div class="row" style="margin-bottom: 5px">
              <div class="col-12 text-center">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="120"
                  height="120"
                  viewBox="0 0 100 125"
                  style="enable-background: new 0 0 100 125"
                  xml:space="preserve"
                  fill="#e14343"
                >
                  <path
                    d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
                  />
                </svg>
              </div>
            </div>
            <div class="d-block text-center">
              <label class="success-popup-style onboarding-modal-message">{{
                modalMessage
              }}</label>
            </div>
            <div class="success-bottom-spacing"></div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <button
                type="button"
                class="btn-login btn-get-started"
                @click="updateAccount"
              >
                Update Account
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>

    <!-- MODAL FOR INFO -->
    <div>
      <b-modal
        ref="info-modal"
        hide-footer
        modal-backdrop
        hide-header
        no-close-on-backdrop
        id="info-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="row">
              <div class="col-12 text-center">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 100 125"
                  xml:space="preserve"
                  width="100"
                  height="100"
                  fill="#149240"
                >
                  <title>A__64</title>
                  <path
                    d="M50,5C25.1,5,5,25.1,5,50s20.1,45,45,45s45-20.1,45-45C95,25.2,74.8,5,50,5z M50,87c-20.4,0-37-16.6-37-37s16.6-37,37-37
                  s37,16.6,37,37C87,70.4,70.4,87,50,87z M45,25c0-2.8,2.2-5,5-5s5,2.2,5,5s-2.2,5-5,5S45,27.8,45,25z M61,70.4V73c0,1.1-0.9,2-2,2H41
                  c-1.1,0-2-0.9-2-2v-2.6c0-0.9,0.6-1.6,1.4-1.9l4.1-1.3c0.3-0.1,0.5-0.4,0.5-0.7V46h-3.1c-1.6,0-2.9-1.3-2.9-2.9c0-1.2,0.8-2.3,2-2.7
                  l11.4-3.6c1.1-0.3,2.2,0.3,2.5,1.3c0.1,0.2,0.1,0.4,0.1,0.6v27.8c0,0.3,0.2,0.6,0.5,0.7l4.1,1.3C60.4,68.7,61,69.5,61,70.4z"
                  />
                </svg>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 text-center">
                <label class="success-popup-style"
                  >When you select "Remember Me," your login phone number will
                  be saved so you just have to enter you Quick Access PIN each
                  time to quickly login.
                </label>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="mx-auto btn-black"
                style="height: 60px"
                @click="hideInfoModal"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal
        ref="validation-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="validation-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                {{ error_message }}
              </label>
            </div>
            <br />
            <div class="text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="hidevalidationModal"
              >
                <label class="purchasepower-modal-ok-label">OK</label>
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import api from "../../api/login.js";
import pay from "../../api/payment.js";
import moment from "moment";
/**
 * write a component's description
 * this component is use to login the user as a consumer
 */
export default {
  name: "Login",
  /**
   * @description-
   * phoneNumber => this will take the consumer's phone no
   * phoneNumber => this will take the consumer's password
   * pinColor => use to validate the password field
   * phoneNumberColor => use to validate the phoneno field
   * token => use to store the user's token
   * @returns {any}
   */
  data() {
    let self = this;
    return {
      remember_me: false,
      error_message: "",
      eyeshow: false,
      email: "",
      phoneNumber: "",
      password: "",
      passwordIcon: "fa fa-eye",
      show: false,
      passwordtype: "password",
      showbuttontitle: "Show",
      phoneNumberColor: "",
      pinColor: "",
      token: "",
      modalMessage: "",
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      isButtonDisabled: false
    };
  },
  created() {
    if (localStorage.getItem("consumer_token") !== null) {
      localStorage.removeItem("consumer_token");
      localStorage.removeItem("consumer_login_response");
    }
    this.$root.$emit("loginapp", ["hi"]);
  },
  mounted() {
    var self = this;
    $("#password_view").hide();
    this.$root.$emit("show_header", true);
    var e1 = document.getElementById("app");
    if (e1) {
      e1.style.setProperty("background-color", "#149240");
    }
    var e2 = document.getElementsByClassName("wrapper");
    if (e2[0]) {
      e2[0].style.setProperty("background-color", "#149240");
    }
    if (localStorage.getItem("remember_me")) {
      self.remember_me = localStorage.getItem("remember_me");
      self.phoneNumber = localStorage.getItem("remember_phone");
    }
    this.$root.$emit("changeWhiteBackground", [false, true, "common"]);
    var ysp = new YouShallPass("*");
    ysp.fixIE9();
    document
      .querySelector("#password")
      .addEventListener("input", ysp.keyboardInputHandle.bind(ysp));
    document.querySelector("#password").addEventListener("input", function () {
      self.password = ysp.realText;
    });
    localStorage.removeItem("phone");
    localStorage.removeItem("email");
    if(localStorage.getItem("microbilt_error_need_bank_link") != null){
      localStorage.removeItem("microbilt_error_need_bank_link");
    }
  },
  methods: {
    showValidationModal(msg) {
      this.error_message = msg;
      this.$refs["validation-modal"].show();
    },
    hidevalidationModal() {
      this.error_message = "";
      this.$refs["validation-modal"].hide();
    },
    switchVisibility() {
      var self = this;
      if (!self.eyeshow) {
        $("#password").hide();
        $("#password_view").show();
        self.eyeshow = true;
      } else {
        $("#password_view").hide();
        $("#password").show();
        self.eyeshow = false;
      }
    },
    addEvent() {
      this.phoneNumberColor = "";
    },
    clicforgotPin() {
      this.$router.push("/forgotpassword");
    },
    clickRelocator() {
      this.$router.push("/locateretailer");
    },
    register() {
      this.$router.push("/registration");
    },
    showModal() {
      this.email = "";
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    openInfoModal() {
      this.$refs["info-modal"].show();
    },
    hideInfoModal() {
      this.$refs["info-modal"].hide();
    },
    isEmailValid: function () {
      return this.email == ""
        ? ""
        : this.reg.test(this.email)
        ? "has-success"
        : "has-error";
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    submitEmail() {
      let self = this;
      if (self.email.length == 0) {
        self.phoneNumberColor = "red";
      } else if (self.isEmailValid() == "has-error") {
        self.showValidationModal("Please enter valid email address.");
      } else {
        self.phoneNumberColor = "";
        self.forgotPasswordEmailSend(this.email);
      }
    },

    login: function () {
      let self = this;
      self.phoneNumber = self.phoneNumber.replace(/[^a-zA-Z0-9]/g, "");
      if (self.phoneNumber == "") {
        self.phoneNumberColor = "red";
        self.showValidationModal(RequiredField);
        return false;
      } else if (self.password == "") {
        self.pinColor = "red";
        self.showValidationModal(RequiredField);
        return false;
      } else {
        var request = {
          phone: self.phoneNumber,
          password: self.password,
        };
        if (self.phoneNumber == "") {
          self.phoneNumberColor = "red";
          return false;
        }
        if (self.password == "") {
          self.pinColor = "red";
          return false;
        }

        localStorage.removeItem("sessionId");
        localStorage.removeItem("session_data");
        localStorage.removeItem("consumer_token");
        localStorage.removeItem("skip_current_banking_solution");
        localStorage.removeItem('show_pp_animation');
        self.phoneNumberColor = "";
        self.pinColor = "";
        self.isButtonDisabled = true;
        api
          .loginUser(request)
          .then((response) => {
            self.isButtonDisabled = false;
            self.phoneNumberColor = "";
            self.pinColor = "";
            self.token = response.data.token;
            localStorage.removeItem("consumer_token");
            localStorage.removeItem("consumer_login_response");
            localStorage.removeItem("microbilt_error_need_bank_link");
            localStorage.removeItem("returnApiTimeStamp");
            localStorage.setItem("check_bank", true);
            localStorage.setItem("last_successful_login_id", response.data.last_successful_login_id);
            localStorage.setItem("refresh_balance_call_required", response.data.refresh_balance_call_required);
            localStorage.setItem('show_pp_animation',1);
            localStorage.setItem("consumer_token", response.data.token);
            localStorage.setItem("returnApiTimeStamp",new Date());
            localStorage.setItem(
              "consumer_login_response",
              JSON.stringify(response.data)
            );
            const event = new CustomEvent('rwStateCahnged', {detail: response.data});
            document.dispatchEvent(event);
            if (response.data.login) {
              localStorage.setItem("overlay_show", true);
            }
            if (self.remember_me) {
              localStorage.setItem("remember_me", self.remember_me);
              localStorage.setItem(
                "remember_phone",
                self.phoneNumber.replace(/[^a-zA-Z0-9]/g, "")
              );
            }
            if (response.data.admin_driven_bank_link == 1 && response.data.microbilt_upload_document_show == 1) {
              localStorage.setItem("show_upload_documents_modal", true);
            } else {
              if (localStorage.getItem("show_upload_documents_modal")) {
                localStorage.removeItem("show_upload_documents_modal");
              }
            }
            var redirectTo = '/pay';
            if(this.$route.query.redirect) {
              redirectTo = self.$route.query.redirect;
            }
            self.$router.push(redirectTo);
          })
          .catch(function (error) {
            self.isButtonDisabled = false;
            if (error.response.data.code == 308) {
              if (error.response.data.data.steps_completed == null) {
                self.showValidationModal(error.response.data.message);
                return false;
              }
              if (error.response.data.data.microbilt_error_need_bank_link == true) {
                localStorage.setItem("microbilt_error_need_bank_link", true);
              } else {
                localStorage.removeItem("microbilt_error_need_bank_link");
              }
              //from backend if it returns 308 meaning a registration session exists with pin set
              localStorage.setItem(
                "session_exists",
                error.response.data.data.session_id
              ); // store a flag to indicate a session exists for phone number
              localStorage.setItem(
                "session_step",
                error.response.data.data.steps_completed
              );
              localStorage.setItem(
                "session_exists_phone",
                self.phoneNumber.replace(/[^a-zA-Z0-9]/g, "")
              );
              self.$router.push("/registration"); //redirect to registration
            } 
            else if(error.response.data.code == 598)
            {
               self.$router.push("/otp-verification/"+btoa(error.response.data.data.email)+"/"+btoa(error.response.data.data.phone));
            }
            else {
              if (error.response.data.data == "is_v1") {
                self.modalMessage = error.response.data.message;
                self.showUpdateModal();
              } else {
                self.showValidationModal(error.response.data.message);
              }
            }
          });
      }
    },

    showHidePassword: function () {
      if (this.passwordtype == "text") {
        this.passwordtype = "password";
        this.showbuttontitle = "Show";
        this.show = false;
      } else {
        this.passwordtype = "text";
        this.showbuttontitle = "Hide";
        this.show = true;
      }
    },

    forgotPasswordEmailSend(email) {
      let self = this;

      var header = {
        "Content-type": "application/json",
      };
      var request = {
        email: self.email,
      };
      api
        .forgotpasswordEmail(request)
        .then((response) => {
          if (response.code == 200) {
            self.hideModal();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    updatePurchasePower: function (user) {
      var self = this;
      // check if consumer is existing user(v1 consumer) and this is their first login
      var last_login_date = localStorage.getItem("last_login_date");
      var today = moment().local().format('YYYY-MM-DD');
      if (user.existing_user == 1 && today != last_login_date) {
        localStorage.setItem("last_login_date", today);
        pay
          .updatePurchasePower()
          .then(function (response) {
            self.$router.push("/pay");
          })
          .catch(function (err) {
            self.$router.push("/pay");
          });
      }else{
        self.$router.push("/pay");
      }
    },

    showUpdateModal() {
      var self = this;
      this.$refs["email-success-modal"].show();
    },

    updateAccount() {
      this.$router.push("/upgradecanpaywithemail");
    },
  },
};
</script>

<style lang="scss">
.b-button {
  background-color: transparent;
}

#pay-modal-center___BV_modal_content_ {
  border-radius: 10px;
  margin: 10px;
  background-color: #ffffff;
}
#pay-modal-center___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}

.content-wrap {
  overflow: visible;
  background-color: #149240;
}
::placeholder {
  color: rgb(0, 0, 0);
  opacity: 1;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
}
#pay-modal-center___BV_modal_content_ {
  border-radius: 10px;
  margin: 10px;
  background-color: #ffffff;
}
#pay-modal-center___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}

#info-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}

.icon-inside-input-right {
  position: absolute;
  top: 12px;
  right: 10px;
}

[type="checkbox"] {
  vertical-align: middle;
}
</style>
